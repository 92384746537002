import React from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import Icon from '../../../Icon';

const MarkButton = ({ format, icon, toggleMark, isMarkActive }) => {
  const editor = useSlate();

  const handleClick = (event) => {
    event.preventDefault();
    toggleMark(editor, format);
  };

  return (
    <button
      type="button"
      className={`edit-control-btn ${isMarkActive(editor, format) ? 'active' : ''}`}
      onMouseDown={handleClick}
    >
      <Icon icon={icon} />
    </button>
  );
};

MarkButton.propTypes = {
  format: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  toggleMark: PropTypes.func.isRequired,
  isMarkActive: PropTypes.func.isRequired,
};

export default MarkButton;
