import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setNewPasswordValidationSchema } from './validators';
import Heading from '../../../../../../components/Heading';
import Input from '../../../../../../components/Input';
import Icon from '../../../../../../components/Icon';
import '../../../../LogInPage.scss';

const SetNewPasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        password: '',
        confirm_password: '',
      }}
      validationSchema={setNewPasswordValidationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className="additional-page--form">
          <Heading
            subtitle="SetNewPasswordPage.heading.subtitle"
            title="SetNewPasswordPage.heading.title"
            position="center"
          />
          <Input
            type="password"
            name="password"
            label={t('SetNewPasswordPage.SetNewPasswordForm.password.label')}
            placeholder={t('SetNewPasswordPage.SetNewPasswordForm.password.placeholder')}
            hint={t('SetNewPasswordPage.SetNewPasswordForm.password.hint')}
            errors={errors}
            touched={touched}
          />
          <Input
            type="password"
            name="confirm_password"
            label={t('SetNewPasswordPage.SetNewPasswordForm.confirmPassword.label')}
            placeholder={t('SetNewPasswordPage.SetNewPasswordForm.confirmPassword.placeholder')}
            errors={errors}
            touched={touched}
          />
          <button type="submit" className="btn btn-lg btn-primary">
            {t('SetNewPasswordPage.SetNewPasswordForm.buttons.submit')}
          </button>
          <div className="form--footer">
            <Link to="/auth" className="link link-gray link-md">
              <Icon icon="arrow-left" className="link--icon" />
              {t('SetNewPasswordPage.SetNewPasswordForm.link')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SetNewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SetNewPasswordForm;
