import React from 'react';
import './NoMessageItem.scss';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';

const NoMessageItem = () => {
  const { t } = useTranslation();

  return (
    <div className="no-message">
      <div className="no-message--icon">
        <Icon icon="chatSearch" />
      </div>
      <p className="no-message--title">{t('ChatPage.messages.noMessages.title')}</p>
      <p className="no-message--subtitle">{t('ChatPage.messages.noMessages.subtitle')}</p>
    </div>
  );
};

export default NoMessageItem;
