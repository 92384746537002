import { createAction } from '@reduxjs/toolkit';
import { asyncActionCreator } from 'global/redux';
import * as constants from './constants';

export const signUpUserAction = asyncActionCreator(constants.SIGN_UP_USER);
export const confirmEmailAction = asyncActionCreator(constants.CONFIRM_EMAIL);
export const getFormValuesAction = asyncActionCreator(constants.GET_FORM_VALUES);
export const updateUserAction = asyncActionCreator(constants.UPDATE_USER);
export const confirmSocialAction = asyncActionCreator(constants.CONFIRM_SOCIAL_ACCOUNT);

export const changeSignUpStatusAction = createAction(constants.CHANGE_SIGN_UP_STATUS);

export default signUpUserAction;
