/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncInitialState, asyncReducerHandler } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  userFeedbacks: asyncInitialState,
  userStats: asyncInitialState,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getFeedbacksByIdAction, 'userFeedbacks'),
  ...asyncReducerHandler(actions.getStatsByIdAction, 'userStats'),
  [actions.postFeedbackAction.ERROR]: (state, action) => {
    state.error = action.payload;
    state.loading = false;
  },
});

const userFeedbacksData = (state) => state.feedbacksReducer.userFeedbacks.data;
const userFeedbacksLoading = (state) => state.feedbacksReducer.userFeedbacks.loading;

const userStatsData = (state) => state.feedbacksReducer.userStats.data;
const userStatsLoading = (state) => state.feedbacksReducer.userStats.loading;

const selectors = {
  userFeedbacksData,
  userFeedbacksLoading,
  userStatsData,
  userStatsLoading,
};

export { selectors, reducer, initialState };
export default reducer;
