import { put, all, takeLatest, call } from 'redux-saga/effects';
import * as actions from './actions';
import SavedListService from './service';

export function* getProfilesDataSaga({ payload }) {
  try {
    const responses = yield all(payload.map((id) => call(SavedListService.getProfileBuId, id)));

    const mappedData = responses.map((response) => response.data.data);

    yield put(actions.getSavedListProfilesDataAction.success(mappedData));
  } catch (error) {
    yield put(actions.getSavedListProfilesDataAction.error(error));
  }
}

export function* deleteProfileSaga({ payload }) {
  try {
    const response = yield call(SavedListService.deleteProfileById, payload);

    yield put(actions.deleteSavedListProfileAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.deleteSavedListProfileAction.error(error));
  }
}

export function* savedListPageWatcherSaga() {
  yield all([takeLatest(actions.getSavedListProfilesDataAction.REQUEST, getProfilesDataSaga)]);
  yield all([takeLatest(actions.deleteSavedListProfileAction.REQUEST, deleteProfileSaga)]);
}
