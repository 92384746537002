import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { selectors } from './reducer';
import { postInstagramCodeAction, postInstagramUserTokenAction } from './actions';
import './AddInstagramAccountPage.scss';

const AddInstagramAccountPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [accountList, setAccountList] = useState(null);
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const redirectUrl = `https://bupnetwork.com${location.pathname}`;
  // const redirectUrl = `http://localhost:3000${location.pathname}`;

  const data = {
    code,
    redirectUrl,
  };

  const instagramData = useSelector(selectors.instagramData);
  const instagramToken = instagramData?.data?.token;

  const getFacebookAccountListHandler = () => {
    axios
      .get(`https://graph.facebook.com/v15.0/me/accounts?access_token=${instagramToken}`)
      .then((response) => {
        setAccountList(response.data.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const postInstagramUserTokenHandler = (id, token) => {
    const payload = { id, token };
    dispatch(postInstagramUserTokenAction.request({ data: payload }));
    // alert('Account is add');
  };

  useEffect(() => {
    dispatch(postInstagramCodeAction.request({ data }));
  }, [code]);

  useEffect(() => {
    if (instagramToken && instagramToken !== '') {
      getFacebookAccountListHandler();
    }
  }, [instagramToken]);

  return (
    <div className="addAccount">
      <div className="text">
        <h2>Connect your account</h2>
        <p>No worries, we’ll send you reset instructions.</p>
      </div>
      {/* {instagramToken && ( */}
      {/*  <Button className="get" onClick={() => getFacebookAccountListHandler()}> */}
      {/*    Get account */}
      {/*  </Button> */}
      {/* )} */}
      <div className="accountList">
        {accountList ? (
          accountList?.map((item) => (
            <div className="line" key={item.id}>
              <h4>{item.name}</h4>
              <button
                type="button"
                className="btn btn-md btn-primary"
                onClick={() => postInstagramUserTokenHandler(item.id, item.access_token)}
              >
                Add Account
              </button>
            </div>
          ))
        ) : (
          <div className="line">
            <h2>Haven't any accounts to connect</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddInstagramAccountPage;
