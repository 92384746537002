import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeAlertAction } from '../../pages/InitComponent/actions';
import ALERT_TYPES from './constants';
import './Alert.scss';

const Alert = ({ id, type, message }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const alertIcons = {
    [ALERT_TYPES.error]: (
      <svg
        className="alert-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0003 6.66663V9.99996M10.0003 13.3333H10.0087M18.3337 9.99996C18.3337 14.6023 14.6027 18.3333 10.0003 18.3333C5.39795 18.3333 1.66699 14.6023 1.66699 9.99996C1.66699 5.39759 5.39795 1.66663 10.0003 1.66663C14.6027 1.66663 18.3337 5.39759 18.3337 9.99996Z"
          stroke="#D92D20"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    [ALERT_TYPES.warning]: (
      <svg className="alert-icon" width="20" height="18" viewBox="0 0 20 18" fill="none">
        <path
          d="M9.99965 6.66668V10M9.99965 13.3333H10.008M8.57465 2.38334L1.51632 14.1667C1.37079 14.4187 1.29379 14.7044 1.29298 14.9954C1.29216 15.2865 1.36756 15.5726 1.51167 15.8254C1.65579 16.0783 1.86359 16.289 2.11441 16.4366C2.36523 16.5841 2.65032 16.6635 2.94132 16.6667H17.058C17.349 16.6635 17.6341 16.5841 17.8849 16.4366C18.1357 16.289 18.3435 16.0783 18.4876 15.8254C18.6317 15.5726 18.7071 15.2865 18.7063 14.9954C18.7055 14.7044 18.6285 14.4187 18.483 14.1667L11.4247 2.38334C11.2761 2.13843 11.0669 1.93594 10.8173 1.79541C10.5677 1.65488 10.2861 1.58105 9.99965 1.58105C9.71321 1.58105 9.43159 1.65488 9.18199 1.79541C8.93238 1.93594 8.72321 2.13843 8.57465 2.38334Z"
          stroke="#DC6803"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    [ALERT_TYPES.success]: (
      <svg className="alert-icon" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M18.3337 9.2333V9.99997C18.3326 11.797 17.7507 13.5455 16.6748 14.9848C15.5988 16.4241 14.0864 17.477 12.3631 17.9866C10.6399 18.4961 8.79804 18.4349 7.11238 17.8121C5.42673 17.1894 3.98754 16.0384 3.00946 14.5309C2.03138 13.0233 1.56682 11.24 1.68506 9.4469C1.80329 7.65377 2.498 5.94691 3.66556 4.58086C4.83312 3.21482 6.41098 2.26279 8.16382 1.86676C9.91665 1.47073 11.7505 1.65192 13.392 2.3833M18.3337 3.3333L10.0003 11.675L7.50033 9.17497"
          stroke="#039855"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  };

  const handleCloseAlert = () => {
    dispatch(removeAlertAction(id));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleCloseAlert();
    }, 5000);

    return () => clearTimeout(timeout);
  });

  const translatedMessage = t(`alerts.${message}`);

  return (
    <div className={`alert-container alert-${type}`} onClick={handleCloseAlert}>
      <div className="alert--icon-container">{alertIcons[type]}</div>
      <span className="alert-message">
        {translatedMessage === message ? message : translatedMessage}
      </span>
    </div>
  );
};

Alert.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Alert;
