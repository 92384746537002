import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectors } from '../../reducer';
import {
  changePasswordResetStatusAction,
  checkEmailVerificationCodeAction,
  resetUserPasswordAction,
} from '../../actions';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import CheckEmailForm from './components/CheckEmailForm';
import { getFromLocalStorage, setToLocalStorage } from '../../../../global/helpers';
import { RESET_PASSWORD_STEPS } from '../../constants';

const CheckEmailPage = () => {
  const dispatch = useDispatch();

  const resetPasswordStatus = useSelector(selectors.resetPasswordStatus);
  const resetPasswordData = useSelector(selectors.resetPasswordData);

  const navigate = useNavigate();
  const [params] = useSearchParams();

  const code = params.get('code');
  const email = params.get('email');

  useEffect(() => {
    if (resetPasswordStatus !== RESET_PASSWORD_STEPS.requestSent && (!code || !email)) {
      navigate('/auth');
    }

    return () => dispatch(changePasswordResetStatusAction(null));
    // eslint-disable-next-line
  }, []);

  const checkCodePostEffect = (token) => {
    dispatch(changePasswordResetStatusAction(RESET_PASSWORD_STEPS.passwordChanging));
    setToLocalStorage('reset_password_step', null);
    setToLocalStorage('reset_password_email', null);
    navigate(`/auth/password-reset?token=${token}`);
  };

  const handleCheckCode = (data) => {
    dispatch(
      checkEmailVerificationCodeAction.request({
        data: {
          email: resetPasswordData?.email || getFromLocalStorage('reset_password_email'),
          ...data,
        },
        onSuccess: checkCodePostEffect,
      }),
    );
  };

  const handleResendCode = (emailResend) => {
    dispatch(
      resetUserPasswordAction.request({
        data: { emailResend },
      }),
    );
  };

  useEffect(() => {
    if (email && code) {
      handleCheckCode({
        email,
        code,
      });
    }
  }, []);

  return (
    <div className="additional-page--wrapper check-email-page--container">
      <Header />
      <CheckEmailForm onSubmit={handleCheckCode} onResend={handleResendCode} />
      <Footer />
    </div>
  );
};

export default CheckEmailPage;
