import * as yup from 'yup';

export const forgotPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('ForgotPasswordPage.ForgotPasswordForm.email.validation.email')
    .required('ForgotPasswordPage.ForgotPasswordForm.email.validation.required'),
});

export default forgotPasswordValidationSchema;
