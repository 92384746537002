import * as yup from 'yup';
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectors } from '../../reducer';
import { getUsersBySearchAction } from '../../actions';
import Avatar from '../../../../components/Avatar';
import Input from '../../../../components/Input';
// import WidgetHeaderDashboard from './components/WidgetHeaderDashboard';
import './HeaderDashboard.scss';
import SearchResults from './components/SearchResults';
// import { selectors as chatSelectors } from '../../../ChatPage/reducer';

const HeaderDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const newMessagesCount = useSelector(chatSelectors.newMsgCount);

  const location = useLocation();

  const userData = useSelector(selectors.userProfileData);
  // const notificationsData = useSelector(selectors.notificationsData);

  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const handleSearchKeyDown = (event, value) => {
    if (event.key === 'Enter' && value.length >= 3) {
      dispatch(getUsersBySearchAction.request(value));
    }
  };

  const handleSearchClickedIn = () => {
    setIsSearchVisible(true);
  };

  const handleSearchClickedOut = () => {
    setIsSearchVisible(false);
  };

  return (
    <header className="header-dashboard">
      <Formik
        initialValues={{
          searchQuery: '',
        }}
        validationSchema={yup.object().shape({
          searchQuery: yup
            .string()
            .min(3, 'Search must be longer than or equal to 3 characters')
            .notRequired(),
        })}
        onSubmit={() => {}}
      >
        {({ values, errors, touched }) => (
          <Form className="header-dashboard--form">
            {location.pathname !== '/search/company' && location.pathname !== '/search/influencer' && (
              <div className="from--search-container">
                <Input
                  icon="lens"
                  className="header-dashboard--form-input"
                  type="text"
                  name="searchQuery"
                  placeholder={t('HeaderDashboard.input')}
                  errors={errors}
                  touched={touched}
                  onKeyDown={(event) => handleSearchKeyDown(event, values.searchQuery)}
                  onClick={handleSearchClickedIn}
                  onBlur={handleSearchClickedOut}
                />
                <SearchResults values={values} isSearchVisible={isSearchVisible} />
              </div>
            )}
          </Form>
        )}
      </Formik>
      <div className="header-dashboard--right-side">
        {/* <button type="button" className="btn btn-xs btn-secondary-gray mr-25"> */}
        {/*  {t('HeaderDashboard.button')} */}
        {/* </button> */}
        {/* <div className="vidgets-container mr-25"> */}
        {/*  <WidgetHeaderDashboard */}
        {/*    messages={newMessagesCount} */}
        {/*    notifications={notificationsData?.length > 0 ? notificationsData.length : null} */}
        {/*  /> */}
        {/* </div> */}
        <Link to={`/profile/${userData?.id}`} className="avatar-link">
          <Avatar size="md" file={userData?.cover} />
        </Link>
      </div>
    </header>
  );
};

export default HeaderDashboard;
