/* eslint-disable global-require */
import ApiService from 'global/services/api';
import { mockImport } from 'global/helpers';
import { isMockChatPage } from '../../global/services/isMockEnabled';

const getAllChatsMock = mockImport(
  () => require('../../__mocks__/ChatService/getAllChats.mock.json'),
  isMockChatPage,
);

const createChatMock = mockImport(
  () => require('../../__mocks__/ChatService/createChat.mock.json'),
  isMockChatPage,
);

const getChatMessagesMock = mockImport(
  () => require('../../__mocks__/ChatService/getChatMessages.mock.json'),
  isMockChatPage,
);

const getNewMsgCountMock = mockImport(
  () => require('../../__mocks__/ChatService/getNewMsgCount.mock.json'),
  isMockChatPage,
);

export class ChatService extends ApiService {
  createChat = async (data) =>
    this.post(
      {
        url: 'chats',
        body: {
          participant: {
            id: data,
          },
        },
      },
      createChatMock,
    );

  getAllChats = async () => this.get({ url: 'chats' }, getAllChatsMock);

  getChatMessages = async (id) => this.get({ url: `chats/${id}/messages` }, getChatMessagesMock);

  getNewMsgCount = async () => this.get({ url: 'chats/messages/count/new' }, getNewMsgCountMock);
}

export default new ChatService();
