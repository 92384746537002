import React from 'react';
import PropTypes from 'prop-types';

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    // eslint-disable-next-line no-param-reassign
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    // eslint-disable-next-line no-param-reassign
    children = <em>{children}</em>;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <span {...attributes}>{children}</span>;
};

Leaf.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  leaf: PropTypes.shape({
    italic: PropTypes.bool,
    bold: PropTypes.bool,
  }).isRequired,
};

Leaf.defaultProps = {
  children: null,
};

export default Leaf;
