import { takeLatest, all, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import ProfileService from './service';

export function* getProfileByIdSaga({ payload }) {
  try {
    const responseProfile = yield call(ProfileService.getProfileById, payload.id);
    const responseFeedback = yield call(ProfileService.getLastFeedbackById, payload.id);
    const responseFriendStatusRequested = yield call(ProfileService.getFriendStatus, {
      id: payload.id,
      status: 'REQUESTED',
    });
    const responseFriendStatusApproved = yield call(ProfileService.getFriendStatus, {
      id: payload.id,
      status: 'APPROVED',
    });
    const responseFollowStatus = yield call(ProfileService.getFollowStatus, payload.userId);

    let friendStatus = [];
    responseFriendStatusRequested.data.data.result.forEach((el) => {
      if (el.initiator.id === payload.userId && el.acceptor.id === payload.id) {
        friendStatus = [el.status, el.id];
      } else if (el.acceptor.id === payload.userId && el.initiator.id === payload.id) {
        friendStatus = ['REQUESTED_OTHER', el.id];
      }
    });
    responseFriendStatusApproved.data.data.result.forEach((el) => {
      if (
        (el.initiator.id === payload.userId && el.acceptor.id === payload.id) ||
        (el.acceptor.id === payload.userId && el.initiator.id === payload.id)
      ) {
        friendStatus = [el.status, el.id];
      }
    });

    let followStatus = [];
    responseFollowStatus.data.data.result.forEach((el) => {
      if (el.initiator.id === payload.userId && el.acceptor.id === payload.id) {
        followStatus = [el.status, el.id];
      }
    });

    yield put(
      actions.getProfileByIdAction.success({
        ...responseProfile.data.data,
        lastFeedback: responseFeedback.data.data.result[0],
        feedbacksTotal: responseFeedback.data.data.total,
        friendStatus,
        followStatus,
      }),
    );
  } catch (error) {
    yield put(actions.getProfileByIdAction.error(error));
  }
}

export function* getProfileFeedByIdSaga({ payload }) {
  try {
    const response = yield call(ProfileService.getProfileFeedById, payload);

    yield put(actions.getProfileFeedByIdAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.getProfileFeedByIdAction.error(error));
  }
}

export function* postLikeSaga({ payload }) {
  try {
    yield call(ProfileService.postLikePost, payload);
    yield put(actions.addLikeToPostByIdAction.success(payload));
  } catch (error) {
    yield put(actions.addLikeToPostByIdAction.error(error));
  }
}

export function* deleteLikeSaga({ payload }) {
  try {
    yield call(ProfileService.deleteLikePost, payload);
    yield put(actions.deleteLikeToPostByIdAction.success(payload));
  } catch (error) {
    yield put(actions.deleteLikeToPostByIdAction.error(error));
  }
}

export function* createProfilePostSaga({ payload }) {
  try {
    const fileResponses = yield all(
      payload.data.files.map((file) => {
        const formData = new FormData();
        formData.append('file', file);

        return call(ProfileService.fileUpload, formData);
      }),
    );

    yield call(ProfileService.createNewPost, {
      ...payload.data,
      files: fileResponses.map((response) => ({
        id: response.data.data.id,
      })),
    });

    yield put(actions.addProfilePostAction.success());
    yield payload.onSuccess();
  } catch (error) {
    yield put(actions.addProfilePostAction.error(error));
  }
}

export function* followUserSaga({ payload }) {
  try {
    const response = yield call(ProfileService.followUser, payload.id);

    yield call(getProfileByIdSaga, { payload });
    yield put(actions.followUserAction.success(response));
  } catch (error) {
    yield put(actions.followUserAction.error(error));
  }
}

export function* unfollowUserSaga({ payload }) {
  try {
    const response = yield call(ProfileService.unfollowUser, payload.requestId);

    yield call(getProfileByIdSaga, { payload });
    yield put(actions.unfollowUserAction.success(response));
  } catch (error) {
    yield put(actions.unfollowUserAction.error(error));
  }
}

export function* addFriendSaga({ payload }) {
  try {
    const response = yield call(ProfileService.addFriend, payload.id);

    yield call(followUserSaga, { payload });
    yield put(actions.addFriendAction.success(response));
  } catch (error) {
    yield put(actions.addFriendAction.error(error));
  }
}

export function* addFriendBackSaga({ payload }) {
  try {
    const response = yield call(ProfileService.addFriendBack, payload.requestId);

    yield call(followUserSaga, { payload });
    yield put(actions.addFriendBackAction.success(response));
  } catch (error) {
    yield put(actions.addFriendBackAction.error(error));
  }
}

export function* profilePageWatcherSaga() {
  yield all([
    takeLatest(actions.getProfileByIdAction.REQUEST, getProfileByIdSaga),
    takeLatest(actions.getProfileFeedByIdAction.REQUEST, getProfileFeedByIdSaga),
    takeLatest(actions.addLikeToPostByIdAction.REQUEST, postLikeSaga),
    takeLatest(actions.deleteLikeToPostByIdAction.REQUEST, deleteLikeSaga),
    takeLatest(actions.addProfilePostAction.REQUEST, createProfilePostSaga),
    takeLatest(actions.addFriendAction.REQUEST, addFriendSaga),
    takeLatest(actions.addFriendBackAction.REQUEST, addFriendBackSaga),
    takeLatest(actions.followUserAction.REQUEST, followUserSaga),
    takeLatest(actions.unfollowUserAction.REQUEST, unfollowUserSaga),
  ]);
}
