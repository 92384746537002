import React, { useEffect, useRef } from 'react';
import { Picker } from 'emoji-mart';

const EmojiPicker = (props) => {
  const ref = useRef();

  useEffect(() => {
    // eslint-disable-next-line no-new
    new Picker({ ...props, ref });
  }, []);

  return <div ref={ref} />;
};

export default EmojiPicker;
