import React from 'react';
import PropTypes from 'prop-types';
import './ChatFilterForm.scss';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';

const ChatFilterForm = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        searchChat: '',
      }}
    >
      {({ values, errors, touched }) => (
        <Form className="chats-list--form">
          <div className="chats-list--from-container">
            <Input
              icon="lens"
              className="chats-list--form-input"
              type="text"
              name="searchChat"
              placeholder={t('ChatPage.chats.search.placeholder')}
              errors={errors}
              touched={touched}
              onChange={setTimeout(() => onChange(values.searchChat), 0)}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

ChatFilterForm.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ChatFilterForm;
