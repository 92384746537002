import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import ChatComponent from 'components/ChatComponent';
import { selectors } from './reducer';
import { selectors as mainSelectors } from '../MainPage/reducer';
import {
  getAllPostsAction,
  createNewPostAction,
  getInfluencersAction,
  getCompaniesAction,
  deleteLikeAction,
  postLikeAction,
  getProfileFollowersAction,
  followUserAction,
} from './actions';
import ContentLoader from '../../components/ContentLoader';
import PostFeedEditor from '../../components/PostFeedEditor';
import FeedItem from '../../components/FeedItem';
import UserInfo from '../../components/UserInfo';
import ProgressLoader from '../../components/ProgressLoader';
import { getPostFilesUploadPercents } from './helper';
import './FeedPage.scss';

const FeedPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filesUploadProgress, setFilesUploadProgress] = useState(null);

  const profileData = useSelector(mainSelectors.userProfileData);

  const feedLoading = useSelector(selectors.postsLoading);
  const feedData = useSelector(selectors.postsData);

  const companiesLoading = useSelector(selectors.companiesLoading);
  const companiesData = useSelector(selectors.companiesData);

  const influencersLoading = useSelector(selectors.influencersLoading);
  const influencersData = useSelector(selectors.influencersData);

  const profileFollowersData = useSelector(selectors.profileFollowersData);

  const [sortBy, setSortBy] = useState({ value: 'desc', label: 'New' });

  useEffect(() => {
    if (profileData?.id) {
      dispatch(getInfluencersAction.request());
      dispatch(getCompaniesAction.request());
      dispatch(getProfileFollowersAction.request(profileData?.id));
    }
  }, [profileData?.id]);

  useEffect(() => {
    if (sortBy.value === 'desc') {
      dispatch(getAllPostsAction.request('desc=updatedAt'));
    } else if (sortBy.value === 'asc') {
      dispatch(getAllPostsAction.request('asc=updatedAt'));
    }
  }, [sortBy]);

  // Watch post files upload percents status
  useEffect(() => {
    const interval = window.setInterval(
      () => setFilesUploadProgress(getPostFilesUploadPercents()),
      500,
    );
    return () => clearInterval(interval);
  }, []);

  const addRepostCallback = useCallback(() => {
    if (sortBy.value === 'desc') {
      dispatch(getAllPostsAction.request('desc=updatedAt'));
    } else if (sortBy.value === 'asc') {
      dispatch(getAllPostsAction.request('asc=updatedAt'));
    }
  }, [sortBy]);

  const handleAddPost = ({ text, files }) => {
    dispatch(
      createNewPostAction.request({
        text,
        files,
        sort: sortBy.value === 'asc' ? 'asc=updatedAt' : 'desc=updatedAt',
      }),
    );
  };

  const followUserRequest = (userId) => {
    dispatch(
      followUserAction.request({
        userId,
        profileId: profileData?.id,
      }),
    );
  };

  return (
    <>
      <ChatComponent />
      <div className="feed-page--wrapper">
        <div className="feed-page--inner">
          {feedLoading || influencersLoading || companiesLoading ? (
            <>
              {filesUploadProgress !== null ? (
                <ProgressLoader
                  visible={true}
                  value={filesUploadProgress}
                  title={`${t(
                    'FeedPage.additionalContent.filesUploadingTitle',
                  )} ${filesUploadProgress}%`}
                />
              ) : (
                <ContentLoader visible={feedLoading || influencersLoading || companiesLoading} />
              )}
            </>
          ) : (
            <div className="app-pages--content">
              <div className="content--main">
                <PostFeedEditor onSubmit={handleAddPost} />
                <div className="feed-page--header">
                  <h2>Feed</h2>
                  <ReactSelect
                    className="feed-page--header-select"
                    classNamePrefix="sort-select"
                    isSearchable={false}
                    defaultValue={sortBy}
                    options={[
                      { value: 'desc', label: 'New' },
                      { value: 'asc', label: 'Old' },
                    ]}
                    onChange={(selectedOption) => setSortBy(selectedOption)}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        background: !state.isFocused && 'none !important',
                        color: state.isSelected
                          ? 'hsl(0, 0%, 20%) !important'
                          : state.isFocused && 'hsl(0, 0%, 20%) !important',
                      }),
                    }}
                  />
                </div>
                <div className="feed-page--posts-wrapper">
                  {feedData?.map((post) => (
                    <FeedItem
                      key={post.id}
                      profileData={profileData}
                      posts={feedData}
                      post={post}
                      onDeleteLike={deleteLikeAction}
                      onAddLike={postLikeAction}
                      addRepostCallback={addRepostCallback}
                    />
                  ))}
                </div>
              </div>
              <div className="content--additional">
                {influencersData?.length ? (
                  <div className="additional--container">
                    <h3 className="container--title">
                      {t('FeedPage.additionalContent.influencersTitle')}
                    </h3>
                    <div className="container--body">
                      {influencersData?.map((u) => (
                        <div className="user--row" key={u.id}>
                          <UserInfo size="sm" user={u} />
                          <button
                            type="button"
                            className="btn btn-secondary-gray btn-xs"
                            disabled={profileFollowersData?.includes(u.id)}
                            onClick={() => followUserRequest(u.id)}
                          >
                            {t(
                              `FeedPage.additionalContent.${
                                profileFollowersData?.includes(u.id) ? 'unfollowBtn' : 'followBtn'
                              }`,
                            )}
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="container--footer">
                      <Link to="/search/influencer" className="link link-color link-btn link-md">
                        {t('FeedPage.additionalContent.seAllBtn')}
                      </Link>
                    </div>
                  </div>
                ) : null}
                {companiesData?.length ? (
                  <div className="additional--container">
                    <h3 className="container--title">
                      {t('FeedPage.additionalContent.companiesTitle')}
                    </h3>
                    <div className="container--body">
                      {companiesData?.map((u) => (
                        <div className="user--row" key={u.id}>
                          <UserInfo size="sm" user={u} />
                          <button
                            type="button"
                            className="btn btn-secondary-gray btn-xs"
                            disabled={profileFollowersData?.includes(u.id)}
                            onClick={() => followUserRequest(u.id)}
                          >
                            {t(
                              `FeedPage.additionalContent.${
                                profileFollowersData?.includes(u.id) ? 'unfollowBtn' : 'followBtn'
                              }`,
                            )}
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="container--footer">
                      <Link to="/search/company" className="link link-color link-btn link-md">
                        {t('FeedPage.additionalContent.seAllBtn')}
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FeedPage;
