/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncReducerHandler, asyncInitialState } from '../../global/redux';
import { getFromLocalStorage, getFromSessionStorage } from '../../global/helpers';
import * as actions from './actions';

const initialState = {
  login: {
    ...asyncInitialState,
    data: {
      token: getFromLocalStorage('token') || getFromSessionStorage('token'),
    },
  },
  resetPassword: {
    ...asyncInitialState,
    data: {
      email: getFromLocalStorage('reset_password_email'),
    },
    status: getFromLocalStorage('reset_password_step'),
  },
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getUserByTokenAction, 'login'),
  [actions.getUserByTokenAction.success]: (state, action) => {
    state.login.data.user = action.payload;
    state.login.loading = false;
  },
  [actions.getUserByTokenAction.error]: (state, action) => {
    state.login.error = action.payload;
    state.login.loading = false;
  },
  ...asyncReducerHandler(actions.logInUserAction, 'login'),
  [actions.logInUserAction.success]: (state, action) => {
    state.login.data.token = action.payload.token;
    state.login.loading = false;
  },
  [actions.logInUserAction.error]: (state, action) => {
    state.login.error = action.payload;
    state.login.loading = false;
  },
  [actions.logoutUserAction]: (state) => {
    state.login.data.token = null;
    state.login.data.user = null;
  },
  ...asyncReducerHandler(actions.resetUserPasswordAction, 'resetPassword'),
  [actions.resetUserPasswordAction.success]: (state, action) => {
    state.resetPassword.data = action.payload;
    state.resetPassword.loading = false;
  },
  ...asyncReducerHandler(actions.checkEmailVerificationCodeAction, 'resetPassword'),
  [actions.checkEmailVerificationCodeAction.success]: (state) => {
    state.resetPassword.loading = false;
  },
  [actions.checkEmailVerificationCodeAction.error]: (state, action) => {
    state.resetPassword.loading = false;
    state.resetPassword.error = action.payload;
  },
  ...asyncReducerHandler(actions.setNewPasswordAction, 'resetPassword'),
  [actions.setNewPasswordAction.success]: (state) => {
    state.resetPassword.loading = false;
  },
  [actions.setNewPasswordAction.error]: (state, action) => {
    state.resetPassword.loading = false;
    state.resetPassword.error = action.payload;
  },
  [actions.changePasswordResetStatusAction]: (state, action) => {
    state.resetPassword.status = action.payload;
  },
});

const loginPageLoadingState = (state) => state.logInReducer.login.loading;
const resetPasswordLoadingState = (state) => state.logInReducer.resetPassword.loading;

const resetPasswordData = (state) => state.logInReducer.resetPassword.data;
const resetPasswordStatus = (state) => state.logInReducer.resetPassword.status;

const userToken = (state) => state.logInReducer.login.data.token;
const userData = (state) => state.logInReducer.login.data.user;

const selectors = {
  loginPageLoadingState,
  resetPasswordLoadingState,
  resetPasswordData,
  resetPasswordStatus,
  userToken,
  userData,
};

export { reducer, initialState, selectors };
export default reducer;
