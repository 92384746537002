/* eslint-disable no-param-reassign */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import NetworkService from './service';

export function* getFriendsSaga({ payload }) {
  try {
    const response = yield call(NetworkService.getFriends, payload);
    const result = response.data.data.result.map((el) => {
      el.fromMe = el.initiator.id === payload.id;
      return el;
    });

    yield put(actions.getFriendsAction.success(result));
  } catch (error) {
    yield put(actions.getFriendsAction.error(error));
  }
}

export function* getFriendsRequestsSaga({ payload }) {
  try {
    const response = yield call(NetworkService.getFriendsRequests, payload);
    const result = response.data.data.result.map((el) => {
      el.fromMe = el.initiator.id === payload.id;
      return el;
    });

    yield put(actions.getFriendsRequestsAction.success(result));
  } catch (error) {
    yield put(actions.getFriendsRequestsAction.error(error));
  }
}

export function* getFollowsSaga({ payload }) {
  try {
    const response = yield call(NetworkService.getFollows, payload);
    const result = response.data.data.result.map((el) => {
      el.fromMe = el.initiator.id === payload.id;
      return el;
    });

    yield put(actions.getFollowsAction.success(result));
  } catch (error) {
    yield put(actions.getFollowsAction.error(error));
  }
}

export function* approveFriendRequestSaga({ payload }) {
  try {
    yield call(NetworkService.approveFriendRequest, payload.id);

    yield put(actions.approveFriendRequestAction.success());
    yield put(actions.getFriendsRequestsAction.request({ id: payload.userId }));
  } catch (error) {
    yield put(actions.approveFriendRequestAction.error(error));
  }
}

export function* declineFriendRequestSaga({ payload }) {
  try {
    yield call(NetworkService.declineFriendRequest, payload.id);

    yield put(actions.declineFriendRequestAction.success());
    yield put(actions.getFriendsRequestsAction.request({ id: payload.userId }));
  } catch (error) {
    yield put(actions.declineFriendRequestAction.error(error));
  }
}

export function* getSuggestedPeopleSaga() {
  try {
    const influencers = yield call(NetworkService.getInfluencers);
    const companies = yield call(NetworkService.getCompanies);

    yield put(
      actions.getSuggestedPeopleAction.success({
        influencers: influencers.data.data.result,
        companies: companies.data.data.result,
      }),
    );
  } catch (error) {
    yield put(actions.getSuggestedPeopleAction.error(error));
  }
}

export function* followUserSaga({ payload }) {
  try {
    yield call(NetworkService.followUser, payload);

    yield put(actions.followUserAction.success());
  } catch (error) {
    yield put(actions.followUserAction.error(error));
  }
}

export function* unfollowUserSaga({ payload }) {
  try {
    yield call(NetworkService.unfollowUser, payload.id);

    yield put(actions.unfollowUserAction.success());
    yield put(actions.getFollowsAction.request({ id: payload.userId }));
  } catch (error) {
    yield put(actions.unfollowUserAction.error(error));
  }
}

export function* networkPageWatcherSaga() {
  yield all([
    takeLatest(actions.getFriendsAction.REQUEST, getFriendsSaga),
    takeLatest(actions.getFriendsRequestsAction.REQUEST, getFriendsRequestsSaga),
    takeLatest(actions.getFollowsAction.REQUEST, getFollowsSaga),
    takeLatest(actions.approveFriendRequestAction.REQUEST, approveFriendRequestSaga),
    takeLatest(actions.declineFriendRequestAction.REQUEST, declineFriendRequestSaga),
    takeLatest(actions.getSuggestedPeopleAction.REQUEST, getSuggestedPeopleSaga),
    takeLatest(actions.followUserAction.REQUEST, followUserSaga),
    takeLatest(actions.unfollowUserAction.REQUEST, unfollowUserSaga),
  ]);
}
