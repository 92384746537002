import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { selectors } from './reducer';
import { selectors as mainSelectors } from '../MainPage/reducer';
import { getFollowersAction, getUsersBySearchParamsAction } from './actions';
import Avatar from '../../components/Avatar';
import SearchSubHeader from './components/SearchSubHeader';
import CompanyCard from '../../components/CompanyCard';
import IndustriesRow from '../../components/IndustriesRow';
import ContentLoader from '../../components/ContentLoader';
import SearchEmptyState from './components/SearchEmptyState';
import './SearchPage.scss';

const SearchPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [params] = useSearchParams();

  const query = params.get('query');

  const searchData = useSelector(selectors.totalSearchData);
  const searchLoading = useSelector(selectors.totalSearchLoading);
  const [filteredSearchData, setFilteredSearchData] = useState([]);

  const profile = useSelector(mainSelectors.userProfileData);

  useEffect(() => {
    if (query && query.length) {
      dispatch(getUsersBySearchParamsAction.request(query || ''));
    }
  }, [query]);

  useEffect(() => {
    if (profile?.id) {
      dispatch(getFollowersAction.request(profile.id));
    }
  }, [query, profile]);

  // Skip myself profile
  useEffect(() => {
    if (searchData !== null && profile?.id) {
      setFilteredSearchData({
        companies: searchData.companies.filter((user) => profile.id !== user.id),
        influencers: searchData.influencers.filter((user) => profile.id !== user.id),
      });
    } else {
      setFilteredSearchData(searchData);
    }
  }, [searchData, profile]);

  return (
    <div className="search-page--wrapper">
      {searchLoading ? (
        <ContentLoader visible={searchLoading} />
      ) : (
        <>
          <SearchSubHeader
            type="influencer"
            amount={filteredSearchData?.influencers?.length || 0}
          />
          {filteredSearchData?.influencers?.length ? (
            <>
              <div className="search-page--influencers-results">
                <table className="influencers--table">
                  <thead>
                    <tr className="table--header-row">
                      <th className="table--header-cell">
                        {t('SearchPage.table.headers.account')}
                      </th>
                      <th className="table--header-cell">
                        {t('SearchPage.table.headers.category')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSearchData?.influencers?.slice(0, 4)?.map((user) => (
                      <tr className="table--row" key={user.id}>
                        <td className="table--cell">
                          <div className="user-info">
                            <Avatar size="md" className="user-avatar" file={user.cover} />
                            <div className="user-details">
                              <h3 className="details-name">{user.fullName}</h3>
                              <span className="details-info">@olivia</span>
                            </div>
                          </div>
                        </td>
                        <td className="table--cell">
                          <IndustriesRow industries={user.industries} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Link
                to={`/search/influencer?query=${query}`}
                className="btn btn-md btn-secondary-gray influencer-btn"
              >
                {t('SearchPage.buttons.seeAllInf')}
              </Link>
            </>
          ) : (
            <SearchEmptyState />
          )}
          <SearchSubHeader type="company" amount={filteredSearchData?.companies?.length || 0} />
          {filteredSearchData?.companies?.length ? (
            <>
              <div className="search-page--companies-results">
                {filteredSearchData?.companies?.slice(0, 4)?.map((user) => (
                  <CompanyCard key={user.id} user={user} />
                ))}
              </div>
              <Link
                to={`/search/companies?query=${query}`}
                className="btn btn-md btn-secondary-gray"
              >
                {t('SearchPage.buttons.seeAllCompanies')}
              </Link>
            </>
          ) : (
            <SearchEmptyState />
          )}
        </>
      )}
    </div>
  );
};

export default SearchPage;
