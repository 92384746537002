import { takeLatest, all, put, call } from 'redux-saga/effects';
import AddInstagramAccountService from './service';
import * as actions from './actions';

export function* postInstagramCodeSaga({ payload }) {
  try {
    const response = yield call(AddInstagramAccountService.postInstagramCode, payload.data);
    yield put(actions.postInstagramCodeAction.success(response.data));
  } catch (error) {
    yield put(actions.postInstagramCodeAction.error(error));
  }
}

export function* postInstagramUserTokenSaga({ payload }) {
  try {
    const response = yield call(AddInstagramAccountService.postInstagramUserToken, payload.data);
    yield put(actions.postInstagramUserTokenAction.success(response.data));
  } catch (error) {
    yield put(actions.postInstagramUserTokenAction.error(error));
  }
}

export function* instagramCodePageWatcherSaga() {
  yield all([
    takeLatest(actions.postInstagramCodeAction.REQUEST, postInstagramCodeSaga),
    takeLatest(actions.postInstagramUserTokenAction.REQUEST, postInstagramUserTokenSaga),
  ]);
}
