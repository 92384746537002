/* eslint-disable global-require */
import ApiService from 'global/services/api';

class AddInstagramAccountService extends ApiService {
  postInstagramCode = async (data) =>
    this.post({ url: `socials/facebook/exchange-code`, body: data });

  postInstagramUserToken = async (data) =>
    this.post({ url: `socials/instagram/user/token`, body: data });
}
export default new AddInstagramAccountService();
