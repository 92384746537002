import { DateTime } from 'luxon';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'global/formats/luxonFormats';

const messageDateFormatter = (t, date) => {
  const now = DateTime.now();
  const parsedDate = DateTime.fromISO(date);
  const isItWasToday = now.hasSame(parsedDate, 'day');

  if (isItWasToday) {
    return `${t('time.today')} ${parsedDate.toFormat(TIME_FORMAT)}`;
  }

  return DateTime.fromISO(date).toFormat(DATE_TIME_FORMAT);
};

export default messageDateFormatter;

export const fileSizeFromatter = (size) => {
  if (size.length >= 4 && size.length < 7) {
    return `${(Number(size) / 1024).toFixed(1)} KB`;
  }
  if (size.length >= 7) {
    return `${(Number(size) / 1048576).toFixed(1)} MB`;
  }
  return `${size} B`;
};

export const getIconByFileType = (url) => {
  const isImg = url.match(/.jpg|.png|.jpeg|.jfif|.gif|.tiff/gm);

  if (isImg) {
    return 'chatFileImg';
  }
  return 'chatFile';
};
