import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { asyncReducerHandler, asyncInitialState } from '../../global/redux';

const initialState = {
  ...asyncInitialState,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getSavedListProfilesDataAction),
});

const savedListData = (state) => state.savedListReducer.data;
const savedListLoading = (state) => state.savedListReducer.loading;

const selectors = {
  savedListData,
  savedListLoading,
};

export { selectors, reducer, initialState };
export default reducer;
