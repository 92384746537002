import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllChatsAction, getNewMsgCountAction } from 'pages/ChatPage/actions';
import { getUserByTokenAction } from '../LogInPage/actions';
import { setInitPath } from './actions';
import { selectors } from '../LogInPage/reducer';
import { selectors as initSelectors } from './reducer';
import ProtectedRoute from '../../components/ProtectedRoute';
import AddSocialPage from '../SignUpPage/components/AddSocialPage';
import FindSocialPage from '../SignUpPage/components/FindSocialPage';
import VerifiedPage from '../SignUpPage/components/VerifiedPage';
import ForgotPasswordPage from '../LogInPage/components/ForgotPasswordPage';
import CheckEmailPage from '../LogInPage/components/CheckEmailPage';
import SetNewPasswordPage from '../LogInPage/components/SetNewPasswordPage';
import PasswordResetSuccessPage from '../LogInPage/components/PasswordResetSuccessPage';
import EmailVerificationPage from '../SignUpPage/components/EmailVerificationPage';
import EmailVerifiedPage from '../SignUpPage/components/EmailVerifiedPage';
import ConfirmEmailPage from '../SignUpPage/components/ConfirmEmailPage';
import UserInformationPage from '../SignUpPage/components/UserInformationPage';
import MobileDeviceAlertPage from '../MobileDeviceAlertPage';
import MainPage from '../MainPage';
import SignUpPage from '../SignUpPage';
import LogInPage from '../LogInPage';

const InitComponent = () => {
  const dispatch = useDispatch();
  const userToken = useSelector(selectors.userToken);
  const userData = useSelector(selectors.userData);

  const initPath = useSelector(initSelectors.applicationInitPath);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (initPath === null) {
      dispatch(setInitPath(`${location.pathname}${location.search}`));
    }
  }, []);

  useEffect(() => {
    dispatch(getUserByTokenAction.request());
    dispatch(getNewMsgCountAction.request());
    dispatch(getAllChatsAction.request());
  }, [userToken]);

  useEffect(() => {
    if (userData && !userData.fullName) {
      navigate('/signup/user/information');
    }
  }, [userData]);

  useEffect(() => {
    if (initPath) {
      navigate(initPath);
    }
  }, [initPath]);

  if (userData && userData.fullName) {
    if (isMobile) {
      return (
        <Routes>
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <MobileDeviceAlertPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      );
    }

    return (
      <Routes>
        <Route
          exact
          path="/signup/social"
          element={
            <ProtectedRoute>
              <AddSocialPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/signup/social/:socialType"
          element={
            <ProtectedRoute>
              <FindSocialPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/signup/social/verified"
          element={
            <ProtectedRoute>
              <VerifiedPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <MainPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* PROTECTED ROUTES */}
      <Route
        exact
        path="/signup/user/information"
        element={
          <ProtectedRoute>
            <UserInformationPage />
          </ProtectedRoute>
        }
      />
      {/* PROTECTED ROUTES */}

      <Route exact path="/signup" element={<SignUpPage />} />
      <Route exact path="/signup/verification" element={<EmailVerificationPage />} />
      <Route exact path="/signup/verification/confirm" element={<ConfirmEmailPage />} />
      <Route exact path="/signup/verification/success" element={<EmailVerifiedPage />} />

      <Route exact path="/auth" element={<LogInPage />} />
      <Route exact path="/auth/forgot-password" element={<ForgotPasswordPage />} />
      <Route exact path="/auth/email-check" element={<CheckEmailPage />} />
      <Route exact path="/auth/password-reset" element={<SetNewPasswordPage />} />
      <Route exact path="/auth/password-reset/success" element={<PasswordResetSuccessPage />} />
      <Route path="/*" element={<Navigate to="/auth" />} />
    </Routes>
  );
};

export default InitComponent;
