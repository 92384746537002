/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { asyncInitialState, asyncReducerHandler } from '../../global/redux';
import { getFromLocalStorage } from '../../global/helpers';

const initialState = {
  userProfile: asyncInitialState,
  formMetadata: asyncInitialState,
  headerSearch: asyncInitialState,
  notifications: asyncInitialState,
  favoriteList: asyncInitialState,
  comparisonList: getFromLocalStorage('comparison') || [],
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getUserProfileAction, 'userProfile'),
  ...asyncReducerHandler(actions.getFormMetadataAction, 'formMetadata'),
  ...asyncReducerHandler(actions.getUsersBySearchAction, 'headerSearch'),
  ...asyncReducerHandler(actions.getNotificationsAction, 'notifications'),
  ...asyncReducerHandler(actions.getFromFavorite, 'favoriteList'),
  [actions.clearSearchDataAction]: (state) => {
    state.headerSearch.data = null;
  },
  [actions.addToComparison]: (state, action) => {
    state.comparisonList = [...state.comparisonList, action.payload];
  },
  [actions.deleteFromComparison]: (state, action) => {
    if (typeof action.payload === 'number') {
      state.comparisonList = state.comparisonList.filter((id) => id !== action.payload);
    } else {
      state.comparisonList = state.comparisonList.filter((id) => !action.payload.includes(id));
    }
  },
  [actions.clearComparisonList]: (state) => {
    state.comparisonList = [];
  },
  [actions.setFavoriteListLoading]: (state, action) => {
    state.favoriteList.loading = action.payload;
  },
});

const userProfileData = (state) => state.mainReducer.userProfile.data;
const userProfileLoading = (state) => state.mainReducer.userProfile.loading;

const formMetadataData = (state) => state.mainReducer.formMetadata.data;
const formMetadataLoading = (state) => state.mainReducer.formMetadata.loading;

const headerSearchData = (state) => state.mainReducer.headerSearch.data;
const headerSearchLoading = (state) => state.mainReducer.headerSearch.loading;

const notificationsData = (state) => state.mainReducer.notifications.data;
const notificationsLoading = (state) => state.mainReducer.notifications.loading;

const favoriteListData = (state) => state.mainReducer.favoriteList.data;
const favoriteListLoading = (state) => state.mainReducer.favoriteList.loading;

const comparisonListData = (state) => state.mainReducer.comparisonList;

const selectors = {
  userProfileData,
  userProfileLoading,
  formMetadataData,
  formMetadataLoading,
  headerSearchData,
  headerSearchLoading,
  notificationsData,
  notificationsLoading,
  favoriteListData,
  favoriteListLoading,
  comparisonListData,
};

export { selectors, reducer, initialState };
export default reducer;
