import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { genericHashLink } from 'react-router-hash-link';
import { selectors } from '../../../MainPage/reducer';
import ROUTES from './routes';
import ROLES from '../../../../constants';
import './AnchorNavigation.scss';

const HashLink = genericHashLink(Link);

const AnchorNavigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const profile = useSelector(selectors.userProfileData);

  return (
    <div className="anchor-navigation--wrapper">
      <HashLink
        to="/profile/settings#details"
        smooth
        className={`anchor--route ${location.hash === '#details' ? 'active-anchor' : ''}`}
      >
        {t(
          `ProfileSettingsPage.rightBar.links.${
            profile?.role === ROLES.influencer ? 'profileDetails' : 'companyDetails'
          }`,
        )}
      </HashLink>
      {ROUTES.map((route) => (
        <HashLink
          key={route.id}
          to={`/profile/settings${route.anchor}`}
          smooth
          className={`anchor--route ${location.hash === route.anchor ? 'active-anchor' : ''}`}
        >
          {t(route.name)}
        </HashLink>
      ))}
      {/* <div className="anchor-navigation--footer"> */}
      {/*  <NavLink to="/" className="upgrade-btn"> */}
      {/*    {t('ProfileSettingsPage.rightBar.upgradePlanBtn')} */}
      {/*  </NavLink> */}
      {/* </div> */}
    </div>
  );
};

export default AnchorNavigation;
