import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './actions';
import FeedService from './service';
import { addAlertAction } from '../InitComponent/actions';
import ALERT_TYPES from '../../components/Alert/constants';
import { getProfilePostCommentsAction } from '../ProfilePage/actions';
import { disablePostFilesUploadPercentsCounter, setPostFilesUploadPercents } from './helper';

export function* createPostSaga({ payload }) {
  try {
    const uploadFilesProgresses = [];

    const fileResponses = yield all(
      payload.files.map((file, index) => {
        const formData = new FormData();
        formData.append('file', file);

        return call(FeedService.fileUpload, formData, (progressEvent) => {
          uploadFilesProgresses[index] = parseInt(
            (progressEvent.loaded / progressEvent.total) * 100,
          );
          const slowestFilePercent = Math.min.apply(Math, uploadFilesProgresses);
          setPostFilesUploadPercents(slowestFilePercent);
        });
      }),
    );

    disablePostFilesUploadPercentsCounter();

    yield call(FeedService.createPost, {
      text: payload.text,
      files: fileResponses.map((response) => ({
        id: response.data.data.id,
      })),
    });

    yield put(actions.createNewPostAction.success());
    yield put(actions.getAllPostsAction.request(payload.sort));
  } catch (error) {
    yield put(
      addAlertAction({
        type: ALERT_TYPES.error,
        message: 'CREATE_NEW_POST_ERROR',
      }),
    );
    yield put(actions.createNewPostAction.error(error));
  }
}

export function* createRepostSaga({ payload }) {
  try {
    const response = yield call(FeedService.createPost, payload);

    yield put(
      addAlertAction({
        type: ALERT_TYPES.success,
        message: 'CREATE_REPOST_SUCCESS',
      }),
    );

    yield put(actions.createRepostAction.success(response.data.data.result));
  } catch (error) {
    yield put(
      addAlertAction({
        type: ALERT_TYPES.error,
        message: 'CREATE_NEW_POST_ERROR',
      }),
    );
    yield put(actions.createNewPostAction.error(error));
  }
}

export function* deletePostSaga({ payload }) {
  try {
    yield call(FeedService.deletePost, payload);
    yield put(actions.deletePostAction.success(payload));
    yield put(
      addAlertAction({
        type: ALERT_TYPES.success,
        message: 'MESSAGE_DELETE',
      }),
    );
  } catch (error) {
    yield put(
      addAlertAction({
        type: ALERT_TYPES.error,
        message: 'DELETE_POST_ERROR',
      }),
    );
    yield put(actions.createNewPostAction.error(error));
  }
}

export function* getAllPostsSaga({ payload }) {
  try {
    const response = yield call(FeedService.getAllPosts, payload);

    yield put(actions.getAllPostsAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.getAllPostsAction.error(error));
  }
}

export function* postLikePostSaga({ payload }) {
  try {
    yield call(FeedService.postLikePost, payload);
    yield put(actions.postLikeAction.success(payload));
  } catch (error) {
    yield put(actions.postLikeAction.error(error));
  }
}

export function* deleteLikePostSaga({ payload }) {
  try {
    yield call(FeedService.deleteLikePost, payload);
    yield put(actions.deleteLikeAction.success(payload));
  } catch (error) {
    yield put(actions.deleteLikeAction.error(error));
  }
}

export function* getInfluencersSaga() {
  try {
    const response = yield call(FeedService.getInfluencers);

    yield put(actions.getInfluencersAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.getInfluencersAction.error(error));
  }
}

export function* getCompaniesSaga() {
  try {
    const response = yield call(FeedService.getCompanies);

    yield put(actions.getCompaniesAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.getCompaniesAction.error(error));
  }
}

export function* getCommentsSaga({ payload }) {
  try {
    const response = yield call(FeedService.getComments, payload);

    const comments = response.data.data.result.sort(
      (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );

    if (payload.isProfilePage) {
      yield put(
        getProfilePostCommentsAction.success({
          comments,
          postId: payload.id,
        }),
      );
    }

    yield put(
      actions.getCommentsAction.success({
        comments,
        postId: payload.id,
      }),
    );
  } catch (error) {
    yield put(actions.getCommentsAction.error(error));
  }
}

export function* createCommentSaga({ payload }) {
  try {
    const fileResponses = yield all(
      payload.files.map((file) => {
        const formData = new FormData();
        formData.append('file', file);

        return call(FeedService.fileUpload, formData);
      }),
    );

    yield call(FeedService.postComment, {
      id: payload.id,
      data: {
        text: payload.text,
        files: fileResponses.map((response) => ({
          id: response.data.data.id,
        })),
      },
    });

    yield put(actions.postCommentAction.success());
    yield put(
      actions.getCommentsAction.request({ id: payload.id, isProfilePage: payload.isProfilePage }),
    );
  } catch (error) {
    yield put(actions.postCommentAction.error(error));
  }
}

export function* getProfileFollowersSaga({ payload }) {
  try {
    const response = yield call(FeedService.getProfileFollowersApproved, payload);
    // const responseRequested = yield call(FeedService.getProfileFollowersRequested, payload);

    yield put(
      actions.getProfileFollowersAction.success([
        ...response.data.data.result,
        // ...responseRequested.data.data.result,
      ]),
    );
  } catch (error) {
    yield put(actions.getProfileFollowersAction.error(error));
  }
}

export function* followUserSaga({ payload }) {
  try {
    yield call(FeedService.followUser, payload.userId);

    yield put(actions.followUserAction.success());
    yield put(actions.getProfileFollowersAction.request(payload.profileId));
  } catch (error) {
    yield put(actions.followUserAction.error(error));
  }
}

export function* unfollowUserSaga({ payload }) {
  try {
    yield call(FeedService.unfollowUser, payload);

    yield put(actions.unfollowUserAction.success());
    yield put(actions.getProfileFollowersAction.request());
  } catch (error) {
    yield put(actions.unfollowUserAction.error(error));
  }
}

export function* feedPageWatcherSaga() {
  yield all([
    takeLatest(actions.createNewPostAction.REQUEST, createPostSaga),
    takeLatest(actions.deletePostAction.REQUEST, deletePostSaga),
    takeLatest(actions.createRepostAction.REQUEST, createRepostSaga),
    takeLatest(actions.getAllPostsAction.REQUEST, getAllPostsSaga),
    takeLatest(actions.postLikeAction.REQUEST, postLikePostSaga),
    takeLatest(actions.deleteLikeAction.REQUEST, deleteLikePostSaga),
    takeLatest(actions.getInfluencersAction.REQUEST, getInfluencersSaga),
    takeLatest(actions.getCompaniesAction.REQUEST, getCompaniesSaga),
    takeLatest(actions.getCommentsAction.REQUEST, getCommentsSaga),
    takeLatest(actions.postCommentAction.REQUEST, createCommentSaga),
    takeLatest(actions.getProfileFollowersAction.REQUEST, getProfileFollowersSaga),
    takeLatest(actions.followUserAction.REQUEST, followUserSaga),
    takeLatest(actions.unfollowUserAction.REQUEST, unfollowUserSaga),
  ]);
}
