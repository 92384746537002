/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isHotkey from 'is-hotkey';
import { createEditor, Element as SlateElement, Editor, Transforms } from 'slate';
import { withReact, Slate, Editable } from 'slate-react';
import { withHistory } from 'slate-history';
import Element from './components/Element';
import Leaf from './components/Leaf';
import MarkButton from './components/MarkButton';
import BlockButton from './components/BlockButton';
import { HOTKEYS, LIST_TYPES, TEXT_ALIGN_TYPES } from './constants';
import './TextEditor.scss';

const TextEditor = ({ value, readOnly, placeholder, label, maxLetters, onChange }) => {
  const { t } = useTranslation();

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const slateEditor = useMemo(() => withHistory(withReact(createEditor())), []);

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);

    return marks ? marks[format] === true : false;
  };

  const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor;
    if (!selection) {
      return false;
    }

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
      }),
    );

    return !!match;
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });

    let newProperties;

    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else {
      newProperties = {
        // eslint-disable-next-line no-nested-ternary
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      };
    }

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const onKeyDown = (event) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        const mark = HOTKEYS[hotkey];
        toggleMark(slateEditor, mark);
      }
    }
  };

  const handleChange = (valueNew) => {
    if (onChange) {
      onChange(valueNew);
    }
  };

  return (
    <div className="text-editor--wrapper">
      {label && <label className="text-editor--label">{label}</label>}
      <Slate editor={slateEditor} value={value} onChange={handleChange}>
        {!readOnly && (
          <div className="text-editor--toolbar">
            <MarkButton
              format="bold"
              icon="bold"
              toggleMark={toggleMark}
              isMarkActive={isMarkActive}
            />
            <MarkButton
              format="italic"
              icon="italic"
              toggleMark={toggleMark}
              isMarkActive={isMarkActive}
            />
            <BlockButton
              format="bulleted-list"
              icon="bulleted-list"
              toggleBlock={toggleBlock}
              isBlockActive={isBlockActive}
            />
            <BlockButton
              format="numbered-list"
              icon="numbered-list"
              toggleBlock={toggleBlock}
              isBlockActive={isBlockActive}
            />
          </div>
        )}
        <div className="text-editor--content">
          <Editable
            className="text-editor"
            readOnly={readOnly}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            spellCheck
            onKeyDown={onKeyDown}
          />
        </div>
        {maxLetters && !readOnly && (
          <span className="text-editor--length-control">{t('textEditor.lengthText')}</span>
        )}
      </Slate>
    </div>
  );
};

TextEditor.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  maxLetters: PropTypes.number,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

TextEditor.defaultProps = {
  placeholder: null,
  label: null,
  maxLetters: null,
  onChange: null,
  readOnly: false,
};

export default TextEditor;
