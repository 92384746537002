export const AUDIENCE_QUALITY_SCORE = [
  {
    id: 'all',
    value: 'all',
    label: 'SearchFilers.form.audienceQuality.any',
  },
  {
    id: 'excellent',
    value: 'excellent',
    label: 'SearchFilers.form.audienceQuality.excellent',
  },
  {
    id: 'very_good',
    value: 'very_good',
    label: 'SearchFilers.form.audienceQuality.very_good',
  },
  {
    id: 'good',
    value: 'good',
    label: 'SearchFilers.form.audienceQuality.good',
  },
  {
    id: 'average',
    value: 'average',
    label: 'SearchFilers.form.audienceQuality.average',
  },
  {
    id: 'could_be_improved',
    value: 'could_be_improved',
    label: 'SearchFilers.form.audienceQuality.could_be_improved',
  },
];

export const INITIAL_FORM_VALUES = {
  industry: null,
  location: null,
  business_type: null,
  gender: null,
  age: [0, 0],
  followers: [0, 0],
  audience_location: null,
  audience_gender: null,
  is_verified_only: false,
  audience_quality: [],
};

export default AUDIENCE_QUALITY_SCORE;
