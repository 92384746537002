import * as yup from 'yup';

export const urlValidationSchema = yup
  .string()
  .matches(
    /(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
    'validations.website.url',
  );

export const passwordValidationSchema = yup
  .string()
  .min(8, 'validations.password.min')
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{8,}$/, 'validations.password.match')
  .matches(/^(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]+$/, 'validations.password.specialSymbol')
  .required('validations.password.required');

export const passwordConfirmValidationSchema = yup
  .string()
  .oneOf([yup.ref('password'), null], 'validations.passwordConfirm.match')
  .min(8, 'validations.passwordConfirm.min')
  .required('validations.passwordConfirm.required');

export const emailValidationSchema = yup
  .string()
  .email('validations.email.email')
  .required('validations.email.required');

export const fullNameValidationSchema = yup
  .string()
  .min(3, 'validations.fullName.min')
  .max(50, 'validations.fullName.max');

export const companyNameValidationSchema = yup
  .string()
  .min(3, 'validations.companyName.min')
  .max(100, 'validations.companyName.max');

export const companyPositionValidationSchema = yup
  .string()
  .min(2, 'validations.companyPosition.min')
  .max(256, 'validations.companyPosition.max');

export const usernameValidationSchema = yup
  .string()
  .min(3, 'validations.username.min')
  .max(50, 'validations.username.max');

export const findSocialValidationSchema = yup.string();
