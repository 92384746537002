/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncInitialState, asyncReducerHandler } from '../../global/redux';
import { getFromLocalStorage } from '../../global/helpers';
import * as actions from './actions';

const initialState = {
  signUp: {
    ...asyncInitialState,
    data: {
      email: getFromLocalStorage('sign_up_email'),
    },
    status: getFromLocalStorage('sign_up_step'),
  },
  userInformation: asyncInitialState,
  socialConfirmation: asyncInitialState,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.signUpUserAction, 'signUp'),
  ...asyncReducerHandler(actions.confirmEmailAction, 'signUp'),
  [actions.confirmEmailAction.error]: (state, action) => {
    state.signUp.error = action.payload;
    state.signUp.loading = false;
  },
  ...asyncReducerHandler(actions.getFormValuesAction, 'userInformation'),
  ...asyncReducerHandler(actions.updateUserAction, 'userInformation'),
  [actions.updateUserAction.success]: (state) => {
    state.userInformation.loading = false;
  },
  [actions.updateUserAction.error]: (state, action) => {
    state.userInformation.loading = false;
    state.userInformation.error = action.payload;
  },
  [actions.changeSignUpStatusAction]: (state, action) => {
    state.signUp.status = action.payload;
  },
  ...asyncReducerHandler(actions.confirmSocialAction, 'socialConfirmation'),
});

const signupPageData = (state) => state.signUpReducer.signUp.data;
const signupPageLoading = (state) => state.signUpReducer.signUp.loading;
const signupPageStatus = (state) => state.signUpReducer.signUp.status;

const userInformationPageData = (state) => state.signUpReducer.userInformation.data;
const userInformationPageLoading = (state) => state.signUpReducer.userInformation.loading;

const userSocialConfirmationPageLoading = (state) => state.signUpReducer.socialConfirmation.loading;

const selectors = {
  signupPageData,
  signupPageLoading,
  signupPageStatus,
  userInformationPageData,
  userInformationPageLoading,
  userSocialConfirmationPageLoading,
};

export { reducer, initialState, selectors };
export default reducer;
