import React from 'react';
import PropTypes from 'prop-types';
import './IndustriesRow.scss';

const IndustriesRow = ({ industries, justify }) => (
  <div className={`industries-wrapper justify-${justify}`}>
    {industries?.slice(0, 2)?.map((ind, index) => (
      <div
        key={ind.id}
        className={`industry-wrapper ${index === 0 ? 'first-item' : 'second-item'}`}
      >
        <span className="industry-name">{ind.name}</span>
      </div>
    ))}
    {(industries?.length || 0) - 2 > 0 ? (
      <div className="industry-wrapper last-element">
        <span className="industry-name">+{(industries?.length || 0) - 2}</span>
      </div>
    ) : null}
  </div>
);

IndustriesRow.propTypes = {
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  justify: PropTypes.oneOf(['center', 'left', 'right']),
};

IndustriesRow.defaultProps = {
  industries: null,
  justify: 'center',
};

export default IndustriesRow;
