import React from 'react';
import PropTypes from 'prop-types';
import './ChatModal.scss';
import { useTranslation } from 'react-i18next';
import useContextMenu from 'hooks/useContextMenu';

const ChatModal = ({ handleReplyMessage }) => {
  const { t } = useTranslation();
  const parentClassName = '.message-item--container';
  const { anchorPoint, show, evt } = useContextMenu(parentClassName);

  if (show && evt.target.closest(parentClassName)) {
    const elementId = evt.target.closest(parentClassName).getAttribute('id');
    return (
      <div className="chatModal-container" style={{ top: anchorPoint.y, left: anchorPoint.x }}>
        <button
          type="button"
          onClick={() => {
            handleReplyMessage(elementId);
          }}
          className="chatModal-item"
        >
          {t('ChatPage.chatModal.reply')}
        </button>
        <button type="button" onClick={() => {}} className="chatModal-item">
          {t('ChatPage.chatModal.complain')}
        </button>
      </div>
    );
  }
  return <></>;
};

ChatModal.propTypes = {
  handleReplyMessage: PropTypes.func.isRequired,
};

export default ChatModal;
