import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { selectors } from '../../reducer';
import { selectors as mainSelectors } from '../../../MainPage/reducer';
import { getCompaniesBySearchParamsAction, getFollowersAction } from '../../actions';
import { getFormMetadataAction } from '../../../MainPage/actions';
import ContentLoader from '../../../../components/ContentLoader';
import CompanyCard from '../../../../components/CompanyCard';
import Input from '../../../../components/Input';
import Icon from '../../../../components/Icon';
import SearchSubHeader from '../SearchSubHeader';
import SearchFilers from '../SearchFilers';
import SearchEmptyState from '../SearchEmptyState';
import useQuery from '../../../../hooks/useQuery';
import './CompanySearchPage.scss';
import { buildActiveFilters, buildApplicationQueryString, buildQueryObject } from '../../helpers';

const CompanySearchPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQuery();

  const profile = useSelector(mainSelectors.userProfileData);

  const companiesSearchData = useSelector(selectors.companiesSearchData);
  const companiesSearchLoading = useSelector(selectors.companiesSearchLoading);
  const [filteredCompaniesSearchData, setFilteredCompaniesSearchData] = useState(null);

  const formMetadataData = useSelector(mainSelectors.formMetadataData);
  const formMetadataLoading = useSelector(mainSelectors.formMetadataLoading);

  const [isFilterShowed, setIsFilterShowed] = useState(false);

  useEffect(() => {
    const queryObject = buildQueryObject(
      params,
      formMetadataData?.genders,
      formMetadataData?.locations,
      formMetadataData?.businessTypes,
      formMetadataData?.industries,
    );

    dispatch(getCompaniesBySearchParamsAction.request(queryObject));
  }, [
    params,
    formMetadataData?.genders,
    formMetadataData?.locations,
    formMetadataData?.businessTypes,
    formMetadataData?.industries,
  ]);

  useEffect(() => {
    dispatch(getFormMetadataAction.request());
  }, []);

  // Skip myself profile
  useEffect(() => {
    if (companiesSearchData !== null && profile?.id) {
      setFilteredCompaniesSearchData(companiesSearchData.filter((user) => profile.id !== user.id));
    } else {
      setFilteredCompaniesSearchData(companiesSearchData);
    }
  }, [companiesSearchData, profile]);

  useEffect(() => {
    if (profile?.id) {
      dispatch(getFollowersAction.request(profile.id));
    }
  }, [profile?.id]);

  const handleCloseSearch = () => {
    setIsFilterShowed(false);
  };

  const handleSearch = (values) => {
    const search = params.get('search');

    const queryString = buildApplicationQueryString({
      ...values,
      search,
    });

    setIsFilterShowed(false);
    navigate(`/search/company/${queryString}`);
  };

  const handleSearchKeyDown = (event, value) => {
    if (event.key === 'Enter' && value.length >= 3) {
      const queryObject = buildQueryObject(
        params,
        formMetadataData?.genders,
        formMetadataData?.locations,
        formMetadataData?.businessTypes,
        formMetadataData?.industries,
      );
      const queryString = buildApplicationQueryString({
        ...queryObject,
        search: value,
      });

      navigate(`/search/company/${queryString}`);
    }
  };

  const queryObject = buildQueryObject(
    params,
    formMetadataData?.genders,
    formMetadataData?.locations,
    formMetadataData?.businessTypes,
    formMetadataData?.industries,
  );

  return (
    <div className="company-search-page--wrapper">
      {companiesSearchLoading || formMetadataLoading ? (
        <ContentLoader visible={companiesSearchLoading || formMetadataLoading} />
      ) : (
        <>
          <SearchFilers
            view="company"
            metadata={formMetadataData}
            initialValue={queryObject}
            isOpen={isFilterShowed}
            onClose={handleCloseSearch}
            onSearch={handleSearch}
          />
          <h2 className="company-search-page--filters-title">
            {t('CompanySearchPage.searchTitle')}
          </h2>
          <div className="company-search-page--filters-wrapper">
            <Formik
              initialValues={{
                search: params.get('search') || '',
              }}
              initialTouched={{
                search: true,
              }}
              validationSchema={yup.object().shape({
                search: yup
                  .string()
                  .min(3, 'Search must be longer than or equal to 3 characters')
                  .notRequired(),
              })}
              onSubmit={() => {}}
              enableReinitialize
            >
              {({ errors, touched, values }) => (
                <Form className="filters-form">
                  <Input
                    name="search"
                    type="text"
                    icon="search"
                    className="search-input"
                    placeholder={t('CompanySearchPage.filters.search.placeholder')}
                    errors={errors}
                    touched={touched}
                    onKeyDown={(event) => handleSearchKeyDown(event, values.search)}
                  />
                </Form>
              )}
            </Formik>
            <button
              type="button"
              className="btn btn-secondary-gray btn-md"
              onClick={() => setIsFilterShowed(true)}
            >
              <Icon icon="filter" className="btn--icon" />
              {t('CompanySearchPage.filters.button')}
            </button>
          </div>
          <div className="company-search-page--active-filters-wrapper">
            {buildActiveFilters(queryObject, t, handleSearch)}
          </div>
          <SearchSubHeader amount={filteredCompaniesSearchData?.length || 0} type="company" />
          {filteredCompaniesSearchData?.length ? (
            <div className="company-search-result--wrapper">
              {filteredCompaniesSearchData.map((user) => (
                <CompanyCard key={user.id} user={user} />
              ))}
            </div>
          ) : (
            <SearchEmptyState />
          )}
        </>
      )}
    </div>
  );
};

export default CompanySearchPage;
