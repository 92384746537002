import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import { ErrorMessage, Field } from 'formik';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import './Select.scss';
import Avatar from '../Avatar';
import Icon from '../Icon';

const { Option, SingleValue } = components;

const IconOption = (props) => {
  const { data } = props;

  return (
    // eslint-disable-next-line
    <Option {...props}>
      {data.icon && (
        <div className="option--icon-wrapper">
          <Icon icon={data.icon} className="option-icon" />
        </div>
      )}
      {data.img && <Avatar size="xs" file={data.img} className="option--icon-wrapper" />}
      <span className="option--text">{data.label}</span>
    </Option>
  );
};

const IconSingleValue = (props) => {
  const { data } = props;

  return (
    // eslint-disable-next-line
    <SingleValue {...props}>
      {data.icon && (
        <div className="option--icon-wrapper">
          <Icon icon={data.icon} className="option-icon" />
        </div>
      )}
      {data.img && <Avatar size="xs" file={data.img} className="option--icon-wrapper" />}
      <span className="option--text">{data.label}</span>
    </SingleValue>
  );
};

const Select = ({
  className,
  label,
  name,
  hint,
  placeholder,
  errors,
  touched,
  isSearchable,
  isMulti,
  isSelectedOutside,
  searchIcon,
  maxSelected,
  options,
  size,
  defaultValue,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line
  const ValueContainer = ({ children, ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ValueContainer {...props}>
      {searchIcon && <Icon icon={searchIcon} className="search--icon" />}
      {children}
    </components.ValueContainer>
  );

  const handleRemoveSelectedItem = (selectedOptions, option, valueSetter) => {
    const mappedOptions = selectedOptions.filter((o) => o.value !== option.value);

    valueSetter(name, mappedOptions);
  };

  return (
    <div className={className}>
      <div className="select-field--wrapper">
        {label && (
          <label htmlFor={name} className="select--label">
            {label}
          </label>
        )}
        <div className="select--wrapper">
          <Field name={name}>
            {({ field, form }) => (
              <>
                {isSelectedOutside && (
                  <div
                    className={`select--selected-values ${
                      field.value?.length ? 'with-values' : ''
                    }`}
                  >
                    {field.value?.map((option) => (
                      <div key={option.value} className="selected--value">
                        <span className="value--text">{option.label}</span>
                        <button
                          type="button"
                          className="value--control-btn"
                          onClick={() =>
                            handleRemoveSelectedItem(field.value, option, form.setFieldValue)
                          }
                        >
                          <Icon icon="cross" className="value--control-icon" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                <ReactSelect
                  className={`select ${isSelectedOutside ? 'select-outside' : ''} select-${size}`}
                  classNamePrefix="select"
                  placeholder={placeholder}
                  isSearchable={isSearchable}
                  isMulti={isMulti}
                  // menuIsOpen
                  defaultValue={defaultValue}
                  value={field.value}
                  options={field.value?.length === maxSelected ? [] : options}
                  components={{ ValueContainer, Option: IconOption, SingleValue: IconSingleValue }}
                  onChange={(values) => form.setFieldValue(field.name, values)}
                  noOptionsMessage={() => {
                    if (maxSelected && field.value?.length === maxSelected) {
                      return t('select.maxOptionsMessage');
                    }
                    return t('select.noOptionsMessage');
                  }}
                />
              </>
            )}
          </Field>
        </div>
        {hint && !errors[name] && !touched[name] && (
          <div className="select-field--hint">{hint}</div>
        )}
        <CSSTransition
          in={errors[name] && touched[name]}
          timeout={500}
          classNames="error-block-animation"
          unmountOnExit
        >
          <div className="select-field--error-block-wrapper">
            <ErrorMessage
              name={name}
              render={(msg) => (
                <div className="select-error-block">
                  <div className="select-validation-message">{t(msg)}</div>
                </div>
              )}
            />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSelectedOutside: PropTypes.bool,
  searchIcon: PropTypes.string,
  maxSelected: PropTypes.number,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  size: PropTypes.oneOf(['lg', 'md']),
  defaultValue: PropTypes.shape({}),
};

Select.defaultProps = {
  className: null,
  label: null,
  hint: null,
  placeholder: null,
  isSearchable: false,
  isSelectedOutside: false,
  isMulti: false,
  searchIcon: null,
  maxSelected: null,
  size: 'lg',
  defaultValue: null,
};

IconSingleValue.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    img: PropTypes.string,
  }).isRequired,
};

IconOption.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    img: PropTypes.string,
  }).isRequired,
};

export default Select;
