/* eslint-disable no-param-reassign */
import { createSelector, createReducer } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { selectors as signUpSelectors } from '../SignUpPage/reducer';
import { selectors as logInSelectors } from '../LogInPage/reducer';
import * as actions from './actions';

const initialState = {
  modalWindow: {
    data: null,
    isOpen: false,
    type: null,
    view: null,
  },
  initPath: null,
  alerts: [],
};

const reducer = createReducer(initialState, {
  [actions.openInfoModalWindow]: (state, action) => {
    state.modalWindow = action.payload;
    state.modalWindow.isOpen = true;
  },
  [actions.closeInfoModalWindow]: (state) => {
    state.modalWindow.isOpen = false;
  },
  [actions.setInitPath]: (state, action) => {
    state.initPath = action.payload;
  },
  [actions.addAlertAction]: (state, action) => {
    state.alerts = [
      ...state.alerts,
      {
        id: uuidv4(),
        ...action.payload,
      },
    ];
  },
  [actions.removeAlertAction]: (state, action) => {
    state.alerts = state.alerts.filter((a) => a.id !== action.payload);
  },
  [actions.clearAlertsAction]: (state) => {
    state.alerts = [];
  },
});

const applicationModalState = (state) => state.initComponentReducer.modalWindow;
const applicationInitPath = (state) => state.initComponentReducer.initPath;

const applicationAlertsData = (state) => state.initComponentReducer.alerts;

const applicationLoadingState = createSelector(
  applicationInitPath,
  signUpSelectors.signupPageLoading,
  signUpSelectors.userInformationPageLoading,
  signUpSelectors.userSocialConfirmationPageLoading,
  logInSelectors.loginPageLoadingState,
  logInSelectors.resetPasswordLoadingState,
  (
    initPath,
    signupPageLoading,
    userInformationPageLoading,
    userSocialConfirmationPageLoading,
    loginPageLoading,
    resetPasswordPageLoading,
  ) =>
    !initPath ||
    signupPageLoading ||
    userInformationPageLoading ||
    userSocialConfirmationPageLoading ||
    loginPageLoading ||
    resetPasswordPageLoading,
);

const selectors = {
  applicationLoadingState,
  applicationModalState,
  applicationInitPath,
  applicationAlertsData,
};

export { selectors, reducer, initialState };

export default reducer;
