import React from 'react';
import PropTypes from 'prop-types';
import './AvatarGroup.scss';

const AvatarGroup = ({ users }) => (
  <div className="avatar-group--wrapper">
    {users.map((u) => (
      <div key={u.id} className="avatar-item" style={{ backgroundImage: `url(${u.avatarUrl})` }} />
    ))}
  </div>
);

AvatarGroup.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      avatarUrl: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AvatarGroup;
