/* eslint-disable global-require */
import ApiService from 'global/services/api';

export class MetricsService extends ApiService {
  getMetricsById = async ({ id, params = '' }) =>
    this.get({
      url: `social-metrics/socials/${id}${params}`,
    });
}

export default new MetricsService();
