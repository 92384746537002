/* eslint-disable no-underscore-dangle, class-methods-use-this, no-param-reassign */
import axios from 'axios';
import i18next from 'i18next';
import HttpService from './http';
import { getFromLocalStorage, getFromSessionStorage } from '../helpers';

export default class ApiService extends HttpService {
  _DEFAULT_OPTIONS = {
    url: '',
    body: null,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept-Language': i18next.language,
    },
    base_api_url: process.env.REACT_APP_API_BASE_URL || '/api-v1/',
  };

  _attachAuthHeaders(options) {
    const token = getFromLocalStorage('token') || getFromSessionStorage('token');

    options.headers = {
      Authorization: `Bearer ${token}`,
      ...options.headers,
    };
  }

  get(options = {}, mockedRequest = null, withAuth = true) {
    const newOptions = { ...this._DEFAULT_OPTIONS, ...options };

    if (withAuth) {
      this._attachAuthHeaders(newOptions);
    }

    if (mockedRequest) {
      return Promise.resolve(mockedRequest);
    }

    return axios.get(`${newOptions.base_api_url}${newOptions.url}`, newOptions);
  }

  post(options = {}, mockedRequest = null, withAuth = true, progressCallback = null) {
    const newOptions = { ...this._DEFAULT_OPTIONS, ...options };

    if (withAuth) {
      this._attachAuthHeaders(newOptions);
    }

    if (mockedRequest) {
      return Promise.resolve(mockedRequest);
    }

    return axios.post(`${newOptions.base_api_url}${newOptions.url}`, newOptions.body, {
      headers: newOptions.headers,
      onUploadProgress: (progressEvent) => progressCallback && progressCallback(progressEvent),
    });
  }

  patch(options = {}, mockedRequest = null, withAuth = true) {
    const newOptions = { ...this._DEFAULT_OPTIONS, ...options };

    if (withAuth) {
      this._attachAuthHeaders(newOptions);
    }

    if (mockedRequest) {
      return Promise.resolve(mockedRequest);
    }

    return axios.patch(`${newOptions.base_api_url}${newOptions.url}`, newOptions.body, {
      headers: newOptions.headers,
    });
  }

  put(options = {}, mockedRequest = null, withAuth = true) {
    const newOptions = { ...this._DEFAULT_OPTIONS, ...options };

    if (withAuth) {
      this._attachAuthHeaders(newOptions);
    }

    if (mockedRequest) {
      return Promise.resolve(mockedRequest);
    }

    return axios.put(`${newOptions.base_api_url}${newOptions.url}`, newOptions.body, {
      headers: newOptions.headers,
    });
  }

  delete(options = {}, mockedRequest = null, withAuth = true) {
    const newOptions = { ...this._DEFAULT_OPTIONS, ...options };

    if (withAuth) {
      this._attachAuthHeaders(newOptions);
    }

    if (mockedRequest) {
      return Promise.resolve(mockedRequest);
    }

    return axios.delete(`${newOptions.base_api_url}${newOptions.url}`, {
      headers: newOptions.headers,
    });
  }
}
