/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncInitialState, asyncReducerHandler } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  userProfile: asyncInitialState,
  userFeed: asyncInitialState,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getProfileByIdAction, 'userProfile'),
  ...asyncReducerHandler(actions.getProfileFeedByIdAction, 'userFeed'),
  ...asyncReducerHandler(actions.addProfilePostAction, 'userFeed'),
  [actions.addProfilePostAction.success]: (state) => {
    state.userFeed.loading = false;
  },
  [actions.addProfilePostAction.error]: (state, action) => {
    state.userFeed.loading = false;
    state.userFeed.error = action.payload;
  },
  [actions.addLikeToPostByIdAction.REQUEST]: (state) => {
    state.userFeed.error = null;
    state.userFeed.loading = false;
  },
  [actions.addLikeToPostByIdAction.SUCCESS]: (state, { payload }) => {
    state.userFeed.data[payload.index].isLike = true;
    state.userFeed.data[payload.index].likesCount += 1;
    state.userFeed.loading = false;
  },
  [actions.addLikeToPostByIdAction.ERROR]: (state, { payload }) => {
    state.userFeed.error = payload;
    state.userFeed.loading = false;
  },
  [actions.deleteLikeToPostByIdAction.REQUEST]: (state) => {
    state.userFeed.error = null;
    state.userFeed.loading = false;
  },
  [actions.deleteLikeToPostByIdAction.SUCCESS]: (state, { payload }) => {
    state.userFeed.data[payload.index].isLike = false;
    state.userFeed.data[payload.index].likesCount -= 1;
    state.userFeed.loading = false;
  },
  [actions.deleteLikeToPostByIdAction.ERROR]: (state, { payload }) => {
    state.userFeed.error = payload;
    state.userFeed.loading = false;
  },
  [actions.getProfilePostCommentsAction.SUCCESS]: (state, action) => {
    const index = state.userFeed.data.indexOf(
      state.userFeed.data.find((el) => el.id === action.payload.postId),
    );

    if (index > -1) {
      state.userFeed.data = [
        ...state.userFeed.data.slice(0, index),
        {
          ...state.userFeed.data[index],
          comments: action.payload.comments,
          commentsCount: action.payload.comments.length,
        },
        ...state.userFeed.data.slice(index + 1, state.userFeed.data.length),
      ];
    }
  },
});

const userProfileData = (state) => state.profileReducer.userProfile.data;
const userProfileLoading = (state) => state.profileReducer.userProfile.loading;

const userFeedData = (state) => state.profileReducer.userFeed.data;
const userFeedLoading = (state) => state.profileReducer.userFeed.loading;

const selectors = {
  userProfileData,
  userProfileLoading,
  userFeedData,
  userFeedLoading,
};

export { selectors, reducer, initialState };
export default reducer;
