import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { forgotPasswordValidationSchema } from './validations';
import Heading from '../../../../../../components/Heading';
import Input from '../../../../../../components/Input';
import Icon from '../../../../../../components/Icon';
import '../../../../LogInPage.scss';

const ForgotPasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={forgotPasswordValidationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className="additional-page--form">
          <Heading
            title="ForgotPasswordPage.heading.title"
            subtitle="ForgotPasswordPage.heading.subtitle"
            position="center"
          />
          <Input
            type="email"
            name="email"
            label={t('ForgotPasswordPage.ForgotPasswordForm.email.label')}
            placeholder={t('ForgotPasswordPage.ForgotPasswordForm.email.placeholder')}
            errors={errors}
            touched={touched}
          />
          <button type="submit" className="btn btn-lg btn-primary">
            {t('ForgotPasswordPage.ForgotPasswordForm.buttons.submit')}
          </button>
          <div className="form--footer">
            <Link to="/sign-up" className="link link-gray link-md">
              <Icon icon="arrow-left" className="link--icon" />
              {t('ForgotPasswordPage.ForgotPasswordForm.link')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
