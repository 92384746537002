import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'global/formats/luxonFormats';

const now = DateTime.now();

const FeedItemPublished = ({ date }) => {
  const { t } = useTranslation();

  const formattedDate = useMemo(() => {
    const publishedDate = DateTime.fromISO(date);
    const isItWasToday = now.hasSame(publishedDate, 'day');

    if (isItWasToday) {
      return `${t('time.today')} ${publishedDate.toFormat(TIME_FORMAT)}`;
    }

    return DateTime.fromISO(date).toFormat(DATE_TIME_FORMAT);
  }, [date]);

  return (
    <div className="published--wrapper">
      <span className="published--date">{formattedDate}</span>
    </div>
  );
};

FeedItemPublished.propTypes = {
  date: PropTypes.string.isRequired,
};

export default FeedItemPublished;
