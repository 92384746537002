export const SOCIALS_NETWORKS = [
  {
    id: 'instagram',
    name: 'ProfileSettingsPage.socials.instagram',
    icon: 'instagram',
    // path: '/signup/social/instagram',
    replace: true,
    path: 'https://www.facebook.com/v15.0/dialog/oauth?response_type=code&client_id=1368082036991443&scope=pages_show_list%2Cpages_read_engagement%2Cinstagram_basic%2Cinstagram_manage_insights&redirect_uri=https%3A%2F%2Fbupnetwork.com%2Fprofile%2Fadd-instagram',
  },
  // {
  //   id: 'facebook',
  //   name: 'ProfileSettingsPage.socials.facebook',
  //   icon: 'facebook',
  //   path: '/signup/social/facebook',
  // },
  // {
  //   id: 'youtube',
  //   name: 'ProfileSettingsPage.socials.youtube',
  //   icon: 'youtube',
  //   path: '/signup/social/youtube',
  // },
  // {
  //   id: 'twitter',
  //   name: 'ProfileSettingsPage.socials.twitter',
  //   icon: 'twitter',
  //   path: '/signup/social/twitter',
  // },
  // {
  //   id: 'tiktok',
  //   name: 'ProfileSettingsPage.socials.tiktok',
  //   icon: 'tiktok',
  //   path: '/signup/social/tiktok',
  // },
];

export default SOCIALS_NETWORKS;
