import * as yup from 'yup';
import {
  fullNameValidationSchema,
  companyNameValidationSchema,
  companyPositionValidationSchema,
  urlValidationSchema,
} from '../../../../../../global/validators';

export const userInformationMainValidationSchema = yup.object().shape({
  view: yup.string().required(),
  companyName: companyNameValidationSchema.when(['view'], {
    is: (view) => view === 'company',
    then: (schema) => schema.required('validations.companyName.required'),
    otherwise: (schema) => schema,
  }),
  businessType: yup.object().when(['view'], {
    is: (view) => view === 'company',
    then: (schema) => schema.required('validations.businessType.required'),
    otherwise: (schema) => schema.nullable(true),
  }),
  location: yup.object().required('validations.location.required'),
  website: urlValidationSchema.when(['view'], {
    is: (view) => view === 'company',
    then: (schema) => schema.required('validations.website.required'),
    otherwise: (schema) => schema,
  }),
  fullName: fullNameValidationSchema.required('validations.fullName.required'),
  positionInCompany: companyPositionValidationSchema.when(['view'], {
    is: (view) => view === 'company',
    then: (schema) => schema.required('validations.companyPosition.required'),
    otherwise: (schema) => schema,
  }),
  gender: yup.object().when(['view'], {
    is: (view) => view === 'influencer',
    then: (schema) => schema.required('validations.gender.required'),
    otherwise: (schema) => schema.nullable(true),
  }),
  birthdayDate: yup
    .date()
    .typeError('validations.date.valid')
    .when(['view'], {
      is: (view) => view === 'influencer',
      then: (schema) => schema.required('validations.date.required'),
      otherwise: (schema) => schema.nullable(),
    }),
});

export const userInformationIndustriesValidationSchema = yup.object().shape({
  industries: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
});

export const userInformationAvatarValidationSchema = yup.object().shape({
  avatar: yup.mixed(),
});

export const userInformationValidationSchema = yup
  .object()
  .shape({})
  .concat(userInformationMainValidationSchema)
  .concat(userInformationIndustriesValidationSchema)
  .concat(userInformationAvatarValidationSchema);
