import React from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { selectors } from './pages/InitComponent/reducer';
import Loader from './components/Loader';
import InfoModalWindow from './components/InfoModalWindow';
import InitComponent from './pages/InitComponent';
import { getFromLocalStorage } from './global/helpers';
import './global/styles/index.scss';
import Alert from './components/Alert';

ChartJS.register(ArcElement, Tooltip, Legend);

const English = require('./global/translations/en.json');
const French = require('./global/translations/fr.json');

i18next.use(initReactI18next).init({
  resources: {
    en: English,
    fr: French,
  },
  lng: getFromLocalStorage('lng') || 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

const App = () => {
  const applicationLoading = useSelector(selectors.applicationLoadingState);

  const applicationModalState = useSelector(selectors.applicationModalState);

  const alertsData = useSelector(selectors.applicationAlertsData);

  return (
    <div className="application-wrapper__containers-wrapper">
      <div className="application-alerts-container">
        {alertsData.map((alert) => (
          <Alert key={alert.id} id={alert.id} type={alert.type} message={alert.message} />
        ))}
      </div>
      <Loader visible={applicationLoading} />
      <InfoModalWindow
        view={applicationModalState.view}
        type={applicationModalState.type}
        isOpened={applicationModalState.isOpen}
        onConfirm={applicationModalState.data?.onConfirm}
      />
      <BrowserRouter>
        <InitComponent />
      </BrowserRouter>
    </div>
  );
};

export default App;
