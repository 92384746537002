import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import ROLES from '../../constants';
import './UserInfo.scss';

const UserInfo = ({ className, size, user }) => (
  <div className={`user-info--wrapper ${className}`}>
    <Avatar size={size} file={user.cover} />
    <div className={`info info-${size}`}>
      <div className="info--name-wrapper">
        {user.fullName && (
          <Link to={`/profile/${user.id}`} className={`info--name info--name-${size}`}>
            {user.fullName}
          </Link>
        )}
        {user.isVerified && <Icon icon="official" className="info--icon" />}
      </div>
      <p className={`info--subtitle info--subtitle-${size}`}>
        {user.role === ROLES.company
          ? `${user.positionInCompany ? user.positionInCompany : ''} ${
              user.companyName ? user.companyName : ''
            }`
          : user.email}
      </p>
    </div>
  </div>
);

UserInfo.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string,
    cover: PropTypes.string,
    positionInCompany: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
    isVerified: PropTypes.bool,
    companyName: PropTypes.string,
  }).isRequired,
};

UserInfo.defaultProps = {
  size: 'md',
  className: null,
};

export default UserInfo;
