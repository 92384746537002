import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from 'pages/ProfilePage/reducer';
import {
  addLikeToPostByIdAction,
  addProfilePostAction,
  deleteLikeToPostByIdAction,
  getProfileFeedByIdAction,
} from 'pages/ProfilePage/actions';
import ContentLoader from 'components/ContentLoader';
import FeedItem from 'components/FeedItem';
import PostFeedEditor from 'components/PostFeedEditor';
import './ProfileFeed.scss';

const ProfileFeed = ({ isProfilePage, userId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [sortBy, setSortBy] = useState({ value: 'desc', label: 'New' });

  const feedData = useSelector(selectors.userFeedData);
  const feedLoading = useSelector(selectors.userFeedLoading);

  useEffect(() => {
    if (sortBy.value === 'desc' && userId) {
      dispatch(getProfileFeedByIdAction.request({ id: userId, sort: 'desc=updatedAt' }));
    } else if (sortBy.value === 'asc' && userId) {
      dispatch(getProfileFeedByIdAction.request({ id: userId, sort: 'asc=updatedAt' }));
    }
  }, [userId, sortBy]);

  const handleAddPost = (data) => {
    const onSuccess = () => {
      if (sortBy.value === 'desc' && userId) {
        dispatch(getProfileFeedByIdAction.request({ id: userId, sort: 'desc=updatedAt' }));
      } else if (sortBy.value === 'asc' && userId) {
        dispatch(getProfileFeedByIdAction.request({ id: userId, sort: 'asc=updatedAt' }));
      }
    };

    dispatch(
      addProfilePostAction.request({
        data,
        onSuccess,
      }),
    );
  };

  const addRepostCallback = useCallback(() => {
    // Reload posts only on my profile page
    if (!isProfilePage) {
      if (sortBy.value === 'desc' && userId) {
        dispatch(getProfileFeedByIdAction.request({ id: userId, sort: 'desc=updatedAt' }));
      } else if (sortBy.value === 'asc' && userId) {
        dispatch(getProfileFeedByIdAction.request({ id: userId, sort: 'asc=updatedAt' }));
      }
    }
  }, [sortBy]);

  return (
    <div className="profile-feed--wrapper">
      {feedLoading ? (
        <ContentLoader visible={feedLoading} />
      ) : (
        <>
          <div className="profile-feed--header">
            <h2>{t('ProfilePage.ProfileFeed.title')}</h2>
            <ReactSelect
              className="profile-feed--header-select"
              classNamePrefix="sort-select"
              isSearchable={false}
              defaultValue={sortBy}
              options={[
                { value: 'desc', label: 'New' },
                { value: 'asc', label: 'Old' },
              ]}
              onChange={(selectedOption) => setSortBy(selectedOption)}
            />
          </div>
          {!isProfilePage ? <PostFeedEditor onSubmit={handleAddPost} /> : null}
          {feedData?.length > 0 && (
            <div className="profile-feed--main">
              {feedData?.map((post) => (
                <FeedItem
                  key={post.id}
                  posts={feedData}
                  post={post}
                  profileData={{ id: userId }}
                  onDeleteLike={deleteLikeToPostByIdAction}
                  onAddLike={addLikeToPostByIdAction}
                  isProfilePage
                  addRepostCallback={addRepostCallback}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

ProfileFeed.propTypes = {
  userId: PropTypes.string,
  isProfilePage: PropTypes.bool.isRequired,
};

ProfileFeed.defaultProps = {
  userId: null,
};

export default ProfileFeed;
