import React from 'react';
import PropTypes from 'prop-types';
import './ChatListItem.scss';
import { useTranslation } from 'react-i18next';
import messageDateFormatter from 'pages/ChatPage/helpers';
import UserInfo from 'components/UserInfo';

const ChatListItem = ({ chat, handleClick, isActive }) => {
  const { t } = useTranslation();

  return (
    <li
      role="menuitem"
      onClick={() => handleClick(chat)}
      key={chat.id}
      className={`chats-list--item ${isActive && 'chats-list--item-active'}`}
    >
      <div className="item--user-info-wrapper">
        <UserInfo size="xxl" user={chat.participant} />
        <p className="item--message-date">{messageDateFormatter(t, chat.updatedAt)}</p>
      </div>
      <div className="item--message-wrapper">
        <p className="item--message-text">
          {chat.lastMessage?.text ? chat.lastMessage.text : t('ChatPage.chats.item.noMessage')}
        </p>
        {chat.newMessagesCount > 0 && (
          <p className="item--message-count">{chat.newMessagesCount}</p>
        )}
      </div>
    </li>
  );
};

ChatListItem.propTypes = {
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
  chat: PropTypes.shape({
    id: PropTypes.string,
    updatedAt: PropTypes.string,
    lastMessage: PropTypes.shape({
      text: PropTypes.string,
    }),
    newMessagesCount: PropTypes.number,
    participant: PropTypes.shape({
      fullName: PropTypes.string,
      positionInCompany: PropTypes.string,
      isVerified: PropTypes.bool,
      cover: PropTypes.string,
    }),
  }),
};

ChatListItem.defaultProps = {
  isActive: false,
  handleClick: null,
  chat: {
    id: null,
    updatedAt: '',
    lastMessage: {
      text: '',
    },
    newMessagesCount: 0,
    participant: {
      fullName: '',
      positionInCompany: '',
      isVerified: false,
      cover: '',
    },
  },
};

export default ChatListItem;
