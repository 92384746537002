export const LOG_IN_USER = 'LOG_IN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_USER_BY_TOKEN = 'GET_USER_BY_TOKEN';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const CHECK_EMAIL_VERIFICATION_CODE = 'CHECK_EMAIL_VERIFICATION_CODE';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';

export const CHANGE_PASSWORD_RESET_STATUS = 'CHANGE_PASSWORD_RESET_STATUS';

export const RESET_PASSWORD_STEPS = {
  requestSent: 'request_sent',
  passwordChanging: 'password_changing',
  passwordChanged: 'password_changed',
};

export default LOG_IN_USER;
