import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Avatar from '../../../../components/Avatar';
import Icon from '../../../../components/Icon';
import ROLES from '../../../../constants';
import UserSocials from '../../../../components/UserSocials';
import './NetworkUser.scss';

const NetworkUser = ({
  id,
  user,
  isFriend,
  isFollower,
  onApprove,
  onDecline,
  onUnfollow,
  onMsgSent,
}) => {
  const { t } = useTranslation();

  return (
    <div className="network-user--wrapper">
      <div className="user-container">
        <div className="user--avatar">
          <Avatar size="lg" file={user.cover} />
        </div>
        <div className="user--details">
          <h2 className="user--name">
            <Link to={`/profile/${user.id}`}>{user.fullName}</Link>
            {user.isVerified ? <Icon icon="verified" className="verified-icon" /> : null}
          </h2>
          {user.role === ROLES.influencer ? (
            <UserSocials />
          ) : (
            <p className="user--position">{user.positionInCompany}</p>
          )}
        </div>
      </div>
      {isFriend ? (
        <div className="user-controls">
          <button
            onClick={() => onMsgSent(user.id)}
            type="button"
            className="btn btn-md btn-secondary-gray"
          >
            {t('NetworkPage.networkUser.buttons.message')}
          </button>
          <button type="button" className="btn btn-md btn-secondary-gray">
            <Icon icon="dotted-options" />
          </button>
        </div>
      ) : null}
      {isFollower ? (
        <div className="user-controls">
          <button
            onClick={() => onUnfollow(id)}
            type="button"
            className="btn btn-md btn-secondary-gray"
          >
            {t('NetworkPage.networkUser.buttons.unfollow')}
          </button>
        </div>
      ) : null}
      {!isFollower && !isFriend ? (
        <div className="user-controls">
          <button type="button" className="btn btn-md btn-secondary" onClick={() => onDecline(id)}>
            {t('NetworkPage.networkUser.buttons.ignore')}
          </button>
          <button type="button" className="btn btn-md btn-primary" onClick={() => onApprove(id)}>
            {t('NetworkPage.networkUser.buttons.accept')}
          </button>
        </div>
      ) : null}
    </div>
  );
};

NetworkUser.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.shape({
    cover: PropTypes.string,
    fullName: PropTypes.string,
    positionInCompany: PropTypes.string,
    role: PropTypes.string,
    isVerified: PropTypes.bool,
    id: PropTypes.string,
  }).isRequired,
  isFriend: PropTypes.bool,
  isFollower: PropTypes.bool,
  onApprove: PropTypes.func,
  onDecline: PropTypes.func,
  onUnfollow: PropTypes.func,
  onMsgSent: PropTypes.func,
};

NetworkUser.defaultProps = {
  isFriend: false,
  isFollower: false,
  onApprove: () => {},
  onDecline: () => {},
  onUnfollow: () => {},
  onMsgSent: () => {},
};

export default NetworkUser;
