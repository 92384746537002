import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './Loader.scss';

const Loader = ({ visible }) => (
  <CSSTransition in={visible} timeout={500} classNames="spinner-appearing-animation" unmountOnExit>
    <div className="spinner-box">
      <div className="circle-border">
        <div className="circle-core" />
      </div>
    </div>
  </CSSTransition>
);

Loader.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default Loader;
