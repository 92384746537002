import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import Avatar from '../../../../components/Avatar';
import FileUpload from '../../../../components/FileUpload';
import ROLES from '../../../../constants';
import { userInformationValidationSchema } from '../../../SignUpPage/components/UserInformationPage/components/UserInformationForm/validations';
import { FORMS } from './constants';

const ProfileDetails = ({ profile, metadata, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className="profile-settings-page--form" id="details">
      <h2 className="profile-settings-page--form-title">
        {t(
          `ProfileSettingsPage.titles.${
            profile?.role === ROLES.influencer ? 'influencer' : 'company'
          }.details`,
        )}
      </h2>
      <Formik
        initialValues={{
          view: profile?.role?.toLowerCase(),
          companyName: profile?.companyName || '',
          website: profile?.website || '',
          fullName: profile?.fullName || '',
          positionInCompany: profile?.positionInCompany || '',
          avatar: profile?.cover || null,
          birthdayDate: profile?.dob || null,
          businessType: metadata?.businessTypes?.find(
            (bt) => bt.value === profile?.businessType?.id,
          ),
          location: metadata?.locations?.find((l) => l.value === profile?.location?.id),
          gender: metadata?.genders?.find((g) => g.value === profile?.gender),

          currentPassword: '',
          newPassword: '',
          repeatNewPassword: '',
        }}
        // enableReinitialize
        validationSchema={userInformationValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldTouched,
          setFieldValue,
          isValid,
          dirty,
        }) => (
          <Form>
            {FORMS[profile?.role]?.map((item) => {
              if (item.type === 'select') {
                return (
                  <Select
                    key={item.id}
                    name={item.name}
                    label={t(item.label)}
                    options={metadata?.[`${item.id}s`] || []}
                    errors={errors}
                    touched={touched}
                  />
                );
              }

              return (
                <Input
                  key={item.id}
                  type={item.type}
                  name={item.name}
                  label={t(item.label)}
                  placeholder={t(item.placeholder)}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                />
              );
            })}

            <h2 className="profile-settings-page--form-title m-top">
              {t(`ProfileSettingsPage.titles.password`)}
            </h2>
            {FORMS.password.map((item) => (
              <Input
                key={item.id}
                type={item.type}
                name={item.name}
                label={t(item.label)}
                placeholder={t(item.placeholder)}
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />
            ))}
            <div className="profile-settings-page--form-footer w-100">
              <button
                type="submit"
                className="btn btn-md btn-primary"
                // disabled={!(isValid && dirty)}
                disabled
              >
                {t('ProfileSettingsPage.footer.buttons.saveChanges')}
              </button>
            </div>

            <div className="avatar-input--wrapper">
              <Avatar size="xxxl" file={values.avatar} />
              <FileUpload
                className="avatar-upload-input"
                name="avatar"
                values={values}
                touched={touched}
                errors={errors}
                withFilePreview={false}
                buttonText={t('ProfileSettingsPage.form.avatar.buttonText')}
                descriptionText={t('ProfileSettingsPage.form.avatar.descriptionText')}
                formatDescription={t('ProfileSettingsPage.form.avatar.formatDescription')}
                onChange={setFieldValue}
                onTouch={setFieldTouched}
              />
            </div>
            <div className="profile-settings-page--form-footer">
              <button type="button" className="btn btn-md btn-tertiary">
                {t('ProfileSettingsPage.form.buttons.cancel')}
              </button>
              <button
                type="submit"
                className="btn btn-md btn-primary"
                disabled={!(isValid && dirty)}
              >
                {t('ProfileSettingsPage.form.buttons.save')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ProfileDetails.propTypes = {
  profile: PropTypes.shape({
    companyName: PropTypes.string,
    website: PropTypes.string,
    fullName: PropTypes.string,
    positionInCompany: PropTypes.string,
    cover: PropTypes.string,
    role: PropTypes.string,
    dob: PropTypes.string,
    businessType: PropTypes.shape({
      id: PropTypes.string,
    }),
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
    gender: PropTypes.string,
  }),
  metadata: PropTypes.shape({
    businessTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    genders: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }),
  onSubmit: PropTypes.func.isRequired,
};

ProfileDetails.defaultProps = {
  profile: null,
  metadata: null,
};

export default ProfileDetails;
