import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import ReactSelect from 'react-select';
import { buildQueryObject } from 'pages/SearchPage/helpers';
import SearchFilers from 'pages/SearchPage/components/SearchFilers';
import useQuery from 'hooks/useQuery';
import { getFormMetadataAction } from 'pages/MainPage/actions';
import { createChatAction, getAllChatsAction } from 'pages/ChatPage/actions';
import ChatComponent from 'components/ChatComponent';
import {
  approveFriendRequestAction,
  declineFriendRequestAction,
  getFriendsAction,
  getFriendsRequestsAction,
} from './actions';
import { selectors as mainSelectors } from '../MainPage/reducer';
import { selectors } from './reducer';
import ContentLoader from '../../components/ContentLoader';
import Icon from '../../components/Icon';
import NetworkUser from './components/NetworkUser';
import Input from '../../components/Input';
import Avatar from '../../components/Avatar';
import './NetworkPage.scss';
import { selectors as chatSelectors } from '../ChatPage/reducer';
import NetworkSettings from './components/NetworkSettings';
import NetworkNav from './components/NetworkNav';

const NetworkPage = () => {
  const dispatch = useDispatch();
  const params = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState({ value: 'desc', label: 'Newest' });

  const user = useSelector(mainSelectors.userProfileData);

  const formMetadataData = useSelector(mainSelectors.formMetadataData);
  const formMetadataLoading = useSelector(mainSelectors.formMetadataLoading);

  const requestsData = useSelector(selectors.requestsData);
  const requestsLoading = useSelector(selectors.requestsLoading);
  const friendsData = useSelector(selectors.friendsData);
  const friendsLoading = useSelector(selectors.friendsLoading);

  const filteredChats = useSelector(chatSelectors.getFilteredChats);

  // eslint-disable-next-line no-unused-vars
  const [isFilterShowed, setIsFilterShowed] = useState(false);

  useEffect(() => {
    if (user?.id) {
      dispatch(
        getFriendsRequestsAction.request({
          id: user.id,
          params: { desc: 'createdAt', acceptor: user.id },
        }),
      );
    }
  }, [user]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const queryObject = buildQueryObject(
      params,
      formMetadataData?.genders,
      formMetadataData?.locations,
      formMetadataData?.businessTypes,
      formMetadataData?.industries,
    );

    if (user?.id) {
      dispatch(getFriendsAction.request({ id: user.id, params: { [sortBy.value]: 'createdAt' } }));
    }
  }, [user, sortBy]);

  useEffect(() => {
    if (!formMetadataData) dispatch(getFormMetadataAction.request());
    if (!filteredChats?.length) dispatch(getAllChatsAction.request());
  }, []);

  const handleApproveFriendRequest = (id) => {
    dispatch(
      approveFriendRequestAction.request({
        id,
        userId: user.id,
      }),
    );
  };

  const handleDeclineFriendRequest = (id) => {
    dispatch(
      declineFriendRequestAction.request({
        id,
        userId: user.id,
      }),
    );
  };

  const handleSearchKeyDown = (event, value) => {
    if (event.key === 'Enter') {
      // Search func like on search page should be there
      // eslint-disable-next-line no-console
      console.log(value);
    }
  };

  const handleCloseSearch = () => {
    setIsFilterShowed(false);
  };

  const handleSearch = () => {
    setIsFilterShowed(false);
  };

  const handleMessageBtnClick = async (id) => {
    const foundChat = await filteredChats.find((chat) => chat.participant?.id === id);
    if (foundChat) {
      navigate('/messages', { state: { chatId: foundChat.participant.id } });
    } else {
      dispatch(createChatAction.request({ profileId: id, navigate }));
    }
  };

  return (
    <div className="network-page--wrapper">
      {requestsLoading || friendsLoading || formMetadataLoading ? (
        <ContentLoader visible={requestsLoading || friendsLoading || formMetadataLoading} />
      ) : (
        <>
          <ChatComponent />
          <SearchFilers
            view="influencer"
            metadata={formMetadataData}
            isOpen={isFilterShowed}
            initialValue={buildQueryObject(
              params,
              formMetadataData?.genders,
              formMetadataData?.locations,
              formMetadataData?.businessTypes,
              formMetadataData?.industries,
            )}
            onClose={handleCloseSearch}
            onSearch={handleSearch}
            canChangeView
          />
          <div className="app-pages--content">
            <div className="content--main">
              {requestsData?.length ? (
                <>
                  <div className="content--header m-35">
                    <h2 className="header--title">{t('NetworkPage.newRequest.title')}</h2>
                    {location.pathname === '/network/all' ? (
                      <Link to="/network/requests" className="btn btn-link">
                        {t('NetworkPage.newRequest.buttons.showAll')}
                        <Icon icon="arrow-left" className="btn--icon" />
                      </Link>
                    ) : null}
                  </div>
                  <div className="content--body">
                    {requestsData?.map((request) => (
                      <NetworkUser
                        key={request.id}
                        id={request.id}
                        user={request.fromMe ? request.acceptor : request.initiator}
                        onApprove={handleApproveFriendRequest}
                        onDecline={handleDeclineFriendRequest}
                      />
                    ))}
                  </div>
                </>
              ) : null}
              <div className="content--header m-15">
                <h2 className="header--title">{t('NetworkPage.myNetwork.title')}</h2>
                <ReactSelect
                  classNamePrefix="sort-select"
                  isSearchable={false}
                  defaultValue={sortBy}
                  options={[
                    { value: 'desc', label: 'Newest' },
                    { value: 'asc', label: 'Oldest' },
                  ]}
                  onChange={(selectedOption) => setSortBy(selectedOption)}
                />
              </div>
              <div className="form--wrapper">
                <Formik
                  initialValues={{
                    search: '',
                  }}
                >
                  {({ errors, touched, values }) => (
                    <Form className="form">
                      <Input
                        name="search"
                        icon="search"
                        className="search-input"
                        placeholder={t('NetworkPage.myNetwork.form.search.placeholder')}
                        errors={errors}
                        touched={touched}
                        onKeyDown={(event) => handleSearchKeyDown(event, values.search)}
                      />
                      <button
                        type="button"
                        className="btn btn-secondary-gray btn-md"
                        onClick={() => setIsFilterShowed(true)}
                      >
                        <Icon icon="filter" className="btn--icon" />
                        {t('NetworkPage.myNetwork.buttons.filter')}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="content--body">
                {friendsData?.length ? (
                  <>
                    {friendsData.map((friend) => (
                      <NetworkUser
                        key={friend.id}
                        id={friend.id}
                        isFriend
                        user={friend.fromMe ? friend.acceptor : friend.initiator}
                        onMsgSent={handleMessageBtnClick}
                      />
                    ))}
                  </>
                ) : (
                  <div className="friends--empty-state">
                    <Avatar size="lg" className="avatar-icon" />
                    <h3 className="empty-state--title">
                      {t('NetworkPage.myNetwork.emptyState.title')}
                    </h3>
                    <p className="empty-state--description">
                      {t('NetworkPage.myNetwork.emptyState.description')}
                    </p>
                    <Link to="/search/influencer" className="btn btn-primary btn-md">
                      {t('NetworkPage.myNetwork.emptyState.button')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="content--additional">
              <NetworkNav />
              <NetworkSettings />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NetworkPage;
