import React from 'react';
import ReactDOM from 'react-dom';
import InfoModalWindow from 'components/InfoModalWindow';

const ErrorFeedbackModal = ({ isOpened, onCancel }) =>
  ReactDOM.createPortal(
    <InfoModalWindow
      type="error"
      view="FEEDBACK_ADD_ERROR"
      isOpened={isOpened}
      onCancel={onCancel}
    />,
    document.getElementById('bup-app'),
  );

export default ErrorFeedbackModal;
