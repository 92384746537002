import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectors } from '../../reducer';
import { changePasswordResetStatusAction, setNewPasswordAction } from '../../actions';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import SetNewPasswordForm from './components/SetNewPasswordForm';
import { RESET_PASSWORD_STEPS } from '../../constants';

const SetNewPasswordPage = () => {
  const dispatch = useDispatch();

  const resetPasswordStatus = useSelector(selectors.resetPasswordStatus);

  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    if (resetPasswordStatus !== RESET_PASSWORD_STEPS.passwordChanging || !params.get('token')) {
      navigate('/auth');
    }
    // eslint-disable-next-line
  }, []);

  const setNewPasswordPostEffect = () => {
    dispatch(changePasswordResetStatusAction(RESET_PASSWORD_STEPS.passwordChanged));
    navigate('/auth/password-reset/success');
  };

  const handleSetNewPassword = (data) => {
    dispatch(
      setNewPasswordAction.request({
        data: {
          data: {
            password: data.password,
          },
          token: params.get('token'),
        },
        onSuccess: setNewPasswordPostEffect,
      }),
    );
  };

  return (
    <div className="additional-page--wrapper">
      <Header />
      <SetNewPasswordForm onSubmit={handleSetNewPassword} />
      <Footer />
    </div>
  );
};

export default SetNewPasswordPage;
