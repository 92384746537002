import React from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import Icon from '../../../Icon';
import { TEXT_ALIGN_TYPES } from '../../constants';

const BlockButton = ({ format, icon, toggleBlock, isBlockActive }) => {
  const editor = useSlate();

  const handleClick = (event) => {
    event.preventDefault();

    toggleBlock(editor, format);
  };

  return (
    <button
      type="button"
      className={`edit-control-btn ${
        isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
          ? 'active'
          : ''
      }`}
      onMouseDown={handleClick}
    >
      <Icon icon={icon} />
    </button>
  );
};

BlockButton.propTypes = {
  format: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  toggleBlock: PropTypes.func.isRequired,
  isBlockActive: PropTypes.func.isRequired,
};

export default BlockButton;
