import { combineReducers } from 'redux';
import { initComponentReducer } from './pages/InitComponent';
import { signUpReducer } from './pages/SignUpPage';
import { logInReducer } from './pages/LogInPage';
import { profileSettingsReducer } from './pages/ProfileSettingsPage';
import { mainReducer } from './pages/MainPage';
import { profileReducer } from './pages/ProfilePage';
import { faqReducer } from './pages/FaqPage';
import { searchReducer } from './pages/SearchPage';
import { feedReducer } from './pages/FeedPage';
import { chatReducer } from './pages/ChatPage';
import { feedbacksReducer } from './pages/FeedbacksPage';
import { savedListReducer } from './pages/SavedListPage';
import { comparePageReducer } from './pages/ComparePage';
import { networkReducer } from './pages/NetworkPage';
import { instagramReducer } from './pages/AddInstagramAccountPage';
import { metricsReducer } from './pages/DashboardPage';

export default combineReducers({
  initComponentReducer,
  signUpReducer,
  logInReducer,
  profileSettingsReducer,
  mainReducer,
  profileReducer,
  faqReducer,
  searchReducer,
  feedReducer,
  chatReducer,
  feedbacksReducer,
  savedListReducer,
  comparePageReducer,
  networkReducer,
  instagramReducer,
  metricsReducer,
});
