export const ROUTES = [
  {
    id: 'feed',
    name: 'sidebar.routes.feed',
    path: '/feed',
    icon: 'nav-house',
  },
  {
    id: 'discover',
    name: 'sidebar.routes.discover',
    path: '/search/influencer',
    icon: 'nav-search',
  },
  {
    id: 'companySearch',
    name: 'sidebar.routes.companySearch',
    path: '/search/company',
    icon: 'nav-company',
  },
  {
    id: 'dashboard',
    name: 'sidebar.routes.dashboard',
    path: '/dashboard',
    icon: 'nav-dashboard',
  },
  {
    id: 'network',
    name: 'sidebar.routes.network',
    path: '/network/all',
    icon: 'nav-network',
  },
  {
    id: 'messages',
    name: 'sidebar.routes.messages',
    path: '/messages',
    icon: 'nav-message',
  },
];

export const COMPANY_ROUTES = [
  {
    id: 'favorite',
    name: 'sidebar.routes.favorite',
    path: '/favorite',
    icon: 'nav-favorite',
  },
  {
    id: 'compare',
    name: 'sidebar.routes.compare',
    path: '/compare',
    icon: 'nav-compare',
  },
];

export const FOOTER_LINKS = [
  {
    id: 1,
    path: '/',
    name: 'sidebar.footer.termsOfService',
  },
  {
    id: 2,
    path: '/',
    name: 'sidebar.footer.privacyPolicy',
  },
  {
    id: 3,
    path: '/',
    name: 'sidebar.footer.about',
  },
  {
    id: 4,
    path: '/',
    name: 'sidebar.footer.forPartners',
  },
  {
    id: 5,
    path: '/',
    name: 'sidebar.footer.cookie',
  },
];

export default ROUTES;
