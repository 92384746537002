import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './SearchSubHeader.scss';

const SearchSubHeader = ({ type, amount }) => {
  const { t } = useTranslation();

  return (
    <div className="search-sub-header">
      <h2 className="header--title">{t(`SearchPage.SearchSubHeader.${type}.title`)}</h2>
      <span className="header--value">
        {amount}
        {t(`SearchPage.SearchSubHeader.${type}.${amount === 1 ? 'amountValueOne' : 'amountValue'}`)}
      </span>
    </div>
  );
};

SearchSubHeader.propTypes = {
  amount: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['company', 'influencer']).isRequired,
};

export default SearchSubHeader;
