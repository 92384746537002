import React from 'react';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-code-input';
import { ErrorMessage, Field } from 'formik';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import './CodeInput.scss';

const CodeInput = ({
  type,
  fields,
  name,
  label,
  hint,
  placeholder,
  autoFocus,
  disabled,
  inputMode,
  className,
  errors,
  touched,
  shouldValidate,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <div onClick={onClick} className={className}>
      <div className="code-input-field--wrapper">
        {label && (
          <label htmlFor={name} className="code-input-label">
            {label}
          </label>
        )}
        <div className="code-input--wrapper">
          <Field name={name} placeholder={placeholder}>
            {({ field }) => (
              <ReactCodeInput
                type={type}
                fields={fields}
                name={field.name}
                value={field.value}
                placeholder={field.placeholder}
                inputMode={inputMode}
                disabled={disabled}
                className="code-input-box"
                autoFocus={autoFocus}
                onChange={(value) => {
                  field.onChange({ target: { value, name: field.name } });
                }}
              />
            )}
          </Field>
        </div>
        {hint && ((!errors[name] && !touched[name]) || !shouldValidate) && (
          <div className="code-input-field--sub-label">{hint}</div>
        )}
        <CSSTransition
          in={errors[name] && touched[name] && shouldValidate}
          timeout={500}
          classNames="error-block-animation"
          unmountOnExit
        >
          <div className="code-input-field--error-block-wrapper">
            <ErrorMessage
              name={name}
              render={(msg) => (
                <div className="code-input-error-block">
                  <div className="input-validation-message">{t(msg)}</div>
                </div>
              )}
            />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

CodeInput.propTypes = {
  type: PropTypes.string,
  fields: PropTypes.number,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  inputMode: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
};

CodeInput.defaultProps = {
  type: 'text',
  fields: 4,
  autoFocus: true,
  disabled: false,
  shouldValidate: false,
  inputMode: 'numeric',
  className: null,
  label: null,
  hint: null,
  placeholder: null,
  onClick: null,
};

export default CodeInput;
