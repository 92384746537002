import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/Icon';
import './SearchEmptyState.scss';

const SearchEmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className="search-empty-state--wrapper">
      <Icon icon="empty-state-search" className="empty-state-icon" />
      <h3 className="empty-state--title">{t('SearchPage.SearchEmptyState.title')}</h3>
      <p className="empty-state--description">{t('SearchPage.SearchEmptyState.description')}</p>
    </div>
  );
};

export default SearchEmptyState;
