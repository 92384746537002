import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { Formik, Form } from 'formik';
// import Box from '@mui/material/Box';
// import Slider from '@mui/material/Slider';
import Icon from '../../../../components/Icon';
import Select from '../../../../components/Select';
import InputDebounce from '../../../../components/InputDebounce';
import CheckboxGroupItem from '../../../../components/CheckboxGroupItem';
import Checkbox from '../../../../components/Checkbox';
import SwitchButton from '../../../../components/SwitchButton';
import { AUDIENCE_QUALITY_SCORE, INITIAL_FORM_VALUES } from './constants';
import './SearchFilers.scss';

const SearchFilers = ({
  view,
  metadata,
  isOpen,
  initialValue,
  onClose,
  onSearch,
  canChangeView,
}) => {
  const { t } = useTranslation();

  const [viewState, setViewState] = useState(view);

  const [isBackdropVisible, setIsBackdropVisible] = useState(false);
  const [isAccordionCollapsed, setIsAccordionCollapsed] = useState(false);
  const [checkedList, setCheckedList] = useState(initialValue.audience_quality || []);

  const handleClose = () => {
    setIsBackdropVisible(false);
  };

  const handleCloseOutside = (e) => {
    if (!e.target.closest('.filters-container-wrapper')) {
      onClose();
    }
  };

  const handelViewChange = (e) => {
    if (e.target.checked) {
      setViewState('company');
    } else {
      setViewState('influencer');
    }
  };

  const itemList = AUDIENCE_QUALITY_SCORE?.map((q) => ({
    id: q.id,
    name: q.value,
  }));

  return (
    <CSSTransition
      in={isOpen}
      classNames="backdrop-animation"
      unmountOnExit
      timeout={400}
      onEntering={() => {
        setIsBackdropVisible(true);
        document.body.classList.add('no-scroll');
      }}
      onExited={() => {
        setIsBackdropVisible(false);
        document.body.classList.remove('no-scroll');
      }}
    >
      <div className="search-filters-wrapper" onClick={handleCloseOutside}>
        <CSSTransition
          in={isBackdropVisible}
          classNames="filter-container-animation"
          unmountOnExit
          timeout={400}
          onExited={() => {
            onClose();
          }}
        >
          <div className="filters-container-wrapper">
            <div className="filters-container--header">
              <h2 className="header--title">{t(`SearchFilers.headers.${viewState}`)}</h2>
              <button type="button" className="header--close-btn" onClick={handleClose}>
                <Icon icon="cross" className="close-btn--icon" />
              </button>
            </div>
            <Formik
              initialValues={{
                industry: null,
                location: null,
                business_type: null,
                gender: null,
                age: [0, 0],
                followers: [0, 0],
                audience_location: null,
                audience_gender: null,
                is_verified_only: false,
                ...initialValue,
              }}
              enableReinitialize
              onSubmit={(values) => {
                onSearch({
                  ...values,
                  audience_quality: checkedList,
                });
                document.body.classList.remove('no-scroll');
              }}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form className="filters-container--form">
                  {canChangeView && (
                    <SwitchButton
                      defaultValue={viewState === 'company'}
                      optionOn={t('SearchFilers.form.type.company')}
                      optionOff={t('SearchFilers.form.type.influencer')}
                      label={t('SearchFilers.form.type.label')}
                      onChange={handelViewChange}
                    />
                  )}
                  <Select
                    name="industry"
                    label={t('SearchFilers.form.industry.label')}
                    placeholder={t('SearchFilers.form.industry.placeholder')}
                    options={metadata?.industries || []}
                    errors={errors}
                    touched={touched}
                  />
                  {viewState === 'company' && (
                    <Select
                      name="business_type"
                      label={t('SearchFilers.form.businessType.label')}
                      placeholder={t('SearchFilers.form.businessType.placeholder')}
                      options={metadata?.businessTypes || []}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                  <Select
                    name="location"
                    label={t('SearchFilers.form.location.label')}
                    placeholder={t('SearchFilers.form.location.placeholder')}
                    options={metadata?.locations || []}
                    errors={errors}
                    touched={touched}
                  />
                  {viewState === 'influencer' && (
                    <>
                      <Select
                        name="gender"
                        label={t('SearchFilers.form.gender.label')}
                        placeholder={t('SearchFilers.form.gender.placeholder')}
                        options={metadata?.genders || []}
                        errors={errors}
                        touched={touched}
                      />
                      <div className="range-wrapper">
                        <span className="range-label">{t('SearchFilers.form.age.label')}</span>
                        <div className="range-inputs--wrapper">
                          <InputDebounce
                            className="range-input-wrapper"
                            name="age"
                            placeholder={t('SearchFilers.form.age.placeholderFrom')}
                            type="number"
                            value={values.age[0]}
                            values={values}
                            errors={errors}
                            touched={touched}
                            onChange={(value) => {
                              if (value > values.age[1]) {
                                setFieldValue('age', [values.age[1], Number(value)]);
                              } else {
                                setFieldValue('age', [Number(value), values.age[1]]);
                              }
                            }}
                          />
                          <InputDebounce
                            className="range-input-wrapper"
                            name="age"
                            placeholder={t('SearchFilers.form.age.placeholderTo')}
                            type="number"
                            value={values.age[1]}
                            values={values}
                            errors={errors}
                            touched={touched}
                            onChange={(value) => {
                              if (value < values.age[0]) {
                                setFieldValue('age', [Number(value), values.age[0]]);
                              } else {
                                setFieldValue('age', [values.age[0], Number(value)]);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="range-wrapper">
                        <span className="range-label">
                          {t('SearchFilers.form.followers.label')}
                        </span>
                        <div className="range-inputs--wrapper">
                          <InputDebounce
                            className="range-input-wrapper"
                            name="followers"
                            placeholder={t('SearchFilers.form.followers.placeholderFrom')}
                            type="number"
                            value={values.followers[0]}
                            values={values}
                            errors={errors}
                            touched={touched}
                            onChange={(value) => {
                              if (value > values.followers[1]) {
                                setFieldValue('followers', [values.followers[1], Number(value)]);
                              } else {
                                setFieldValue('followers', [Number(value), values.followers[1]]);
                              }
                            }}
                          />
                          <InputDebounce
                            className="range-input-wrapper"
                            name="followers"
                            placeholder={t('SearchFilers.form.followers.placeholderTo')}
                            type="number"
                            value={values.followers[1]}
                            values={values}
                            errors={errors}
                            touched={touched}
                            onChange={(value) => {
                              if (value < values.followers[0]) {
                                setFieldValue('followers', [Number(value), values.followers[0]]);
                              } else {
                                setFieldValue('followers', [values.followers[0], Number(value)]);
                              }
                            }}
                          />
                        </div>
                        {/* Should be updated. Freezing now. */}
                        {/* <div className="slider-wrapper"> */}
                        {/*  <Box sx={{ width: 335 }}> */}
                        {/*    <Slider */}
                        {/*      value={values.followers} */}
                        {/*      min={0} */}
                        {/*      max={10000} */}
                        {/*      onChange={(e, value) => { */}
                        {/*        setFieldValue('followers', value); */}
                        {/*      }} */}
                        {/*    /> */}
                        {/*  </Box> */}
                        {/* </div> */}
                      </div>
                      <div
                        className={`filter-accordion ${isAccordionCollapsed ? 'collapsed' : ''}`}
                      >
                        <div
                          className="accordion--header"
                          onClick={() => setIsAccordionCollapsed((prev) => !prev)}
                        >
                          <h2 className="header--title">
                            {t('SearchFilers.form.accordion.audience')}
                          </h2>
                          <Icon icon="chevron-bottom" className="header--icon" />
                        </div>
                        <div className="accordion--body">
                          <div className="checkboxes-wrapper">
                            <label className="checkboxes-wrapper--label">
                              {t('SearchFilers.form.audienceQuality.label')}
                            </label>
                            {AUDIENCE_QUALITY_SCORE.map((q) => (
                              <CheckboxGroupItem
                                key={q.id}
                                value={{
                                  id: q.id,
                                  name: q.value,
                                }}
                                label={q.label}
                                itemList={itemList}
                                checkedList={checkedList}
                                onChange={setCheckedList}
                              />
                            ))}
                          </div>
                          <Select
                            name="audience_gender"
                            label={t('SearchFilers.form.audienceGender.label')}
                            placeholder={t('SearchFilers.form.audienceGender.placeholder')}
                            options={metadata?.genders || []}
                            errors={errors}
                            touched={touched}
                          />
                          <Select
                            name="audience_location"
                            label={t('SearchFilers.form.audienceLocation.label')}
                            placeholder={t('SearchFilers.form.audienceLocation.placeholder')}
                            options={metadata?.locations || []}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="verified-profile-check--wrapper">
                    <Checkbox
                      name="is_verified_only"
                      label={t('SearchFilers.form.isVerifiedOnly.label')}
                    />
                  </div>
                  <div className="search-filters--footer">
                    <button
                      type="button"
                      className="btn btn-md btn-link"
                      onClick={() => {
                        onSearch(INITIAL_FORM_VALUES);
                        document.body.classList.remove('no-scroll');
                      }}
                    >
                      <Icon icon="trash" className="btn--icon" />
                      {t('SearchFilers.form.footer.buttons.clear')}
                    </button>
                    <button type="submit" className="btn btn-md btn-primary">
                      {t('SearchFilers.form.footer.buttons.show')}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

SearchFilers.propTypes = {
  view: PropTypes.oneOf(['influencer', 'company']),
  metadata: PropTypes.shape({
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    genders: PropTypes.arrayOf(PropTypes.shape({})),
    industries: PropTypes.arrayOf(PropTypes.shape({})),
    businessTypes: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  initialValue: PropTypes.shape({
    audience_quality: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  canChangeView: PropTypes.bool,
};

SearchFilers.defaultProps = {
  view: 'influencer',
  metadata: null,
  initialValue: {},
  canChangeView: false,
};

export default SearchFilers;
