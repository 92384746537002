/* eslint-disable global-require */
import ApiService from 'global/services/api';
import { mockImport } from 'global/helpers';
import { isMockComparePage } from '../../global/services/isMockEnabled';

const getUserByIdMock = mockImport(
  () => require('../../__mocks__/CompareService/getProfileById.mock.json'),
  isMockComparePage,
);

class CompareService extends ApiService {
  getUserById = (id) => this.get({ url: `users/${id}` }, getUserByIdMock);
}

export default new CompareService();
