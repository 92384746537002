import { asyncActionCreator } from '../../global/redux';
import * as constants from './constants';

export const getProfileByIdAction = asyncActionCreator(constants.GET_PROFILE_BY_ID);
export const getProfileFeedByIdAction = asyncActionCreator(constants.GET_PROFILE_FEED_BY_ID);
export const addLikeToPostByIdAction = asyncActionCreator(constants.ADD_LIKE_TO_POST_BY_ID);
export const deleteLikeToPostByIdAction = asyncActionCreator(constants.DELETE_LIKE_TO_POST_BY_ID);
export const addProfilePostAction = asyncActionCreator(constants.ADD_PROFILE_POST);
export const getProfilePostCommentsAction = asyncActionCreator(constants.GET_PROFILE_POST_COMMENTS);
export const createProfilePostCommentAction = asyncActionCreator(
  constants.CREATE_PROFILE_POST_COMMENT,
);
export const addFriendAction = asyncActionCreator(constants.ADD_FRIEND);
export const followUserAction = asyncActionCreator(constants.FOLLOW_USER);
export const unfollowUserAction = asyncActionCreator(constants.UNFOLLOW_USER);
export const addFriendBackAction = asyncActionCreator(constants.ADD_FRIEND_BACK);
