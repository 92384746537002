import React from 'react';
import PropTypes from 'prop-types';
import './ChatItem.scss';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import { useNavigate } from 'react-router-dom';

const ChatItem = ({ chat }) => {
  const navigate = useNavigate();

  const handleChatClick = async (id) => {
    navigate('/messages', { state: { chatId: id } });
  };

  return (
    <div key={chat.id} className="chat-item" onClick={() => handleChatClick(chat.participant.id)}>
      <Avatar size="md" file={chat.participant.cover} />
      <div className="chat-item--message-wrapper">
        <div className="chat-item--user-name">
          {chat.participant.fullName && <p>{chat.participant.fullName}</p>}
          {chat.participant.isVerified && <Icon icon="official" className="info--icon" />}
        </div>
        {chat.lastMessage?.text && (
          <p className="chat-item--message">
            {chat.lastMessage.text.length > 50
              ? chat.lastMessage.text.slice(0, 50).concat('...')
              : chat.lastMessage.text}
          </p>
        )}
      </div>
      {chat.newMessagesCount > 0 && (
        <p className="chat-item--message-count">{chat.newMessagesCount}</p>
      )}
    </div>
  );
};

ChatItem.propTypes = {
  chat: PropTypes.shape({
    id: PropTypes.string,
    updatedAt: PropTypes.string,
    lastMessage: PropTypes.shape({
      text: PropTypes.string,
    }),
    newMessagesCount: PropTypes.number,
    participant: PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
      positionInCompany: PropTypes.string,
      isVerified: PropTypes.bool,
      cover: PropTypes.string,
    }),
  }),
};

ChatItem.defaultProps = {
  chat: {
    id: null,
    updatedAt: '',
    lastMessage: {
      text: '',
    },
    newMessagesCount: 0,
    participant: {
      id: '',
      fullName: '',
      positionInCompany: '',
      isVerified: false,
      cover: '',
    },
  },
};

export default ChatItem;
