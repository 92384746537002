export const GET_PROFILE_BY_ID = 'GET_PROFILE_BY_ID';
export const GET_PROFILE_FEED_BY_ID = 'GET_PROFILE_FEED_BY_ID';
export const ADD_LIKE_TO_POST_BY_ID = 'ADD_LIKE_TO_POST_BY_ID';
export const DELETE_LIKE_TO_POST_BY_ID = 'DELETE_LIKE_TO_POST_BY_ID';
export const ADD_PROFILE_POST = 'ADD_PROFILE_POST';
export const GET_PROFILE_POST_COMMENTS = 'GET_PROFILE_POST_COMMENTS';
export const CREATE_PROFILE_POST_COMMENT = 'CREATE_PROFILE_POST_COMMENT';
export const ADD_FRIEND = 'ADD_FRIEND';
export const ADD_FRIEND_BACK = 'ADD_FRIEND_BACK';
export const FOLLOW_USER = 'FOLLOW_USER_PROFILE_PAGE';
export const UNFOLLOW_USER = 'UNFOLLOW_USER_PROFILE_PAGE';
