import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { setToLocalStorage, setToSessionStorage } from '../../global/helpers';

const withAuth = (WrappedComponent) => {
  class ComposedComponent extends Component {
    responseInterceptor = axios.interceptors.response.use(
      (res) => res,
      (error) => {
        const { logoutUserAction, navigate } = this.props;
        const { status } = error.response;
        if (status === 401 || status === 403) {
          setToSessionStorage('token', null);
          setToLocalStorage('token', null);
          logoutUserAction();
        }
        if (status === 404) {
          navigate('/not-found');
        }
        return Promise.reject(error);
      },
    );

    componentWillUnmount() {
      axios.interceptors.response.eject(this.responseInterceptor);
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} />;
    }
  }

  ComposedComponent.propTypes = {
    logoutUserAction: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  return ComposedComponent;
};

export default withAuth;
