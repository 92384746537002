import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectors } from '../../reducer';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import Heading from '../../../../components/Heading';
import Icon from '../../../../components/Icon';
import { SIGN_UP_STEPS } from '../../constants';
import { changeSignUpStatusAction } from '../../actions';
import { setToLocalStorage } from '../../../../global/helpers';

const EmailVerifiedPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const signUpStatus = useSelector(selectors.signupPageStatus);

  useEffect(() => {
    if (signUpStatus !== SIGN_UP_STEPS.emailVerified) {
      navigate('/auth');
    }

    return () => {
      dispatch(changeSignUpStatusAction(null));
      setToLocalStorage('sign_up_step', null);
      setToLocalStorage('sign_up_email', null);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="additional-page--wrapper">
      <Header />
      <div className="additional-page--form">
        <Heading
          subtitle="EmailVerifiedPage.heading.subtitle"
          title="EmailVerifiedPage.heading.title"
          position="center"
        />
        <NavLink to="/auth" className="btn btn-lg btn-primary">
          {t('EmailVerifiedPage.buttons.continue')}
        </NavLink>
        <div className="form--footer">
          <Link to="/auth" className="link link-gray link-md">
            <Icon icon="arrow-left" className="link--icon" />
            {t('EmailVerifiedPage.footer.link')}
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmailVerifiedPage;
