/* eslint-disable global-require */
import ApiService from 'global/services/api';
import i18next from 'i18next';
import { mockImport } from 'global/helpers';
import { isMockFaqPage } from '../../global/services/isMockEnabled';

const faqListMock = mockImport(
  () => require('../../__mocks__/FaqService/getFaq.mock.json'),
  isMockFaqPage,
);

class FaqService extends ApiService {
  getFaqList = async () =>
    this.get({ url: 'metadata/faq', params: { lang: i18next.language } }, faqListMock);
}

export default new FaqService();
