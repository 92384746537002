import { asyncActionCreator } from '../../global/redux';
import * as constants from './constants';

export const getNetworkDataAction = asyncActionCreator(constants.GET_NETWORK_DATA);
export const getFriendsAction = asyncActionCreator(constants.GET_FRIENDS);
export const getFriendsRequestsAction = asyncActionCreator(constants.GET_FRIENDS_REQUESTS);
export const getFollowsAction = asyncActionCreator(constants.GET_FOLLOWS);
export const approveFriendRequestAction = asyncActionCreator(constants.FRIEND_APPROVE);
export const declineFriendRequestAction = asyncActionCreator(constants.FRIEND_DECLINE);
export const getSuggestedPeopleAction = asyncActionCreator(constants.GET_SUGGESTED_PEOPLE);
export const followUserAction = asyncActionCreator(constants.FOLLOW_USER);
export const unfollowUserAction = asyncActionCreator(constants.UNFOLLOW_USER);
