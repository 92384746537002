export const GET_USERS_BY_SEARCH_PARAMS = 'GET_USERS_BY_SEARCH_PARAMS';
export const GET_COMPANIES_BY_SEARCH_PARAMS = 'GET_COMPANIES_BY_SEARCH_PARAMS';
export const GET_INFLUENCERS_BY_SEARCH_PARAMS = 'GET_INFLUENCERS_BY_SEARCH_PARAMS';

export const GET_FOLLOWERS_COMPANY_SEARCH = 'GET_FOLLOWERS_COMPANY_SEARCH';
export const FOLLOW_COMPANY_COMPANY_SEARCH = 'FOLLOW_COMPANY_COMPANY_SEARCH';

export const SOCIAL_NETWORKS_SELECTOR = [
  {
    label: 'Facebook',
    value: 'facebook',
    icon: 'facebook',
  },
  {
    label: 'Instagram',
    value: 'instagram',
    icon: 'instagram',
  },
  // {
  //   label: 'Twitter',
  //   value: 'twitter',
  //   icon: 'twitter',
  // },
  // {
  //   label: 'YouTube',
  //   value: 'youtube',
  //   icon: 'youtube',
  // },
  // {
  //   label: 'TikTok',
  //   value: 'tiktok',
  //   icon: 'tiktok',
  // },
];
