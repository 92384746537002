import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './actions';
import MetricsService from './service';

export function* getMetricsByIdSaga({ payload }) {
  try {
    const response = yield call(MetricsService.getMetricsById, payload);
    yield put(actions.getMetricsByIdAction.success(response.data.data));
  } catch (error) {
    yield put(actions.getMetricsByIdAction.error(error));
  }
}

export function* metricsWatcherSaga() {
  yield all([takeLatest(actions.getMetricsByIdAction.REQUEST, getMetricsByIdSaga)]);
}
