import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'components/Icon';
import { Link, useNavigate } from 'react-router-dom';
import { selectors as mainSelectors } from '../MainPage/reducer';
import CheckboxGroupItem from '../../components/CheckboxGroupItem';
import Tooltip from '../../components/Tooltip';
import UserInfo from '../../components/UserInfo';
import IndustriesRow from '../../components/IndustriesRow';
import { addToComparison, deleteFormFavorite } from '../MainPage/actions';
import ContentLoader from '../../components/ContentLoader';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';
import './SavedListPage.scss';

const SavedListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const savedListData = useSelector(mainSelectors.favoriteListData);
  const savedListLoading = useSelector(mainSelectors.favoriteListLoading);

  const [checkedList, setCheckedList] = useState([]);

  const itemList = savedListData?.map((el) => ({
    id: el.user.id,
    name: el.user.fullName,
    savedId: el.id,
  }));

  const handleCheckAll = () => {
    setCheckedList(itemList);
  };

  const handleDeleteFormSavedList = () => {
    dispatch(deleteFormFavorite.request(checkedList.map((item) => item.savedId)));
    setCheckedList([]);
  };

  const handleAddToComparison = () => {
    const comparisonIds = checkedList.map((i) => i.id);
    dispatch(addToComparison(comparisonIds));

    const prevComparison = getFromLocalStorage('comparison');
    const newComparison = (prevComparison || []).filter((id) => !comparisonIds?.includes(id));

    setToLocalStorage(
      'comparison',
      prevComparison ? [...newComparison, ...comparisonIds] : comparisonIds,
    );

    navigate('/compare');
  };

  return (
    <div className="saved-list-page--wrapper">
      {savedListLoading ? (
        <ContentLoader visible={savedListLoading} />
      ) : (
        <div className="app-pages--content">
          {savedListData?.length ? (
            <div className="content--main">
              <div className="saved-list-page--header">
                <div className="header--title">
                  <h2 className="title">
                    {t('SavedListPage.title')}
                    <span>
                      {savedListData?.length}{' '}
                      {t(`SavedListPage.text.influencer${savedListData.length > 1 ? 's' : ''}`)}
                    </span>
                  </h2>
                  <p className="description">{t('SavedListPage.description')}</p>
                </div>
                <div className="header--controls">
                  <button
                    type="button"
                    className="btn btn-md btn-link"
                    disabled={!checkedList?.length}
                    onClick={handleDeleteFormSavedList}
                  >
                    {t('SavedListPage.buttons.delete')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-md btn-secondary-gray"
                    disabled={checkedList?.length === itemList?.length}
                    onClick={handleCheckAll}
                  >
                    {t('SavedListPage.buttons.choseAll')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-md btn-primary"
                    disabled={!checkedList?.length}
                    onClick={handleAddToComparison}
                  >
                    {t('SavedListPage.buttons.compare')}
                  </button>
                </div>
              </div>
              <div className="saved-list-page--table">
                <table className="table">
                  <thead>
                    <tr className="table--header-row">
                      <th className="table--header-cell">
                        <CheckboxGroupItem
                          value={{
                            id: 'all',
                            name: 'All',
                          }}
                          itemList={itemList}
                          checkedList={checkedList}
                          onChange={setCheckedList}
                        />
                      </th>
                      <th className="table--header-cell table--header-cell__align-left">
                        {t('SavedListPage.table.headers.account')}
                      </th>
                      <th className="table--header-cell table--header-cell__align-left">
                        <div className="table--header-cell__with-tooltip">
                          <span>{t('SavedListPage.table.headers.aqs.text')}</span>
                          <Tooltip text={t('SavedListPage.table.headers.aqs.tooltip')} />
                        </div>
                      </th>
                      <th className="table--header-cell table--header-cell__align-left">
                        <div className="table--header-cell__with-tooltip">
                          <span>{t('SavedListPage.table.headers.er.text')}</span>
                          <Tooltip text={t('SavedListPage.table.headers.er.tooltip')} />
                        </div>
                      </th>
                      <th className="table--header-cell table--header-cell__align-left">
                        {t('SavedListPage.table.headers.instagramAvgLikesCount')}
                      </th>
                      <th className="table--header-cell table--header-cell__align-left">
                        {t('SavedListPage.table.headers.category')}
                      </th>
                      <th className="table--header-cell table--header-cell__align-left">
                        {t('SavedListPage.table.headers.audAge')}
                      </th>
                      <th className="table--header-cell table--header-cell__align-left">
                        {t('SavedListPage.table.headers.instagramFollowers')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {savedListData?.map((el) => (
                      <tr className="table--row" key={el.user.id}>
                        <td className="table--cell">
                          <CheckboxGroupItem
                            value={{
                              id: el.user.id,
                              name: el.user.fullName,
                              savedId: el.id,
                            }}
                            itemList={itemList}
                            checkedList={checkedList}
                            onChange={setCheckedList}
                          />
                        </td>
                        <td className="table--cell">
                          <UserInfo user={el.user} />
                        </td>
                        <td className="table--cell">AQS</td>
                        <td className="table--cell">ER</td>
                        <td className="table--cell">
                          {
                            el?.user?.mainSocials?.instagram?.mainMetrics?.avg_like_comments_count
                              ?.values?.like_count
                          }
                        </td>
                        <td className="table--cell">
                          <IndustriesRow industries={el.user.industries} justify="left" />
                        </td>
                        <td className="table--cell">Aud Age</td>
                        <td className="table--cell">
                          {
                            el?.user?.mainSocials?.instagram?.mainMetrics?.avg_like_comments_count
                              ?.values?.comments_count
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="saved-page--404">
              <div className="saved-page--404__icon-wrapper">
                <Icon icon="like" stroke="#7F56D9" />
              </div>
              <p className="saved-page--404__title">{t('SavedListPage.emptyState.title')}</p>
              <span className="saved-page--404__description">
                {t('SavedListPage.emptyState.description')}
              </span>
              <span className="saved-page--404__description">
                {t('SavedListPage.emptyState.subdescription')}
              </span>
              <Link to="/search/influencer" className="btn btn-primary btn-lg">
                {t('SavedListPage.emptyState.buttons.search')}
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SavedListPage;
