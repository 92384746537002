import React from 'react';
import PropTypes from 'prop-types';
import { Rating } from '@mui/material';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import ROLES from 'constants';
import { Link, useParams } from 'react-router-dom';
import './ProfileFeedbacks.scss';

const ProfileFeedbacks = ({ feedback, feedbacksTotal }) => {
  const params = useParams();

  return (
    <div className="profile-feedbacks--wrapper">
      <div className="header--user">
        <Avatar size="md" className="user--avatar" file={feedback.owner.cover} />
        <div className="user--info">
          <div className="user--info-name">
            <h2 className="info--name">{feedback.owner.fullName}</h2>
            {feedback.owner.isVerified && <Icon icon="verified" />}
          </div>
          <span className="info--email">
            {feedback.owner.role === ROLES.influencer ? (
              <>{feedback.owner.email}</>
            ) : (
              <>
                {feedback.owner.companyName} {feedback.owner.positionInCompany}
              </>
            )}
          </span>
        </div>
      </div>
      <div>
        <div className="profile-feedbacks--collaboration">
          {feedback.collaborated && (
            <div className={`info--social-name ${feedback.socialName}`}>
              <Icon icon={feedback.socialName.toLowerCase()} />
              <span>{feedback.socialName.toLowerCase()}</span>
            </div>
          )}
        </div>
      </div>
      <div className="profile-feedbacks--rating">
        <Rating
          defaultValue={Number(
            (
              (feedback.availability +
                feedback.deadline +
                feedback.quality +
                feedback.satisfaction) /
              4
            ).toFixed(1),
          )}
          precision={0.5}
          readOnly
        />
        <span>
          {(
            (feedback.availability + feedback.deadline + feedback.quality + feedback.satisfaction) /
            4
          ).toFixed(1)}
        </span>
      </div>
      <p className="profile-feedbacks--description">{feedback.text}</p>
      <div className="profile-feedbacks--divider" />
      <Link to={`/profile/${params.id}/feedbacks`} className="profile-feedbacks--see-all">
        See All ({feedbacksTotal})
      </Link>
    </div>
  );
};

ProfileFeedbacks.propTypes = {
  feedback: PropTypes.shape({
    text: PropTypes.string,
    collaborated: PropTypes.bool,
    socialName: PropTypes.string,
    availability: PropTypes.number,
    deadline: PropTypes.number,
    quality: PropTypes.number,
    satisfaction: PropTypes.number,
    updatedAt: PropTypes.string,
    owner: PropTypes.shape({
      fullName: PropTypes.string,
      companyName: PropTypes.string,
      email: PropTypes.string,
      cover: PropTypes.string,
      isVerified: PropTypes.bool,
      role: PropTypes.string,
      positionInCompany: PropTypes.string,
    }),
  }).isRequired,
  feedbacksTotal: PropTypes.number.isRequired,
};

export default ProfileFeedbacks;
