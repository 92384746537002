import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import Heading from 'components/Heading';
import SOCIAL_NETWORKS from './constants';
import './AddSocialForm.scss';

const AddSocialForm = () => {
  const { t } = useTranslation();

  return (
    <div className="add-social-form">
      <Heading title="AddSocialPage.heading.title" subtitle="AddSocialPage.heading.subtitle" />
      {SOCIAL_NETWORKS.map(({ id, to, translate, icon }) => (
        <Link key={id} to={to} type="button" className="btn btn-lg btn-tertiary">
          <Icon icon={icon} className="btn--icon" />
          {t(translate)}
        </Link>
      ))}
      <Link to="/feed" className="link link-gray link-md">
        {t('AddSocialPage.after')}
      </Link>
    </div>
  );
};

export default AddSocialForm;
