import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { asyncActionCreator, asyncInitialState } from '../../global/redux';

const initialState = {
  profileDetails: asyncInitialState,
  linkedAccounts: asyncInitialState,
};

const reducer = createReducer(initialState, {
  ...asyncActionCreator(actions.updateProfileDetailsSettingsAction, 'profileDetails'),
  ...asyncActionCreator(actions.authFacebookByCodeAction, 'linkedAccounts'),
  ...asyncActionCreator(actions.deleteAccountAction, 'profileDetails'),
});

const profileDetailsStateSelector = (state) => state.profileSettingsReducer.profileDetails;

const linkedAccountsStateSelector = (state) => state.profileSettingsReducer.linkedAccounts;

const selectors = {
  profileDetailsStateSelector,
  linkedAccountsStateSelector,
};

export { selectors, reducer, initialState };
export default reducer;
