import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ChatList.scss';
import { useSelector } from 'react-redux';
import { selectors } from '../../reducer';
import ChatFilterForm from './components/ChatFilterForm';
import ChatListItem from './components/ChatListItem';

const ChatList = ({ onChange, handleChatClick, chatParticipant }) => {
  const [chats, setChats] = useState([]);
  const filteredChats = useSelector(selectors.getFilteredChats);

  useEffect(() => {
    setChats(filteredChats);
  }, [filteredChats]);

  return (
    <div className="chats-list--wrapper">
      <ChatFilterForm onChange={onChange} />
      <ul className="chats-list">
        {chats &&
          chats?.length > 0 &&
          [...chats]
            .sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))
            .map((chat) => (
              <ChatListItem
                isActive={chatParticipant?.id === chat?.participant.id}
                handleClick={handleChatClick}
                key={chat.id}
                chat={chat}
              />
            ))}
      </ul>
    </div>
  );
};

ChatList.propTypes = {
  onChange: PropTypes.func.isRequired,
  handleChatClick: PropTypes.func.isRequired,
  chatParticipant: PropTypes.shape({
    id: PropTypes.string,
  }),
};

ChatList.defaultProps = {
  chatParticipant: {
    id: '',
  },
};

export default ChatList;
