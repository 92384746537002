import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import './Checkbox.scss';

const Checkbox = ({ className, name, label, hint }) => (
  <div className={className}>
    <div className="checkbox-field--wrapper">
      <Field name={name}>
        {({ field, form: { values, setFieldValue } }) => (
          <div className="checkbox--wrapper">
            <label className="checkbox--label">
              <input
                type="checkbox"
                name={field.name}
                className="checkbox--input"
                onChange={(event) => setFieldValue(field.name, event.target.checked)}
                checked={values[name]}
              />
              <span className="label--checkmark" />
              {label && <span className="label--content">{label}</span>}
            </label>
          </div>
        )}
      </Field>
      {hint && <div className="checkbox-field--hint">{hint}</div>}
    </div>
  </div>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
};

Checkbox.defaultProps = {
  className: null,
  hint: null,
  label: null,
};

export default Checkbox;
