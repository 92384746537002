import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import { selectors as mainSelectors } from 'pages/MainPage/reducer';
import SOCIALS_NETWORKS from './socials';
import './LinkedAccounts.scss';

const LinkedAccounts = () => {
  const { t } = useTranslation();
  const profile = useSelector(mainSelectors.userProfileData);

  const connectedSocials = useMemo(
    () =>
      profile?.mainSocials && typeof profile.mainSocials === 'object'
        ? Object.keys(profile.mainSocials)
        : [],
    [profile],
  );

  return (
    <div className="profile-settings-page--form" id="tracking">
      <h2 className="profile-settings-page--form-title">
        {t('ProfileSettingsPage.titles.linkedAccounts')}
      </h2>
      {/* <div className="linked-account--row"> */}
      {/*  <div className="row--status"> */}
      {/*    <Icon icon="facebook" className="social-icon" /> */}
      {/*    <h3 className="social-name">{t('ProfileSettingsPage.socials.facebook')}</h3> */}
      {/*  </div> */}
      {/*  <a */}
      {/*    className="row-control-btn" */}
      {/*    href={`https://www.facebook.com/v13.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=https://localhost:3000/profile/settings&state=facebook`} */}
      {/*  > */}
      {/*    <Icon icon="round-plus" /> */}
      {/*  </a> */}
      {/* </div> */}
      {SOCIALS_NETWORKS.map((social) => (
        <div key={social.id} className="linked-account--row">
          <div className="row--status">
            <Icon icon={social.icon} className="social-icon" />
            <h3 className="social-name">{t(social.name)}</h3>
          </div>
          {connectedSocials.indexOf(social.id) !== -1 ? (
            <div
              className="row-control-btn row-control-btn__success"
              title={t('ProfileSettingsPage.connectedStatus')}
            >
              <Icon icon="share" />
            </div>
          ) : (
            <>
              {social?.replace !== true ? (
                <Link className="row-control-btn" to={social.path}>
                  <Icon icon="round-plus" />
                </Link>
              ) : (
                <a className="row-control-btn" href={social.path}>
                  <Icon icon="round-plus" />
                </a>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

LinkedAccounts.propTypes = {};

export default LinkedAccounts;
