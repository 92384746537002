import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { confirmEmailValidationSchema } from './validations';
import Heading from '../../../../../../components/Heading';
import CodeInput from '../../../../../../components/CodeInput';
import Icon from '../../../../../../components/Icon';

const ConfirmEmailForm = ({ email, code, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        code: code || '',
      }}
      validationSchema={confirmEmailValidationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className="additional-page--form">
          <Heading
            subtitle="ConfirmEmailPage.heading.subtitle"
            title="ConfirmEmailPage.heading.subtitle"
            subtext={email}
            position="center"
          />
          <CodeInput name="code" errors={errors} touched={touched} />
          <button type="submit" className="btn btn-lg btn-primary">
            {t('ConfirmEmailPage.ConfirmEmailForm.buttons.submit')}
          </button>
          <div className="form--sub-action">
            <span className="sub-action--text">
              {t('ConfirmEmailPage.ConfirmEmailForm.subAction.text')}
            </span>
            <button type="button" className="link link-gray link-md sub-action--btn">
              {t('ConfirmEmailPage.ConfirmEmailForm.subAction.link')}
            </button>
          </div>
          <div className="form--footer">
            <Link to="/auth" className="link link-gray link-md">
              <Icon icon="arrow-left" className="link--icon" />
              {t('ConfirmEmailPage.ConfirmEmailForm.link')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ConfirmEmailForm.propTypes = {
  email: PropTypes.string,
  code: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

ConfirmEmailForm.defaultProps = {
  email: null,
  code: '',
};

export default ConfirmEmailForm;
