import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { getFromLocalStorage } from 'global/helpers';
import { checkEmailValidationSchema } from './validations';
import { selectors } from '../../../../reducer';
import Heading from '../../../../../../components/Heading';
import CodeInput from '../../../../../../components/CodeInput';
import Icon from '../../../../../../components/Icon';
import '../../../../LogInPage.scss';

const CheckEmailForm = ({ onSubmit, onResend }) => {
  const { t } = useTranslation();

  const [forgotPasswordData, setForgotPasswordData] = useState(
    useSelector(selectors.resetPasswordData),
  );

  useEffect(() => {
    if (!forgotPasswordData) {
      setForgotPasswordData(getFromLocalStorage('reset_password_email'));
    }
  }, [forgotPasswordData]);

  return (
    <Formik
      initialValues={{
        code: '',
      }}
      validationSchema={checkEmailValidationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className="additional-page--form">
          <Heading
            title="CheckEmailPage.heading.title"
            subtitle="CheckEmailPage.heading.subtitle"
            subtext={forgotPasswordData?.email}
            position="center"
          />
          <CodeInput name="code" errors={errors} touched={touched} />
          <button type="submit" className="btn btn-lg btn-primary">
            {t('CheckEmailPage.CheckEmailForm.buttons.submit')}
          </button>
          <div className="form--sub-action">
            <span className="sub-action--text">
              {t('CheckEmailPage.CheckEmailForm.subAction.text')}
            </span>
            <button
              onClick={() => onResend(forgotPasswordData?.email)}
              type="button"
              className="link link-gray link-md sub-action--btn"
            >
              {t('CheckEmailPage.CheckEmailForm.subAction.link')}
            </button>
          </div>
          <div className="form--footer">
            <Link to="/auth" className="link link-gray link-md">
              <Icon icon="arrow-left" className="link--icon" />
              {t('CheckEmailPage.CheckEmailForm.link')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CheckEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
};

export default CheckEmailForm;
