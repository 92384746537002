export const VIEW_TYPES = [
  {
    id: 1,
    name: 'UserInformationPage.UserInformationForm.view.influencer',
    value: 'influencer',
    icon: 'man',
  },
  {
    id: 2,
    name: 'UserInformationPage.UserInformationForm.view.company',
    value: 'company',
    icon: 'case',
  },
];

export const COMPANY_FORM = [
  {
    id: 1,
    type: 'text',
    name: 'companyName',
    label: 'UserInformationPage.UserInformationForm.companyName.label',
    placeholder: 'UserInformationPage.UserInformationForm.companyName.placeholder',
  },
  {
    id: 2,
    type: 'select',
    name: 'businessType',
    label: 'UserInformationPage.UserInformationForm.businessType.label',
    placeholder: 'UserInformationPage.UserInformationForm.businessType.placeholder',
  },
  {
    id: 3,
    type: 'select',
    name: 'location',
    label: 'UserInformationPage.UserInformationForm.location.label',
    placeholder: 'UserInformationPage.UserInformationForm.location.placeholder',
  },
  {
    id: 4,
    type: 'text',
    name: 'website',
    label: 'UserInformationPage.UserInformationForm.website.label',
    placeholder: 'UserInformationPage.UserInformationForm.website.placeholder',
  },
  {
    id: 5,
    type: 'text',
    name: 'fullName',
    label: 'UserInformationPage.UserInformationForm.fullName.label',
    placeholder: 'UserInformationPage.UserInformationForm.fullName.placeholder',
  },
  {
    id: 6,
    type: 'text',
    name: 'positionInCompany',
    label: 'UserInformationPage.UserInformationForm.companyPosition.label',
    placeholder: 'UserInformationPage.UserInformationForm.companyPosition.placeholder',
  },
];

export const INFLUENCER_FORM = [
  {
    id: 1,
    type: 'text',
    name: 'fullName',
    label: 'UserInformationPage.UserInformationForm.fullName.label',
    placeholder: 'UserInformationPage.UserInformationForm.fullName.placeholder',
  },
  {
    id: 2,
    type: 'select',
    name: 'location',
    label: 'UserInformationPage.UserInformationForm.location.label',
    placeholder: 'UserInformationPage.UserInformationForm.location.placeholder',
  },
  {
    id: 3,
    type: 'select',
    name: 'gender',
    label: 'UserInformationPage.UserInformationForm.gender.label',
    placeholder: 'UserInformationPage.UserInformationForm.gender.placeholder',
  },
  {
    id: 4,
    type: 'date',
    name: 'birthdayDate',
    label: 'UserInformationPage.UserInformationForm.birthdayDate.label',
    placeholder: 'UserInformationPage.UserInformationForm.birthdayDate.placeholder',
  },
];

export const FORM_STEPS = {
  main: 'main',
  industries: 'industries',
  avatar: 'avatar',
};
