/* eslint-disable global-require */
import ApiService from '../../global/services/api';
import { mockImport } from '../../global/helpers';
import { isMockNetworkPage } from '../../global/services/isMockEnabled';

const getFriendsRequestsMock = mockImport(
  () => require('../../__mocks__/NetworkService/getFriendsRequest.mock.json'),
  isMockNetworkPage,
);
const approveFriendMock = mockImport(
  () => require('../../__mocks__/NetworkService/approveFriend.mock.json'),
  isMockNetworkPage,
);
const declineFriendMock = mockImport(
  () => require('../../__mocks__/NetworkService/declineFriend.mock.json'),
  isMockNetworkPage,
);
const getInfluencersMock = mockImport(
  () => require('../../__mocks__/NetworkService/getInfluencers.mock.json'),
  isMockNetworkPage,
);
const getCompaniesMock = mockImport(
  () => require('../../__mocks__/NetworkService/getCompanies.mock.json'),
  isMockNetworkPage,
);
const followUserMock = mockImport(
  () => require('../../__mocks__/NetworkService/followUser.mock.json'),
  isMockNetworkPage,
);

class NetworkService extends ApiService {
  getFriends = ({ id, params }) =>
    this.get(
      { url: `relations/friends/${id}`, params: { ...params, status: 'APPROVED' } },
      getFriendsRequestsMock,
    );

  getFriendsRequests = ({ id, params }) =>
    this.get(
      { url: `relations/friends/${id}`, params: { ...params, status: 'REQUESTED' } },
      getFriendsRequestsMock,
    );

  approveFriendRequest = (id) =>
    this.patch(
      { url: `relations/friends/request/${id}`, body: { status: 'APPROVED' } },
      approveFriendMock,
    );

  declineFriendRequest = (id) =>
    this.patch(
      { url: `relations/friends/request/${id}`, body: { status: 'DECLINED' } },
      declineFriendMock,
    );

  getInfluencers = () =>
    this.get({ url: 'users/search?role=INFLUENCER&take=3' }, getInfluencersMock);

  getCompanies = () => this.get({ url: 'users/search?role=COMPANY&take=3' }, getCompaniesMock);

  followUser = (id) =>
    this.post({ url: `relations/followers/request/${id}`, body: {} }, followUserMock);

  unfollowUser = (id) =>
    this.patch({ url: `relations/followers/request/${id}`, body: { status: 'DECLINED' } }, null);

  getFollows = ({ id, params }) =>
    this.get({ url: `relations/followers/${id}`, params: { ...params, status: 'APPROVED' } }, null);
}

export default new NetworkService();
