import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextEditor from '../../../../components/TextEditor';
import ROLES from '../../../../constants';

const ProfileDescription = ({ profile, onSubmit }) => {
  const { t } = useTranslation();

  const initTextEditorValue = profile?.bio
    ? JSON.parse(profile?.bio)
    : [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ];

  const [textEditorValue, setTextEditorValue] = useState(initTextEditorValue);

  const handleTextEditorChange = (value) => {
    setTextEditorValue(value);
  };

  const handleSubmit = () => {
    onSubmit(textEditorValue);
  };

  return (
    <div className="profile-settings-page--form">
      <h2 className="profile-settings-page--form-title">
        {t(
          `ProfileSettingsPage.titles.${
            profile?.role === ROLES.influencer ? 'influencer' : 'company'
          }.description`,
        )}
      </h2>
      <div className="bio-input--wrapper">
        <TextEditor
          placeholder={t('ProfileSettingsPage.form.bio.placeholder')}
          label={t('ProfileSettingsPage.form.bio.label')}
          value={textEditorValue}
          onChange={handleTextEditorChange}
        />
      </div>
      <div className="profile-settings-page--form-footer">
        <button type="button" className="btn btn-md btn-tertiary">
          {t('ProfileSettingsPage.form.buttons.cancel')}
        </button>
        <button
          type="submit"
          className="btn btn-md btn-primary"
          disabled={JSON.stringify(initTextEditorValue) === JSON.stringify(textEditorValue)}
          onClick={handleSubmit}
        >
          {t('ProfileSettingsPage.form.buttons.done')}
        </button>
      </div>
    </div>
  );
};

ProfileDescription.propTypes = {
  profile: PropTypes.shape({
    role: PropTypes.string,
    bio: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

ProfileDescription.defaultProps = {
  profile: null,
};

export default ProfileDescription;
