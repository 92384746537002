import ROLES from '../../../../constants';

export const FORMS = {
  [ROLES.company]: [
    {
      id: 'companyName',
      name: 'companyName',
      type: 'text',
      label: 'ProfileSettingsPage.form.companyName.label',
      placeholder: 'ProfileSettingsPage.form.companyName.placeholder',
    },
    {
      id: 'businessType',
      name: 'businessType',
      type: 'select',
      label: 'ProfileSettingsPage.form.businessType.label',
    },
    {
      id: 'location',
      name: 'location',
      type: 'select',
      label: 'ProfileSettingsPage.form.location.label',
    },
    {
      id: 'website',
      name: 'website',
      type: 'text',
      label: 'ProfileSettingsPage.form.website.label',
      placeholder: 'ProfileSettingsPage.form.website.placeholder',
    },
    {
      id: 'fullName',
      name: 'fullName',
      type: 'text',
      label: 'ProfileSettingsPage.form.fullName.label',
      placeholder: 'ProfileSettingsPage.form.fullName.placeholder',
    },
    {
      id: 'positionInCompany',
      name: 'positionInCompany',
      type: 'text',
      label: 'ProfileSettingsPage.form.positionInCompany.label',
      placeholder: 'ProfileSettingsPage.form.positionInCompany.placeholder',
    },
  ],
  [ROLES.influencer]: [
    {
      id: 'fullName',
      name: 'fullName',
      type: 'text',
      label: 'ProfileSettingsPage.form.fullName.label',
      placeholder: 'ProfileSettingsPage.form.fullName.placeholder',
    },
    {
      id: 'location',
      name: 'location',
      type: 'select',
      label: 'ProfileSettingsPage.form.location.label',
    },
    {
      id: 'gender',
      name: 'gender',
      type: 'select',
      label: 'ProfileSettingsPage.form.gender.label',
    },
    {
      id: 'birthdayDate',
      name: 'birthdayDate',
      type: 'date',
      label: 'ProfileSettingsPage.form.dob.label',
      placeholder: 'ProfileSettingsPage.form.dob.placeholder',
    },
  ],
  password: [
    {
      id: 'currentPassword',
      name: 'currentPassword',
      type: 'password',
      label: 'ProfileSettingsPage.form.currentPassword.label',
      placeholder: 'ProfileSettingsPage.form.currentPassword.placeholder',
    },
    {
      id: 'newPassword',
      name: 'newPassword',
      type: 'password',
      label: 'ProfileSettingsPage.form.newPassword.label',
      placeholder: 'ProfileSettingsPage.form.newPassword.placeholder',
    },
    {
      id: 'repeatNewPassword',
      name: 'repeatNewPassword',
      type: 'password',
      label: 'ProfileSettingsPage.form.repeatNewPassword.label',
      placeholder: 'ProfileSettingsPage.form.repeatNewPassword.placeholder',
    },
  ],
};

export default FORMS;
