export const disablePostFilesUploadPercentsCounter = () => {
  localStorage.removeItem('postFilesUploadProgress');
};

export const getPostFilesUploadPercents = () => {
  const data = localStorage.getItem('postFilesUploadProgress');
  return data !== null && data.length ? parseInt(data) : null;
};

export const setPostFilesUploadPercents = (percents) => {
  localStorage.setItem('postFilesUploadProgress', percents);
};
