export const mockImport = (callback, condition) => {
  try {
    if (condition || process.env.MOCKS_ENABLED) {
      return callback();
    }
    throw new Error('Mocks disabled');
  } catch {
    return null;
  }
};

export const getFromLocalStorage = (key) => {
  const storage = JSON.parse(localStorage.getItem('bup_social') || '{}');

  return storage[key];
};

export const setToLocalStorage = (key, value) => {
  const storage = JSON.parse(localStorage.getItem('bup_social') || '{}');

  localStorage.setItem(
    'bup_social',
    JSON.stringify({
      ...storage,
      [key]: value,
    }),
  );
};

export const getFromSessionStorage = (key) => {
  const storage = JSON.parse(sessionStorage.getItem('bup_social') || '{}');

  return storage[key];
};

export const setToSessionStorage = (key, value) => {
  const storage = JSON.parse(sessionStorage.getItem('bup_social') || '{}');

  sessionStorage.setItem(
    'bup_social',
    JSON.stringify({
      ...storage,
      [key]: value,
    }),
  );
};

export const getValidValueForBackend = (value) => {
  if (typeof value === 'string') {
    return value.length ? value : null;
  }

  return value;
};

export const mapDataForSelect = (data) => {
  const mappedData = data.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  return mappedData.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

export const mapLocationsForSelect = (data) => {
  const mappedData = data.map((element) => ({
    value: element.id,
    label: element.name,
    img: `https://purecatamphetamine.github.io/country-flag-icons/3x2/${element.id}.svg`,
  }));

  return mappedData.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

export const mapGendersForSelect = (data) =>
  Object.entries(data).reduce(
    (obj, [key, name]) => [
      ...obj,
      {
        value: key,
        label: name,
      },
    ],
    [],
  );

export const mapDataForSelectWithCategories = (data) =>
  data.map((element) => ({
    label: element.name,
    options: element.items.map((item) => ({
      label: item.name,
      value: item.id,
    })),
  }));
