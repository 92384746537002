import React from 'react';
import './LoaderDotted.scss';

const LoaderDotted = () => (
  <div className="snippet" data-title=".dot-flashing">
    <div className="stage">
      <div className="dot-flashing" />
    </div>
  </div>
);

export default LoaderDotted;
