import { all, takeLatest, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import SignUpService from './service';
import { openInfoModalWindow } from '../InitComponent/actions';
import MODAL_TYPES from '../../components/InfoModalWindow/constants';
import {
  mapDataForSelect,
  mapLocationsForSelect,
  mapDataForSelectWithCategories,
  mapGendersForSelect,
} from '../../global/helpers';

export function* signUpUserSaga({ payload }) {
  try {
    yield call(SignUpService.signUp, payload.data);

    yield put(
      actions.signUpUserAction.success({
        email: payload.data.email,
      }),
    );

    yield payload.onSuccess(payload.data.email);
  } catch (error) {
    yield put(actions.signUpUserAction.error(error));
    yield put(
      openInfoModalWindow({
        type: MODAL_TYPES.error,
        view: error.response.data.message[0],
      }),
    );
  }
}

export function* confirmEmailSaga({ payload }) {
  try {
    yield call(SignUpService.confirmEmail, payload.data);

    yield put(actions.confirmEmailAction.success());

    yield payload.onSuccess();
  } catch (error) {
    yield put(actions.confirmEmailAction.error(error));
  }
}

export function* getFormValuesSaga() {
  try {
    const locationsResponse = yield call(SignUpService.getLocations);
    const gendersResponse = yield call(SignUpService.getGenders);
    const businessTypesResponse = yield call(SignUpService.getBusinessTypes);
    const industriesResponse = yield call(SignUpService.getIndustries);

    const mappedData = {
      locations: mapLocationsForSelect(locationsResponse.data.data),
      genders: mapGendersForSelect(gendersResponse.data.data),
      businessTypes: mapDataForSelect(businessTypesResponse.data.data),
      industries: mapDataForSelectWithCategories(industriesResponse.data.data),
    };

    yield put(actions.getFormValuesAction.success(mappedData));
  } catch (error) {
    yield put(actions.getFormValuesAction.error(error));
  }
}

export function* updateUserSaga({
  payload: {
    data: { avatar, ...data },
    onSuccess,
  },
}) {
  try {
    let avatarResponse;

    if (avatar) {
      avatarResponse = yield call(SignUpService.postUserAvatar, avatar);
    }

    const response = yield call(SignUpService.updateUser, {
      ...data,
      cover: avatarResponse?.data?.data?.id
        ? {
            id: avatarResponse?.data?.data?.id,
          }
        : null,
    });

    yield put(actions.updateUserAction.success(response.data));

    yield onSuccess();
  } catch (error) {
    yield put(actions.updateUserAction.error(error));
    // MODAL ERROR HANDLING HERE
  }
}

export function* confirmSocialAccountSaga({ payload }) {
  try {
    yield call(SignUpService.validateSocial, payload);
    yield put(actions.confirmSocialAction.success());
  } catch (error) {
    yield put(actions.confirmSocialAction.error(error));
  }
}

export function* signUpPageWatcherSaga() {
  yield all([
    takeLatest(actions.signUpUserAction.REQUEST, signUpUserSaga),
    takeLatest(actions.confirmEmailAction.REQUEST, confirmEmailSaga),
    takeLatest(actions.getFormValuesAction.REQUEST, getFormValuesSaga),
    takeLatest(actions.updateUserAction.REQUEST, updateUserSaga),
    takeLatest(actions.confirmSocialAction.REQUEST, confirmSocialAccountSaga),
  ]);
}
