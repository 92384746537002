/* eslint-disable global-require */
import ApiService from 'global/services/api';
import {
  isMockLogInPage,
  isMockResetPasswordPage,
  isMockCheckEmailPage,
  isMockSetNewPasswordPage,
} from 'global/services/isMockEnabled';
import { mockImport } from 'global/helpers';

const logInUserMock = mockImport(
  () => require('../../__mocks__/LogInService/logInUser.mock.json'),
  isMockLogInPage,
);
const userResetPasswordMock = mockImport(
  () => require('../../__mocks__/LogInService/userResetPassword.mock.json'),
  isMockResetPasswordPage,
);
const checkEmailVerificationCodeMock = mockImport(
  () => require('../../__mocks__/LogInService/checkEmailVerificationCode.mock.json'),
  isMockCheckEmailPage,
);
const setNewPasswordMock = mockImport(
  () => require('../../__mocks__/LogInService/setNewPassword.mock.json'),
  isMockSetNewPasswordPage,
);
const getUserMock = mockImport(
  () => require('../../__mocks__/LogInService/getUser.mock.json'),
  isMockLogInPage,
);

class LogInService extends ApiService {
  logIn = (data) => this.post({ url: 'auth/signin', body: data }, logInUserMock);

  resetPassword = (data) =>
    this.post({ url: 'auth/reset-password', body: data }, userResetPasswordMock);

  checkEmailVerificationCode = (data) =>
    this.post(
      { url: 'auth/confirmation/reset-password', body: data },
      checkEmailVerificationCodeMock,
    );

  setNewPassword = (data, token) =>
    this.patch(
      { url: 'auth/profile', body: data, headers: { Authorization: `Bearer ${token}` } },
      setNewPasswordMock,
    );

  getUser = () => this.get({ url: 'auth/profile' }, getUserMock);
}

export default new LogInService();
