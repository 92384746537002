import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Icon from '../Icon';
import './Tooltip.scss';

const Tooltip = ({ text }) => {
  const { t } = useTranslation();

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const translatedText = t(text) === text ? text : t(text);

  return (
    <div className="tooltip-wrapper">
      <button
        type="button"
        className="tooltip-btn"
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
      >
        <Icon icon="tooltip-icon" className="tooltip-icon" />
      </button>
      <CSSTransition
        in={isTooltipVisible}
        timeout={300}
        classNames="tooltip--animation"
        unmountOnExit
      >
        <div className="tooltip-text">{translatedText}</div>
      </CSSTransition>
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Tooltip;
