import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './components/LanguageSelector';
import Icon from '../Icon';
import './Header.scss';

const Header = ({ link }) => {
  const { t } = useTranslation();

  return (
    <div className="header--wrapper">
      {link ? (
        <Link to={link} className="link link-md link-gray header--link">
          <Icon icon="arrow-left" className="link--icon" />
          {t('Header.link')}
        </Link>
      ) : (
        <div />
      )}
      <LanguageSelector />
    </div>
  );
};

Header.propTypes = {
  link: PropTypes.string,
};

Header.defaultProps = {
  link: null,
};

export default Header;
