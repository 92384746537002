import { asyncActionCreator } from '../../global/redux';
import * as constants from './constants';

export const updateProfileDetailsSettingsAction = asyncActionCreator(
  constants.UPDATE_PROFILE_DETAILS_SETTINGS,
);
export const updateProfileDescriptionSettingsAction = asyncActionCreator(
  constants.UPDATE_PROFILE_DESCRIPTION,
);
export const deleteAccountAction = asyncActionCreator(constants.DELETE_ACCOUNT);

export const authFacebookByCodeAction = asyncActionCreator(constants.AUTH_FACEBOOK_BY_CODE);
