export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_FORM_METADATA = 'GET_FORM_METADATA';
export const GET_USERS_BY_SEARCH = 'GET_USERS_BY_SEARCH';
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';

export const GET_FAVORITE = 'GET_FAVORITE';
export const ADD_TO_FAVORITE = 'ADD_TO_FAVORITE';
export const DELETE_FROM_FAVORITE = 'DELETE_FROM_FAVORITE';
export const FAVORITE_LIST_LOADING = 'FAVORITE_LIST_LOADING';

export const ADD_TO_COMPARISON = 'ADD_TO_COMPARISON';
export const DELETE_FROM_COMPARISON = 'DELETE_FROM_COMPARISON';
export const CLEAR_COMPARISON_LIST = 'CLEAR_COMPARISON_LIST';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
