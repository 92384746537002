import React from 'react';
import PropTypes from 'prop-types';
import './SwitchButton.scss';

const SwitchButton = ({ optionOn, optionOff, onChange, label, defaultValue }) => (
  <div className="switch-btn">
    {label && <label className="switch-btn-label">{label}</label>}
    <input
      type="checkbox"
      id="switch-btn-checkbox"
      onChange={onChange}
      defaultChecked={defaultValue}
    />
    <label
      htmlFor="switch-btn-checkbox"
      data-on={optionOn}
      data-off={optionOff}
      className="switch-btn-inner"
    />
  </div>
);

SwitchButton.propTypes = {
  optionOn: PropTypes.string.isRequired,
  optionOff: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
};

SwitchButton.defaultProps = {
  label: null,
  defaultValue: false,
};

export default SwitchButton;
