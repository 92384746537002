import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectors } from '../../reducer';
import Icon from '../../../../components/Icon';
import LanguageSelector from './components/LanguageSelector/LanguageSelector';
import { FOOTER_LINKS, ROUTES, COMPANY_ROUTES } from '../../routes';
import ROLES from '../../../../constants';
import './Sidebar.scss';
// import SidebarBanner from './components/SidebarBanner';
import { selectors as chatSelectors } from '../../../ChatPage/reducer';

const Sidebar = () => {
  const { t } = useTranslation();

  const profile = useSelector(selectors.userProfileData);
  const comparison = useSelector(selectors.comparisonListData);
  const favorite = useSelector(selectors.favoriteListData);
  const newMessagesCount = useSelector(chatSelectors.newMsgCount);

  const buildLabelAmount = (route) => {
    const { path } = route;

    if (path === '/compare') {
      return comparison?.length;
    }

    if (path === '/messages') {
      return newMessagesCount;
    }

    if (path === '/favorite') {
      return favorite?.length;
    }

    if (path === '/network/all') {
      return 10;
    }

    return 0;
  };

  return (
    <div
      className={`app-sidebar ${profile?.role === ROLES.influencer ? 'influencer-sidebar' : ''}`}
    >
      <div className="sidebar--header">
        <Link to="/feed">
          <Icon
            icon={profile?.role === ROLES.influencer ? 'logo-purple' : 'logo-white'}
            className="header--logo"
          />
        </Link>
      </div>
      <div className="sidebar--routes">
        {ROUTES.map((route) => (
          <NavLink key={route.id} className="sidebar--route" to={route.path}>
            <div className="route--content">
              <Icon icon={route.icon} className="route--icon" />
              <span className="route--name">{t(route.name)}</span>
            </div>
            {buildLabelAmount(route) ? (
              <span className="route--label">{buildLabelAmount(route)}</span>
            ) : null}
          </NavLink>
        ))}
        {profile?.role === ROLES.company
          ? COMPANY_ROUTES.map((route) => (
              <NavLink key={route.id} className="sidebar--route" to={route.path}>
                <div className="route--content">
                  <Icon icon={route.icon} className="route--icon" />
                  <span className="route--name">{t(route.name)}</span>
                </div>
                {buildLabelAmount(route) ? (
                  <span className="route--label">{buildLabelAmount(route)}</span>
                ) : null}
              </NavLink>
            ))
          : null}
        {/* <SidebarBanner /> */}
        <LanguageSelector />
        <NavLink className="sidebar--route" to="/support">
          <div className="route--content">
            <Icon icon="nav-support" className="route--icon" />
            <span className="route--name">{t('sidebar.routes.support')}</span>
          </div>
        </NavLink>
        <NavLink className="sidebar--route" to="/profile/settings#details">
          <div className="route--content">
            <Icon icon="nav-settings" className="route--icon" />
            <span className="route--name">{t('sidebar.routes.settings')}</span>
          </div>
        </NavLink>
      </div>
      <div className="sidebar--footer">
        <div className="footer--links-row">
          {FOOTER_LINKS.map((link) => (
            <Link key={link.id} to={link.path} className="footer--link">
              {t(link.name)}
            </Link>
          ))}
        </div>
        <Link to="/" className="footer--download-link">
          {t('sidebar.footer.downloadApp')}
        </Link>
        <p className="footer--copyright">{t('sidebar.footer.copyright')}</p>
      </div>
    </div>
  );
};

export default Sidebar;
