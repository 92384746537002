/* eslint-disable global-require */
import ApiService from 'global/services/api';
import { mockImport } from 'global/helpers';
import { isMockFeedPage } from '../../global/services/isMockEnabled';

const createPostMock = mockImport(
  () => require('../../__mocks__/FeedService/createPost.mock.json'),
  isMockFeedPage,
);
const getAllPostsMock = mockImport(
  () => require('../../__mocks__/FeedService/getAllPosts.mock.json'),
  isMockFeedPage,
);
const fileUploadMock = mockImport(
  () => require('../../__mocks__/FeedService/fileUpload.mock.json'),
  isMockFeedPage,
);
const getCompaniesMock = mockImport(
  () => require('../../__mocks__/FeedService/getCompanies.mock.json'),
  isMockFeedPage,
);
const getInfluencersMock = mockImport(
  () => require('../../__mocks__/FeedService/getInfluencers.mock.json'),
  isMockFeedPage,
);

export class FeedService extends ApiService {
  createPost = async (data) => this.post({ url: 'posts', body: data }, createPostMock);

  getAllPosts = async (sort) => this.get({ url: `posts?${sort}` }, getAllPostsMock);

  postLikePost = async ({ id }) => this.post({ url: `posts/${id}/likes`, body: {} }, null);

  deletePost = async ({ id }) => this.delete({ url: `posts/${id}`, body: {} }, null);

  deleteLikePost = async ({ id }) => this.delete({ url: `posts/${id}/likes`, body: {} }, null);

  fileUpload = async (data, progressCallback = null) =>
    this.post(
      { url: 'files', headers: { 'Content-Type': 'multipart/form-data' }, body: data },
      fileUploadMock,
      true,
      progressCallback,
    );

  getInfluencers = async () =>
    this.get({ url: 'users/search?role=INFLUENCER&take=3' }, getInfluencersMock);

  getCompanies = async () =>
    this.get({ url: 'users/search?role=COMPANY&take=3' }, getCompaniesMock);

  getComments = async ({ id }) => this.get({ url: `posts/${id}/comments` }, null);

  postComment = async ({ id, data }) =>
    this.post({ url: `posts/${id}/comments`, body: data }, null);

  getProfileFollowersApproved = async (id) =>
    this.get({ url: `relations/followers/${id}?status=APPROVED` }, null);

  getProfileFollowersRequested = async (id) =>
    this.get({ url: `relations/followers/${id}?status=REQUESTED` }, null);

  followUser = async (id) =>
    this.post({ url: `relations/followers/request/${id}`, body: {} }, null);

  unfollowUser = async (id) =>
    this.patch(
      {
        url: `relations/followers/${id}`,
        body: {
          status: 'DECLINED',
        },
      },
      null,
    );
}

export default new FeedService();
