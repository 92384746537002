import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import EmojiPicker from 'components/EmojiPicker';
import Icon from 'components/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import './MessageForm.scss';
import { addAlertAction } from 'pages/InitComponent/actions';
import { sendMessageFilesAction } from 'pages/ChatPage/actions';
import { getIconByFileType } from 'pages/ChatPage/helpers';
import ALERT_TYPES from '../../../../components/Alert/constants';

const MessageForm = ({ sendMessage, chatId, replyMessage, setReplyMessage, isFilesUploading }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const [sendFiles, setSendFiles] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const fileInput = useRef(null);

  const handleAddFile = () => {
    setShowEmoji(false);
    fileInput.current.click();
  };

  const handleFilesChange = (event) => {
    const isBig = Array.from(event.target.files).filter((file) => file.size > 159061428);

    if (isBig.length) {
      dispatch(
        addAlertAction({
          type: ALERT_TYPES.error,
          message: 'FILE_UPLOAD_TOO_BIG',
        }),
      );
      return;
    }

    // Check is file already in list
    let isAlreadyInList = false;

    sendFiles.map((fileInList) => {
      Array.from(event.target.files).map((newFile) => {
        if (newFile.name === fileInList.name && newFile.size === fileInList.size) {
          isAlreadyInList = true;
        }

        return newFile;
      });

      return fileInList;
    });

    // Add new files in list
    if (!isAlreadyInList) {
      Array.from(event.target.files).map((file) => setSendFiles((prev) => [...prev, file]));
    }

    // if (event.target.files.length) {
    //   const filesArr = Array.from(event.target.files);
    //   const replyId = replyMessage?.id || '';
    //   dispatch(sendMessageFilesAction.request({ filesArr, chatId, sendMessage, replyId }));
    //   setReplyMessage(null);
    // }
  };

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const handleEmojiShow = () => {
    setShowEmoji((v) => !v);
  };

  const handleEmojiSelect = (e) => {
    // eslint-disable-next-line
    setText((text) => text + e.native);
  };

  const handleReplyClose = () => setReplyMessage(null);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (chatId) {
      setShowEmoji(false);
      const replyId = replyMessage?.id || null;
      const trimmedText = text.trim();

      if (trimmedText) {
        await sendMessage({ messageText: trimmedText, chatId, replyId });
      }

      if (sendFiles.length) {
        dispatch(
          sendMessageFilesAction.request({ filesArr: sendFiles, chatId, sendMessage, replyId }),
        );
      }

      setText('');
      setReplyMessage(null);
      setSendFiles([]);
    }
  };

  return (
    <>
      <form className="messageForm" id="messageForm" onSubmit={handleSendMessage}>
        <div className="messageForm-input--wrapper">
          {' '}
          {replyMessage && (
            <div className="messageForm-reply--wrapper">
              <Icon icon="chatAnswer" />
              <div className="messageForm-reply">
                <p className="messageForm-reply--owner">{replyMessage.owner.fullName}</p>
                {replyMessage.type === 'FILE' ? (
                  <div className="messageForm-reply--file">
                    <div className="messageForm-reply--icon">
                      {' '}
                      <Icon
                        width="10"
                        height="10"
                        icon={getIconByFileType(replyMessage.file.extname)}
                      />
                    </div>
                    <p className="messageForm-reply--text">{replyMessage.file.title}</p>
                  </div>
                ) : (
                  <p className="messageForm-reply--text">{replyMessage.text}</p>
                )}
              </div>
              <button className="messageForm-reply--close" type="button" onClick={handleReplyClose}>
                <Icon icon="close" />
              </button>
            </div>
          )}
          <input
            className="form-input"
            value={text}
            type="text"
            placeholder="Message..."
            onChange={handleChangeText}
            onFocus={() => setShowEmoji(false)}
          />
          <button className="input-btn" type="button" onClick={handleAddFile}>
            <input
              onChange={(event) => {
                handleFilesChange(event);
                fileInput.current.value = null;
              }}
              multiple
              ref={fileInput}
              id="file"
              name="file"
              type="file"
              accept="file/*"
              className="input-btn-file"
            />
            <Icon icon="chatClip" className="input-btn--icon" />
          </button>
          <button className="input-btn" type="button" onClick={handleEmojiShow}>
            <Icon icon="chatEmoji" className="input-btn--icon" />
          </button>
        </div>
        <button
          className="btn btn-lg btn-primary form--submit-btn"
          type="submit"
          form="messageForm"
          disabled={isFilesUploading}
          title={isFilesUploading ? 'Files uploading' : ''}
        >
          {isFilesUploading ? (
            <CircularProgress style={{ color: '#ffffff' }} size={20} />
          ) : (
            <Icon icon="chatSend" className="input-btn--icon" />
          )}
        </button>
      </form>
      {showEmoji && (
        <div className="picker--container">
          <EmojiPicker
            onEmojiSelect={handleEmojiSelect}
            theme="light"
            icons="outline"
            emojiSize={20}
            showSkinTones="false"
            navPosition="bottom"
          />
        </div>
      )}
      <div className="messageFilesList">
        <ul className="messageFilesList__list">
          {sendFiles.map((file, fileIndex) => (
            <li key={`${file.name}${file.size}`} className="messageFilesList__listItem">
              <Icon
                className="messageFilesList__fileIcon"
                width="10"
                height="10"
                icon={getIconByFileType(file.name)}
              />
              <span className="messageFilesList__fileText">{file.name}</span>
              <Icon
                className="messageFilesList__deleteIcon"
                width="12"
                height="12"
                icon="cross"
                onClick={() => {
                  setSendFiles(
                    sendFiles.filter((stateFile, stateFileIndex) => stateFileIndex !== fileIndex),
                  );
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

MessageForm.propTypes = {
  setReplyMessage: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  chatId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  replyMessage: PropTypes.shape({
    type: PropTypes.oneOf(['FILE', 'TEXT']),
    id: PropTypes.string,
    file: PropTypes.shape({
      extname: PropTypes.string,
      title: PropTypes.string,
    }),
    text: PropTypes.string,
    owner: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }),
  isFilesUploading: PropTypes.bool,
};

MessageForm.defaultProps = {
  replyMessage: {},
  chatId: '',
  isFilesUploading: false,
};

export default MessageForm;
