import { Rating } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import './FeedbackStats.scss';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';

const FeedbackStats = ({ statsData }) => {
  const { t } = useTranslation();

  return (
    statsData && (
      <div className="feedback-stats--wrapper">
        <div className="feedback-stats--overall-rating">
          <Icon icon="star" />
          <p>{statsData.rating ? statsData.rating.toFixed(2) : 0}</p>
          <span>•</span>
          <p>
            {statsData.total} {t('FeedbacksPage.stats.reviews')}
          </p>
        </div>
        <div className="feedback-stats--categories">
          <p>{t('FeedbacksPage.stats.availability')}</p>
          <div className="feedback-stats--rating">
            <Rating
              defaultValue={statsData.availability ? statsData.availability : 0}
              precision={0.5}
              readOnly
              size="small"
            />
            <p>{statsData.availability ? statsData.availability.toFixed(1) : 0}</p>
          </div>
        </div>
        <div className="feedback-stats--categories">
          <p>{t('FeedbacksPage.stats.deadline')}</p>
          <div className="feedback-stats--rating">
            <Rating
              defaultValue={statsData.deadline ? statsData.deadline : 0}
              precision={0.5}
              readOnly
              size="small"
            />
            <p>{statsData.deadline ? statsData.deadline.toFixed(1) : 0}</p>
          </div>
        </div>
        <div className="feedback-stats--categories">
          <p>{t('FeedbacksPage.stats.quality')}</p>
          <div className="feedback-stats--rating">
            <Rating
              defaultValue={statsData.quality ? statsData.quality : 0}
              precision={0.5}
              readOnly
              size="small"
            />
            <p>{statsData.quality ? statsData.quality.toFixed(1) : 0}</p>
          </div>
        </div>
        <div className="feedback-stats--categories">
          <p>{t('FeedbacksPage.stats.satisfaction')}</p>
          <div className="feedback-stats--rating">
            <Rating
              defaultValue={statsData.satisfaction ? statsData.satisfaction : 0}
              precision={0.5}
              readOnly
              size="small"
            />
            <p>{statsData.satisfaction ? statsData.satisfaction.toFixed(1) : 0}</p>
          </div>
        </div>
      </div>
    )
  );
};

FeedbackStats.propTypes = {
  statsData: PropTypes.shape({
    availability: PropTypes.number,
    deadline: PropTypes.number,
    quality: PropTypes.number,
    satisfaction: PropTypes.number,
    total: PropTypes.number,
    rating: PropTypes.number,
  }),
};

FeedbackStats.defaultProps = {
  statsData: null,
};

export default FeedbackStats;
