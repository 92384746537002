import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors as mainSelectors, selectors } from '../../../../reducer';
import { clearSearchDataAction } from '../../../../actions';
import ContentLoader from '../../../../../../components/ContentLoader';
import Avatar from '../../../../../../components/Avatar';
import ROLES from '../../../../../../constants';
import Icon from '../../../../../../components/Icon';
import './SearchResults.scss';

const SearchResults = ({ values, isSearchVisible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const profile = useSelector(mainSelectors.userProfileData);

  const headerSearchData = useSelector(selectors.headerSearchData);
  const headerSearchLoading = useSelector(selectors.headerSearchLoading);
  const [filteredHeaderSearchData, setFilteredHeaderSearchData] = useState([]);

  const handleExited = () => {
    dispatch(clearSearchDataAction());
  };

  // Skip myself profile
  useEffect(() => {
    if (headerSearchData === null) {
      setFilteredHeaderSearchData([]);
    } else {
      setFilteredHeaderSearchData(
        headerSearchData.filter((user) => !profile?.id || (profile?.id && profile.id !== user.id)),
      );
    }
  }, [headerSearchData, profile]);

  return (
    <CSSTransition
      in={isSearchVisible}
      timeout={300}
      classNames="search-result-animation"
      unmountOnExit
      onExited={handleExited}
    >
      <div className="search-result-container">
        <div className="search-result--content">
          {headerSearchLoading ? (
            <ContentLoader visible={headerSearchLoading} />
          ) : (
            <>
              {filteredHeaderSearchData?.length ? (
                filteredHeaderSearchData?.map((user) => (
                  <Link key={user.id} to={`/profile/${user.id}`} className="user--row">
                    <div className="user--avatar">
                      <Avatar file={user.cover} size="md" />
                    </div>
                    <div className="user--info">
                      <h3 className="user--name">
                        {user.role === ROLES.company ? user.companyName : user.fullName}
                        {user.isVerified ? (
                          <Icon icon="verified" className="user-verified-icon" />
                        ) : null}
                      </h3>
                      {user.role === ROLES.company ? (
                        <div className="user--position">{user.positionInCompany}</div>
                      ) : (
                        <div className="user--socials">
                          <div className="social">
                            <Icon icon="facebook" className="social-icon" />
                            <span className="social-value">346k</span>
                          </div>
                          <div className="social">
                            <Icon icon="instagram" className="social-icon" />
                            <span className="social-value">346k</span>
                          </div>
                          <div className="social">
                            <Icon icon="tiktok" className="social-icon" />
                            <span className="social-value">346k</span>
                          </div>
                          <div className="social">
                            <Icon icon="youtube" className="social-icon" />
                            <span className="social-value">346k</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Link>
                ))
              ) : (
                <div className="search--empty">
                  <Icon icon="enter" className="search--empty--icon" />
                  <div className="empty-message">
                    <h3 className="message--title">
                      {t('HeaderDashboard.emptySearch.titleStart')}@{values.searchQuery}
                      {t('HeaderDashboard.emptySearch.titleEnd')}
                    </h3>
                    <span className="message--description">
                      {t('HeaderDashboard.emptySearch.description')}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="search-result--footer">
          <Link to={`/search?query=${values.searchQuery}`} className="link link-color link-md">
            {t(
              filteredHeaderSearchData?.length
                ? 'HeaderDashboard.seeAllBtn'
                : 'HeaderDashboard.emptyResultBtn',
            )}
          </Link>
        </div>
      </div>
    </CSSTransition>
  );
};

SearchResults.propTypes = {
  values: PropTypes.shape({
    searchQuery: PropTypes.string,
  }).isRequired,
  isSearchVisible: PropTypes.bool,
};

SearchResults.defaultProps = {
  isSearchVisible: false,
};

export default SearchResults;
