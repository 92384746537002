/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncReducerHandler, asyncInitialState } from '../../global/redux';
import * as actions from './actions';
import { clearComparisonList } from '../MainPage/actions';

const initialState = {
  ...asyncInitialState,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getComparisonUsersDataAction),
  [clearComparisonList]: (state) => {
    state.data = [];
  },
});

const comparisonUsersData = (state) => state.comparePageReducer.data;
const comparisonUsersLoading = (state) => state.comparePageReducer.loading;

const selectors = {
  comparisonUsersData,
  comparisonUsersLoading,
};

export { selectors, initialState, reducer };
export default reducer;
