/* eslint-disable global-require */
import ApiService from 'global/services/api';
import { mockImport } from 'global/helpers';
import { isMockFeedbacksPage } from '../../global/services/isMockEnabled';

const getFeedbacksByIdMock = mockImport(
  () => require('../../__mocks__/FeedbacksService/getFeedbacksById.mock.json'),
  isMockFeedbacksPage,
);

const getStatsByIdMock = mockImport(
  () => require('../../__mocks__/FeedbacksService/getStatsById.mock.json'),
  isMockFeedbacksPage,
);

const createFeedbackMock = mockImport(
  () => require('../../__mocks__/FeedbacksService/createFeedback.mock.json'),
  isMockFeedbacksPage,
);

class FeedbacksService extends ApiService {
  getFeedbacksById = async ({ id, sort = 'desc=updatedAt' }) =>
    this.get({ url: `feedbacks/recipients/${id}?${sort}` }, getFeedbacksByIdMock);

  getStatsById = async (id) =>
    this.get({ url: `feedbacks/recipients/${id}/stats` }, getStatsByIdMock);

  postFeedback = async (data) => this.post({ url: `feedbacks`, body: data }, createFeedbackMock);
}

export default new FeedbacksService();
