import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUserProfileAction } from '../MainPage/actions';
import { getProfileByIdAction } from './actions';
import { selectors } from './reducer';
import { selectors as mainSelectors } from '../MainPage/reducer';
import ProfileDetails from './components/ProfileDetails';
import ProfileFeedbacks from './components/ProfileFeedbacks';
import ContentLoader from '../../components/ContentLoader';
import ChatComponent from '../../components/ChatComponent';
import FeedbackAddNew from '../FeedbacksPage/components/FeedbackAddNew';
import ProfileFeed from './components/ProfileFeed';
import './ProfilePage.scss';
import ProfileSocialsStats from './components/ProfileSocialsStats';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const profile = useSelector(mainSelectors.userProfileData);
  const isProfileLoading = useSelector(mainSelectors.userProfileLoading);

  const userProfile = useSelector(selectors.userProfileData);
  const isUserProfileLoading = useSelector(selectors.userProfileLoading);

  useEffect(() => {
    if (params.id !== profile?.id) {
      dispatch(getProfileByIdAction.request({ id: params.id, userId: profile?.id }));
    } else {
      dispatch(getUserProfileAction.request());
    }
  }, [params.id, profile?.id, dispatch]);

  return (
    <div className="company-profile-page--wrapper">
      {isProfileLoading || isUserProfileLoading ? (
        <ContentLoader visible={isProfileLoading || isUserProfileLoading} />
      ) : (
        <>
          <ChatComponent />
          <div className="app-pages--content">
            <div className="content--main">
              <ProfileDetails
                profile={params.id !== profile?.id ? userProfile : profile}
                isProfilePage={params.id !== profile?.id}
              />
              <ProfileFeed
                isProfilePage={params.id !== profile?.id}
                userId={params.id ? params.id : profile?.id}
              />
            </div>
            <div className="content--additional">
              {userProfile?.lastFeedback ? (
                <ProfileFeedbacks
                  feedback={userProfile?.lastFeedback}
                  feedbacksTotal={userProfile?.feedbacksTotal}
                />
              ) : (
                !!params.id &&
                params.id !== profile?.id && (
                  <FeedbackAddNew recipientId={params.id ? params.id : profile?.id} />
                )
              )}
              <ProfileSocialsStats
                metrics={params.id !== profile?.id ? userProfile?.socials : profile?.socials}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfilePage;
