export const getValuesForSubmitting = (values) => {
  const data = {
    role: values.view.toUpperCase(),
    fullName: values.fullName,
    location: {
      id: values.location.value,
    },
    industries: values.industries.map(({ value: id }) => ({ id })),
  };

  if (values.avatar) {
    const formData = new FormData();
    formData.append('file', values.avatar);

    data.avatar = formData;
  }

  if (values.view === 'company') {
    return {
      ...data,
      companyName: values.companyName,
      businessType: {
        id: values.businessType.value,
      },
      website: values.website,
      positionInCompany: values.positionInCompany,
    };
  }

  return {
    ...data,
    gender: values.gender.value,
    dob: new Date(values.birthdayDate).toISOString(),
  };
};

export default getValuesForSubmitting;
