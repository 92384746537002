import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from 'components/Avatar';
import PostFeedEditor from 'components/PostFeedEditor';
import LoaderDotted from 'components/LoaderDotted';
import { getCommentsAction, postCommentAction } from 'pages/FeedPage/actions';
import { selectors } from 'pages/FeedPage/reducer';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'global/formats/luxonFormats';
import PropTypes from 'prop-types';
import ROLES from 'constants';

const now = DateTime.now();

const FeedItemComments = ({ id, comments, isProfilePage }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector(selectors.commentsLoading);

  useEffect(() => {
    dispatch(getCommentsAction.request({ id, isProfilePage }));
  }, []);

  const handleSubmitComment = ({ text, files }) => {
    dispatch(
      postCommentAction.request({
        id,
        text,
        files,
        isProfilePage,
      }),
    );
  };

  const getFormattedDate = useCallback(
    (date) => {
      const publishedDate = DateTime.fromISO(date);
      const isItWasToday = now.hasSame(publishedDate, 'day');

      if (isItWasToday) {
        return `${t('time.today')} ${publishedDate.toFormat(TIME_FORMAT)}`;
      }

      return DateTime.fromISO(date).toFormat(DATE_TIME_FORMAT);
    },
    [t],
  );

  return (
    <div className="feed-post--comments">
      {isLoading ? (
        <div className="comments--loader-wrapper">
          <LoaderDotted />
        </div>
      ) : (
        <>
          {comments?.map((el) => (
            <div key={el.id} className="comment">
              <Avatar size="md" file={el.owner?.cover} />
              <div className="comment--body">
                <p className="comment--user-name">
                  {el.owner.role === ROLES.company ? el.owner.companyName : el.owner.fullName}
                  <span>{getFormattedDate(el.updatedAt)}</span>
                </p>
                <div className="comment--message">{el.text}</div>
              </div>
            </div>
          ))}
        </>
      )}
      <PostFeedEditor onSubmit={handleSubmitComment} canSendFiles={false} />
    </div>
  );
};

FeedItemComments.propTypes = {
  id: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  isProfilePage: PropTypes.bool,
};

FeedItemComments.defaultProps = {
  comments: [],
  isProfilePage: false,
};

export default FeedItemComments;
