import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../../../components/Icon';
import './ViewSelector.scss';

const ViewSelector = ({ name, value, icon, isActive, onSelect }) => {
  const { t } = useTranslation();

  const handleClickSelector = () => {
    onSelect(value);
  };

  return (
    <button
      type="button"
      className={`view-selector ${isActive ? 'active' : ''}`}
      onClick={handleClickSelector}
    >
      {icon && <Icon icon={icon} className="view-selector--icon" />}
      {t(name)}
    </button>
  );
};

ViewSelector.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

ViewSelector.defaultProps = {
  icon: null,
  isActive: false,
};

export default ViewSelector;
