import { all, takeLatest, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import LogInService from './service';
import { setToLocalStorage, setToSessionStorage } from '../../global/helpers';
import { openInfoModalWindow } from '../InitComponent/actions';
import INFO_MODAL_TYPES from '../../components/InfoModalWindow/constants';

export function* logInUserSaga({
  payload: {
    data: { isRemember, ...data },
    onSuccess,
  },
}) {
  try {
    const response = yield call(LogInService.logIn, data);

    const setter = isRemember ? setToLocalStorage : setToSessionStorage;

    setter('token', response.data.data.token);

    yield put(
      actions.logInUserAction.success({
        token: response.data.data.token,
      }),
    );

    yield onSuccess();
  } catch (error) {
    yield put(actions.logInUserAction.error(error));
    yield put(
      openInfoModalWindow({
        type: INFO_MODAL_TYPES.error,
        view: error.response.data.message[0],
      }),
    );
  }
}

export function* resetUserPasswordSaga({ payload }) {
  try {
    yield call(LogInService.resetPassword, payload.data);

    yield put(actions.resetUserPasswordAction.success(payload.data));

    if (payload.onSuccess) {
      yield payload.onSuccess();
    }
  } catch (error) {
    yield put(actions.resetUserPasswordAction.error(error));
  }
}

export function* checkEmailVerificationCodeSaga({ payload }) {
  try {
    const response = yield call(LogInService.checkEmailVerificationCode, payload.data);

    yield put(actions.checkEmailVerificationCodeAction.success());

    yield payload.onSuccess(response.data.data.token);
  } catch (error) {
    yield put(actions.resetUserPasswordAction.error(error));
    yield put(
      openInfoModalWindow({
        type: INFO_MODAL_TYPES.error,
        view: error.response.data.message[0],
      }),
    );
  }
}

export function* setNewPasswordSaga({ payload }) {
  try {
    yield call(LogInService.setNewPassword, payload.data.data, payload.data.token);

    yield put(actions.setNewPasswordAction.success());

    yield payload.onSuccess();
  } catch (error) {
    yield put(actions.setNewPasswordAction.error(error));
  }
}

export function* getUserByTokenSaga() {
  try {
    const response = yield call(LogInService.getUser);

    yield put(actions.getUserByTokenAction.success(response.data.data));
  } catch (error) {
    yield put(actions.getUserByTokenAction.error(error));
  }
}

export function* logInPageWatcherSaga() {
  yield all([
    takeLatest(actions.logInUserAction.REQUEST, logInUserSaga),
    takeLatest(actions.resetUserPasswordAction.REQUEST, resetUserPasswordSaga),
    takeLatest(actions.checkEmailVerificationCodeAction.REQUEST, checkEmailVerificationCodeSaga),
    takeLatest(actions.setNewPasswordAction.REQUEST, setNewPasswordSaga),
    takeLatest(actions.getUserByTokenAction.REQUEST, getUserByTokenSaga),
  ]);
}
