import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import AvatarGroup from '../AvatarGroup';
import { USERS } from './constants';
import './AuthContainer.scss';

const AuthContainer = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className="auth-container--wrapper">
      <div className="content-container">{children}</div>
      <div className="info-container">
        <div className="info-container--content-wrapper">
          <Icon icon="stars" className="content-wrapper--icon" />
          <h1 className="content-wrapper--title">{t('AuthContainer.title')}</h1>
          <p className="content-wrapper--description">{t('AuthContainer.description')}</p>
          <div className="content-wrapper--people">
            <AvatarGroup users={USERS} />
            <p className="people--text">{t('AuthContainer.peopleText')}</p>
          </div>
          <Icon icon="big-arrow" className="content-wrapper--big-arrow-icon" />
        </div>
      </div>
    </div>
  );
};

AuthContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

AuthContainer.defaultProps = {
  children: null,
};

export default AuthContainer;
