import React from 'react';
import Icon from '../Icon';
import './ActionsButton.scss';

const ActionsButton = () => (
  <button type="button" className="btn btn-md btn-secondary actions-btn">
    <Icon icon="action" className="actions-btn--icon" />
  </button>
);

export default ActionsButton;
