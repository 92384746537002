import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
// import Icon from 'components/Icon';
import Heading from 'components/Heading';
import Input from 'components/Input';
import { logInValidationSchema } from './validations';
import './LogInForm.scss';
import Checkbox from '../../../../components/Checkbox';

const LogInForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        isRemember: true,
      }}
      validationSchema={logInValidationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className="log-in-form">
          <Heading title="LogInPage.heading.title" subtitle="LogInPage.heading.subtitle" />
          <Input
            type="email"
            name="email"
            label={t('LogInPage.LogInForm.email.label')}
            placeholder={t('LogInPage.LogInForm.email.placeholder')}
            errors={errors}
            touched={touched}
          />
          <Input
            type="password"
            name="password"
            label={t('LogInPage.LogInForm.password.label')}
            placeholder={t('LogInPage.LogInForm.password.placeholder')}
            errors={errors}
            touched={touched}
          />
          <div className="log-in-form--footer">
            <Checkbox label={t('LogInPage.LogInForm.rememberMe')} name="isRemember" />
            <Link to="/auth/forgot-password" className="link link-md link-color">
              {t('LogInPage.LogInForm.forgotPassword')}
            </Link>
          </div>
          <button type="submit" className="btn btn-lg btn-primary">
            {t('LogInPage.LogInForm.buttons.submit')}
          </button>
          {/* <button type="button" className="btn btn-lg btn-tertiary"> */}
          {/*  <Icon icon="google" className="btn--icon" /> */}
          {/*  {t('LogInPage.LogInForm.buttons.Google')} */}
          {/* </button> */}
          {/* <button type="button" className="btn btn-lg btn-tertiary"> */}
          {/*  <Icon icon="facebook" className="btn--icon" /> */}
          {/*  {t('LogInPage.LogInForm.buttons.Facebook')} */}
          {/* </button> */}
          <div className="log-in-form--already-have">
            {t('LogInPage.LogInForm.haveAccount.text')}
            <Link to="/signup" className="link link-color link-md">
              {t('LogInPage.LogInForm.haveAccount.link')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LogInForm;
