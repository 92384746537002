import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from '../../pages/LogInPage/reducer';

const ProtectedRoute = ({ children }) => {
  const userToken = useSelector(selectors.userToken);

  if (!userToken) {
    return <Navigate to="/auth" replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

export default ProtectedRoute;
