import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { createRepostAction, deletePostAction } from 'pages/FeedPage/actions';
import Icon from '../Icon';
import UserInfo from '../UserInfo';
import FeedItemComments from './FeedItemComments';
import FeedItemPublished from './FeedItemPublished';
import './FeedItem.scss';

const FeedItem = ({
  posts,
  post,
  onDeleteLike,
  onAddLike,
  isProfilePage,
  profileData,
  addRepostCallback,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCommentsOpened, setIsCommentsOpened] = useState(false);
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);
  const [isAlreadyReposted, setIsAlreadyReposted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const postMenuOpen = Boolean(anchorEl);

  const item = useMemo(() => {
    if (post.type === 'REPOST' && !!post.original) {
      return post.original;
    }

    return post;
  }, [post]);

  const handleChangeLikeStatus = () => {
    const index = posts.indexOf(posts.find((el) => el.id === post.id));

    if (post.isLike) {
      dispatch(onDeleteLike.request({ id: post.id, index }));
    } else {
      dispatch(onAddLike.request({ id: post.id, index }));
    }
  };

  const getMediaElement = (url) => {
    const isImg = url.match(/.jpg|.png|.jpeg|.jfif|.gif|.tiff/gm);

    if (isImg) {
      return <img src={url} className="img" alt="name" />;
    }

    return (
      <video className="video" controls>
        <source src={url} type="video/mp4" />
        <track kind="captions" />
      </video>
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRepost = async (id) => {
    setIsAlreadyReposted(true);
    await dispatch(createRepostAction.request({ original: { id } }));

    if (addRepostCallback !== undefined) {
      addRepostCallback();
    }
  };

  const handleDelete = (id) => {
    dispatch(deletePostAction.request({ id }));
  };

  return (
    <div className="feed-post--wrapper">
      <div className="feed-post--header">
        <div className="feed-post--user-info-row">
          <UserInfo user={item.owner} />
          {post.owner?.id === profileData?.id && (
            <>
              <button
                id={`post-menu-opener-${post.id}`}
                className="header--control-btn"
                aria-controls={postMenuOpen ? `post-menu-${post.id}` : undefined}
                aria-haspopup="true"
                aria-expanded={postMenuOpen ? 'true' : undefined}
                type="button"
                onClick={handleClick}
              >
                <Icon icon="action" className="control-btn--icon" />
              </button>
              <Menu
                id={`post-menu-${post.id}`}
                anchorEl={anchorEl}
                open={postMenuOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': `post-menu-opener-${post.id}`,
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleDelete(post.id);
                    handleClose();
                  }}
                >
                  {t('FeedPage.buttons.delete')}
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
        {item.createdAt && <FeedItemPublished date={post.createdAt} />}
      </div>
      <div className="feed-post--body">
        {item.text && (
          <p className="body--text">
            {isFullTextVisible ? item.text : item.text.split(0, 204)[0]}
            {!isFullTextVisible && item.text.length > 204 ? (
              <div className="hidden-text">
                <button
                  type="button"
                  className="link link-color link-btn link-md"
                  onClick={() => setIsFullTextVisible(true)}
                >
                  {t('feedItem.readMoreBtn')}
                </button>
              </div>
            ) : null}
          </p>
        )}
        <div className="images--wrapper">
          {item.files?.length === 1 && (
            <div className="single-image--wrapper">{getMediaElement(item.files[0])}</div>
          )}
          {item.files?.length === 2 && (
            <div className="duo-images--wrapper">
              {item.files.map((img) => (
                <div key={img} className="image--wrapper">
                  {getMediaElement(img)}
                </div>
              ))}
            </div>
          )}
          {item.files?.length === 3 && (
            <div className="trio-image--wrapper">
              <div className="left-image">{getMediaElement(item.files[0])}</div>
              <div className="right-images">
                {getMediaElement(item.files[1])}
                {getMediaElement(item.files[2])}
              </div>
            </div>
          )}
          {item.files?.length === 4 && (
            <div className="squad-image--wrapper">
              <div className="imgs--row">
                <div className="img--wrapper">{getMediaElement(item.files[0])}</div>
                <div className="img--wrapper">{getMediaElement(item.files[1])}</div>
              </div>
              <div className="imgs--row">
                <div className="img--wrapper">{getMediaElement(item.files[2])}</div>
                <div className="img--wrapper">{getMediaElement(item.files[3])}</div>
              </div>
            </div>
          )}
          {item.files?.length > 4 && (
            <div className="post-image--wrapper">
              <div className="upper--row">
                <div className="img--wrapper">{getMediaElement(item.files[0])}</div>
                <div className="img--wrapper">{getMediaElement(item.files[1])}</div>
              </div>
              <div className="down--row">
                <div className="img--wrapper">{getMediaElement(item.files[2])}</div>
                <div className="img--wrapper">{getMediaElement(item.files[3])}</div>
                <div className="see-more" style={{ backgroundImage: `url(${item.files[4]})` }}>
                  <span className="more--text">+{item.files.length - 4}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isCommentsOpened && (
        <FeedItemComments id={post.id} comments={post?.comments} isProfilePage={isProfilePage} />
      )}
      <div className="feed-post--footer">
        <div className="footer--btn-block">
          <button onClick={handleChangeLikeStatus} type="button" className="footer--interact-btn">
            <Icon
              icon="like"
              className="interact-btn--icon"
              stroke={post.isLike ? ' #3538cd' : '#667085'}
            />
          </button>
          <span className="interact-btn--count">
            {post.likesCount !== 0 ? post.likesCount : ''}
          </span>
          <button
            type="button"
            className="footer--interact-btn"
            onClick={() => setIsCommentsOpened((prev) => !prev)}
          >
            <Icon icon="comment" className="interact-btn--icon" />
          </button>
          <span className="interact-btn--count">
            {post.commentsCount !== 0 ? post.commentsCount : ''}
          </span>
          <button
            onClick={() => !isAlreadyReposted && handleRepost(post.id)}
            type="button"
            className="footer--interact-btn"
            disabled={isAlreadyReposted}
          >
            <Icon icon="repost" className="interact-btn--icon" />
          </button>
          <span className="interact-btn--count">
            {post.repostCount !== 0 ? post.repostCount : ''}
          </span>
        </div>
        <div>
          <button type="button" className="footer--interact-btn">
            <Icon icon="share" className="interact-btn--icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

FeedItem.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})),
  post: PropTypes.shape({
    type: PropTypes.string,
    original: PropTypes.shape({}),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    owner: PropTypes.shape({
      id: PropTypes.string,
      role: PropTypes.string,
      cover: PropTypes.string,
      positionInCompany: PropTypes.string,
      email: PropTypes.string,
      fullName: PropTypes.string,
      isVerified: PropTypes.bool,
      companyName: PropTypes.string,
    }).isRequired,
    text: PropTypes.string,
    isLike: PropTypes.bool,
    likesCount: PropTypes.number,
    commentsCount: PropTypes.number,
    repostCount: PropTypes.number,
    files: PropTypes.arrayOf(PropTypes.string),
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    createdAt: PropTypes.string,
  }).isRequired,
  onDeleteLike: PropTypes.shape({
    request: PropTypes.func.isRequired,
  }).isRequired,
  onAddLike: PropTypes.shape({
    request: PropTypes.func.isRequired,
  }).isRequired,
  isProfilePage: PropTypes.bool,
  profileData: PropTypes.shape({ id: PropTypes.string }).isRequired,
  addRepostCallback: PropTypes.func,
};

FeedItem.defaultProps = {
  posts: [],
  isProfilePage: false,
  addRepostCallback: undefined,
};

export default FeedItem;
