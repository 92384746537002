export const isMockSignUpPage = false;
export const isMockConfirmEmailPage = false;
export const isMockUserInformationPage = false;
export const isMockUserSocialPages = true;

export const isMockLogInPage = false;
export const isMockResetPasswordPage = false;
export const isMockCheckEmailPage = false;
export const isMockSetNewPasswordPage = false;

export const isMockMainPage = false;
export const isMockProfileSettingsPage = false;
export const isMockProfilePage = false;
export const isMockFaqPage = false;
export const isMockSearchPage = false;
export const isMockFeedPage = false;
export const isMockChatPage = false;

export const isMockFeedbacksPage = false;
export const isMockSavedListPage = false;
export const isMockComparePage = false;
export const isMockNetworkPage = false;
export const isMockNotificationsPage = true;
