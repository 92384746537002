import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import QRCode from 'react-qr-code';
import Heading from 'components/Heading';
import Input from 'components/Input';
import Icon from 'components/Icon';
import CodeInput from 'components/CodeInput';
import getRandomPin from 'global/services/randomDigitsString';
import SOCIAL_LINKS from './constants';
import findSocialValidationSchema from './validations';
import './FindSocialForm.scss';

const FindSocialForm = ({ socialType, onSubmit }) => {
  const [formStep, setFormStep] = useState('find');
  const { t } = useTranslation();

  const code = useMemo(() => getRandomPin(1000, 9999), []);

  return (
    <Formik
      initialValues={{
        userName: '',
        code,
        socialType,
      }}
      onSubmit={onSubmit}
      validationSchema={findSocialValidationSchema}
    >
      {({ errors, touched, values, isValid, dirty }) => (
        <Form className="find-social-page--form">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={formStep}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false);
              }}
              classNames="fade-animation"
            >
              <div className="form--steps-container">
                {formStep === 'find' && (
                  <div className="social-form">
                    <Heading
                      title="FindSocialPage.heading.title"
                      subtitle="FindSocialPage.heading.subtitle"
                    />
                    <Input
                      type="text"
                      name="userName"
                      label={`${t('FindSocialPage.FindSocialForm.userName.label.first')}
                          ${socialType}
                          ${t('FindSocialPage.FindSocialForm.userName.label.second')}`}
                      errors={errors}
                      touched={touched}
                    />
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      disabled={!(isValid && dirty)}
                      onClick={() => {
                        setFormStep('almost');
                      }}
                    >
                      {t('FindSocialPage.FindSocialForm.buttons.enter')}
                    </button>
                    <button type="button" className="btn btn-lg btn-tertiary">
                      {t('FindSocialPage.FindSocialForm.buttons.upgradePlan')}
                    </button>
                  </div>
                )}
                {formStep === 'almost' && (
                  <div className="social-form">
                    <Heading title="SubscriptionPage.heading.title" />

                    <p className="social-form-subtitle">
                      {t('SubscriptionPage.subtitle.first')}
                      <span className="highlighted-span">
                        {t('SubscriptionPage.subtitle.second')}
                      </span>
                      {t('SubscriptionPage.subtitle.third')}
                    </p>

                    <button type="button" className="btn btn-lg btn-primary">
                      {t('SubscriptionPage.buttons.upgrade')}
                    </button>

                    <button type="button" className="btn btn-lg btn-secondary">
                      {t('SubscriptionPage.buttons.readMore')}
                    </button>

                    <button
                      type="button"
                      onClick={() => {
                        setFormStep('confirm');
                      }}
                      className="link link-gray link-md link-btn"
                    >
                      {t('SubscriptionPage.buttons.later')}
                    </button>
                  </div>
                )}
                {formStep === 'confirm' && (
                  <div className="social-form">
                    <Heading title="ConfirmPage.heading.title" />
                    <ol className="confirm-form-list">
                      <li className="confirm-form-list--item">
                        <p className="item--text">
                          {t('ConfirmPage.stepsList.firstItem.text.first')}
                          <span className="highlighted-span">
                            {t('ConfirmPage.stepsList.firstItem.text.second')}
                          </span>
                          {t('ConfirmPage.stepsList.firstItem.text.third')}
                        </p>
                        <CodeInput
                          onClick={() => navigator.clipboard.writeText(values.code)}
                          className="find-social-form--code-input"
                          name="code"
                          errors={errors}
                          touched={touched}
                          disabled
                        />
                      </li>
                      <li className="confirm-form-list--item">
                        <p className="item--text">
                          {t('ConfirmPage.stepsList.secondItem.text.first')}
                          <span className="highlighted-span">
                            {t('ConfirmPage.stepsList.secondItem.text.second')}
                          </span>
                          {t('ConfirmPage.stepsList.secondItem.text.third')}
                          <span className="highlighted-span">{values.userName}</span>
                        </p>
                      </li>
                    </ol>

                    <a
                      className="link link-md open-social-link"
                      href={SOCIAL_LINKS[socialType]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('ConfirmPage.buttons.openLink')}
                    </a>

                    <QRCode
                      size={100}
                      value={SOCIAL_LINKS[socialType]}
                      className="find-social-form--qr"
                    />

                    <button type="submit" className="btn btn-lg btn-primary">
                      {t('ConfirmPage.buttons.check')}
                    </button>
                    <Link to="/signup/social/" className="link link-gray link-md">
                      <Icon icon="arrow-left" className="link--icon" />
                      {t('ConfirmPage.buttons.back')}
                    </Link>
                  </div>
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </Form>
      )}
    </Formik>
  );
};

FindSocialForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  socialType: PropTypes.string.isRequired,
};

export default FindSocialForm;
