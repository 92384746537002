import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeSignUpStatusAction, signUpUserAction } from './actions';
import AuthContainer from '../../components/AuthContainer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SignUpForm from './components/SignUpForm';
import { setToLocalStorage } from '../../global/helpers';
import { SIGN_UP_STEPS } from './constants';
import './SignUpPage.scss';

const SignUpPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const successSignUpPostEffect = (email) => {
    dispatch(changeSignUpStatusAction(SIGN_UP_STEPS.emailVerification));
    setToLocalStorage('sign_up_step', SIGN_UP_STEPS.emailVerification);
    setToLocalStorage('sign_up_email', email);
    navigate('/signup/verification');
  };

  const handleSignUpUser = ({ passwordRepeat: deleted, ...data }) => {
    dispatch(
      signUpUserAction.request({
        data,
        onSuccess: successSignUpPostEffect,
      }),
    );
  };

  return (
    <AuthContainer>
      <div className="sign-up-page--content">
        <Header />
        <div className="content--sign-up-form-container">
          <SignUpForm onSubmit={handleSignUpUser} />
        </div>
        <Footer />
      </div>
    </AuthContainer>
  );
};

export default SignUpPage;
