/* eslint-disable global-require */
import i18next from 'i18next';
import ApiService from 'global/services/api';
import {
  isMockSignUpPage,
  isMockConfirmEmailPage,
  isMockUserInformationPage,
  isMockUserSocialPages,
} from 'global/services/isMockEnabled';
import { mockImport } from 'global/helpers';

const signUpUserMock = mockImport(
  () => require('../../__mocks__/SignUpService/signUpUser.mock.json'),
  isMockSignUpPage,
);
const confirmEmailMock = mockImport(
  () => require('../../__mocks__/SignUpService/confirmEmail.mock.json'),
  isMockConfirmEmailPage,
);
const updateUserMock = mockImport(
  () => require('../../__mocks__/SignUpService/updateUser.mock.json'),
  isMockUserInformationPage,
);
const postAvatarMock = mockImport(
  () => require('../../__mocks__/SignUpService/postAvatar.mock.json'),
  isMockUserInformationPage,
);
const getLocationsMock = mockImport(
  () => require('../../__mocks__/SignUpService/getLocations.mock.json'),
  isMockUserInformationPage,
);
const getGendersMock = mockImport(
  () => require('../../__mocks__/SignUpService/getGenders.mock.json'),
  isMockUserInformationPage,
);
const getBusinessTypesMock = mockImport(
  () => require('../../__mocks__/SignUpService/getBussinessTypes.mock.json'),
  isMockUserInformationPage,
);
const getIndustriesMock = mockImport(
  () => require('../../__mocks__/SignUpService/getIndustries.mock.json'),
  isMockUserInformationPage,
);
const validateSocialMock = mockImport(
  () => require('../../__mocks__/SignUpService/validateSocial.mock.json'),
  isMockUserSocialPages,
);

class SignUpService extends ApiService {
  signUp = (data) => this.post({ url: 'auth/confirmation/email', body: data }, signUpUserMock);

  confirmEmail = (data) => this.post({ url: 'auth/register', body: data }, confirmEmailMock);

  postUserAvatar = (data) =>
    this.post(
      { url: 'files', headers: { 'Content-Type': 'multipart/form-data' }, body: data },
      postAvatarMock,
    );

  updateUser = (data) => this.patch({ url: `auth/profile`, body: data }, updateUserMock);

  getLocations = () =>
    this.get({ url: 'metadata/locations', params: { lang: i18next.language } }, getLocationsMock);

  getGenders = () =>
    this.get({ url: 'metadata/genders', params: { lang: i18next.language } }, getGendersMock);

  getBusinessTypes = () =>
    this.get(
      { url: 'metadata/business-types', params: { lang: i18next.language } },
      getBusinessTypesMock,
    );

  getIndustries = () =>
    this.get({ url: 'metadata/industries', params: { lang: i18next.language } }, getIndustriesMock);

  validateSocial = (data) => this.post({ url: '', body: data }, validateSocialMock);
}

export default new SignUpService();
