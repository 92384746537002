import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changePasswordResetStatusAction, resetUserPasswordAction } from '../../actions';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { setToLocalStorage } from '../../../../global/helpers';
import { RESET_PASSWORD_STEPS } from '../../constants';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const resetPasswordPostEffect = (email) => {
    dispatch(changePasswordResetStatusAction(RESET_PASSWORD_STEPS.requestSent));
    setToLocalStorage('reset_password_step', RESET_PASSWORD_STEPS.requestSent);
    setToLocalStorage('reset_password_email', email);
    navigate('/auth/email-check');
  };

  const handleResetPassword = (data) => {
    dispatch(
      resetUserPasswordAction.request({
        data,
        onSuccess: () => resetPasswordPostEffect(data.email),
      }),
    );
  };

  return (
    <div className="additional-page--wrapper">
      <Header />
      <ForgotPasswordForm onSubmit={handleResetPassword} />
      <Footer />
    </div>
  );
};

export default ForgotPasswordPage;
