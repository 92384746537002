/* eslint-disable global-require */
import ApiService from 'global/services/api';
import { mockImport } from 'global/helpers';
import { isMockProfilePage } from '../../global/services/isMockEnabled';

const getProfileByIdMock = mockImport(
  () => require('../../__mocks__/ProfileService/getProfileById.mock.json'),
  isMockProfilePage,
);

class ProfileService extends ApiService {
  getProfileById = async (id) => this.get({ url: `users/${id}` }, getProfileByIdMock);

  getLastFeedbackById = async (id) => this.get({ url: `feedbacks/recipients/${id}?take=1` });

  getProfileFeedById = async ({ id, sort }) => this.get({ url: `posts/owners/${id}?${sort}` });

  postLikePost = async ({ id }) => this.post({ url: `posts/${id}/likes`, body: {} }, null);

  deleteLikePost = async ({ id }) => this.delete({ url: `posts/${id}/likes`, body: {} }, null);

  createNewPost = async (data) => this.post({ url: 'posts', body: data }, null);

  fileUpload = async (data) =>
    this.post(
      { url: 'files', headers: { 'Content-Type': 'multipart/form-data' }, body: data },
      null,
    );

  getComments = async ({ id }) => this.get({ url: `posts/${id}/comments` }, null);

  postComment = async ({ id, data }) =>
    this.post({ url: `posts/${id}/comments`, body: data }, null);

  addFriend = async (id) => this.post({ url: `relations/friends/request/${id}`, body: {} }, null);

  getFriendStatus = async ({ id, status }) =>
    this.get({ url: `relations/friends/${id}?status=${status}` }, null);

  followUser = async (id) =>
    this.post({ url: `relations/followers/request/${id}`, body: {} }, null);

  unfollowUser = async (id) =>
    this.patch({ url: `relations/followers/request/${id}`, body: { status: 'DECLINED' } }, null);

  getFollowStatus = async (id) =>
    this.get({ url: `relations/followers/${id}?status=APPROVED` }, null);

  addFriendBack = async (id) =>
    this.patch({ url: `relations/friends/request/${id}`, body: { status: 'APPROVED' } }, null);
}

export default new ProfileService();
