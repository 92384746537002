import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from 'components/Footer';
import Header from 'components/Header';
import SocailContainer from 'components/SocailContainer';
import FindSocialForm from './components';
import './FindSocialPage.scss';

const FindSocialPage = () => {
  const { socialType } = useParams();
  const handleSubmitForm = (values) => values;
  return (
    <SocailContainer socialNetwork={socialType}>
      <div className="add-social-page--content">
        <Header />
        <div className="content--add-social-form-container">
          <FindSocialForm onSubmit={handleSubmitForm} socialType={socialType} />
        </div>
        <Footer />
      </div>
    </SocailContainer>
  );
};

export default FindSocialPage;
