import React from 'react';
import PropTypes from 'prop-types';
import './ProfileSocialsStats.scss';
import Icon from '../../../../components/Icon';

const ProfileSocialsStats = ({ metrics }) =>
  metrics?.length && (
    <div className="profile-social-metrics--wrapper">
      {metrics.map((account) => (
        <div key={account.id} className="account">
          <h4>
            {account.name} <Icon icon="instagram" />{' '}
          </h4>
          <div className="stats">
            {account.mainMetrics.media_followers_count.values &&
              Object.entries(account.mainMetrics.media_followers_count.values).map(
                ([key, value]) =>
                  key === 'followers_count' && (
                    <p key={`${key}${value}`}>
                      {key.replace('_', ' ')} : {value}
                    </p>
                  ),
              )}
          </div>
        </div>
      ))}
    </div>
  );

ProfileSocialsStats.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.shape({})),
};

ProfileSocialsStats.defaultProps = {
  metrics: [],
};

export default ProfileSocialsStats;
