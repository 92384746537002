import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { selectors } from '../../../../reducer';
import Icon from '../../../../../../components/Icon';
import Heading from '../../../../../../components/Heading';
import ViewSelector from './components/ViewSelector';
import Input from '../../../../../../components/Input';
import Select from '../../../../../../components/Select';
import FileUpload from '../../../../../../components/FileUpload';
import { userInformationValidationSchema } from './validations';
import { COMPANY_FORM, INFLUENCER_FORM, VIEW_TYPES, FORM_STEPS } from './constants';
import './UserInformationForm.scss';

const UserInformationForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const userInformationFormData = useSelector(selectors.userInformationPageData);

  const [formStep, setFormStep] = useState('main');

  const handleChangeFormStep = async (s) => {
    setFormStep(s);
  };

  return (
    <Formik
      initialValues={{
        view: 'company',
        companyName: '',
        businessType: null,
        location: null,
        website: '',
        fullName: '',
        positionInCompany: '',
        gender: null,
        birthdayDate: null,
      }}
      validationSchema={userInformationValidationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleBlur,
        errors,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
        isValid,
        dirty,
      }) => (
        <Form className="additional-page--form">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={formStep}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false);
              }}
              classNames="fade-animation"
            >
              <div className="form--steps-container">
                {formStep === FORM_STEPS.main && (
                  <div className="form--section">
                    <Heading
                      subtitle="UserInformationPage.heading.subtitle"
                      title="UserInformationPage.heading.title"
                      position="center"
                    />
                    <div className="form--view-selector">
                      {VIEW_TYPES.map((selector) => (
                        <ViewSelector
                          key={selector.id}
                          name={selector.name}
                          value={selector.value}
                          icon={selector.icon}
                          isActive={values.view === selector.value}
                          onSelect={(value) => setFieldValue('view', value)}
                        />
                      ))}
                    </div>
                    {(values.view === 'company' ? COMPANY_FORM : INFLUENCER_FORM).map((formItem) =>
                      formItem.type === 'select' ? (
                        <Select
                          key={formItem.id}
                          name={formItem.name}
                          label={t(formItem.label)}
                          placeholder={t(formItem.placeholder)}
                          isSearchable={formItem.name === 'location'}
                          options={
                            userInformationFormData
                              ? userInformationFormData[`${formItem.name}s`]
                              : []
                          }
                          errors={errors}
                          touched={touched}
                        />
                      ) : (
                        <Input
                          key={formItem.id}
                          type={formItem.type}
                          name={formItem.name}
                          label={t(formItem.label)}
                          placeholder={t(formItem.placeholder)}
                          onBlur={handleBlur}
                          errors={errors}
                          touched={touched}
                        />
                      ),
                    )}
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      disabled={!(isValid && dirty)}
                      onClick={() => handleChangeFormStep(FORM_STEPS.industries)}
                    >
                      {t('UserInformationPage.UserInformationForm.buttons.next')}
                    </button>
                  </div>
                )}
                {formStep === FORM_STEPS.industries && (
                  <div className="form--section">
                    <Heading
                      subtitle="UserInformationPage.industriesHeading.subtitle"
                      title="UserInformationPage.industriesHeading.title"
                      position="center"
                    />
                    <Select
                      name="industries"
                      placeholder={t(
                        'UserInformationPage.UserInformationForm.industries.placeholder',
                      )}
                      isSelectedOutside
                      isSearchable
                      isMulti
                      searchIcon="search"
                      maxSelected={6}
                      errors={errors}
                      options={userInformationFormData.industries}
                      touched={touched}
                    />
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={() => handleChangeFormStep(FORM_STEPS.avatar)}
                    >
                      {t('UserInformationPage.UserInformationForm.buttons.continue')}
                    </button>
                    <button type="button" className="btn btn-lg btn-tertiary">
                      {t('UserInformationPage.UserInformationForm.buttons.skip')}
                    </button>
                    <div className="form--footer">
                      <button
                        type="button"
                        className="link link-gray link-md"
                        onClick={() => handleChangeFormStep(FORM_STEPS.main)}
                      >
                        <Icon icon="arrow-left" className="link--icon" />
                        {t('UserInformationPage.UserInformationForm.buttons.back')}
                      </button>
                    </div>
                  </div>
                )}
                {formStep === FORM_STEPS.avatar && (
                  <div className="form--section">
                    <Heading
                      subtitle="UserInformationPage.logoHeading.subtitle"
                      title="UserInformationPage.logoHeading.title"
                      position="center"
                    />
                    <FileUpload
                      name="avatar"
                      buttonText={t('UserInformationPage.UserInformationForm.avatar.buttonText')}
                      descriptionText={t(
                        'UserInformationPage.UserInformationForm.avatar.descriptionText',
                      )}
                      formatDescription={t(
                        'UserInformationPage.UserInformationForm.avatar.formatDescription',
                      )}
                      onChange={setFieldValue}
                      onTouch={setFieldTouched}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                    <button type="submit" className="btn btn-lg btn-primary">
                      {t('UserInformationPage.UserInformationForm.buttons.continue')}
                    </button>
                    <button type="submit" className="btn btn-lg btn-tertiary">
                      {t('UserInformationPage.UserInformationForm.buttons.skip')}
                    </button>
                    <div className="form--footer">
                      <button
                        type="button"
                        className="link link-gray link-md"
                        onClick={() => handleChangeFormStep(FORM_STEPS.industries)}
                      >
                        <Icon icon="arrow-left" className="link--icon" />
                        {t('UserInformationPage.UserInformationForm.buttons.back')}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </Form>
      )}
    </Formik>
  );
};

UserInformationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UserInformationForm;
