import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { closeInfoModalWindow } from '../../pages/InitComponent/actions';
import Icon from '../Icon';
import INFO_MODAL_TYPES from './constants';
import './InfoModalWindow.scss';

const InfoModalWindow = ({ view, type, isOpened, onCancel, onConfirm }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isBackdropAppeared, setIsBackdropAppeared] = useState(false);

  const handleOutsideCloseModal = (e) => {
    if (!e.target.closest('.info-modal')) {
      dispatch(closeInfoModalWindow());
    }
  };

  const handleCloseModal = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }

    dispatch(closeInfoModalWindow());
  };

  const handleConfirmModal = () => {
    dispatch(closeInfoModalWindow());
    onConfirm();
  };

  const modalFooters = {
    [INFO_MODAL_TYPES.error]: (
      <button
        type="button"
        className="btn btn-lg btn-primary btn-primary-error"
        onClick={handleCloseModal}
      >
        {t('modals.buttons.tryAgain')}
      </button>
    ),
    [INFO_MODAL_TYPES.warningConfirm]: (
      <>
        <button
          type="button"
          className="btn btn-lg btn-secondary btn-secondary-gray w-50"
          onClick={handleCloseModal}
        >
          {t('modals.buttons.cancel')}
        </button>
        <button
          type="button"
          className="btn btn-lg btn-primary btn-primary-error w-50"
          onClick={handleConfirmModal}
        >
          {t(`modals.${view}.confirmBtn`)}
        </button>
      </>
    ),
    [INFO_MODAL_TYPES.success]: (
      <button type="button" className="btn btn-lg btn-primary" onClick={handleCloseModal}>
        {t(`modals.${view}.confirmBtn`)}
      </button>
    ),
  };

  const modalIcons = {
    [INFO_MODAL_TYPES.error]: 'modal-error',
    [INFO_MODAL_TYPES.warningConfirm]: 'modal-warning',
    [INFO_MODAL_TYPES.success]: 'modal-success',
  };

  return (
    <CSSTransition
      in={isOpened}
      timeout={300}
      classNames="info-modal-backdrop-animation"
      unmountOnExit
      onEntering={() => setIsBackdropAppeared(true)}
      onExit={() => setIsBackdropAppeared(false)}
    >
      <div className="info-modal-backdrop" onClick={handleOutsideCloseModal}>
        <CSSTransition
          in={isBackdropAppeared}
          timeout={300}
          classNames="info-modal-animation"
          unmountOnExit
        >
          <div className="info-modal">
            <div className="info-modal-body">
              <Icon icon={modalIcons[type]} className="modal--icon" />
              <h4 className="info-modal--title">{t(`modals.${view}.title`)}</h4>
              <p className="info-modal--description">{t(`modals.${view}.description`)}</p>
            </div>
            <div className="info-modal--footer">{modalFooters[type]}</div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

InfoModalWindow.propTypes = {
  view: PropTypes.string,
  type: PropTypes.string,
  isOpened: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

InfoModalWindow.defaultProps = {
  view: null,
  type: null,
  isOpened: false,
  onCancel: null,
  onConfirm: null,
};

export default InfoModalWindow;
