import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import Avatar from '../Avatar';
import './FileUpload.scss';

const FileUpload = ({
  className,
  name,
  accept,
  buttonText,
  descriptionText,
  formatDescription,
  hint,
  errors,
  touched,
  values,
  withFilePreview,
  onChange,
  onTouch,
}) => {
  const { t } = useTranslation();

  const inputRef = useRef();

  const onInputChange = (event) => {
    if (event.currentTarget.files[0]) {
      onChange(name, event.currentTarget.files[0]);
      onTouch(name, true, true);
    }
  };

  return (
    <div className={className}>
      <div className="upload-field--wrapper">
        <div className="upload--wrapper">
          <label htmlFor={name} className="upload-field--label">
            {values[name] && withFilePreview ? (
              <Avatar size="xxxl" file={values[name]} />
            ) : (
              <>
                <Icon icon="upload" className="label--icon" />
                <p className="label--text">
                  <span>{buttonText} </span>
                  {descriptionText}
                </p>
                <p className="label--text">{formatDescription}</p>
              </>
            )}
          </label>
          <input
            ref={inputRef}
            className="input"
            type="file"
            name={name}
            id={name}
            accept={accept}
            onChange={onInputChange}
          />
        </div>
        {hint && !errors[name] && !touched[name] && (
          <div className="upload-field--hint">{hint}</div>
        )}
        <CSSTransition
          in={errors[name] && touched[name]}
          timeout={500}
          classNames="error-block-animation"
          unmountOnExit
        >
          <div className="upload-field--error-block-wrapper">
            <ErrorMessage
              name={name}
              render={(msg) => (
                <div className="upload-error-block">
                  <div className="upload-validation-message">{t(msg)}</div>
                </div>
              )}
            />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

FileUpload.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  formatDescription: PropTypes.string.isRequired,
  hint: PropTypes.string,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({}).isRequired,
  withFilePreview: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onTouch: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  className: null,
  accept: null,
  hint: null,
  withFilePreview: true,
};

export default FileUpload;
