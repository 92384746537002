import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import './Heading.scss';

const Heading = ({ title, subtitle, subtext, position }) => {
  const { t } = useTranslation();

  return (
    <div className={`heading--wrapper ${position === 'center' ? 'align-center' : ''}`}>
      <Icon icon="logo" className="heading--logo" />
      <h2 className="heading--title">{t(title)}</h2>
      {subtitle && (
        <p className="heading--subtitle">
          {t(subtitle)}
          {subtext && <span className="subtitle--subtext">{subtext}</span>}
        </p>
      )}
    </div>
  );
};

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  subtext: PropTypes.string,
  position: PropTypes.string,
};

Heading.defaultProps = {
  subtitle: null,
  position: 'left',
  subtext: null,
};

export default Heading;
