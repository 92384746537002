import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './ChatItem.scss';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import UserInfo from 'components/UserInfo';
import ChatModal from './components/ChatModal';
import MessageItem from './components/MessageItem';
import { selectors } from '../../reducer';

const ChatItem = ({ chatParticipant, chatWindowRef, handleReplyMessage, handleReadMessage }) => {
  const { t } = useTranslation();
  const chatMessages = useSelector(selectors.chatMessages);

  return (
    <>
      <div className="chat--heading">
        <UserInfo size="xxl" user={chatParticipant} />
        <div className="heading--btn-wrapper">
          <button type="button" className="btn btn-sm btn-primary heading--feedback-btn">
            {t('ChatPage.messages.heading.feedback')}
          </button>
          <button type="button" className="btn btn-sm btn-secondary heading--control-btn">
            <Icon icon="action" />
          </button>
        </div>
      </div>
      <div id="chatWindow" ref={chatWindowRef} className="chat-messages--wrapper">
        <ChatModal handleReplyMessage={handleReplyMessage} />
        {chatParticipant &&
          chatMessages &&
          chatMessages.map((message) => (
            <MessageItem
              key={message.id}
              message={message}
              participantId={chatParticipant.id}
              chatWindowRef={chatWindowRef}
              handleReadMessage={handleReadMessage}
            />
          ))}
        <div id="end" />
      </div>
    </>
  );
};

ChatItem.propTypes = {
  // eslint-disable-next-line
  chatWindowRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
  handleReadMessage: PropTypes.func.isRequired,
  chatParticipant: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  handleReplyMessage: PropTypes.func.isRequired,
};

export default ChatItem;
