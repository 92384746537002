import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const UploadGallery = ({ files, onDelete }) => (
  <div className="gallery-wrapper">
    {files.map((file) => (
      <button
        key={`${file.name}_${uuidv4}`}
        className="gallery-wrapper-item"
        type="button"
        onClick={() => onDelete(file.name)}
      >
        {file.type.slice(0, 5) === 'image' ? (
          <img className="gallery-wrapper-media" alt="file.name" src={URL.createObjectURL(file)} />
        ) : (
          <video className="gallery-wrapper-media" width="320" height="240" controls>
            <source src={URL.createObjectURL(file)} type="video/mp4" />
            <track kind="captions" />
          </video>
        )}
      </button>
    ))}
  </div>
);

UploadGallery.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  onDelete: PropTypes.func.isRequired,
};

UploadGallery.defaultProps = {
  files: [],
};

export default UploadGallery;
