import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NetworkNav = () => {
  const { t } = useTranslation();

  return (
    <div className="page--navigation">
      <div className="navigation--body">
        <NavLink to="/network/friends" className="navigation--link">
          {t('NetworkPage.additionalContent.navigation.links.myNetwork')}
          {/* {friendsData?.length > 0 && (
            <span className="link--counter">{friendsData?.length}</span>
          )} */}
        </NavLink>
        <NavLink to="/network/requests" className="navigation--link">
          {t('NetworkPage.additionalContent.navigation.links.newRequests')}
          {/* {requestsData?.length > 0 && (
              <span className="link--counter">{requestsData?.length}</span>
            )} */}
        </NavLink>
        <NavLink to="/network/followers" className="navigation--link">
          {t('NetworkPage.additionalContent.navigation.links.following')}
          {/* {followsData?.length > 0 && (
            <span className="link--counter">{followsData?.length}</span>
          )} */}
        </NavLink>
      </div>
      <div className="navigation--footer">
        <Link to="/search/influencer" className="btn btn-link btn-md">
          {t('NetworkPage.additionalContent.navigation.buttons.search')}
        </Link>
      </div>
    </div>
  );
};
export default NetworkNav;
