import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as actions from './actions';
import FaqService from './service';

export function* getFaqListSaga() {
  try {
    const response = yield call(FaqService.getFaqList);

    const sortedData = response.data.data.result.sort((a, b) => a.order - b.order);

    yield put(actions.getFaqListAction.success(sortedData));
  } catch (error) {
    yield put(actions.getFaqListAction.error(error));
  }
}

export function* faqPageWatcherSaga() {
  yield all([takeLatest(actions.getFaqListAction.REQUEST, getFaqListSaga)]);
}
