import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './NotificationItem.scss';

const NotificationItem = ({ notification }) => {
  const { t } = useTranslation();

  return (
    <div className="notification-item--wrapper">
      <div>
        <Avatar size="md" className="notification-item--avatar" file={notification?.owner?.cover} />
        <div>
          <div className="notification-item--info info">
            <p className="info--title">{notification.owner.fullName}</p>
            {notification.owner.isVerified && <Icon icon="verified" />}
            <p className="info--company">
              {notification.owner.positionInCompany} {notification.owner.companyName}
            </p>
          </div>
          <p className="notification-item--description">{notification.description}</p>
          <div className="notification-item--footer">
            {notification.type === 'FRIEND_REQUEST' && (
              <>
                <button type="button" className="btn btn-link btn-gray">
                  {t('NotificationsPage.item.buttons.ignore')}
                </button>
                <button type="button" className="btn btn-link">
                  {t('NotificationsPage.item.buttons.accept')}
                </button>
              </>
            )}
            {notification.type === 'FRIEND_ACCEPT' && (
              <button type="button" className="btn btn-link">
                {t('NotificationsPage.item.buttons.viewProfile')}
              </button>
            )}
            {(notification.type === 'LIKE' || notification.type === 'SUBSCRIPTION') && (
              <p className="notification-item--description">{notification.time}</p>
            )}
          </div>
        </div>
      </div>
      <div>
        <button type="button" className="notification-item--control-btn">
          <Icon icon="action" className="control-btn--icon" />
        </button>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    description: PropTypes.string,
    time: PropTypes.string,
    owner: PropTypes.shape({
      fullName: PropTypes.string,
      isVerified: PropTypes.bool,
      positionInCompany: PropTypes.string,
      companyName: PropTypes.string,
      cover: PropTypes.string,
    }),
  }).isRequired,
};

export default NotificationItem;
