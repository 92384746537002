import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logInUserAction } from './actions';
import LogInForm from './components/LogInForm';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AuthContainer from '../../components/AuthContainer';
import './LogInPage.scss';

const LogInPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const successLoginPostEffect = () => {
    navigate('/feed');
  };

  const handleLogInUser = (data) => {
    dispatch(
      logInUserAction.request({
        data,
        onSuccess: successLoginPostEffect,
      }),
    );
  };

  return (
    <AuthContainer>
      <div className="log-in-page--content">
        <Header />
        <div className="content--log-in-form-container">
          <LogInForm onSubmit={handleLogInUser} />
        </div>
        <Footer />
      </div>
    </AuthContainer>
  );
};

export default LogInPage;
