import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Avatar.scss';
import Icon from '../Icon';

const Avatar = ({ size, file, className }) => {
  const [avatarToDisplay, setAvatarToDisplay] = useState(null);

  const getFile = () => {
    if (!file) {
      setAvatarToDisplay(null);
      return;
    }

    const isFileUrl = typeof file === 'string';

    if (!isFileUrl) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setAvatarToDisplay(reader.result);
      };

      reader.readAsDataURL(file);
    }

    setAvatarToDisplay(file);
  };

  useEffect(() => {
    getFile();
    // eslint-disable-next-line
  }, [file]);

  return (
    <div className={`avatar--wrapper ${className}`}>
      <div className={`avatar avatar-${size}`}>
        {avatarToDisplay ? (
          <img className="avatar--img" src={avatarToDisplay} alt="avatar" />
        ) : (
          <Icon icon="user" className="avatar--icon" />
        )}
      </div>
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.string,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
};

Avatar.defaultProps = {
  size: 'xl',
  file: null,
  className: null,
};

export default Avatar;
