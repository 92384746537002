import React from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import SocailContainer from 'components/SocailContainer';
import AddSocialForm from './components';
import './AddSocialPage.scss';

const AddSocialPage = () => (
  <SocailContainer socialNetwork="tiktok">
    <div className="add-social-page--content">
      <Header />
      <div className="content--add-social-form-container">
        <AddSocialForm />
      </div>
      <Footer />
    </div>
  </SocailContainer>
);

export default AddSocialPage;
