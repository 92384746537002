import { createReducer } from '@reduxjs/toolkit';
import { asyncReducerHandler, asyncInitialState } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  totalSearch: asyncInitialState,
  companySearch: asyncInitialState,
  influencerSearch: asyncInitialState,
  followers: asyncInitialState,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getUsersBySearchParamsAction, 'totalSearch'),
  ...asyncReducerHandler(actions.getCompaniesBySearchParamsAction, 'companySearch'),
  ...asyncReducerHandler(actions.getInfluencersBySearchParamsAction, 'influencerSearch'),
  ...asyncReducerHandler(actions.getFollowersAction, 'followers'),
});

const totalSearchData = (state) => state.searchReducer.totalSearch.data;
const totalSearchLoading = (state) => state.searchReducer.totalSearch.loading;

const companiesSearchData = (state) => state.searchReducer.companySearch.data;
const companiesSearchLoading = (state) => state.searchReducer.companySearch.loading;

const influencersSearchData = (state) => state.searchReducer.influencerSearch.data;
const influencersSearchLoading = (state) => state.searchReducer.influencerSearch.loading;

const followersData = (state) => state.searchReducer.followers.data?.map((i) => i.acceptor.id);
const followersLoading = (state) => state.searchReducer.followers.loading;

const selectors = {
  totalSearchData,
  totalSearchLoading,
  companiesSearchData,
  companiesSearchLoading,
  influencersSearchData,
  influencersSearchLoading,
  followersData,
  followersLoading,
};

export { selectors, reducer, initialState };
export default reducer;
