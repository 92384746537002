import React from 'react';
import AUDIENCE_QUALITY_SCORE from './components/SearchFilers/constants';
import Icon from '../../components/Icon';
import ROLES from '../../constants';

export const buildQueryString = (params, isCompany = true) => {
  let str;

  if (isCompany) {
    str = '?role=COMPANY';
  } else {
    str = '?role=INFLUENCER';
  }

  if (!params) {
    return str;
  }

  if (params.gender) {
    str = `${str}&gender=${params.gender.value}`;
  }

  if (params.is_verified_only) {
    str = `${str}&isVerified=${true}`;
  }

  if (params.age[0]) {
    str = `${str}&ageFrom=${params.age[0]}`;
  }

  if (params.age[1]) {
    str = `${str}&ageTo=${params.age[1]}`;
  }

  if (params.location) {
    str = `${str}&location=${params.location.value}`;
  }

  if (params.business_type) {
    str = `${str}&businessType=${params.business_type.value}`;
  }

  if (params.search) {
    str = `${str}&search=${params.search}`;
  }

  if (params.industry) {
    str = `${str}&industries=${params.industry.value}`;
  }

  return str;
};

export const buildApplicationQueryString = (params) => {
  let str = '';
  let isFirst = true;

  if (params.age[0]) {
    str = `${str}${isFirst ? '?' : '&'}ageFrom=${params.age[0]}`;
    isFirst = false;
  }

  if (params.age[1]) {
    str = `${str}${isFirst ? '?' : '&'}ageTo=${params.age[1]}`;
    isFirst = false;
  }

  if (params.followers[0]) {
    str = `${str}${isFirst ? '?' : '&'}followersFrom=${params.followers[0]}`;
    isFirst = false;
  }

  if (params.followers[1]) {
    str = `${str}${isFirst ? '?' : '&'}followersTo=${params.followers[1]}`;
    isFirst = false;
  }

  if (params.audience_gender) {
    str = `${str}${isFirst ? '?' : '&'}audience_gender=${params.audience_gender.value}`;
    isFirst = false;
  }

  if (params.audience_location) {
    str = `${str}${isFirst ? '?' : '&'}audience_location=${params.audience_location.value}`;
    isFirst = false;
  }

  if (params.audience_quality?.length) {
    str = `${str}${isFirst ? '?' : '&'}audience_quality=${params.audience_quality
      .map(({ id }) => id)
      .join('+')}`;
    isFirst = false;
  }

  if (params.business_type) {
    str = `${str}${isFirst ? '?' : '&'}business_type=${params.business_type.value}`;
    isFirst = false;
  }

  if (params.gender) {
    str = `${str}${isFirst ? '?' : '&'}gender=${params.gender.value}`;
    isFirst = false;
  }

  if (params.industry) {
    str = `${str}${isFirst ? '?' : '&'}industry=${params.industry.value}`;
    isFirst = false;
  }

  if (params.location) {
    str = `${str}${isFirst ? '?' : '&'}location=${params.location.value}`;
    isFirst = false;
  }

  if (params.is_verified_only) {
    str = `${str}${isFirst ? '?' : '&'}is_verified_only=${true}`;
    isFirst = false;
  }

  if (params.search) {
    str = `${str}${isFirst ? '?' : '&'}search=${params.search}`;
  }

  return str;
};

export const buildQueryObject = (params, genders, location, businessTypes, industries) => {
  const keys = params.keys();
  const queryObject = {
    age: [],
    audience_gender: null,
    audience_location: null,
    audience_quality: [],
    business_type: null,
    followers: [],
    gender: null,
    industry: null,
    is_verified_only: false,
    location: null,
  };

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key of keys) {
    if (key === 'followersFrom') {
      queryObject.followers[0] = params.get(key);
    }

    if (key === 'followersTo') {
      queryObject.followers[1] = params.get(key);
    }

    if (key === 'ageFrom') {
      queryObject.age[0] = params.get(key);
    }

    if (key === 'ageTo') {
      queryObject.age[1] = params.get(key);
    } else if (key === 'gender' || key === 'audience_gender') {
      const value = params.get(key);
      queryObject[key] = genders?.find((g) => g.value === value);
    } else if (key === 'business_type') {
      const value = params.get(key);
      queryObject[key] = businessTypes?.find((b) => b.value === value);
    } else if (key === 'audience_location' || key === 'location') {
      const value = params.get(key);
      queryObject[key] = location?.find((l) => l.value === value);
    } else if (key === 'audience_quality') {
      const value = params.get(key);
      queryObject[key] = AUDIENCE_QUALITY_SCORE.filter((l) => value.includes(l.value)).map((l) => ({
        id: l.id,
        value: l.value,
      }));
    } else if (key === 'industry') {
      const value = params.get(key);
      queryObject[key] = industries
        ?.reduce((acc, i) => [...acc, ...i.options], [])
        ?.find((i) => i.value === value);
    } else if (key === 'is_verified_only') {
      queryObject[key] = Boolean(params.get(key));
    } else {
      queryObject[key] = params.get(key);
    }
  }

  return queryObject;
};

export const buildActiveFilters = (queryObject, t, handleSearch) => {
  const filtersButtons = {
    gender: (value) => (
      <div className="active-filter--item">
        <span className="item--name">{value.label}</span>
        <button
          type="button"
          className="btn"
          onClick={() => {
            handleSearch({
              ...queryObject,
              gender: null,
            });
          }}
        >
          <Icon icon="cross" className="btn--icon" />
        </button>
      </div>
    ),
    industry: (value) => (
      <div className="active-filter--item" key={value.value}>
        <span className="item--name">{value.label}</span>
        <button
          type="button"
          className="btn"
          onClick={() => {
            handleSearch({
              ...queryObject,
              industry: null,
            });
          }}
        >
          <Icon icon="cross" className="btn--icon" />
        </button>
      </div>
    ),
    location: (value) => (
      <div className="active-filter--item" key={value.value}>
        <span className="item--name">{value.label}</span>
        <button
          type="button"
          className="btn"
          onClick={() => {
            handleSearch({
              ...queryObject,
              location: null,
            });
          }}
        >
          <Icon icon="cross" className="btn--icon" />
        </button>
      </div>
    ),
    audience_gender: (value) => (
      <div className="active-filter--item" key={value.value}>
        <span className="item--name">
          {t('SearchFilers.form.audienceQuality.title')}
          {value.label}
        </span>
        <button
          type="button"
          className="btn"
          onClick={() => {
            handleSearch({
              ...queryObject,
              audience_gender: null,
            });
          }}
        >
          <Icon icon="cross" className="btn--icon" />
        </button>
      </div>
    ),
    audience_location: (value) => (
      <div className="active-filter--item" key={value.value}>
        <span className="item--name">
          {t('SearchFilers.form.audienceQuality.title')}
          {value.label}
        </span>
        <button
          type="button"
          className="btn"
          onClick={() => {
            handleSearch({
              ...queryObject,
              audience_location: null,
            });
          }}
        >
          <Icon icon="cross" className="btn--icon" />
        </button>
      </div>
    ),
    audience_quality: (value) =>
      value.map((item) => (
        <div className="active-filter--item" key={item.id}>
          <span className="item--name">{t(`SearchFilers.form.audienceQuality.${item.value}`)}</span>
          <button
            type="button"
            className="btn"
            onClick={() => {
              handleSearch({
                ...queryObject,
                audience_quality: queryObject.audience_quality.filter(
                  (element) => element.id !== item.id,
                ),
              });
            }}
          >
            <Icon icon="cross" className="btn--icon" />
          </button>
        </div>
      )),
  };

  return Object.entries(queryObject).map(([key, value]) => {
    if (filtersButtons[key] && value) {
      return filtersButtons[key](value);
    }

    return null;
  });
};

export const mapUsersByRole = (users) => {
  const mappedData = {
    companies: [],
    influencers: [],
  };

  users.map((user) => {
    if (user.role === ROLES.company) {
      mappedData.companies.push(user);
    }

    if (user.role === ROLES.influencer) {
      mappedData.influencers.push(user);
    }

    return user;
  });

  return mappedData;
};

export default buildQueryString;
