import { asyncActionCreator } from '../../global/redux';
import * as constants from './constants';

export const getUsersBySearchParamsAction = asyncActionCreator(
  constants.GET_USERS_BY_SEARCH_PARAMS,
);
export const getCompaniesBySearchParamsAction = asyncActionCreator(
  constants.GET_COMPANIES_BY_SEARCH_PARAMS,
);
export const getInfluencersBySearchParamsAction = asyncActionCreator(
  constants.GET_INFLUENCERS_BY_SEARCH_PARAMS,
);

export const getFollowersAction = asyncActionCreator(constants.GET_FOLLOWERS_COMPANY_SEARCH);
export const followCompanyAction = asyncActionCreator(constants.FOLLOW_COMPANY_COMPANY_SEARCH);
