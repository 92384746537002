import * as yup from 'yup';
import {
  emailValidationSchema,
  passwordValidationSchema,
  passwordConfirmValidationSchema,
} from '../../../../global/validators';

export const signUpValidationSchema = yup.object().shape({
  email: emailValidationSchema,
  password: passwordValidationSchema,
  passwordRepeat: passwordConfirmValidationSchema,
});

export default signUpValidationSchema;
