/* eslint-disable no-param-reassign */
import { createReducer, createSelector } from '@reduxjs/toolkit';
import { asyncReducerHandler, asyncInitialState } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  chatList: asyncInitialState,
  filter: asyncInitialState,
  chatMessages: asyncInitialState,
  newMsgCount: asyncInitialState,
  isFilesUploading: false,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getAllChatsAction, 'chatList'),
  ...asyncReducerHandler(actions.getChatMessagesAction, 'chatMessages'),
  ...asyncReducerHandler(actions.getNewMsgCountAction, 'newMsgCount'),

  [actions.changeFilterSuccess]: (state, { payload }) => {
    state.filter.data = payload;
  },

  [actions.receiveNewMessageSuccess]: (state, { payload }) => {
    state.chatMessages.data = [...state.chatMessages.data, payload];
  },

  [actions.newMessageCountIncSuccess]: (state) => {
    state.newMsgCount.data += 1;
  },

  [actions.newMessageCountDecSuccess]: (state) => {
    state.newMsgCount.data -= 1;
  },

  [actions.receiveNewMessageChatUpdateSuccess]: (state, { payload }) => {
    if (payload.index || payload.index === 0) {
      state.chatList.data[payload.index].newMessagesCount = payload.chatNewMsgCount;
      if (payload.chat) {
        state.chatList.data[payload.index].lastMessage = payload.chat.lastMessage;
      }
    } else {
      state.chatList.data = [...state.chatList.data, payload.chat];
    }
  },

  [actions.updateMessageStatusSuccess]: (state, { payload }) => {
    state.chatMessages.data[payload].status = 'READ';
  },

  [actions.chatMessageCountDecSuccess]: (state, { payload }) => {
    if (payload.index || payload.index === 0) {
      state.chatList.data[payload.index].newMessagesCount -= 1;
    }
  },

  [actions.startFilesUploading]: (state) => {
    state.isFilesUploading = true;
  },

  [actions.finishFilesUploading]: (state) => {
    state.isFilesUploading = false;
  },
});

const chatsData = (state) => state.chatReducer.chatList.data;
const chatsLoading = (state) => state.chatReducer.chatList.loading;
const chatsfilter = (state) => state.chatReducer.filter.data;
const chatMessages = (state) => state.chatReducer.chatMessages.data;
const sentFilesId = (state) => state.chatReducer.sentFiles.data;
const newMsgCount = (state) => state.chatReducer.newMsgCount.data;
const isFilesUploading = (state) => state.chatReducer.isFilesUploading;

const getFilteredChats = createSelector([chatsData, chatsfilter], (data, filter) => {
  if (data?.length && filter) {
    const normilizedFilter = filter.toLowerCase();
    return data.filter((chat) =>
      chat.participant.fullName.toLowerCase().includes(normilizedFilter),
    );
  }
  return data;
});

const selectors = {
  chatsLoading,
  chatsData,
  chatsfilter,
  getFilteredChats,
  chatMessages,
  sentFilesId,
  newMsgCount,
  isFilesUploading,
};

export { initialState, reducer, selectors };
export default reducer;
