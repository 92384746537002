import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import Icon from 'components/Icon';
import { selectors as mainPageSelectors } from 'pages/MainPage/reducer';
import { getFeedbacksByIdAction, getStatsByIdAction } from './actions';
import { selectors } from './reducer';
import FeedbackAddNew from './components/FeedbackAddNew';
import FeedbackSingle from './components/FeedbackSingle';
import FeedbackStats from './components/FeedbackStats';
import ContentLoader from '../../components/ContentLoader';
import './FeedbacksPage.scss';

const FeedbacksPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const feedbacksData = useSelector(selectors.userFeedbacksData);
  const feedbacksLoading = useSelector(selectors.userFeedbacksLoading);

  const statsData = useSelector(selectors.userStatsData);
  const statsLoading = useSelector(selectors.userStatsLoading);

  const userProfileData = useSelector(mainPageSelectors.userProfileData);

  const [canLeaveFeedback, setCanLeaveFeedback] = useState(true);
  const [sortBy, setSortBy] = useState({ value: 'desc', label: 'New' });

  useEffect(() => {
    dispatch(getStatsByIdAction.request(params.id));
  }, [params.id]);

  useEffect(() => {
    if (feedbacksData?.result.findIndex((item) => item.owner.id === userProfileData?.id) === -1) {
      setCanLeaveFeedback(true);
    } else {
      setCanLeaveFeedback(false);
    }
  }, [feedbacksData, userProfileData]);

  useEffect(() => {
    if (sortBy.value === 'desc') {
      dispatch(getFeedbacksByIdAction.request({ id: params.id }));
    } else if (sortBy.value === 'asc') {
      dispatch(getFeedbacksByIdAction.request({ id: params.id, sort: 'asc=updatedAt' }));
    }
  }, [sortBy]);

  return (
    <div className="feedbacks-page--wrapper">
      <Link to={`/profile/${params.id}`} className="feedbacks-page--link-back">
        <Icon icon="arrow-left-2" />
        {t('FeedbacksPage.link')}
      </Link>
      {feedbacksLoading || statsLoading ? (
        <ContentLoader visible={feedbacksLoading} />
      ) : (
        <div className="app-pages--content">
          <div className="content--main">
            <div className="feedbacks-page--header header">
              <h2 className="header--title">{t('FeedbacksPage.title')}</h2>
              <ReactSelect
                className="header--select"
                classNamePrefix="sort-select"
                isSearchable={false}
                defaultValue={sortBy}
                options={[
                  { value: 'desc', label: 'New' },
                  { value: 'asc', label: 'Old' },
                ]}
                onChange={(selectedOption) => setSortBy(selectedOption)}
              />
            </div>
            <div className="feedbacks-page--posts">
              {feedbacksData?.result.length > 0 ? (
                feedbacksData.result.map((fb) => <FeedbackSingle key={fb.id} feedback={fb} />)
              ) : (
                <div className="feedbacks-page--404">
                  <p>No feedbacks found</p>
                </div>
              )}
            </div>
          </div>
          <div className="content--additional">
            <FeedbackStats statsData={statsData} />
            {canLeaveFeedback && (
              <FeedbackAddNew recipientId={params.id} sortUpdate={sortBy.value} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbacksPage;
