import ALERT_TYPES from 'components/Alert/constants';
import FeedService from 'pages/FeedPage/service';
import { addAlertAction } from 'pages/InitComponent/actions';
import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import ChatService from './service';

export function* getAllChatsSaga() {
  try {
    const response = yield call(ChatService.getAllChats);
    yield put(actions.getAllChatsAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.getAllChatsAction.error(error));
  }
}

export function* getNewMsgCountSaga() {
  try {
    const response = yield call(ChatService.getNewMsgCount);
    yield put(actions.getNewMsgCountAction.success(response.data.data));
  } catch (error) {
    yield put(actions.getNewMsgCountAction.error(error));
  }
}

export function* getChatMessagesSaga({ payload }) {
  try {
    const response = yield call(ChatService.getChatMessages, payload);
    yield put(actions.getChatMessagesAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.getChatMessagesAction.error(error));
  }
}

export function* sendMessageFilesSaga({ payload }) {
  try {
    yield put(actions.startFilesUploading());
    const fileResponses = yield all(
      payload.filesArr.map((file) => {
        const formData = new FormData();
        formData.append('file', file);

        return call(FeedService.fileUpload, formData);
      }),
    );
    yield put(actions.finishFilesUploading());
    yield all(
      fileResponses.map((response) =>
        call(payload.sendMessage, {
          fileId: response.data.data.id,
          chatId: payload.chatId,
          replyId: payload.replyId,
        }),
      ),
    );
  } catch (error) {
    yield put(
      addAlertAction({
        type: ALERT_TYPES.error,
        message: 'FILE_UPLOAD_ERROR',
      }),
    );
    yield put(actions.finishFilesUploading());
  }
}

export function* createChatSaga({ payload }) {
  try {
    const response = yield call(ChatService.createChat, payload.profileId);
    yield call(getAllChatsSaga);
    payload.navigate('/messages', { state: { chatId: response.data.data.id } });
  } catch (error) {
    yield put(
      addAlertAction({
        type: ALERT_TYPES.error,
        message: 'CREATE_CHAT_ERROR',
      }),
    );
  }
}

export function* chatPageWatcherSaga() {
  yield all([
    takeLatest(actions.getAllChatsAction.REQUEST, getAllChatsSaga),
    takeLatest(actions.getChatMessagesAction.REQUEST, getChatMessagesSaga),
    takeLatest(actions.getNewMsgCountAction.REQUEST, getNewMsgCountSaga),
    takeEvery(actions.sendMessageFilesAction.REQUEST, sendMessageFilesSaga),
    takeLatest(actions.createChatAction.REQUEST, createChatSaga),
  ]);
}
