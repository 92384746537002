/* eslint-disable no-alert */
import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors } from 'pages/MainPage/reducer';
import TextField from '@mui/material/TextField';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import { addAlertAction } from '../../pages/InitComponent/actions';
import ALERT_TYPES from '../Alert/constants';
import './PostFeedEditor.scss';
import UploadGallery from './UploadGallery';

const PostFeedEditor = ({ onSubmit, canSendFiles }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [text, setText] = useState('');
  const [files, setFiles] = useState([]);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const userData = useSelector(selectors.userProfileData);

  const hiddenPhotoInput = useRef(null);
  const hiddenVideoInput = useRef(null);

  const handleSubmit = () => {
    onSubmit({
      text,
      files,
    });
    setText('');
  };

  const handleAddImage = () => {
    hiddenPhotoInput.current.click();
  };

  const handleAddVideo = () => {
    hiddenVideoInput.current.click();
  };

  const handleFilesChange = useCallback(
    async (event) => {
      const isBig = Array.from(event.target.files).filter((file) => file.size > 159061428);
      let isEnough = false;

      if (isBig.length) {
        dispatch(
          addAlertAction({
            type: ALERT_TYPES.error,
            message: 'FILE_UPLOAD_TOO_BIG',
          }),
        );
        return;
      }

      await setFiles((prev) => {
        if (prev.length + event.target.files.length > 10) {
          isEnough = true;
          return prev;
        }

        // Collect files for add
        const filesToAdd = [];

        // Filter files and check is input files already in form
        Array.prototype.forEach.call(event.target.files, (oneInputFile) => {
          let isAlreadyInForm = false;

          prev.forEach((onePrevFile) => {
            if (oneInputFile.name === onePrevFile.name && oneInputFile.size === onePrevFile.size) {
              isAlreadyInForm = true;
            }
          });

          if (!isAlreadyInForm) {
            filesToAdd.push(oneInputFile);
          } else {
            dispatch(
              addAlertAction({
                type: ALERT_TYPES.warning,
                message: 'FILE_ALREADY_EXIST_IN_MESSAGE',
              }),
            );
          }
        });

        return [...prev, ...filesToAdd];
      });

      if (isEnough) {
        dispatch(
          addAlertAction({
            type: ALERT_TYPES.error,
            message: 'FILE_UPLOAD_TOO_MUCH',
          }),
        );
      }

      // Clear hidden file inputs
      hiddenPhotoInput.current.value = '';
      hiddenVideoInput.current.value = '';
    },
    [hiddenPhotoInput, hiddenVideoInput],
  );

  const handleFileDelete = (filename) => {
    setFiles((prev) => [...prev.filter((el) => el.name !== filename)]);
  };

  return (
    <div className="post-editor">
      <Avatar file={userData?.cover} size="md" />
      <div className="post-editor-top">
        <TextField
          id="PostFeedEditorTextArea"
          className="post-editor-input"
          variant="outlined"
          placeholder={t('PostFeedEditor.placeholder')}
          multiline
          fullWidth
          value={text}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            if (event.nativeEvent.inputType !== 'insertLineBreak') {
              setText(event.target.value);
            } else if (isShiftPressed) {
              setText(event.target.value);
            }
          }}
          onKeyUp={(event) => {
            if (!event.shiftKey) {
              setIsShiftPressed(false);
            }

            if (event.key === 'Enter' && !isShiftPressed) {
              handleSubmit();
            }
          }}
          onKeyDown={(event) => {
            if (event.shiftKey) {
              setIsShiftPressed(true);
            }
          }}
        />
      </div>

      {files.length ? <UploadGallery files={files} onDelete={handleFileDelete} /> : null}

      <div className="post-editor-bottom">
        <div className="post-editor-bottom-media">
          {canSendFiles && (
            <>
              <button onClick={handleAddImage} type="button" className="media-button">
                <input
                  onChange={(event) => {
                    handleFilesChange(event);
                  }}
                  multiple
                  ref={hiddenPhotoInput}
                  id="file"
                  name="file"
                  type="file"
                  accept="image/*"
                  className="media-button-input"
                />
                <Icon className="icon" icon="pic" />
              </button>
              <button onClick={handleAddVideo} type="button" className="media-button">
                <input
                  onChange={(event) => {
                    handleFilesChange(event);
                  }}
                  multiple
                  ref={hiddenVideoInput}
                  id="file"
                  name="file"
                  type="file"
                  accept="video/*"
                  className="media-button-input"
                />
                <Icon className="icon" icon="video" />
              </button>
            </>
          )}
        </div>
        <button type="submit" onClick={handleSubmit} className="btn btn-xs btn-primary">
          {t('PostFeedEditor.button')}
        </button>
      </div>
    </div>
  );
};

PostFeedEditor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  canSendFiles: PropTypes.bool,
};

PostFeedEditor.defaultProps = {
  canSendFiles: true,
};

export default PostFeedEditor;
