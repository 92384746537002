import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { selectors as initSelectors } from '../InitComponent/reducer';
import { getUserProfileAction, getFromFavorite, getNotificationsAction } from './actions';
import { logoutUserAction } from '../LogInPage/actions';
import Sidebar from './components/Sidebar';
import HeaderDashboard from './components/HeaderDashboard';
import FeedPage from '../FeedPage';
import ProfilePage from '../ProfilePage';
import NotFoundPage from '../NotFoundPage';
import FaqPage from '../FaqPage';
import ProfileSettingsPage from '../ProfileSettingsPage';
import CompanySearchPage from '../SearchPage/components/CompanySearchPage';
import InfluencerSearchPage from '../SearchPage/components/InfluencerSearchPage';
import SearchPage from '../SearchPage';
import ChatPage from '../ChatPage';
import withRouter from '../../hocs/withRouter';
import withAuth from '../../hocs/withAuth';
import FeedbacksPage from '../FeedbacksPage';
import SavedListPage from '../SavedListPage';
import ComparePage from '../ComparePage';
import NotificationsPage from '../NotificationsPage';
import './MainPage.scss';
import NetworkPage from '../NetworkPage/NetworkPage';
import NetworkRequestsPage from '../NetworkPage/NetworkRequestsPage';
import NetworkFriendsPage from '../NetworkPage/NetworkFriendsPage';
import NetworkFollowersPage from '../NetworkPage/NetworkFollowersPage';
import AddInstagramAccountPage from '../AddInstagramAccountPage';
import DashboardPage from '../DashboardPage';

const MainPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const initPath = useSelector(initSelectors.applicationInitPath);

  useEffect(() => {
    dispatch(getFromFavorite.request());
    dispatch(getUserProfileAction.request());
    dispatch(getNotificationsAction.request());
  }, []);

  useEffect(() => {
    if (initPath) {
      navigate(initPath);
    }
  }, []);

  return (
    <div className="main-container--wrapper">
      <Sidebar />
      <div className="page--wrapper">
        <HeaderDashboard />
        <div className="page--content-wrapper">
          <div className="page--content">
            <Routes>
              <Route exact path="/feed" element={<FeedPage />} />
              <Route exact path="/messages" element={<ChatPage />} />
              <Route exact path="/network/all" element={<NetworkPage />} />
              <Route exact path="/network/requests" element={<NetworkRequestsPage />} />
              <Route exact path="/network/friends" element={<NetworkFriendsPage />} />
              <Route exact path="/network/followers" element={<NetworkFollowersPage />} />
              <Route exact path="/search" element={<SearchPage />} />
              <Route exact path="/search/influencer" element={<InfluencerSearchPage />} />
              <Route exact path="/search/company" element={<CompanySearchPage />} />
              <Route exact path="/support" element={<FaqPage />} />
              <Route exact path="/profile/settings" element={<ProfileSettingsPage />} />
              <Route exact path="/profile/add-instagram" element={<AddInstagramAccountPage />} />
              <Route exact path="/profile/:id" element={<ProfilePage />} />
              <Route exact path="/profile/:id/feedbacks" element={<FeedbacksPage />} />
              <Route exact path="/not-found" element={<NotFoundPage />} />
              <Route exact path="/notifications" element={<NotificationsPage />} />
              <Route exact path="/favorite" element={<SavedListPage />} />
              <Route exact path="/compare" element={<ComparePage />} />
              <Route exact path="/dashboard" element={<DashboardPage />} />
              <Route path="/*" element={<Navigate to="/feed" />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  logoutUserAction,
};

export default connect(null, mapDispatchToProps)(withRouter(withAuth(MainPage)));
