import { createAction } from '@reduxjs/toolkit';
import { asyncActionCreator } from 'global/redux';
import * as constants from './constants';

export const logInUserAction = asyncActionCreator(constants.LOG_IN_USER);
export const logoutUserAction = createAction(constants.LOGOUT_USER);
export const getUserByTokenAction = asyncActionCreator(constants.GET_USER_BY_TOKEN);
export const resetUserPasswordAction = asyncActionCreator(constants.RESET_USER_PASSWORD);
export const checkEmailVerificationCodeAction = asyncActionCreator(
  constants.CHECK_EMAIL_VERIFICATION_CODE,
);
export const setNewPasswordAction = asyncActionCreator(constants.SET_NEW_PASSWORD);

export const changePasswordResetStatusAction = createAction(constants.CHANGE_PASSWORD_RESET_STATUS);
