import { createReducer } from '@reduxjs/toolkit';
import { asyncReducerHandler, asyncInitialState } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  ...asyncInitialState,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getFaqListAction),
});

const faqData = (state) => state.faqReducer.data;
const faqLoading = (state) => state.faqReducer.loading;

const selectors = {
  faqData,
  faqLoading,
};

export { reducer, selectors, initialState };
export default reducer;
