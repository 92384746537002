import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import {
  buildQueryObject,
  buildQueryString,
  buildApplicationQueryString,
} from 'pages/SearchPage/helpers';
import SearchFilers from 'pages/SearchPage/components/SearchFilers';
import useQuery from 'hooks/useQuery';
import { getFormMetadataAction } from 'pages/MainPage/actions';
import {
  approveFriendRequestAction,
  declineFriendRequestAction,
  getFriendsRequestsAction,
} from './actions';
import { selectors as mainSelectors } from '../MainPage/reducer';
import { selectors } from './reducer';
import ContentLoader from '../../components/ContentLoader';
import NetworkUser from './components/NetworkUser';
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import NetworkSettings from './components/NetworkSettings';
import NetworkNav from './components/NetworkNav';
import './NetworkPage.scss';

const NetworkRequestsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQuery();

  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState({ value: 'desc', label: 'Newest' });

  const user = useSelector(mainSelectors.userProfileData);

  const formMetadataData = useSelector(mainSelectors.formMetadataData);
  const formMetadataLoading = useSelector(mainSelectors.formMetadataLoading);

  const requestsData = useSelector(selectors.requestsData);
  const requestsLoading = useSelector(selectors.requestsLoading);

  const [isFilterShowed, setIsFilterShowed] = useState(false);

  useEffect(() => {
    if (user?.id) {
      const queryObject = buildQueryObject(
        params,
        formMetadataData?.genders,
        formMetadataData?.locations,
        formMetadataData?.businessTypes,
        formMetadataData?.industries,
      );
      const queryParams = Object.fromEntries(
        new URLSearchParams(buildQueryString(queryObject, false)),
      );

      dispatch(
        getFriendsRequestsAction.request({
          id: user.id,
          params: { desc: 'createdAt', acceptor: user.id, ...queryParams },
        }),
      );
    }
  }, [user, sortBy, params]);

  useEffect(() => {
    if (!formMetadataData) dispatch(getFormMetadataAction.request());
  }, []);

  const handleApproveFriendRequest = (id) => {
    dispatch(
      approveFriendRequestAction.request({
        id,
        userId: user.id,
      }),
    );
  };

  const handleDeclineFriendRequest = (id) => {
    dispatch(
      declineFriendRequestAction.request({
        id,
        userId: user.id,
      }),
    );
  };

  const handleSearchKeyDown = (event, value) => {
    if (event.key === 'Enter') {
      const queryObject = buildQueryObject(
        params,
        formMetadataData?.genders,
        formMetadataData?.locations,
        formMetadataData?.businessTypes,
        formMetadataData?.industries,
      );
      const queryString = buildApplicationQueryString({
        ...queryObject,
        search: value,
      });

      navigate(`/network/requests/${queryString}`);
    }
  };

  const handleCloseSearch = () => {
    setIsFilterShowed(false);
  };

  const handleSearch = (values) => {
    const search = params.get('search');

    const queryString = buildApplicationQueryString({
      ...values,
      search,
    });

    setIsFilterShowed(false);
    navigate(`/network/requests/${queryString}`);
  };

  return (
    <div className="network-page--wrapper">
      {requestsLoading || formMetadataLoading ? (
        <ContentLoader visible={requestsLoading || formMetadataLoading} />
      ) : (
        <>
          <SearchFilers
            view="influencer"
            metadata={formMetadataData}
            isOpen={isFilterShowed}
            initialValue={buildQueryObject(
              params,
              formMetadataData?.genders,
              formMetadataData?.locations,
              formMetadataData?.businessTypes,
              formMetadataData?.industries,
            )}
            onClose={handleCloseSearch}
            onSearch={handleSearch}
          />
          <div className="app-pages--content">
            <div className="content--main">
              <div className="form--wrapper">
                <Formik
                  initialValues={{
                    search: params.get('search') || '',
                  }}
                >
                  {({ errors, touched, values }) => (
                    <Form className="form">
                      <Input
                        name="search"
                        icon="search"
                        className="search-input"
                        placeholder={t('NetworkPage.following.form.search.placeholder')}
                        errors={errors}
                        touched={touched}
                        onKeyDown={(event) => handleSearchKeyDown(event, values.search)}
                      />
                      <button
                        type="button"
                        className="btn btn-secondary-gray btn-md"
                        onClick={() => setIsFilterShowed(true)}
                      >
                        <Icon icon="filter" className="btn--icon" />
                        {t('NetworkPage.following.buttons.filter')}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              {requestsData?.length ? (
                <>
                  <div className="content--header m-35">
                    <h2 className="header--title">{t('NetworkPage.newRequest.title')}</h2>
                    <ReactSelect
                      classNamePrefix="sort-select"
                      isSearchable={false}
                      defaultValue={sortBy}
                      options={[
                        { value: 'desc', label: 'Newest' },
                        { value: 'asc', label: 'Oldest' },
                      ]}
                      onChange={(selectedOption) => setSortBy(selectedOption)}
                    />
                  </div>
                  <div className="content--body">
                    {requestsData?.map((request) => (
                      <NetworkUser
                        key={request.id}
                        id={request.id}
                        user={request.fromMe ? request.acceptor : request.initiator}
                        onApprove={handleApproveFriendRequest}
                        onDecline={handleDeclineFriendRequest}
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </div>
            <div className="content--additional">
              <NetworkNav />
              <NetworkSettings />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NetworkRequestsPage;
