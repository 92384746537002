import { Rating } from '@mui/material';
import Avatar from 'components/Avatar';
import React from 'react';
import PropTypes from 'prop-types';
import './FeedbackSingle.scss';
import Icon from 'components/Icon';
import ROLES from 'constants';

const FeedbackSingle = ({ feedback }) => {
  const feedbackTime = new Date(feedback.updatedAt).toLocaleString('en-us', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <div className="feedback-single--wrapper">
      <div className="feedback-single--header header">
        <div className="header--user">
          <Avatar size="xxl" className="user--avatar" file={feedback.owner.cover} />
          <div className="user--info">
            <div>
              <div className="user--info-name">
                <h2 className="info--name">{feedback.owner.fullName}</h2>
                {feedback.owner.isVerified && <Icon icon="verified" />}
              </div>
              <span className="info--email">
                {feedback.owner.role === ROLES.influencer ? (
                  <>{feedback.owner.email}</>
                ) : (
                  <>
                    {feedback.owner.companyName} {feedback.owner.positionInCompany}
                  </>
                )}
              </span>
            </div>
            <div>
              <p className="info--date">{feedbackTime}</p>
              {feedback.collaborated && (
                <div className={`info--social-name ${feedback.socialName}`}>
                  <Icon icon={feedback.socialName.toLowerCase()} />
                  <span>{feedback.socialName.toLowerCase()}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="feedback-single--rating">
        <Rating
          defaultValue={Number(
            (
              (feedback.availability +
                feedback.deadline +
                feedback.quality +
                feedback.satisfaction) /
              4
            ).toFixed(1),
          )}
          precision={0.5}
          readOnly
        />
        <span>
          {(
            (feedback.availability + feedback.deadline + feedback.quality + feedback.satisfaction) /
            4
          ).toFixed(1)}
        </span>
      </div>
      <p className="feedback-single--description">{feedback.text}</p>
    </div>
  );
};

FeedbackSingle.propTypes = {
  feedback: PropTypes.shape({
    text: PropTypes.string,
    collaborated: PropTypes.bool,
    socialName: PropTypes.string,
    availability: PropTypes.number,
    deadline: PropTypes.number,
    quality: PropTypes.number,
    satisfaction: PropTypes.number,
    updatedAt: PropTypes.string,
    owner: PropTypes.shape({
      fullName: PropTypes.string,
      companyName: PropTypes.string,
      email: PropTypes.string,
      cover: PropTypes.string,
      isVerified: PropTypes.bool,
      role: PropTypes.string,
      positionInCompany: PropTypes.string,
    }),
  }).isRequired,
};

export default FeedbackSingle;
