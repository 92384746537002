import { useRef, useEffect, useState } from 'react';

const useElementOnScreen = (options) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef(null);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    let observer;
    if (containerRef.current) {
      observer = new IntersectionObserver(callbackFunction, options);
      observerRef.current = observer;
      observerRef.current.observe(containerRef.current);
    }
    return () => {
      if (observer) observer.disconnect();
    };
  }, [containerRef.current]);

  return [containerRef, isVisible];
};

export default useElementOnScreen;
