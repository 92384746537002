import React from 'react';
import PropTypes from 'prop-types';
import './SocailContainer.scss';
import socialImages from './constants';

const SocailContainer = ({ children, socialNetwork }) => (
  <div className="social-container--wrapper">
    <div className="content-container">{children}</div>
    <div
      style={{ backgroundImage: `url(${socialImages[socialNetwork]})` }}
      className="info-container"
    />
  </div>
);

SocailContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  socialNetwork: PropTypes.string.isRequired,
};

SocailContainer.defaultProps = {
  children: null,
};

export default SocailContainer;
