import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { selectors } from './reducer';
import getFaqListAction from './actions';
import Icon from '../../components/Icon';
import ContentLoader from '../../components/ContentLoader';
import getQuestionNumber from './helpers';
import './FaqPage.scss';

const FaqPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [expanded, setExpanded] = useState(null);

  const faqData = useSelector(selectors.faqData);
  const faqLoading = useSelector(selectors.faqLoading);

  useEffect(() => {
    dispatch(getFaqListAction.request());
  }, [i18n.language]);

  const handleChangeAccordion = (id) => {
    setExpanded((prev) => {
      if (prev === id) {
        return null;
      }

      return id;
    });
  };

  return (
    <div className="faq-page--wrapper">
      {faqLoading ? (
        <ContentLoader visible={faqLoading} />
      ) : (
        <div className="app-pages--content">
          <div className="content--main">
            <div className="page--header">
              <h2 className="header--title">{t('FaqPage.header.title')}</h2>
              <p className="header--description">{t('FaqPage.header.description')}</p>
              <Link to="/" className="btn btn-md btn-primary">
                {t('FaqPage.header.buttons.open')}
              </Link>
            </div>
            <div className="page--body">
              <h2 className="body--title">{t('FaqPage.body.title')}</h2>
              <p className="body--description">{t('FaqPage.body.description')}</p>
            </div>
            <div className="page--accordions-wrapper">
              {faqData?.map(({ id, question, answer, order }) => (
                <Accordion
                  key={id}
                  expanded={expanded === id}
                  onClick={() => handleChangeAccordion(id)}
                >
                  <AccordionSummary
                    aria-controls="faq-question-1-content"
                    id="faq-question-1-content"
                  >
                    <span className="accordion-number">{getQuestionNumber(order)}</span>
                    <h3 className="accordion--title">{question}</h3>
                    <button type="button" className="accordion-control-btn">
                      <Icon
                        icon={expanded === id ? 'rounded-x' : 'chevron-bottom'}
                        className="btn--icon"
                      />
                    </button>
                  </AccordionSummary>
                  <AccordionDetails>{answer}</AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FaqPage;
