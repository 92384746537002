/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncReducerHandler, asyncInitialState } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  metricsData: asyncInitialState,
  loading: false,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getMetricsByIdAction, 'metricsData'),
  [actions.getMetricsByIdAction.ERROR]: (state, action) => {
    state.error = action.payload;
    state.loading = false;
  },
});

const metricsData = (state) => state.metricsReducer.metricsData.data;
const metricsLoading = (state) => state.metricsReducer.loading;

const selectors = {
  metricsData,
  metricsLoading,
};

export { initialState, reducer, selectors };
export default reducer;
