import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './MessageItem.scss';
import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import messageDateFormatter, { fileSizeFromatter, getIconByFileType } from 'pages/ChatPage/helpers';
import useElementOnScreen from 'hooks/useElementOnScreen/useElementOnScreen';

const MessageItem = ({ message, participantId, chatWindowRef, handleReadMessage }) => {
  const { t } = useTranslation();
  const [shouldUseCallback, setShouldUseCallback] = useState(true);
  const msgIsMine = message.owner.id !== participantId;

  const [containerRef, isVisible] = useElementOnScreen({
    root: chatWindowRef.current,
    rootMargin: '0px',
    threshold: 0.7,
  });

  const handleReplyLinkClick = (id) => {
    const targetMessage = document.getElementById(id);
    if (targetMessage) {
      targetMessage.scrollIntoView({ behavior: 'smooth' });
      targetMessage.classList.add('focus');
      setTimeout(() => {
        targetMessage.classList.remove('focus');
      }, 1500);
    }
  };

  const handleSetRef = (elementRef) => {
    if (message.status === 'SENT' && message.owner.id === participantId) {
      containerRef.current = elementRef;
    }
  };

  useEffect(() => {
    if (isVisible && shouldUseCallback) {
      handleReadMessage(message.id);
      setShouldUseCallback(false);
    }
  }, [isVisible]);

  return (
    <div id={message.id} ref={handleSetRef} tabIndex="-1" className="message-item--container">
      {!msgIsMine && (
        <div className="message-item--avatar">
          {' '}
          <Avatar size="md" file={message.owner.cover} />
        </div>
      )}
      <div
        className={`message-item--wrapper
        ${msgIsMine ? 'message-item--mine' : 'message-item'}`}
      >
        <div className="info">
          <div className="info--name-wrapper">
            <p className="info--name">
              {msgIsMine ? t('ChatPage.messages.message.owner') : message.owner.fullName}
            </p>
            <p className="info--date">{messageDateFormatter(t, message.updatedAt)}</p>
          </div>
          <div className={`item--message ${msgIsMine ? 'message--mine' : 'message'}`}>
            {message.reply && (
              <div onClick={() => handleReplyLinkClick(message.reply.id)} className="reply">
                <Icon className="reply--icon" icon="chatAnswer" width="10" height="10" />
                <div className="reply-text-block">
                  <p className="reply-owner">{message.reply.owner.fullName}</p>
                  {message.reply.type === 'FILE' ? (
                    <div className="reply-file--wrapper">
                      <div className="reply-file--icon">
                        <Icon
                          icon={getIconByFileType(message.reply.file.extname)}
                          width="10"
                          height="10"
                        />
                      </div>
                      <p className="reply--text">{message.reply.file.title}</p>
                    </div>
                  ) : (
                    <p className="reply--text">{message.reply.text}</p>
                  )}
                </div>
              </div>
            )}
            {message.type === 'FILE' ? (
              <a
                target="_blank"
                rel="noreferrer"
                className="item--link"
                href={message.file.src}
                download
              >
                <div className="item--file">
                  <div className="file--icon">
                    <Icon icon={getIconByFileType(message.file.extname)} width="20" height="20" />
                  </div>
                  <div className="file--info">
                    <p className="file--name">{message.file.title}</p>
                    <p className="file--size">{fileSizeFromatter(message.file.fileSize)}</p>
                  </div>
                </div>
              </a>
            ) : (
              <p className="item--text">{message.text}</p>
            )}
            {message.status === 'READ' && message.owner.id !== participantId && (
              <div className="item--read-icon">
                <Icon icon="chatRead" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MessageItem.propTypes = {
  // eslint-disable-next-line
  chatWindowRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
  handleReadMessage: PropTypes.func.isRequired,
  participantId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    status: PropTypes.oneOf(['SENT', 'READ']),
    id: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
    type: PropTypes.oneOf(['FILE', 'TEXT']),
    file: PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
      fileSize: PropTypes.string,
      extname: PropTypes.string,
    }),
    text: PropTypes.string,
    owner: PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string,
      cover: PropTypes.string,
    }),
    reply: PropTypes.shape({
      type: PropTypes.oneOf(['FILE', 'TEXT']),
      id: PropTypes.string,
      file: PropTypes.shape({
        extname: PropTypes.string,
        title: PropTypes.string,
      }),
      text: PropTypes.string,
      owner: PropTypes.shape({
        fullName: PropTypes.string,
      }),
    }),
  }),
};

MessageItem.defaultProps = {
  message: {
    updatedAt: '',
    type: 'TEXT',
    file: {
      src: '',
      title: '',
      fileSize: '',
    },
    text: '',
    owner: {
      fullName: '',
      cover: '',
    },
  },
};

export default MessageItem;
