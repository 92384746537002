import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectors } from '../../reducer';
import { SIGN_UP_STEPS } from '../../constants';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import ConfirmEmailForm from './components/ConfirmEmailForm/ConfirmEmailForm';
import { changeSignUpStatusAction, confirmEmailAction } from '../../actions';
import { setToLocalStorage } from '../../../../global/helpers';

const ConfirmEmailPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [params] = useSearchParams();

  const signUpStatus = useSelector(selectors.signupPageStatus);
  const signUpData = useSelector(selectors.signupPageData);

  const code = params.get('code');
  const email = params.get('email');

  useEffect(() => {
    if (signUpStatus !== SIGN_UP_STEPS.emailVerification && (!code || !email)) {
      navigate('/auth');
    }
  }, []);

  const confirmEmailPostEffect = () => {
    dispatch(changeSignUpStatusAction(SIGN_UP_STEPS.emailVerified));
    setToLocalStorage('sign_up_step', null);
    setToLocalStorage('sign_up_email', null);
    navigate('/signup/verification/success');
  };

  const handleConfirmEmail = (data) => {
    dispatch(
      confirmEmailAction.request({
        data: {
          email: signUpData.email,
          ...data,
        },
        onSuccess: confirmEmailPostEffect,
      }),
    );
  };

  useEffect(() => {
    if (code && email) {
      handleConfirmEmail({
        code,
        email,
      });
    }
  }, []);

  return (
    <div className="additional-page--wrapper">
      <Header />
      <ConfirmEmailForm email={signUpData?.email} code={code} onSubmit={handleConfirmEmail} />
      <Footer />
    </div>
  );
};

export default ConfirmEmailPage;
