import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  buildQueryObject,
  buildQueryString,
  buildApplicationQueryString,
} from 'pages/SearchPage/helpers';
import SearchFilers from 'pages/SearchPage/components/SearchFilers';
import useQuery from 'hooks/useQuery';
import { getFormMetadataAction } from 'pages/MainPage/actions';
import ChatComponent from 'components/ChatComponent';
import {
  unfollowUserAction,
  getFollowsAction,
  getSuggestedPeopleAction,
  followUserAction,
} from './actions';
import { selectors as mainSelectors } from '../MainPage/reducer';
import { selectors } from './reducer';
import ContentLoader from '../../components/ContentLoader';
import Icon from '../../components/Icon';
import NetworkUser from './components/NetworkUser';
import NetworkSuggest from './components/NetworkSuggest';
import NetworkNav from './components/NetworkNav';
import Input from '../../components/Input';
import Avatar from '../../components/Avatar';
import './NetworkPage.scss';

const NetworkFollowersPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQuery();

  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState({ value: 'desc', label: 'Newest' });

  const user = useSelector(mainSelectors.userProfileData);

  const formMetadataData = useSelector(mainSelectors.formMetadataData);
  const formMetadataLoading = useSelector(mainSelectors.formMetadataLoading);

  const followsData = useSelector(selectors.followsData);
  const followsLoading = useSelector(selectors.followsLoading);
  const suggestData = useSelector(selectors.suggestData);

  // eslint-disable-next-line no-unused-vars
  const [isFilterShowed, setIsFilterShowed] = useState(false);

  useEffect(() => {
    if (user?.id) {
      const queryObject = buildQueryObject(
        params,
        formMetadataData?.genders,
        formMetadataData?.locations,
        formMetadataData?.businessTypes,
        formMetadataData?.industries,
      );
      const queryParams = Object.fromEntries(
        new URLSearchParams(buildQueryString(queryObject, false)),
      );
      dispatch(
        getFollowsAction.request({
          id: user.id,
          params: { [sortBy.value]: 'createdAt', ...queryParams },
        }),
      );
    }
  }, [user, sortBy, params]);

  useEffect(() => {
    if (!formMetadataData) dispatch(getFormMetadataAction.request());
    if (!suggestData?.length) dispatch(getSuggestedPeopleAction.request());
  }, []);

  const followUserRequest = (id) => {
    dispatch(followUserAction.request(id));
  };

  const unfollowUserRequest = (id) => {
    dispatch(unfollowUserAction.request({ id, userId: user?.id }));
  };

  const handleSearchKeyDown = (event, value) => {
    if (event.key === 'Enter') {
      const queryObject = buildQueryObject(
        params,
        formMetadataData?.genders,
        formMetadataData?.locations,
        formMetadataData?.businessTypes,
        formMetadataData?.industries,
      );
      const queryString = buildApplicationQueryString({
        ...queryObject,
        search: value,
      });

      navigate(`/network/followers/${queryString}`);
    }
  };

  const handleCloseSearch = () => {
    setIsFilterShowed(false);
  };

  const handleSearch = (values) => {
    const search = params.get('search');

    const queryString = buildApplicationQueryString({
      ...values,
      search,
    });

    setIsFilterShowed(false);
    navigate(`/network/followers/${queryString}`);
  };

  return (
    <div className="network-page--wrapper">
      {formMetadataLoading || followsLoading ? (
        <ContentLoader visible={formMetadataLoading || followsLoading} />
      ) : (
        <>
          <ChatComponent />
          <SearchFilers
            view="influencer"
            metadata={formMetadataData}
            isOpen={isFilterShowed}
            initialValue={buildQueryObject(
              params,
              formMetadataData?.genders,
              formMetadataData?.locations,
              formMetadataData?.businessTypes,
              formMetadataData?.industries,
            )}
            onClose={handleCloseSearch}
            onSearch={handleSearch}
          />
          <div className="app-pages--content">
            <div className="content--main">
              <div className="content--header m-15">
                <h2 className="header--title">{t('NetworkPage.following.title')}</h2>
                <ReactSelect
                  classNamePrefix="sort-select"
                  isSearchable={false}
                  defaultValue={sortBy}
                  options={[
                    { value: 'desc', label: 'Newest' },
                    { value: 'asc', label: 'Oldest' },
                  ]}
                  onChange={(selectedOption) => setSortBy(selectedOption)}
                />
              </div>
              <div className="form--wrapper">
                <Formik
                  initialValues={{
                    search: params.get('search') || '',
                  }}
                >
                  {({ errors, touched, values }) => (
                    <Form className="form">
                      <Input
                        name="search"
                        icon="search"
                        className="search-input"
                        placeholder={t('NetworkPage.following.form.search.placeholder')}
                        errors={errors}
                        touched={touched}
                        onKeyDown={(event) => handleSearchKeyDown(event, values.search)}
                      />
                      <button
                        type="button"
                        className="btn btn-secondary-gray btn-md"
                        onClick={() => setIsFilterShowed(true)}
                      >
                        <Icon icon="filter" className="btn--icon" />
                        {t('NetworkPage.following.buttons.filter')}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="content--body">
                {followsData?.length ? (
                  <>
                    {followsData.map((follower) => (
                      <NetworkUser
                        key={follower.id}
                        id={follower.id}
                        isFollower
                        user={follower.fromMe ? follower.acceptor : follower.initiator}
                        onUnfollow={unfollowUserRequest}
                      />
                    ))}
                  </>
                ) : (
                  <div className="friends--empty-state">
                    <Avatar size="lg" className="avatar-icon" />
                    <h3 className="empty-state--title">
                      {t('NetworkPage.myNetwork.emptyState.title')}
                    </h3>
                    <p className="empty-state--description">
                      {t('NetworkPage.myNetwork.emptyState.description')}
                    </p>
                    <Link to="/search/influencer" className="btn btn-primary btn-md">
                      {t('NetworkPage.myNetwork.emptyState.button')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="content--additional">
              <NetworkNav />
              {suggestData && (
                <NetworkSuggest
                  influencers={suggestData.influencers}
                  companies={suggestData.companies}
                  onClick={followUserRequest}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NetworkFollowersPage;
