/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncInitialState, asyncReducerHandler } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  instagramData: asyncInitialState,
  instagramUserToken: asyncInitialState,
  loading: false,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.postInstagramCodeAction, 'instagramData'),
  [actions.postInstagramCodeAction.ERROR]: (state, action) => {
    state.error = action.payload;
    state.loading = false;
  },
  ...asyncReducerHandler(actions.postInstagramUserTokenAction, 'instagramUserToken'),
  [actions.postInstagramUserTokenAction.ERROR]: (state, action) => {
    state.error = action.payload;
    state.loading = false;
  },
});

const instagramData = (state) => state.instagramReducer.instagramData.data;
const instagramLoading = (state) => state.instagramReducer.loading;

const instagramUserToken = (state) => state.instagramReducer.instagramUserToken.data;

const selectors = {
  instagramData,
  instagramLoading,
  instagramUserToken,
};

export { selectors, reducer, initialState };
export default reducer;
