/* eslint-disable global-require */
import ApiService from 'global/services/api';
import { mockImport } from 'global/helpers';
import { isMockSearchPage } from '../../global/services/isMockEnabled';
import { buildQueryString } from './helpers';

const getUsersBySearchMock = mockImport(
  () => require('../../__mocks__/SearchService/getUsersBySearch.mock.json'),
  isMockSearchPage,
);
const getCompaniesBySearchMock = mockImport(
  () => require('../../__mocks__/SearchService/getCompaniesBySearch.mock.json'),
  isMockSearchPage,
);
const getInfluencersBySearchMock = mockImport(
  () => require('../../__mocks__/SearchService/getInfluencersBySearch.mock.json'),
  isMockSearchPage,
);

class SearchService extends ApiService {
  getUsersBySearch = async (search) =>
    this.get({ url: `users/search?search=${search}` }, getUsersBySearchMock);

  getCompaniesBySearch = async (queryObj) =>
    this.get({ url: `users/search${buildQueryString(queryObj)}` }, getCompaniesBySearchMock);

  getInfluencersBySearch = async (queryObj) =>
    this.get(
      { url: `users/search${buildQueryString(queryObj, false)}` },
      getInfluencersBySearchMock,
    );

  getProfileFollowersApproved = async (id) =>
    this.get({ url: `relations/followers/${id}?status=APPROVED` }, null);

  getProfileFollowersRequested = async (id) =>
    this.get({ url: `relations/followers/${id}?status=REQUESTED` }, null);

  followUser = async (id) =>
    this.post({ url: `relations/followers/request/${id}`, body: {} }, null);
}

export default new SearchService();
