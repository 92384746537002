import { all } from 'redux-saga/effects';
import { chatPageWatcherSaga } from 'pages/ChatPage/saga';
import { signUpPageWatcherSaga } from './pages/SignUpPage/saga';
import { logInPageWatcherSaga } from './pages/LogInPage/saga';
import { profileSettingsPageWatcherSaga } from './pages/ProfileSettingsPage/saga';
import { mainPageWatcherSaga } from './pages/MainPage/saga';
import { profilePageWatcherSaga } from './pages/ProfilePage/saga';
import { faqPageWatcherSaga } from './pages/FaqPage/saga';
import { searchPageWatcherSaga } from './pages/SearchPage/saga';
import { feedPageWatcherSaga } from './pages/FeedPage/saga';
import { feedbacksPageWatcherSaga } from './pages/FeedbacksPage/saga';
import { savedListPageWatcherSaga } from './pages/SavedListPage/saga';
import { comparePageWatcherSaga } from './pages/ComparePage/saga';
import { networkPageWatcherSaga } from './pages/NetworkPage/saga';
import { instagramCodePageWatcherSaga } from './pages/AddInstagramAccountPage/saga';
import { metricsWatcherSaga } from './pages/DashboardPage/saga';

export default function* rootSaga() {
  yield all([
    signUpPageWatcherSaga(),
    logInPageWatcherSaga(),
    profileSettingsPageWatcherSaga(),
    mainPageWatcherSaga(),
    profilePageWatcherSaga(),
    faqPageWatcherSaga(),
    searchPageWatcherSaga(),
    feedPageWatcherSaga(),
    chatPageWatcherSaga(),
    feedbacksPageWatcherSaga(),
    savedListPageWatcherSaga(),
    comparePageWatcherSaga(),
    networkPageWatcherSaga(),
    instagramCodePageWatcherSaga(),
    metricsWatcherSaga(),
  ]);
}
