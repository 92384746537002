import AvatarFirst from '../../global/images/Avatar_1.png';
import AvatarSecond from '../../global/images/Avatar_2.png';
import AvatarThird from '../../global/images/Avatar_3.png';
import AvatarFourth from '../../global/images/Avatar_4.png';
import AvatarFifth from '../../global/images/Avatar_5.png';

export const USERS = [
  {
    id: 1,
    avatarUrl: AvatarFifth,
  },
  {
    id: 2,
    avatarUrl: AvatarFourth,
  },
  {
    id: 3,
    avatarUrl: AvatarThird,
  },
  {
    id: 4,
    avatarUrl: AvatarSecond,
  },
  {
    id: 5,
    avatarUrl: AvatarFirst,
  },
];

export default USERS;
