export const CREATE_NEW_POST = 'CREATE_NEW_POST';
export const CREATE_REPOST = 'CREATE_REPOST';
export const DELETE_POST = 'DELETE_POST';
export const GET_ALL_POSTS = 'GET_ALL_POSTS';
export const LIKE_POST = 'LIKE_POST';
export const UNLIKE_POST = 'UNLIKE_POST';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_INFLUENCERS = 'GET_INFLUENCERS';
export const GET_COMMENTS = 'GET_COMMENTS';
export const POST_COMMENT = 'POST_COMMENT';
export const GET_PROFILE_FOLLOWERS = 'GET_PROFILE_FOLLOWERS';
export const FOLLOW_USER_FEED_PAGE = 'FOLLOW_USER_FEED_PAGE';
export const UNFOLLOW_USER_FEED_PAGE = 'UNFOLLOW_USER_FEED_PAGE';
