import React from 'react';
import { useNavigate } from 'react-router-dom';

const withRouter = (Component) => (props) => {
  const navigate = useNavigate();

  return (
    <Component
      navigate={navigate}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default withRouter;
