/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncInitialState, asyncReducerHandler } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  requests: asyncInitialState,
  friends: asyncInitialState,
  follows: asyncInitialState,
  suggestedPeople: asyncInitialState,
};

const reducer = createReducer(initialState, {
  // [actions.getNetworkDataAction.request]: (state) => {
  //   state.requests.loading = true;
  //   state.friends.loading = true;
  //   state.follows.loading = true;
  // },
  // [actions.getNetworkDataAction.success]: (state, action) => {
  //   state.requests.loading = false;
  //   state.requests.data = action.payload.requests;
  //   state.friends.loading = false;
  //   state.friends.data = action.payload.friends;
  //   state.follows.loading = false;
  //   state.follows.data = action.payload.follows;
  // },
  // [actions.getNetworkDataAction.error]: (state, action) => {
  //   state.requests.loading = false;
  //   state.requests.data = null;
  //   state.requests.error = action.payload;
  //   state.friends.loading = false;
  //   state.friends.data = null;
  //   state.friends.error = action.payload;
  //   state.follows.loading = false;
  //   state.follows.data = null;
  //   state.follows.error = action.payload;
  // },
  ...asyncReducerHandler(actions.getFriendsAction, 'friends'),
  ...asyncReducerHandler(actions.getFriendsRequestsAction, 'requests'),
  ...asyncReducerHandler(actions.getFollowsAction, 'follows'),
  ...asyncReducerHandler(actions.approveFriendRequestAction, 'requests'),
  [actions.approveFriendRequestAction.error]: (state, action) => {
    state.requests.loading = false;
    state.requests.error = action.payload;
  },
  ...asyncReducerHandler(actions.declineFriendRequestAction, 'requests'),
  [actions.declineFriendRequestAction.error]: (state, action) => {
    state.requests.loading = false;
    state.requests.error = action.payload;
  },
  ...asyncReducerHandler(actions.getSuggestedPeopleAction, 'suggestedPeople'),
});

const requestsData = (state) => state.networkReducer.requests.data;
const requestsLoading = (state) => state.networkReducer.requests.loading;

const friendsData = (state) => state.networkReducer.friends.data;
const friendsLoading = (state) => state.networkReducer.friends.loading;

const followsData = (state) => state.networkReducer.follows.data;
const followsLoading = (state) => state.networkReducer.follows.loading;

const suggestData = (state) => state.networkReducer.suggestedPeople.data;

const selectors = {
  requestsData,
  requestsLoading,
  friendsData,
  friendsLoading,
  followsData,
  followsLoading,
  suggestData,
};

export { initialState, reducer, selectors };
export default reducer;
