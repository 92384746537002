import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { signUpValidationSchema } from './validations';
import Input from '../../../../components/Input';
// import Icon from '../../../../components/Icon';
import Heading from '../../../../components/Heading';
import './SignUpForm.scss';

const SignUpForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        passwordRepeat: '',
      }}
      validationSchema={signUpValidationSchema}
      onSubmit={onSubmit}
    >
      {({ handleBlur, errors, touched }) => (
        <Form className="sign-up-form">
          <Heading title="SignUpPage.heading.title" subtitle="SignUpPage.heading.subtitle" />
          <Input
            type="email"
            name="email"
            label={t('SignUpPage.SignUpForm.email.label')}
            placeholder={t('SignUpPage.SignUpForm.email.placeholder')}
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
          />
          <Input
            type="password"
            name="password"
            label={t('SignUpPage.SignUpForm.password.label')}
            hint={t('SignUpPage.SignUpForm.password.hint')}
            placeholder={t('SignUpPage.SignUpForm.password.placeholder')}
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
          />
          <Input
            type="password"
            name="passwordRepeat"
            label={t('SignUpPage.SignUpForm.passwordRepeat.label')}
            hint={t('SignUpPage.SignUpForm.passwordRepeat.hint')}
            placeholder={t('SignUpPage.SignUpForm.passwordRepeat.placeholder')}
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
          />
          <button type="submit" className="btn btn-lg btn-primary">
            {t('SignUpPage.SignUpForm.buttons.submit')}
          </button>
          {/* <button type="button" className="btn btn-lg btn-tertiary"> */}
          {/*  <Icon icon="google" className="btn--icon" /> */}
          {/*  {t('SignUpPage.SignUpForm.buttons.signUpGoogle')} */}
          {/* </button> */}
          {/* <button type="button" className="btn btn-lg btn-tertiary"> */}
          {/*  <Icon icon="facebook" className="btn--icon" /> */}
          {/*  {t('SignUpPage.SignUpForm.buttons.signUpFacebook')} */}
          {/* </button> */}
          <div className="sign-up-form--already-have">
            {t('SignUpPage.SignUpForm.haveAccount.text')}
            <Link to="/login" className="link link-color link-md">
              {t('SignUpPage.SignUpForm.haveAccount.link')}
            </Link>
          </div>
          <div className="sign-up-form--terms-and-conditions">
            {t('SignUpPage.SignUpForm.termsAndConditions.text')}
            <Link to="/login" className="link link-gray link-md">
              {t('SignUpPage.SignUpForm.termsAndConditions.link')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignUpForm;
