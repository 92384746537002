/* eslint-disable global-require */
import i18next from 'i18next';
import ApiService from 'global/services/api';
import { mockImport } from 'global/helpers';
import { isMockMainPage, isMockNotificationsPage } from '../../global/services/isMockEnabled';

const getUserProfileByTokenMock = mockImport(
  () => require('../../__mocks__/MainService/getUserByToken.mock.json'),
  isMockMainPage,
);
const getLocationsMock = mockImport(
  () => require('../../__mocks__/MainService/getLocations.mock.json'),
  isMockMainPage,
);
const getGendersMock = mockImport(
  () => require('../../__mocks__/MainService/getGenders.mock.json'),
  isMockMainPage,
);
const getBusinessTypesMock = mockImport(
  () => require('../../__mocks__/MainService/getBussinessTypes.mock.json'),
  isMockMainPage,
);
const getIndustriesMock = mockImport(
  () => require('../../__mocks__/MainService/getIndustries.mock.json'),
  isMockMainPage,
);
const getUsersBySearchMock = mockImport(
  () => require('../../__mocks__/MainService/getUsersBySearch.mock.json'),
  isMockMainPage,
);
const getNotificationshMock = mockImport(
  () => require('../../__mocks__/MainService/getNotifications.mock.json'),
  isMockNotificationsPage,
);

class MainService extends ApiService {
  getUserProfileByToken = async () => this.get({ url: 'auth/profile' }, getUserProfileByTokenMock);

  getLocations = () =>
    this.get({ url: 'metadata/locations', params: { lang: i18next.language } }, getLocationsMock);

  getGenders = () =>
    this.get({ url: 'metadata/genders', params: { lang: i18next.language } }, getGendersMock);

  getBusinessTypes = () =>
    this.get(
      { url: 'metadata/business-types', params: { lang: i18next.language } },
      getBusinessTypesMock,
    );

  getIndustries = () =>
    this.get({ url: 'metadata/industries', params: { lang: i18next.language } }, getIndustriesMock);

  getUsersBySearch = async (search) =>
    this.get({ url: `users/search?search=${search}&take=3` }, getUsersBySearchMock);

  getFavoriteUsers = async () => this.get({ url: 'relations/saved' }, null);

  addUserToFavorite = async (data) => this.post({ url: 'relations/saved', body: data }, null);

  deleteUserFromFavorite = async (id) => this.delete({ url: `relations/saved/${id}` }, null);

  getNotifications = async () => this.get({ url: 'notifications' }, getNotificationshMock);
}

export default new MainService();
