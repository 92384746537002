import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Icon from '../Icon';
import './Input.scss';

const Input = ({
  className,
  name,
  label,
  hint,
  type,
  placeholder,
  disabled,
  errors,
  touched,
  icon,
  onKeyDown,
  onClick,
  onBlur,
}) => {
  const { t } = useTranslation();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div className={className}>
      <div className="input-field--wrapper">
        {label && (
          <label htmlFor={name} className="input-label">
            {label}
          </label>
        )}
        <div className="input--wrapper">
          {type === 'date' ? (
            <Field name={name} placeholder={placeholder} disabled={disabled}>
              {({ field, form }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={field.value}
                    onBlur={form.handleBlur}
                    onChange={(value) => {
                      if (Number.isNaN(Date.parse(value))) {
                        form.setFieldError(field.name, 'validations.date.valid');
                      }

                      form.setFieldValue(field.name, value);
                      form.setFieldTouched(field.name, true, true);
                    }}
                    renderInput={({ inputRef, inputProps }) => (
                      <input
                        ref={inputRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...inputProps}
                        className={`input ${errors[name] && touched[name] ? 'state-danger' : ''}`}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            </Field>
          ) : (
            <>
              {icon && (
                <div className="input--icon">
                  <Icon icon={icon} />
                </div>
              )}
              <Field
                type={isPasswordVisible ? 'text' : type}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                onClick={handleClick}
                className={`input 
                  ${errors[name] && touched[name] ? 'state-danger' : ''}
                  ${icon ? 'input-with-icon' : ''}`}
              />
              <div className="input--controls-alerts">
                {errors[name] && touched[name] && (
                  <Icon icon="alert" className="input--icon-alert" />
                )}
                {type === 'password' && (
                  <button
                    type="button"
                    className="input--password-visibility-btn"
                    onClick={() => setIsPasswordVisible((prev) => !prev)}
                  >
                    <Icon
                      icon={
                        isPasswordVisible
                          ? 'password-visibility-hide'
                          : 'password-visibility-visible'
                      }
                      className="input--password-visibility-icon"
                    />
                  </button>
                )}
              </div>
            </>
          )}
        </div>
        {hint && !errors[name] && !touched[name] && <div className="input-field--hint">{hint}</div>}
        <CSSTransition
          in={errors[name] && touched[name]}
          timeout={500}
          classNames="error-block-animation"
          unmountOnExit
        >
          <div className="input-field--error-block-wrapper">
            <ErrorMessage
              name={name}
              render={(msg) => (
                <div className="input-error-block">
                  <div className="input-validation-message">{t(msg)}</div>
                </div>
              )}
            />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  values: PropTypes.shape({}),
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
};

Input.defaultProps = {
  className: null,
  label: null,
  hint: null,
  placeholder: null,
  icon: null,
  type: 'text',
  disabled: false,
  values: null,
  onKeyDown: null,
  onClick: null,
  onBlur: null,
};

export default Input;
