import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFormValuesAction, updateUserAction } from '../../actions';
import { getUserByTokenAction } from '../../../LogInPage/actions';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import UserInformationForm from './components/UserInformationForm';
import { getValuesForSubmitting } from '../../helpers';
import './UserInformationPage.scss';

const UserInformationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFormValuesAction.request());
    // eslint-disable-next-line
  }, []);

  const handleSubmitUserInformation = (values) => {
    const data = getValuesForSubmitting(values);

    const onSuccess = () => {
      dispatch(getUserByTokenAction.request());
      navigate('/signup/social');
    };

    dispatch(
      updateUserAction.request({
        data,
        onSuccess,
      }),
    );
  };

  return (
    <div className="additional-page--wrapper">
      <Header />
      <UserInformationForm onSubmit={handleSubmitUserInformation} />
      <Footer />
    </div>
  );
};

export default UserInformationPage;
