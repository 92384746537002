import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectors as mainSelectors } from '../MainPage/reducer';
import { selectors } from './reducer';
import {
  authFacebookByCodeAction,
  deleteAccountAction,
  updateProfileDescriptionSettingsAction,
  updateProfileDetailsSettingsAction,
} from './actions';
import { getFormMetadataAction, getUserProfileAction } from '../MainPage/actions';
import { logoutUserAction } from '../LogInPage/actions';
import ProfileDetails from './components/ProfileDetails';
import ProfileDescription from './components/ProfileDescription';
import ContentLoader from '../../components/ContentLoader';
import AnchorNavigation from './components/AnchorNavigation';
// import TryPremium from './components/TryPremium';
import LinkedAccounts from './components/LinkedAccounts';
import { setToLocalStorage, setToSessionStorage } from '../../global/helpers';
import { mapValuesForUpdateProfileDetails } from './helpers';
import './ProfileSettingsPage.scss';
import { addAlertAction, openInfoModalWindow } from '../InitComponent/actions';
import ALERT_TYPES from '../../components/Alert/constants';
import INFO_MODAL_TYPES from '../../components/InfoModalWindow/constants';

const ProfileSettingsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [params] = useSearchParams();
  const navigate = useNavigate();

  const code = params.get('code');
  const socialState = params.get('state');

  const profile = useSelector(mainSelectors.userProfileData);
  const metadata = useSelector(mainSelectors.formMetadataData);

  const isProfileLoading = useSelector(mainSelectors.userProfileLoading);
  const isMetadataLoading = useSelector(mainSelectors.formMetadataLoading);

  const linkedAccountsState = useSelector(selectors.linkedAccountsStateSelector);

  useEffect(() => {
    dispatch(getUserProfileAction.request());
    dispatch(getFormMetadataAction.request());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (code) {
      if (socialState === 'facebook') {
        const onError = () => {
          navigate('/profile/settings#details');
          dispatch(
            addAlertAction({
              type: ALERT_TYPES.error,
              message: 'SOME MESSAGE HERE',
            }),
          );
        };

        const onSuccess = () => {
          navigate('/profile/settings#details');
          dispatch(
            addAlertAction({
              type: ALERT_TYPES.success,
              message: 'SOME MESSAGE HERE',
            }),
          );
        };

        dispatch(
          authFacebookByCodeAction.request({
            data: {
              code,
            },
            onSuccess,
            onError,
          }),
        );
      }
    }
  }, [code]);

  const handleSaveProfileDetails = (values) => {
    const onSuccess = () => {
      dispatch(getUserProfileAction.request());
    };

    dispatch(
      updateProfileDetailsSettingsAction.request({
        data: mapValuesForUpdateProfileDetails(profile, values),
        onSuccess,
      }),
    );
  };

  const handleSaveProfileDescription = (value) => {
    const onSuccess = () => {
      dispatch(getUserProfileAction.request());
    };

    dispatch(
      updateProfileDescriptionSettingsAction.request({
        data: {
          bio: JSON.stringify(value),
        },
        onSuccess,
      }),
    );
  };

  const handleLogoutUser = () => {
    dispatch(logoutUserAction());
    setToLocalStorage('token', null);
    setToSessionStorage('token', null);
  };

  const handleConfirmDeleteUser = () => {
    dispatch(deleteAccountAction.request());
  };

  const handleDeleteUser = () => {
    dispatch(
      openInfoModalWindow({
        type: INFO_MODAL_TYPES.warningConfirm,
        view: 'DELETE_ACCOUNT_CONFIRMATION',
        data: {
          onConfirm: handleConfirmDeleteUser,
        },
      }),
    );
  };

  return (
    <div className="profile-settings-page--wrapper">
      {isProfileLoading || isMetadataLoading || linkedAccountsState.loading ? (
        <ContentLoader
          visible={isProfileLoading || isMetadataLoading || linkedAccountsState.loading}
        />
      ) : (
        <div className="app-pages--content">
          <div className="content--main">
            <ProfileDetails
              profile={profile}
              metadata={metadata}
              onSubmit={handleSaveProfileDetails}
            />
            <ProfileDescription profile={profile} onSubmit={handleSaveProfileDescription} />
            <LinkedAccounts profile={profile} />
            <div className="profile-settings-page--footer">
              <button
                type="button"
                className="btn btn-primary btn-lg btn-primary-error"
                onClick={handleLogoutUser}
              >
                {t('ProfileSettingsPage.footer.buttons.logout')}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-link-error"
                onClick={handleDeleteUser}
              >
                {t('ProfileSettingsPage.footer.buttons.deleteAccount')}
              </button>
            </div>
          </div>
          <div className="content--additional">
            <AnchorNavigation />
            {/* <TryPremium /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSettingsPage;
