import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import './ProgressLoader.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#6941c6',
  },
}));

const ProgressLoader = ({ visible, value, title }) => (
  <CSSTransition in={visible} timeout={500} classNames="loader-appearing-animation" unmountOnExit>
    <div className="progressLoader">
      {title && <h3 className="progressLoader__title">{title}</h3>}
      <BorderLinearProgress className="progressLoader__bar" variant="determinate" value={value} />
    </div>
  </CSSTransition>
);

ProgressLoader.propTypes = {
  visible: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  title: PropTypes.string,
};

ProgressLoader.defaultProps = {
  title: undefined,
};

export default ProgressLoader;
