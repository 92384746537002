import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectors as mainSelectors } from '../MainPage/reducer';
import { selectors } from './reducer';
import getComparisonUsersDataAction from './actions';
import Tooltip from '../../components/Tooltip';
import UserInfo from '../../components/UserInfo';
import Icon from '../../components/Icon';
import ContentLoader from '../../components/ContentLoader';
import './ComparePage.scss';
import { clearComparisonList } from '../MainPage/actions';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';

const ComparePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const comparisonPageData = useSelector(mainSelectors.comparisonListData);
  const comparisonUsersData = useSelector(selectors.comparisonUsersData);
  const comparisonUsersLoading = useSelector(selectors.comparisonUsersLoading);

  useEffect(() => {
    if (comparisonPageData?.length) {
      dispatch(getComparisonUsersDataAction.request(getFromLocalStorage('comparison')));
    }
  }, [comparisonPageData]);

  const handleClearComparison = () => {
    setToLocalStorage('comparison', []);
    dispatch(clearComparisonList());
  };

  return (
    <div className="compare-page--wrapper">
      {comparisonUsersLoading ? (
        <ContentLoader visible={comparisonUsersLoading} />
      ) : (
        <div className="app-pages--content">
          <div className="content--main">
            {comparisonPageData?.length > 0 ? (
              <>
                <div className="compare-page--header">
                  <div className="header--info">
                    <h2 className="title">
                      {t('ComparePage.title')}
                      <span>
                        {getFromLocalStorage('comparison')?.length}{' '}
                        {t(`ComparePage.influencer${comparisonUsersData?.length > 1 ? 's' : ''}`)}
                      </span>
                    </h2>
                    <p className="description">{t('ComparePage.description')}</p>
                  </div>
                  <div className="header--controls">
                    <button
                      type="button"
                      className="btn btn-md btn-link"
                      onClick={handleClearComparison}
                    >
                      {t('ComparePage.buttons.delete')}
                    </button>
                    <button type="button" className="btn btn-md btn-secondary-gray">
                      <Icon icon="export" /> {t('ComparePage.buttons.export')}
                    </button>
                  </div>
                </div>
                <div className="compare-page--table">
                  <table className="table">
                    <tbody>
                      <tr className="table--row table--header-row">
                        <th className="table--header-cell" />
                        {comparisonUsersData?.map((user) => (
                          <td className="table--cell" key={user.id}>
                            <UserInfo user={user} />
                          </td>
                        ))}
                      </tr>
                      <tr className="table--row table--header-row">
                        <th className="table--header-cell">
                          <div className="table--header-cell__with-tooltip">
                            <span>{t('ComparePage.table.headers.aqs.text')}</span>
                            <Tooltip text={t('ComparePage.table.headers.aqs.tooltip')} />
                          </div>
                        </th>
                        {comparisonUsersData?.map((user) => (
                          <td className="table--cell" key={user.id}>
                            Very Good
                          </td>
                        ))}
                      </tr>
                      <tr className="table--row table--header-row">
                        <th className="table--header-cell">
                          <div className="table--header-cell__with-tooltip">
                            <span>{t('ComparePage.table.headers.er.text')}</span>
                            <Tooltip text={t('ComparePage.table.headers.er.tooltip')} />
                          </div>
                        </th>
                        {comparisonUsersData?.map((user) => (
                          <td className="table--cell" key={user.id}>
                            1.05%
                          </td>
                        ))}
                      </tr>
                      <tr className="table--row table--header-row">
                        <th className="table--header-cell">
                          {t('ComparePage.table.headers.qualityAud')}
                        </th>
                        {comparisonUsersData?.map((user) => (
                          <td className="table--cell" key={user.id}>
                            1.05%
                          </td>
                        ))}
                      </tr>
                      <tr className="table--row table--header-row">
                        <th className="table--header-cell">
                          {t('ComparePage.table.headers.category')}
                        </th>
                        {comparisonUsersData?.map((user) => (
                          <td className="table--cell" key={user.id} />
                        ))}
                      </tr>
                      <tr className="table--row table--header-row">
                        <th className="table--header-cell">
                          {t('ComparePage.table.headers.audAge')}
                        </th>
                        {comparisonUsersData?.map((user) => (
                          <td className="table--cell" key={user.id}>
                            11.76
                          </td>
                        ))}
                      </tr>
                      <tr className="table--row table--header-row">
                        <th className="table--header-cell">
                          {t('ComparePage.table.headers.followers')}
                        </th>
                        {comparisonUsersData?.map((user) => (
                          <td className="table--cell" key={user.id}>
                            11.9M
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="compare-page--404">
                <div className="compare-page--404__icon-wrapper">
                  <Icon icon="nav-compare" />
                </div>
                <p className="compare-page--404__title">{t('ComparePage.emptyState.title')}</p>
                <span className="compare-page--404__description">
                  {t('ComparePage.emptyState.description')}
                </span>
                <span className="compare-page--404__description">
                  {t('ComparePage.emptyState.subdescription')}
                </span>
                <Link to="/search/influencer" className="btn btn-primary btn-lg">
                  {t('ComparePage.emptyState.buttons.search')}
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparePage;
