/* eslint-disable global-require */
import ApiService from '../../global/services/api';
import { mockImport } from '../../global/helpers';
import { isMockSavedListPage } from '../../global/services/isMockEnabled';

const getProfileMock = mockImport(
  () => require('../../__mocks__/SavedListService/getProfileById.mock.json'),
  isMockSavedListPage,
);

export class SavedListService extends ApiService {
  getProfileBuId = () => this.get({ url: `relations/saved` }, getProfileMock);

  deleteProfileById = (id) => this.delete({ url: `relations/saved/${id}` });
}

export default new SavedListService();
