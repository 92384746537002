import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectors } from '../../reducer';
import { changePasswordResetStatusAction } from '../../actions';
import Header from '../../../../components/Header';
import Heading from '../../../../components/Heading';
import Footer from '../../../../components/Footer';
import Icon from '../../../../components/Icon';
import { RESET_PASSWORD_STEPS } from '../../constants';

const PasswordResetSuccessPage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const resetPasswordStatus = useSelector(selectors.resetPasswordStatus);

  useEffect(() => {
    if (resetPasswordStatus !== RESET_PASSWORD_STEPS.passwordChanged) {
      navigate('/auth');
    }

    return () => dispatch(changePasswordResetStatusAction(null));
    // eslint-disable-next-line
  }, []);
  return (
    <div className="additional-page--wrapper">
      <Header />
      <div className="additional-page--form">
        <Heading
          subtitle="PasswordResetSuccessPage.heading.subtitle"
          title="PasswordResetSuccessPage.heading.title"
          position="center"
        />
        <NavLink to="/auth" className="btn btn-lg btn-primary">
          {t('PasswordResetSuccessPage.buttons.continue')}
        </NavLink>
        <div className="form--footer">
          <Link to="/auth" className="link link-gray link-md">
            <Icon icon="arrow-left" className="link--icon" />
            {t('PasswordResetSuccessPage.footer.link')}
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordResetSuccessPage;
