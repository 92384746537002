import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './NotFoundPage.scss';
import Icon from '../../components/Icon';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div className="not-found-page--wrapper">
      <div className="page--content-text">
        <h2 className="content--type">{t('NotFoundPage.type')}</h2>
        <h3 className="content--title">{t('NotFoundPage.title')}</h3>
        <p className="content--description">{t('NotFoundPage.description')}</p>
        <div className="content--controls">
          <Link to="/feed" className="btn btn-md btn-tertiary">
            <Icon icon="arrow-left" className="btn--icon" />
            {t('NotFoundPage.buttons.back')}
          </Link>
          <Link to="/feed" className="btn btn-md btn-primary">
            {t('NotFoundPage.buttons.search')}
          </Link>
        </div>
      </div>
      <div className="page--image">
        <Icon icon="404" className="image--not-found" />
      </div>
    </div>
  );
};

export default NotFoundPage;
