import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { ErrorMessage } from 'formik';
import Icon from '../Icon';
import useDebounce from '../../hooks/useDebounce';

const InputDebounce = ({
  className,
  label,
  type,
  name,
  placeholder,
  hint,
  disabled,
  errors,
  touched,
  value,
  values,
  icon,
  onChange,
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(value !== undefined ? value : values[name]);

  const debounceValue = useDebounce(inputValue, 250);

  useEffect(() => {
    if (debounceValue) {
      onChange(debounceValue);
    }
  }, [debounceValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={className}>
      <div className="input-field--wrapper">
        {label && (
          <label htmlFor={name} className="input-label">
            {label}
          </label>
        )}
        <div className="input--wrapper">
          <input
            type={type}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            className={`input 
                  ${errors[name] && touched[name] ? 'state-danger' : ''}
                  ${icon ? 'input-with-icon' : ''}`}
          />
          <div className="input--controls-alerts">
            {errors[name] && touched[name] && <Icon icon="alert" className="input--icon-alert" />}
          </div>
        </div>
        {hint && !errors[name] && !touched[name] && <div className="input-field--hint">{hint}</div>}
        <CSSTransition
          in={errors[name] && touched[name]}
          timeout={500}
          classNames="error-block-animation"
          unmountOnExit
        >
          <div className="input-field--error-block-wrapper">
            <ErrorMessage
              name={name}
              render={(msg) => (
                <div className="input-error-block">
                  <div className="input-validation-message">{t(msg)}</div>
                </div>
              )}
            />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

InputDebounce.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

InputDebounce.defaultProps = {
  className: null,
  label: null,
  hint: null,
  placeholder: null,
  icon: null,
  type: 'text',
  disabled: false,
  value: undefined,
};

export default InputDebounce;
