import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ReactSelect, { components } from 'react-select';
import { getFormMetadataAction } from 'pages/MainPage/actions';
import { options as languages } from '../../../../../../languages';
import { getFromLocalStorage, setToLocalStorage } from '../../../../../../global/helpers';
import './LanguageSelector.scss';

const { Option, SingleValue } = components;

const IconOption = (props) => {
  const { t } = useTranslation();

  const { data } = props;

  return (
    // eslint-disable-next-line
    <Option {...props}>
      <div className="option--icon">
        <img src={data.icon} className="icon--img" alt="option-icon" />
      </div>
      <span className="option--text">{t(data.label)}</span>
    </Option>
  );
};

const IconSingleValue = (props) => {
  const { t } = useTranslation();

  const { data } = props;

  return (
    // eslint-disable-next-line
    <SingleValue {...props}>
      <div className="selected-option--icon">
        <img src={data.icon} className="icon--img" alt="option-icon" />
      </div>
      <span className="option--text">{t(data.label)}</span>
    </SingleValue>
  );
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setToLocalStorage('lng', language);
    dispatch(getFormMetadataAction.request());
  };

  return (
    <ReactSelect
      className="sidebar--language-select"
      classNamePrefix="language-select"
      isSearchable={false}
      options={languages}
      defaultValue={
        languages.find((option) => option.value === getFromLocalStorage('lng')) || languages[0]
      }
      components={{ Option: IconOption, SingleValue: IconSingleValue }}
      onChange={(value) => changeLanguage(value.value)}
    />
  );
};

IconOption.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

IconSingleValue.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

export default LanguageSelector;
