import { asyncActionCreator } from '../../global/redux';
import * as constants from './constants';

export const createNewPostAction = asyncActionCreator(constants.CREATE_NEW_POST);
export const createRepostAction = asyncActionCreator(constants.CREATE_REPOST);
export const deletePostAction = asyncActionCreator(constants.DELETE_POST);
export const getAllPostsAction = asyncActionCreator(constants.GET_ALL_POSTS);
export const postLikeAction = asyncActionCreator(constants.LIKE_POST);
export const deleteLikeAction = asyncActionCreator(constants.UNLIKE_POST);
export const getInfluencersAction = asyncActionCreator(constants.GET_INFLUENCERS);
export const getCompaniesAction = asyncActionCreator(constants.GET_COMPANIES);
export const getCommentsAction = asyncActionCreator(constants.GET_COMMENTS);
export const postCommentAction = asyncActionCreator(constants.POST_COMMENT);
export const getProfileFollowersAction = asyncActionCreator(constants.GET_PROFILE_FOLLOWERS);
export const followUserAction = asyncActionCreator(constants.FOLLOW_USER_FEED_PAGE);
export const unfollowUserAction = asyncActionCreator(constants.UNFOLLOW_USER_FEED_PAGE);
