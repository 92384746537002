import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as actions from './actions';
import MainService from './service';
import {
  mapDataForSelect,
  mapDataForSelectWithCategories,
  mapGendersForSelect,
  mapLocationsForSelect,
} from '../../global/helpers';

export function* getUserByTokenSaga() {
  try {
    const response = yield call(MainService.getUserProfileByToken);

    yield put(actions.getUserProfileAction.success(response.data.data));
  } catch (error) {
    yield put(actions.getUserProfileAction.error(error));
  }
}

export function* getFormMetadataSaga() {
  try {
    const locationsResponse = yield call(MainService.getLocations);
    const gendersResponse = yield call(MainService.getGenders);
    const businessTypesResponse = yield call(MainService.getBusinessTypes);
    const industriesResponse = yield call(MainService.getIndustries);

    const mappedData = {
      locations: mapLocationsForSelect(locationsResponse.data.data),
      genders: mapGendersForSelect(gendersResponse.data.data),
      businessTypes: mapDataForSelect(businessTypesResponse.data.data),
      industries: mapDataForSelectWithCategories(industriesResponse.data.data),
    };

    yield put(actions.getFormMetadataAction.success(mappedData));
  } catch (error) {
    yield put(actions.getFormMetadataAction.error(error));
  }
}

export function* getUsersBySearch({ payload }) {
  try {
    const response = yield call(MainService.getUsersBySearch, payload);

    yield put(actions.getUsersBySearchAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.getUsersBySearchAction.error(error));
  }
}

export function* getFavoriteUsersSaga() {
  try {
    const response = yield call(MainService.getFavoriteUsers);

    yield put(actions.getFromFavorite.success(response.data.data));
  } catch (error) {
    yield put(actions.getFromFavorite.error(error));
  }
}

export function* addUserToFavoriteSaga({ payload }) {
  try {
    yield call(MainService.addUserToFavorite, payload);

    yield put(actions.addToFavorite.success(payload));
    yield put(actions.getFromFavorite.request());
  } catch (error) {
    yield put(actions.addToFavorite.error(error));
  }
}

export function* deleteUserFromFavoriteSaga({ payload }) {
  try {
    yield put(actions.setFavoriteListLoading(true));

    if (Array.isArray(payload)) {
      for (let i = 0; i < payload.length; i += 1) {
        yield call(MainService.deleteUserFromFavorite, payload[i]);
      }
    } else {
      yield call(MainService.deleteUserFromFavorite, payload);
    }

    yield put(actions.deleteFormFavorite.success(payload));
    yield put(actions.getFromFavorite.request());
  } catch (error) {
    yield put(actions.deleteFormFavorite.error(error));
  }
}

export function* getNotificationsSaga() {
  try {
    const response = yield call(MainService.getNotifications);

    yield put(actions.getNotificationsAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.getNotificationsAction.error(error));
  }
}

export function* mainPageWatcherSaga() {
  yield all([
    takeLatest(actions.getUserProfileAction.REQUEST, getUserByTokenSaga),
    takeLatest(actions.getFormMetadataAction.REQUEST, getFormMetadataSaga),
    takeLatest(actions.getUsersBySearchAction.REQUEST, getUsersBySearch),
    takeLatest(actions.getNotificationsAction.REQUEST, getNotificationsSaga),
    takeLatest(actions.getFromFavorite.REQUEST, getFavoriteUsersSaga),
    takeLatest(actions.addToFavorite.REQUEST, addUserToFavoriteSaga),
    takeLatest(actions.deleteFormFavorite.REQUEST, deleteUserFromFavoriteSaga),
  ]);
}
