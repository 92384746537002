import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactSelect, { components } from 'react-select';
import { getFormValuesAction } from 'pages/SignUpPage/actions';
import { options } from './constants';
import { setToLocalStorage, getFromLocalStorage } from '../../../../global/helpers';
import './LanguageSelector.scss';

const { Option, SingleValue } = components;

const IconOption = (props) => {
  const { t } = useTranslation();

  const { data } = props;

  return (
    // eslint-disable-next-line
    <Option {...props}>
      <div className="option--icon">
        <img
          src={data.icon}
          className="icon--img"
          alt="option-icon"
          style={{ display: 'block', width: 20 }}
        />
      </div>
      <span className="option--text">{t(data.label)}</span>
    </Option>
  );
};

const IconSingleValue = (props) => {
  const { t } = useTranslation();

  const { data } = props;

  return (
    // eslint-disable-next-line
    <SingleValue {...props}>
      <div className="selected-option--icon">
        <img
          src={data.icon}
          className="icon--img"
          alt="option-icon"
          style={{ display: 'block', width: 20 }}
        />
      </div>
      <span className="option--text">{t(data.label)}</span>
    </SingleValue>
  );
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setToLocalStorage('lng', language);

    // Update locations, genders, industries, business-types translations
    dispatch(getFormValuesAction.request());
  };

  const customStyles = useMemo(
    () => ({
      control: (styles, { isFocused, isSelected }) => ({
        ...styles,
        fonSize: '14px',
        border: isFocused ? 0 : 0,
        boxShadow: isFocused ? 0 : 0,
        '&:hover': {
          border: isFocused ? 0 : 0,
        },
        borderColor: isSelected ? 'none' : 'none',
        backgroundColor: 'white',
        cursor: 'pointer',
      }),
      option: (styles, { isFocused, isDisabled, isSelected }) => ({
        ...styles,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        backgroundColor: isFocused ? '#F9F5FF' : 'transparent',
        color: isFocused || isSelected ? '#53389E' : '#101828',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        borderRadius: '10px',
      }),
      menu: (styles) => ({
        ...styles,
        boxShadow: '0 4px 6px -2px #1018280D, 0 12px 16px -4px #1018281A',
        padding: '5px',
      }),
      singleValue: (styles) => ({
        ...styles,
        color: '#667085',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
      }),
    }),
    [],
  );

  return (
    <ReactSelect
      className="link link-md link-gray header--link"
      isSearchable={false}
      styles={customStyles}
      defaultValue={
        options.find((option) => option.value === getFromLocalStorage('lng')) || options[0]
      }
      options={options}
      components={{ Option: IconOption, SingleValue: IconSingleValue }}
      onChange={(value) => changeLanguage(value.value)}
    />
  );
};

IconOption.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

IconSingleValue.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

export default LanguageSelector;
