import { useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatMessageCountDecSuccess,
  newMessageCountDecSuccess,
  newMessageCountIncSuccess,
  receiveNewMessageSuccess,
  receiveNewMessageChatUpdateSuccess,
  updateMessageStatusSuccess,
} from 'pages/ChatPage/actions';
import { selectors as chatSelectors } from '../../pages/ChatPage/reducer';
import { selectors as userSelectors } from '../../pages/LogInPage/reducer';

const SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;
const CHAT_RECEIVE_MESSAGE = 'CHAT_RECEIVE_MESSAGE';
const CHAT_READ_MESSAGE = 'CHAT_READ_MESSAGE';

const useChat = (activeChatId) => {
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const accessToken = useSelector(userSelectors.userToken);
  const chats = useSelector(chatSelectors.chatsData);
  const messages = useSelector(chatSelectors.chatMessages);
  const userData = useSelector(userSelectors.userData);

  function receivedMessage({ chat, message }) {
    const index = chats.findIndex((c) => chat.id === c.id);
    if (index >= 0) {
      const foundChat = chats[index];
      let chatNewMsgCount = foundChat.newMessagesCount;

      if (message.owner.id !== userData.id) {
        chatNewMsgCount = chat.newMessagesCount;
        dispatch(newMessageCountIncSuccess());
      }
      dispatch(receiveNewMessageChatUpdateSuccess({ chat, index, chatNewMsgCount }));
      if (foundChat.id === activeChatId) {
        dispatch(receiveNewMessageSuccess(message));
      }
    } else {
      dispatch(receiveNewMessageChatUpdateSuccess({ chat, index: null }));
      dispatch(newMessageCountIncSuccess());
    }
  }

  const readMessage = (messageId, chatId) => {
    socketRef.current.emit('CHAT_READ_MESSAGE', {
      id: messageId,
      chat: { id: chatId },
    });
  };

  const sendMessage = ({ messageText, fileId, replyId, chatId }) => {
    let message;
    if (chatId) {
      if (replyId) {
        if (fileId) {
          message = {
            type: 'FILE',
            text: '',
            file: { id: fileId },
            chat: { id: chatId },
            reply: { id: replyId },
          };
        } else if (messageText) {
          message = {
            type: 'TEXT',
            text: messageText,
            chat: { id: chatId },
            reply: { id: replyId },
          };
        }
      } else if (!replyId) {
        if (fileId) {
          message = {
            type: 'FILE',
            text: '',
            file: { id: fileId },
            chat: { id: chatId },
          };
        } else if (messageText) {
          message = {
            type: 'TEXT',
            text: messageText,
            chat: { id: chatId },
          };
        }
      }
      socketRef.current.emit('CHAT_SEND_MESSAGE', message);
    }
  };

  const updateMessage = (data) => {
    const index = messages.findIndex((m) => data.message.id === m.id);
    if (index >= 0) {
      dispatch(newMessageCountDecSuccess());
      dispatch(updateMessageStatusSuccess(index));

      const chatIndex = chats.findIndex((chat) => data.chat.id === chat.id);
      if (chatIndex >= 0) {
        dispatch(chatMessageCountDecSuccess({ index: chatIndex }));
      }
    }
  };

  useEffect(() => {
    if (accessToken) {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      socketRef.current = io(SERVER_URL, {
        auth: { token: accessToken },
        transports: ['websocket'],
        reconnectionAttempts: 5,
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [accessToken]);

  useEffect(() => {
    if (
      socketRef.current &&
      chats &&
      userData &&
      !socketRef.current.listeners(CHAT_RECEIVE_MESSAGE).length
    ) {
      socketRef.current.on(CHAT_RECEIVE_MESSAGE, (data) => {
        receivedMessage(data);
      });
    }
    if (
      socketRef.current &&
      chats &&
      messages &&
      !socketRef.current.listeners(CHAT_READ_MESSAGE).length
    ) {
      socketRef.current.on(CHAT_READ_MESSAGE, (data) => {
        updateMessage(data);
      });
    }
  }, [socketRef.current, chats, messages, userData, activeChatId]);

  useEffect(() => {
    if (!activeChatId && socketRef.current.listeners(CHAT_READ_MESSAGE).length) {
      socketRef.current.off(CHAT_READ_MESSAGE);
    }

    if (activeChatId && messages) {
      if (socketRef.current.listeners(CHAT_RECEIVE_MESSAGE).length) {
        socketRef.current.off(CHAT_RECEIVE_MESSAGE);
        socketRef.current.on(CHAT_RECEIVE_MESSAGE, (data) => {
          receivedMessage(data);
        });
      }
      if (socketRef.current.listeners(CHAT_READ_MESSAGE).length) {
        socketRef.current.off(CHAT_READ_MESSAGE);
        socketRef.current.on(CHAT_READ_MESSAGE, (data) => {
          updateMessage(data);
        });
      }
    }
  }, [activeChatId, messages]);

  return {
    sendMessage,
    readMessage,
  };
};

export default useChat;
