import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as actions from './actions';
import { logoutUserAction } from '../LogInPage/actions';
import ProfileSettingsService from './service';
import { addAlertAction } from '../InitComponent/actions';
import ALERT_TYPES from '../../components/Alert/constants';

export function* updateProfileDetailsSettingSaga({ payload }) {
  try {
    const {
      data: { avatar, ...data },
      onSuccess,
    } = payload;
    let avatarResponse;

    if (avatar) {
      avatarResponse = yield call(ProfileSettingsService.avatarUpload, avatar);
    }

    const response = yield call(ProfileSettingsService.updateProfileDetails, {
      ...data,
      cover: avatarResponse?.data?.data?.id ? { id: avatarResponse?.data?.data?.id } : undefined,
    });

    yield put(actions.updateProfileDetailsSettingsAction.success(response.data.data));

    yield onSuccess();
  } catch (error) {
    yield put(actions.updateProfileDetailsSettingsAction.error(error));
  }
}

export function* updateProfileDescriptionSaga({ payload }) {
  try {
    const response = yield call(ProfileSettingsService.updateProfileDescription, payload.data);

    yield put(actions.updateProfileDescriptionSettingsAction.success(response.data.data));

    yield payload.onSuccess();
  } catch (error) {
    yield put(actions.updateProfileDescriptionSettingsAction.error(error));
  }
}

export function* deleteAccountSaga() {
  try {
    yield call(ProfileSettingsService.deleteAccount);

    yield put(actions.deleteAccountAction.success());
    yield put(logoutUserAction());
    yield put(
      addAlertAction({
        type: ALERT_TYPES.success,
        message: 'DELETE_ACCOUNT',
      }),
    );
  } catch (error) {
    yield put(actions.deleteAccountAction.error(error));
  }
}

export function* authFacebookByCodeSaga({ payload }) {
  try {
    yield call(ProfileSettingsService.authFacebookByCode, payload.data);

    yield put(actions.authFacebookByCodeAction.success());
    yield payload.onSuccess();
  } catch (error) {
    yield put(actions.authFacebookByCodeAction.error());
    yield payload.onError();
  }
}

export function* profileSettingsPageWatcherSaga() {
  yield all([
    takeLatest(actions.updateProfileDetailsSettingsAction.REQUEST, updateProfileDetailsSettingSaga),
    takeLatest(
      actions.updateProfileDescriptionSettingsAction.REQUEST,
      updateProfileDescriptionSaga,
    ),
    takeLatest(actions.deleteAccountAction.REQUEST, deleteAccountSaga),
    takeLatest(actions.authFacebookByCodeAction.REQUEST, authFacebookByCodeSaga),
  ]);
}
