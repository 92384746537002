export const SIGN_UP_USER = 'SIGN_UP_USER';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const GET_FORM_VALUES = 'GET_FORM_VALUES';
export const UPDATE_USER = 'UPDATE_USER';
export const CONFIRM_SOCIAL_ACCOUNT = 'CONFIRM_SOCIAL_ACCOUNT';

export const CHANGE_SIGN_UP_STATUS = 'CHANGE_SIGN_UP_STATUS';

export const SIGN_UP_STEPS = {
  emailVerification: 'email_verification',
  emailVerified: 'email_verified',
};
