import { createAction } from '@reduxjs/toolkit';
import { asyncActionCreator } from '../../global/redux';
import * as constants from './constants';

export const getAllChatsAction = asyncActionCreator(constants.GET_ALL_CHATS);
export const getChatMessagesAction = asyncActionCreator(constants.GET_CHAT_MESSAGES);
export const getNewMsgCountAction = asyncActionCreator(constants.GET_NEW_MESSAGES_COUNT);
export const sendMessageFilesAction = asyncActionCreator(constants.SEND_MESSAGE_FILES);
export const createChatAction = asyncActionCreator(constants.CREATE_CHAT);
export const startFilesUploading = createAction(constants.START_FILES_UPLOADING);
export const finishFilesUploading = createAction(constants.FINISH_FILES_UPLOADING);
export const changeFilterSuccess = createAction('filter/data/changeFilterSuccess');
export const receiveNewMessageSuccess = createAction('chatMessages/data/receiveNewMessageSuccess');
export const newMessageCountIncSuccess = createAction('newMsgCount/data/newMessageCountIncSuccess');
export const newMessageCountDecSuccess = createAction('newMsgCount/data/newMessageCountDecSuccess');
export const receiveNewMessageChatUpdateSuccess = createAction(
  'chatList/data/receiveNewMessageChatUpdateSuccess',
);
export const updateMessageStatusSuccess = createAction(
  'chatMessages/data/updateMessageStatusSuccess',
);

export const chatMessageCountDecSuccess = createAction('chatList/data/chatMessageCountDecSuccess');
