import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getFeedbacksByIdAction, postFeedbackAction } from 'pages/FeedbacksPage/actions';
import { getProfileByIdAction } from 'pages/ProfilePage/actions';
import AddNewFeedbackModal from './components/AddNewFeedbackModal';
import SuccessFeedbackModal from './components/SuccessFeedbackModal/SuccessFeedbackModal';
import './FeedbackAddNew.scss';
import ErrorFeedbackModal from './components/ErrorFeedbackModal';

const FeedbackAddNew = ({ recipientId, sortUpdate }) => {
  const { t } = useTranslation();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const dispatch = useDispatch();

  const handleNewFeedback = (newFeedback) => {
    const onSuccess = () => {
      setOpenSuccessModal((prev) => !prev);
    };

    const onError = () => {
      setOpenErrorModal((prev) => !prev);
    };

    dispatch(
      postFeedbackAction.request({
        data: newFeedback,
        onSuccess,
        onError,
      }),
    );
    setOpenAddModal((prev) => !prev);
  };

  const handleSuccess = () => {
    if (sortUpdate === 'desc') {
      dispatch(getFeedbacksByIdAction.request({ id: recipientId }));
    } else if (sortUpdate === 'asc') {
      dispatch(getFeedbacksByIdAction.request({ id: recipientId, sort: 'asc=updatedAt' }));
    }

    dispatch(getProfileByIdAction.request(recipientId));

    setOpenSuccessModal((prev) => !prev);
  };

  return (
    <div className="feedback-add-new--wrapper">
      <p className="feedback-add-new--title">{t('FeedbacksPage.leaveFeedback.title')}</p>
      <button
        type="button"
        className="btn btn-primary btn-md"
        onClick={() => setOpenAddModal((prev) => !prev)}
      >
        {t('FeedbacksPage.leaveFeedback.btn')}
      </button>
      <AddNewFeedbackModal
        isOpened={openAddModal}
        onCancel={() => setOpenAddModal((prev) => !prev)}
        onConfirm={handleNewFeedback}
        recipientId={recipientId}
      />
      <SuccessFeedbackModal isOpened={openSuccessModal} onCancel={handleSuccess} />
      <ErrorFeedbackModal
        isOpened={openErrorModal}
        onCancel={() => setOpenErrorModal((prev) => !prev)}
      />
    </div>
  );
};

FeedbackAddNew.propTypes = {
  recipientId: PropTypes.string.isRequired,
  sortUpdate: PropTypes.string,
};

FeedbackAddNew.defaultProps = {
  sortUpdate: 'desc',
};

export default FeedbackAddNew;
