import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NetworkSettings = () => {
  const { t } = useTranslation();

  return (
    <div className="page--settings">
      <p className="setting--description">
        {t('NetworkPage.additionalContent.settings.description')}
      </p>
      <Link to="/profile/settings" className="btn btn-link btn-secondary btn-md">
        {t('NetworkPage.additionalContent.settings.button')}
      </Link>
    </div>
  );
};
export default NetworkSettings;
