import React from 'react';
import Icon from '../Icon';
import './UserSocials.scss';

const UserSocials = () => (
  <div className="user--socials">
    <div className="social">
      <Icon icon="facebook" className="social-icon" />
      <span className="social-value">346k</span>
    </div>
    <div className="social">
      <Icon icon="instagram" className="social-icon" />
      <span className="social-value">346k</span>
    </div>
    <div className="social">
      <Icon icon="tiktok" className="social-icon" />
      <span className="social-value">346k</span>
    </div>
    <div className="social">
      <Icon icon="youtube" className="social-icon" />
      <span className="social-value">346k</span>
    </div>
  </div>
);

export default UserSocials;
