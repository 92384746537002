/* eslint-disable global-require */
import ApiService from 'global/services/api';
import { mockImport } from 'global/helpers';
import { isMockProfileSettingsPage } from '../../global/services/isMockEnabled';

const updateProfileDetailsSettingsMock = mockImport(
  () => require('../../__mocks__/ProfileSettingsService/updateProfileDetailsSettings.mock.json'),
  isMockProfileSettingsPage,
);
const updateProfileDescriptionSettingsMock = mockImport(
  () =>
    require('../../__mocks__/ProfileSettingsService/updateProfileDescriptionSettings.mock.json'),
  isMockProfileSettingsPage,
);
const deleteProfileMock = mockImport(
  () => require('../../__mocks__/ProfileSettingsService/deleteProfile.mock.json'),
  isMockProfileSettingsPage,
);
const avatarUploadMock = mockImport(
  () => require('../../__mocks__/ProfileSettingsService/postAvatar.mock.json'),
  isMockProfileSettingsPage,
);
const authFacebookByCodeMock = mockImport(
  () => require('../../__mocks__/SignUpService/authFacebookByCode.mock.json'),
  isMockProfileSettingsPage,
);

class ProfileSettingsService extends ApiService {
  updateProfileDetails = async (data) =>
    this.patch({ url: 'auth/profile', body: data }, updateProfileDetailsSettingsMock);

  updateProfileDescription = async (data) =>
    this.patch({ url: 'auth/profile', body: data }, updateProfileDescriptionSettingsMock);

  deleteAccount = async () => this.delete({ url: 'auth/profile' }, deleteProfileMock);

  avatarUpload = async (data) =>
    this.post(
      { url: 'files', headers: { 'Content-Type': 'multipart/form-data' }, body: data },
      avatarUploadMock,
    );

  authFacebookByCode = async (data) =>
    this.post({ url: 'auth/facebook/user/token', body: data }, authFacebookByCodeMock);
}

export default new ProfileSettingsService();
