export const ROUTES = [
  {
    id: 'tracking',
    name: 'ProfileSettingsPage.rightBar.links.accounts',
    anchor: '#tracking',
  },
  {
    id: 'notifications',
    name: 'ProfileSettingsPage.rightBar.links.notifications',
    anchor: '#notifications',
  },
  {
    id: 'subscription',
    name: 'ProfileSettingsPage.rightBar.links.subscription',
    anchor: '#subscription',
  },
];

export default ROUTES;
