import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from '../../pages/SearchPage/reducer';
import { selectors as mainSelectors } from '../../pages/MainPage/reducer';
import { followCompanyAction } from '../../pages/SearchPage/actions';
import Avatar from '../Avatar';
import Icon from '../Icon';
import ActionsButton from '../ActionsButton';
import IndustriesRow from '../IndustriesRow';
import './CompanyCard.scss';

const CompanyCard = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const followersData = useSelector(selectors.followersData);
  const profile = useSelector(mainSelectors.userProfileData);

  const handleFollowCompany = () => {
    dispatch(
      followCompanyAction.request({
        userId: user.id,
        profileId: profile?.id,
      }),
    );
  };

  return (
    <div className="company-card--wrapper">
      <div className="card-content--wrapper">
        <div className="card-content--info">
          <Avatar size="xxl" className="card--avatar" file={user.cover} />
          <div className="info--name-wrapper">
            <Link to={`/profile/${user.id}`} className="name">
              {user.companyName}
            </Link>
            {user?.isVerified && <Icon icon="verified" className="name--verified-icon" />}
          </div>
          <p className="info--position">{user.positionInCompany}</p>
          <IndustriesRow industries={user.industries} />
        </div>
        <div className="card-content--controls">
          <button
            type="button"
            className="btn btn-primary btn-md"
            onClick={handleFollowCompany}
            disabled={followersData?.includes(user.id)}
          >
            {t(`CompanyCard.buttons.${followersData?.includes(user.id) ? 'connected' : 'connect'}`)}
          </button>
          <ActionsButton />
        </div>
      </div>
    </div>
  );
};

CompanyCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    companyName: PropTypes.string,
    positionInCompany: PropTypes.string,
    cover: PropTypes.string,
    isVerified: PropTypes.bool,
    industries: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default CompanyCard;
