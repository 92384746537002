import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './CheckboxGroupItem.scss';

const CheckboxGroupItem = ({ onChange, value, label, itemList, checkedList }) => {
  const { t } = useTranslation();

  const toggleAll = ({ target: { checked } }) => {
    if (checked) {
      onChange(itemList);
    } else {
      onChange([]);
    }
  };

  const handleCheckboxChange = (v) => {
    if (checkedList.find((el) => el.id === v.id)) {
      onChange(checkedList.filter((el) => el.id !== v.id));
    } else {
      onChange([...checkedList, v]);
    }
  };

  return (
    <label className="checkbox-wrapper">
      <input
        type="checkbox"
        className="checkbox__input"
        name={value.name}
        checked={
          !!checkedList.find((item) => item.id === value.id) ||
          (checkedList.length && checkedList?.length === itemList?.length)
        }
        onChange={(event) => {
          if (value.id === 'all') {
            toggleAll(event);
          } else {
            handleCheckboxChange(value);
          }
        }}
      />
      <div className="checkbox__box" />
      {label && <div className="checkbox__label">{t(label)}</div>}
    </label>
  );
};

CheckboxGroupItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  itemList: PropTypes.arrayOf(PropTypes.shape({})),
  checkedList: PropTypes.arrayOf(PropTypes.shape({})),
};

CheckboxGroupItem.defaultProps = {
  label: null,
  itemList: [],
  checkedList: [],
};

export default CheckboxGroupItem;
