import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { createChatAction, getAllChatsAction } from 'pages/ChatPage/actions';
import { selectors as mainSelectors } from '../../../MainPage/reducer';
import Avatar from '../../../../components/Avatar';
import Icon from '../../../../components/Icon';
import TextEditor from '../../../../components/TextEditor';
import ROLES from '../../../../constants';
import './ProfileDetails.scss';
import {
  addToComparison,
  addToFavorite,
  deleteFormFavorite,
  deleteFromComparison,
} from '../../../MainPage/actions';
import {
  addFriendAction,
  followUserAction,
  addFriendBackAction,
  unfollowUserAction,
} from '../../actions';
import { getFromLocalStorage, setToLocalStorage } from '../../../../global/helpers';
import { selectors as chatSelectors } from '../../../ChatPage/reducer';

const ProfileDetails = ({ profile, isProfilePage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const myProfile = useSelector(mainSelectors.userProfileData);
  const favoriteLoading = useSelector(mainSelectors.favoriteListLoading);
  const favoriteData = useSelector(mainSelectors.favoriteListData);
  const comparisonData = useSelector(mainSelectors.comparisonListData);
  const filteredChats = useSelector(chatSelectors.getFilteredChats);

  const [isComparisonActive, setIsComparisonActive] = useState(
    comparisonData.includes(profile?.id),
  );

  useEffect(() => {
    dispatch(getAllChatsAction.request());
  }, []);

  const handleChangeFavorite = () => {
    const { id: profileId } = profile;
    const isExists = favoriteData.some((e) => e.user.id === profileId);
    const favoriteId = favoriteData.find((e) => e.user.id === profileId);

    if (isExists) {
      dispatch(deleteFormFavorite.request(favoriteId.id));
    } else {
      const data = {
        user: {
          id: profileId,
        },
      };
      dispatch(addToFavorite.request(data));
    }
  };

  const handleChangeComparison = () => {
    const { id: profileId } = profile;
    const prevComparison = getFromLocalStorage('comparison');
    const isExists = prevComparison.includes(profileId);

    if (isExists) {
      setIsComparisonActive(false);
      dispatch(deleteFromComparison(profileId));
      setToLocalStorage(
        'comparison',
        prevComparison ? prevComparison.filter((id) => id !== profileId) : [profileId],
      );
    } else {
      setIsComparisonActive(true);
      dispatch(addToComparison(profileId));
      setToLocalStorage(
        'comparison',
        prevComparison ? [...prevComparison, profileId] : [profileId],
      );
    }
  };

  const handleAddFriend = (status, id) => {
    if (status === 'REQUESTED_OTHER') {
      dispatch(
        addFriendBackAction.request({ id: profile.id, userId: myProfile.id, requestId: id }),
      );
    } else {
      dispatch(addFriendAction.request({ id: profile.id, userId: myProfile.id }));
    }
  };

  const handleFollowUser = () => {
    dispatch(followUserAction.request({ id: profile.id, userId: myProfile.id }));
  };

  const handleUnfollowUser = (requestId) => {
    dispatch(unfollowUserAction.request({ id: profile.id, userId: myProfile.id, requestId }));
  };

  const handleMessageBtnClick = async () => {
    const foundChat = await filteredChats.find((chat) => chat.participant?.id === profile.id);
    if (foundChat) {
      navigate('/messages', { state: { chatId: foundChat.participant.id } });
    } else {
      dispatch(createChatAction.request({ profileId: profile.id, navigate }));
    }
  };

  return (
    <div
      className={`profile-details--wrapper ${
        profile?.role === ROLES.influencer ? 'influencer-view' : ''
      }`}
    >
      <Avatar size="xxl" file={profile?.cover} />
      <div className="profile--name-wrapper">
        <h2 className="profile--name">
          {profile?.role === ROLES.influencer ? profile?.fullName : profile?.companyName}
        </h2>
        <Icon icon="verified" className="profile-verified-icon" />
      </div>
      {profile?.role === ROLES.influencer && profile?.location && (
        <div className="profile--location-wrapper">
          <Avatar
            size="xs"
            file={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${profile?.location?.id}.svg`}
            className="location--icon"
          />
          <span className="location--name">{profile?.location?.name}</span>
        </div>
      )}
      <div className="profile--industries-wrapper">
        {profile?.industries?.map((ind) => (
          <div key={ind.id} className="industry--wrapper">
            <span className="industry-name">{ind.name}</span>
          </div>
        ))}
      </div>
      {profile?.bio && (
        <div className="profile--bio">
          <TextEditor value={JSON.parse(profile?.bio)} readOnly />
        </div>
      )}
      <div className="profile--controls-wrapper">
        {!isProfilePage ? (
          <div className="my-profile--controls">
            {myProfile?.role === ROLES.company ? (
              <div className="controls-company">
                <Link to="/" className="btn btn-md btn-primary">
                  {t('ProfilePage.ProfileDetails.buttons.upgrade')}
                </Link>
                <Link to="/profile/settings#details" className="btn btn-md btn-secondary">
                  {t('ProfilePage.ProfileDetails.buttons.edit')}
                </Link>
              </div>
            ) : (
              <div className="controls-influencer">
                <Link to="/profile/settings#details" className="btn btn-md btn-primary">
                  {t('ProfilePage.ProfileDetails.buttons.edit')}
                </Link>
                <Link to="/" className="btn btn-md btn-secondary">
                  {t('ProfilePage.ProfileDetails.buttons.upgrade')}
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div className="user-profile--controls">
            {myProfile?.role === ROLES.company ? (
              <div className="company-view-controls">
                {profile?.role === ROLES.company ? (
                  <div className="controls-company">
                    <button
                      onClick={() =>
                        handleAddFriend(profile?.friendStatus[0], profile?.friendStatus[1])
                      }
                      type="button"
                      className="btn btn-md btn-primary"
                      disabled={
                        profile?.friendStatus.length > 0 &&
                        profile?.friendStatus[0] !== 'REQUESTED_OTHER'
                      }
                    >
                      {profile?.friendStatus.length === 0 &&
                        t('ProfilePage.ProfileDetails.buttons.connect.default')}
                      {profile?.friendStatus[0] === 'REQUESTED' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.requested')}
                      {profile?.friendStatus[0] === 'APPROVED' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.approved')}
                      {profile?.friendStatus[0] === 'REQUESTED_OTHER' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.default')}
                    </button>
                    <button
                      onClick={
                        profile?.followStatus.length > 0
                          ? () => handleUnfollowUser(profile?.followStatus[1])
                          : handleFollowUser
                      }
                      type="button"
                      className="btn btn-md btn-tertiary"
                    >
                      {(profile?.followStatus.length === 0 ||
                        profile?.followStatus[0] === 'DECLINED') &&
                        t('ProfilePage.ProfileDetails.buttons.follow.default')}
                      {profile?.followStatus[0] === 'APPROVED' &&
                        t('ProfilePage.ProfileDetails.buttons.follow.approved')}
                    </button>
                  </div>
                ) : (
                  <div className="controls-influencer">
                    <button
                      onClick={handleMessageBtnClick}
                      type="button"
                      className="btn btn-md btn-primary btn--message"
                      disabled={profile?.friendStatus[0] !== 'APPROVED'}
                    >
                      {profile?.friendStatus[0] !== 'APPROVED' ? (
                        <Icon className="btn--icon" icon="lock" />
                      ) : null}
                      {t('ProfilePage.ProfileDetails.buttons.message')}
                    </button>
                    <button
                      onClick={() =>
                        handleAddFriend(profile?.friendStatus[0], profile?.friendStatus[1])
                      }
                      type="button"
                      className="btn btn-md btn-secondary"
                      disabled={
                        profile?.friendStatus.length > 0 &&
                        profile?.friendStatus[0] !== 'REQUESTED_OTHER'
                      }
                    >
                      {profile?.friendStatus.length === 0 &&
                        t('ProfilePage.ProfileDetails.buttons.connect.default')}
                      {profile?.friendStatus[0] === 'REQUESTED' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.requested')}
                      {profile?.friendStatus[0] === 'APPROVED' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.approved')}
                      {profile?.friendStatus[0] === 'REQUESTED_OTHER' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.default')}
                    </button>
                    <button
                      onClick={
                        profile?.followStatus.length > 0
                          ? () => handleUnfollowUser(profile?.followStatus[1])
                          : handleFollowUser
                      }
                      type="button"
                      className="btn btn-md btn-tertiary"
                    >
                      {(profile?.followStatus.length === 0 ||
                        profile?.followStatus[0] === 'DECLINED') &&
                        t('ProfilePage.ProfileDetails.buttons.follow.default')}
                      {profile?.followStatus[0] === 'APPROVED' &&
                        t('ProfilePage.ProfileDetails.buttons.follow.approved')}
                    </button>
                    <button
                      type="button"
                      className={`btn btn-md btn-tertiary comparison ${
                        isComparisonActive ? 'active' : ''
                      }`}
                      onClick={handleChangeComparison}
                    >
                      <Icon icon="compare-black" className="btn--icon" />
                    </button>
                    <button
                      type="button"
                      className={`btn btn-md btn-tertiary saved ${
                        !favoriteLoading &&
                        Array.isArray(favoriteData) &&
                        favoriteData.some((e) => e.user.id === profile?.id)
                          ? 'active'
                          : ''
                      }`}
                      onClick={handleChangeFavorite}
                    >
                      <Icon icon="wish" className="wish-icon" />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="influencer-view-controls">
                {profile?.role === ROLES.company ? (
                  <div className="controls-company">
                    <button
                      onClick={handleMessageBtnClick}
                      type="button"
                      className="btn btn-md btn-primary"
                      disabled={profile?.friendStatus[0] !== 'APPROVED'}
                    >
                      {t('ProfilePage.ProfileDetails.buttons.message')}
                    </button>
                    <button
                      onClick={() =>
                        handleAddFriend(profile?.friendStatus[0], profile?.friendStatus[1])
                      }
                      type="button"
                      className="btn btn-md btn-secondary"
                      disabled={
                        profile?.friendStatus.length > 0 &&
                        profile?.friendStatus[0] !== 'REQUESTED_OTHER'
                      }
                    >
                      {profile?.friendStatus.length === 0 &&
                        t('ProfilePage.ProfileDetails.buttons.connect.default')}
                      {profile?.friendStatus[0] === 'REQUESTED' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.requested')}
                      {profile?.friendStatus[0] === 'APPROVED' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.approved')}
                      {profile?.friendStatus[0] === 'REQUESTED_OTHER' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.default')}
                    </button>
                    <button
                      onClick={
                        profile?.followStatus.length > 0
                          ? () => handleUnfollowUser(profile?.followStatus[1])
                          : handleFollowUser
                      }
                      type="button"
                      className="btn btn-md btn-tertiary"
                    >
                      {(profile?.followStatus.length === 0 ||
                        profile?.followStatus[0] === 'DECLINED') &&
                        t('ProfilePage.ProfileDetails.buttons.follow.default')}
                      {profile?.followStatus[0] === 'APPROVED' &&
                        t('ProfilePage.ProfileDetails.buttons.follow.approved')}
                    </button>
                  </div>
                ) : (
                  <div className="controls-influencer">
                    <button
                      onClick={handleMessageBtnClick}
                      type="button"
                      className="btn btn-md btn-primary"
                      disabled={profile?.friendStatus[0] !== 'APPROVED'}
                    >
                      {t('ProfilePage.ProfileDetails.buttons.message')}
                    </button>
                    <button
                      onClick={() =>
                        handleAddFriend(profile?.friendStatus[0], profile?.friendStatus[1])
                      }
                      type="button"
                      className="btn btn-md btn-secondary"
                      disabled={
                        profile?.friendStatus.length > 0 &&
                        profile?.friendStatus[0] !== 'REQUESTED_OTHER'
                      }
                    >
                      {profile?.friendStatus.length === 0 &&
                        t('ProfilePage.ProfileDetails.buttons.connect.default')}
                      {profile?.friendStatus[0] === 'REQUESTED' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.requested')}
                      {profile?.friendStatus[0] === 'APPROVED' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.approved')}
                      {profile?.friendStatus[0] === 'REQUESTED_OTHER' &&
                        t('ProfilePage.ProfileDetails.buttons.connect.default')}
                    </button>
                    <button
                      onClick={
                        profile?.followStatus.length > 0
                          ? () => handleUnfollowUser(profile?.followStatus[1])
                          : handleFollowUser
                      }
                      type="button"
                      className="btn btn-md btn-tertiary"
                    >
                      {(profile?.followStatus.length === 0 ||
                        profile?.followStatus[0] === 'DECLINED') &&
                        t('ProfilePage.ProfileDetails.buttons.follow.default')}
                      {profile?.followStatus[0] === 'APPROVED' &&
                        t('ProfilePage.ProfileDetails.buttons.follow.approved')}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ProfileDetails.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.string,
    cover: PropTypes.string,
    companyName: PropTypes.string,
    role: PropTypes.string,
    fullName: PropTypes.string,
    industries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    location: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    bio: PropTypes.string,
    friendStatus: PropTypes.arrayOf(PropTypes.string),
    followStatus: PropTypes.arrayOf(PropTypes.string),
  }),
  isProfilePage: PropTypes.bool,
};

ProfileDetails.defaultProps = {
  profile: null,
  isProfilePage: false,
};

export default ProfileDetails;
