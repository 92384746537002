import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  LineElement,
  PieController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { selectors } from '../LogInPage/reducer';
import { selectors as initSelectors } from './reducer';
import { getMetricsByIdAction } from './actions';

import './DashboardPage.scss';

ChartJS.register(
  CategoryScale,
  PointElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  PieController,
  BarController,
);

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { socials } = useSelector(selectors.userData);
  const metricsData = useSelector(initSelectors.metricsData);
  const [followersCount, setFollowersCount] = useState(null);
  const [followsCount, setFollowsCount] = useState(null);
  const [mediaCount, setMediaCount] = useState(null);
  const [likeCount, setLikeCount] = useState(null);
  const [commentsCount, setCommentsCount] = useState(null);
  const [audienceLocale, setAudienceLocale] = useState(null);
  const [audienceCountry, setAudienceCountry] = useState(null);
  const [audienceCity, setAudienceCity] = useState(null);
  const [genderAge, setGenderAge] = useState(null);

  const getMetricsHandler = (id, params) => {
    dispatch(getMetricsByIdAction.request({ id, params }));
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    socials.map((account) => {
      const params = '?desc=createdAt';
      if (account.id) {
        getMetricsHandler(account.id, params);
      }
    });
  }, [socials]);

  useEffect(() => {
    if (metricsData) {
      // eslint-disable-next-line no-console
      console.log(metricsData, 'metricsData');
      setFollowersCount({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Followers count',
            },
          },
        },
        data: {
          labels: metricsData?.media_followers_count?.map((el) =>
            new Date(el.createdAt).toLocaleDateString(),
          ),
          datasets: [
            {
              label: 'Followers count',
              data: metricsData?.media_followers_count?.map((el) => el.values.followers_count),
              borderColor: 'tomato',
              backgroundColor: '#7F56D9',
              borderRadius: 10,
            },
          ],
        },
      });
      setFollowsCount({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Follows count',
            },
          },
        },
        data: {
          labels: metricsData?.media_followers_count?.map((el) =>
            new Date(el.createdAt).toLocaleDateString(),
          ),
          datasets: [
            {
              label: 'Follows count',
              data: metricsData?.media_followers_count?.map((el) => el.values.follows_count),
              borderColor: 'black',
              backgroundColor: '#7F56D9',
              borderRadius: 10,
            },
          ],
        },
      });
      setMediaCount({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Media followers count',
            },
          },
        },
        data: {
          labels: metricsData?.media_followers_count?.map((el) =>
            new Date(el.createdAt).toLocaleDateString(),
          ),
          datasets: [
            {
              label: 'Media',
              data: metricsData?.media_followers_count?.map((el) => el.values.media_count),
              backgroundColor: '#7F56D9',
              borderRadius: 10,
            },
          ],
        },
      });
      setLikeCount({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Likes count',
            },
          },
        },
        data: {
          labels: metricsData?.avg_like_comments_count?.map((el) =>
            new Date(el.createdAt).toLocaleDateString(),
          ),
          datasets: [
            {
              label: 'Likes',
              data: metricsData?.avg_like_comments_count?.map((el) => el?.values?.like_count),
              backgroundColor: '#7F56D9',
              borderRadius: 10,
            },
          ],
        },
      });
      setCommentsCount({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Comments count',
            },
          },
        },
        data: {
          labels: metricsData?.avg_like_comments_count?.map((el) =>
            new Date(el.createdAt).toLocaleDateString(),
          ),
          datasets: [
            {
              label: 'Comments',
              data: metricsData?.avg_like_comments_count?.map((el) => el?.values?.comments_count),
              backgroundColor: '#7F56D9',
              borderRadius: 10,
            },
          ],
        },
      });
      setAudienceCity({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Audience city',
            },
          },
        },
        data: {
          labels: Object.keys(metricsData?.audience_city?.[0]?.values),
          datasets: [
            {
              label: 'Media count',
              data: Object.values(metricsData?.audience_city?.[0]?.values),
              backgroundColor: ['#32D583', '#7A5AF8', '#EE46BC', '#53B1FD', '#FDB022'],
            },
          ],
        },
      });
      setAudienceCountry({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Audience country',
            },
          },
        },
        data: {
          labels: Object.keys(metricsData?.audience_country?.[0]?.values),
          datasets: [
            {
              label: 'Media count',
              data: Object.values(metricsData?.audience_country?.[0]?.values),
              backgroundColor: ['#32D583', '#7A5AF8', '#EE46BC', '#53B1FD', '#FDB022'],
            },
          ],
        },
      });
      setAudienceLocale({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Audience locale',
            },
          },
        },
        data: {
          labels: Object.keys(metricsData?.audience_locale?.[0]?.values),
          datasets: [
            {
              label: 'M',
              data: Object.values(metricsData?.audience_locale?.[0]?.values),
              backgroundColor: ['#32D583', '#7A5AF8', '#EE46BC', '#53B1FD', '#FDB022'],
            },
          ],
        },
      });
      setGenderAge({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Gender & Age',
            },
          },
        },
        data: {
          labels: Object.keys(metricsData?.audience_gender_age?.[0]?.values),
          datasets: [
            {
              label: 'M',
              data: Object.values(metricsData?.audience_gender_age?.[0]?.values),
              backgroundColor: ['#32D583', '#7A5AF8', '#EE46BC', '#53B1FD', '#FDB022'],
            },
          ],
        },
      });
    }
  }, [metricsData]);

  return (
    <div className="dashboardPage">
      <h1>{t('DashboardPage.title')}</h1>
      {/* {metricsData?.map((item) => ( */}
      {/*  <div key={item.id}> */}
      {/*    <h3>{item.name}</h3> */}
      {/*    /!* {item.values && item?.values.keys((value) => <p>{value}</p>)} *!/ */}
      {/*  </div> */}
      {/* ))} */}
      {metricsData && (
        <div className="chartsWrapper">
          {followersCount && (
            <div className="chartContainer">
              <Chart type="bar" data={followersCount?.data} options={followersCount?.options} />
            </div>
          )}
          {followsCount && (
            <div className="chartContainer">
              <Chart type="bar" data={followsCount?.data} options={followsCount?.options} />
            </div>
          )}
          {mediaCount && (
            <div className="chartContainer">
              <Chart type="bar" data={mediaCount?.data} options={mediaCount?.options} />
            </div>
          )}
          {likeCount && (
            <div className="chartContainer">
              <Chart type="bar" data={likeCount?.data} options={likeCount?.options} />
            </div>
          )}
          {commentsCount && (
            <div className="chartContainer">
              <Chart type="bar" data={commentsCount?.data} options={commentsCount?.options} />
            </div>
          )}
          {audienceCity && (
            <div className="chartContainer">
              <Chart type="doughnut" data={audienceCity?.data} options={audienceCity?.options} />
            </div>
          )}
          {audienceLocale && (
            <div className="chartContainer">
              <Chart
                type="doughnut"
                data={audienceLocale?.data}
                options={audienceLocale?.options}
              />
            </div>
          )}
          {audienceCountry && (
            <div className="chartContainer">
              <Chart
                type="doughnut"
                data={audienceCountry?.data}
                options={audienceCountry?.options}
              />
            </div>
          )}
          {genderAge && (
            <div className="chartContainer">
              <Chart type="doughnut" data={genderAge?.data} options={genderAge?.options} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
