import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { Rating } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import ReactSelect, { components } from 'react-select';
import Icon from 'components/Icon';
import * as yup from 'yup';

const { Option, SingleValue } = components;

const IconOption = (props) => {
  const { data } = props;

  return (
    // eslint-disable-next-line
    <Option {...props}>
      <div className="option--icon">
        <Icon icon={data.icon} />
      </div>
      <span className="option--text">{data.label}</span>
    </Option>
  );
};

const IconSingleValue = (props) => {
  const { data } = props;

  return (
    // eslint-disable-next-line
    <SingleValue {...props}>
      <div className="selected-option--icon">
        <Icon icon={data.icon} />
      </div>
      <span className="option--text">{data.label}</span>
    </SingleValue>
  );
};

const options = [
  { value: 'FACEBOOK', label: 'Facebook', icon: 'facebook' },
  { value: 'INSTAGRAM', label: 'Instagram', icon: 'instagram' },
];

const AddNewFeedbackModal = ({ isOpened, onCancel, onConfirm, recipientId }) => {
  const { t } = useTranslation();

  const [isBackdropAppeared, setIsBackdropAppeared] = useState(false);

  const handleOutsideCloseModal = (e) => {
    if (!e.target.closest('.info-modal')) {
      onCancel();
    }
  };

  const handleConfirmModal = (values) => {
    const newFeedback = {
      text: values.feedbackDesription,
      collaborated: values.hasColaborated,
      socialName: values.hasColaborated ? values.socialNetwork : null,
      availability: Number(values.ratingAvailability),
      deadline: Number(values.ratingDeadline),
      quality: Number(values.ratingQuality),
      satisfaction: Number(values.ratingSatisfaction),
      recipient: {
        id: recipientId,
      },
    };
    onConfirm(newFeedback);
  };

  return ReactDOM.createPortal(
    <CSSTransition
      in={isOpened}
      timeout={300}
      classNames="info-modal-backdrop-animation"
      unmountOnExit
      onEntering={() => setIsBackdropAppeared(true)}
      onExit={() => setIsBackdropAppeared(false)}
    >
      <div className="info-modal-backdrop" onClick={handleOutsideCloseModal}>
        <CSSTransition
          in={isBackdropAppeared}
          timeout={300}
          classNames="info-modal-animation"
          unmountOnExit
        >
          <div className="feedback-add-new modal info-modal">
            <Formik
              initialValues={{
                feedbackDesription: '',
                ratingDeadline: 0,
                ratingAvailability: 0,
                ratingQuality: 0,
                ratingSatisfaction: 0,
                socialNetwork: 'FACEBOOK',
                hasColaborated: true,
              }}
              validationSchema={yup.object().shape({
                feedbackDesription: yup.string().max(300).required(),
                ratingDeadline: yup.number().min(0.5).max(5).required(),
                ratingAvailability: yup.number().min(0.5).max(5).required(),
                ratingQuality: yup.number().min(0.5).max(5).required(),
                ratingSatisfaction: yup.number().min(0.5).max(5).required(),
              })}
              enableReinitialize
              onSubmit={handleConfirmModal}
            >
              {({ values, errors, handleChange, setFieldValue }) => (
                <Form>
                  <div className="info-modal-body">
                    <h4 className="info-modal--title">
                      {t('FeedbacksPage.leaveFeedback.modal.title')}
                    </h4>
                    <p className="info-modal--description">
                      {t('FeedbacksPage.leaveFeedback.modal.description')}
                    </p>
                    <div className="modal--collaboration">
                      <p>{t('FeedbacksPage.leaveFeedback.modal.collaboration.title')}</p>
                      <div className="modal--collaboration-radio">
                        <label>
                          <input
                            type="radio"
                            checked={values.hasColaborated}
                            onChange={() => setFieldValue('hasColaborated', true)}
                          />
                          {t('FeedbacksPage.leaveFeedback.modal.collaboration.yes')}
                        </label>
                        <label>
                          <input
                            type="radio"
                            checked={!values.hasColaborated}
                            onChange={() => setFieldValue('hasColaborated', false)}
                          />
                          {t('FeedbacksPage.leaveFeedback.modal.collaboration.no')}
                        </label>
                      </div>
                      {values.hasColaborated && (
                        <>
                          <p>
                            {t(
                              'FeedbacksPage.leaveFeedback.modal.collaboration.socialNetworkTitle',
                            )}
                          </p>
                          <div className="modal--collaboration-select">
                            <ReactSelect
                              className="modal--select"
                              classNamePrefix="collaboration-select"
                              isSearchable={false}
                              defaultValue={options[0]}
                              options={options}
                              components={{ Option: IconOption, SingleValue: IconSingleValue }}
                              onChange={(selectedOption) =>
                                setFieldValue('socialNetwork', selectedOption.value)
                              }
                              name="socialNetwork"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="modal--ratings">
                      <p>{t('FeedbacksPage.leaveFeedback.modal.ratings.title')}</p>
                      <div className="feedback-stats--categories">
                        <p className={`${errors.ratingAvailability ? 'state-danger' : ''}`}>
                          {t('FeedbacksPage.leaveFeedback.modal.ratings.availability')}
                        </p>
                        <div className="feedback-stats--rating">
                          <Rating
                            precision={0.5}
                            value={Number(values.ratingAvailability)}
                            onChange={handleChange}
                            name="ratingAvailability"
                          />
                          <p>{values.ratingAvailability}</p>
                        </div>
                      </div>
                      <div className="feedback-stats--categories">
                        <p className={`${errors.ratingDeadline ? 'state-danger' : ''}`}>
                          {t('FeedbacksPage.leaveFeedback.modal.ratings.deadline')}
                        </p>
                        <div className="feedback-stats--rating">
                          <Rating
                            precision={0.5}
                            value={Number(values.ratingDeadline)}
                            onChange={handleChange}
                            name="ratingDeadline"
                          />
                          <p>{values.ratingDeadline}</p>
                        </div>
                      </div>
                      <div className="feedback-stats--categories">
                        <p className={`${errors.ratingQuality ? 'state-danger' : ''}`}>
                          {t('FeedbacksPage.leaveFeedback.modal.ratings.quality')}
                        </p>
                        <div className="feedback-stats--rating">
                          <Rating
                            precision={0.5}
                            value={Number(values.ratingQuality)}
                            onChange={handleChange}
                            name="ratingQuality"
                          />
                          <p>{values.ratingQuality}</p>
                        </div>
                      </div>
                      <div className="feedback-stats--categories">
                        <p className={`${errors.ratingSatisfaction ? 'state-danger' : ''}`}>
                          {t('FeedbacksPage.leaveFeedback.modal.ratings.satisfaction')}
                        </p>
                        <div className="feedback-stats--rating">
                          <Rating
                            precision={0.5}
                            value={Number(values.ratingSatisfaction)}
                            onChange={handleChange}
                            name="ratingSatisfaction"
                          />
                          <p>{values.ratingSatisfaction}</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal--description">
                      <p>{t('FeedbacksPage.leaveFeedback.modal.leaveFeedback')}</p>
                      <Field
                        name="feedbackDesription"
                        value={values.feedbackDesription}
                        placeholder={t('FeedbacksPage.leaveFeedback.modal.leaveFeedback')}
                        as="textarea"
                        className={`${errors.feedbackDesription ? 'state-danger' : ''}`}
                      />
                    </div>
                  </div>
                  <div className="info-modal--footer">
                    <button
                      type="button"
                      className="btn btn-lg btn-secondary btn-secondary-gray w-50"
                      onClick={onCancel}
                    >
                      {t('modals.FEEDBACK_ADD_NEW.cancel')}
                    </button>
                    <button type="submit" className="btn btn-lg btn-primary w-50">
                      {t('modals.FEEDBACK_ADD_NEW.confirmBtn')}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>,
    document.getElementById('bup-app'),
  );
};

IconSingleValue.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

IconOption.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

export default AddNewFeedbackModal;
