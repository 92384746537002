import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserInfo from '../../../../components/UserInfo';

const NetworkSuggest = ({ influencers, companies, onClick }) => {
  const { t } = useTranslation();

  return (
    <>
      {influencers?.length ? (
        <div className="page--people">
          <h3 className="people--title">{t('NetworkPage.additionalContent.people.title')}</h3>
          <div className="people--body">
            {influencers?.map((u) => (
              <div className="people--row" key={u.id}>
                <UserInfo user={u} size="sm" />
                <button
                  type="button"
                  className="btn btn-secondary-gray btn-xs"
                  onClick={() => onClick(u.id)}
                >
                  {t('NetworkPage.additionalContent.people.buttons.follow')}
                </button>
              </div>
            ))}
          </div>
          <div className="people--footer">
            <Link to="/search/influencer" className="btn btn-link btn-md">
              {t('NetworkPage.additionalContent.people.buttons.seeAll')}
            </Link>
          </div>
        </div>
      ) : null}
      {companies?.length ? (
        <div className="page--people">
          <h3 className="people--title">
            {t('NetworkPage.additionalContent.people.companyTitle')}
          </h3>
          <div className="people--body">
            {companies?.map((u) => (
              <div className="people--row" key={u.id}>
                <UserInfo user={u} size="sm" />
                <button
                  type="button"
                  className="btn btn-secondary-gray btn-xs"
                  onClick={() => onClick(u.id)}
                >
                  {t('NetworkPage.additionalContent.people.buttons.follow')}
                </button>
              </div>
            ))}
          </div>
          <div className="people--footer">
            <Link to="/search/company" className="btn btn-link btn-md">
              {t('NetworkPage.additionalContent.people.buttons.seeAll')}
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
};

NetworkSuggest.propTypes = {
  influencers: PropTypes.arrayOf(PropTypes.shape({})),
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
};

NetworkSuggest.defaultProps = {
  influencers: [],
  companies: [],
  onClick: () => {},
};

export default NetworkSuggest;
