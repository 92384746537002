import React, { useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors } from '../../reducer';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import Heading from '../../../../components/Heading';
import Icon from '../../../../components/Icon';
import { SIGN_UP_STEPS } from '../../constants';

const EmailVerificationPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const signUpStatus = useSelector(selectors.signupPageStatus);
  const signUpData = useSelector(selectors.signupPageData);

  useEffect(() => {
    if (signUpStatus !== SIGN_UP_STEPS.emailVerification) {
      navigate('/auth');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="additional-page--wrapper">
      <Header />
      <div className="additional-page--form">
        <Heading
          subtitle="EmailVerificationPage.heading.subtitle"
          title="EmailVerificationPage.heading.title"
          subtext={signUpData.email}
          position="center"
        />
        <NavLink to="/signup/verification/confirm" className="btn btn-lg btn-primary">
          {t('EmailVerificationPage.buttons.enterCode')}
        </NavLink>
        <div className="form--footer">
          <Link to="/auth" className="link link-gray link-md">
            <Icon icon="arrow-left" className="link--icon" />
            {t('EmailVerificationPage.footer.link')}
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmailVerificationPage;
