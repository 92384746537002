/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncReducerHandler, asyncInitialState } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  ...asyncInitialState,
  influencers: asyncInitialState,
  companies: asyncInitialState,
  commentsForPost: asyncInitialState,
  followers: asyncInitialState,
};

const reducer = createReducer(initialState, {
  ...asyncReducerHandler(actions.getAllPostsAction),
  ...asyncReducerHandler(actions.createNewPostAction),
  [actions.createNewPostAction.ERROR]: (state, action) => {
    state.error = action.payload;
    state.loading = false;
  },
  [actions.deletePostAction.SUCCESS]: (state, action) => {
    state.data = state.data.filter((post) => post.id !== action.payload.id);
  },
  [actions.postLikeAction.REQUEST]: (state) => {
    state.error = null;
    state.loading = false;
  },
  [actions.postLikeAction.SUCCESS]: (state, { payload }) => {
    state.data[payload.index].isLike = true;
    state.data[payload.index].likesCount += 1;
    state.loading = false;
  },
  [actions.postLikeAction.ERROR]: (state, { payload }) => {
    state.error = payload;
    state.loading = false;
  },
  [actions.deleteLikeAction.REQUEST]: (state) => {
    state.error = null;
    state.loading = false;
  },
  [actions.deleteLikeAction.SUCCESS]: (state, { payload }) => {
    state.data[payload.index].isLike = false;
    state.data[payload.index].likesCount -= 1;
    state.loading = false;
  },
  [actions.deleteLikeAction.ERROR]: (state, { payload }) => {
    state.error = payload;
    state.loading = false;
  },
  ...asyncReducerHandler(actions.getCompaniesAction, 'companies'),
  ...asyncReducerHandler(actions.getInfluencersAction, 'influencers'),
  ...asyncReducerHandler(actions.getCommentsAction, 'commentsForPost'),
  [actions.getCommentsAction.SUCCESS]: (state, action) => {
    state.commentsForPost.loading = false;

    if (state.data) {
      const index = state.data.indexOf(state.data.find((el) => el.id === action.payload.postId));

      if (index > -1) {
        state.data = [
          ...state.data.slice(0, index),
          {
            ...state.data[index],
            comments: action.payload.comments,
            commentsCount: action.payload.comments.length,
          },
          ...state.data.slice(index + 1, state.data.length),
        ];
      }
    }
  },
  ...asyncReducerHandler(actions.getProfileFollowersAction, 'followers'),
});

const postsData = (state) => state.feedReducer.data;
const postsLoading = (state) => state.feedReducer.loading;

const companiesData = (state) => state.feedReducer.companies.data;
const companiesLoading = (state) => state.feedReducer.companies.loading;

const influencersData = (state) => state.feedReducer.influencers.data;
const influencersLoading = (state) => state.feedReducer.influencers.loading;

const commentsLoading = (state) => state.feedReducer.commentsForPost.loading;

const profileFollowersData = (state) => state.feedReducer.followers.data?.map((i) => i.acceptor.id);
const profileFollowersLoading = (state) => state.feedReducer.followers.loading;

const selectors = {
  postsLoading,
  postsData,
  companiesData,
  companiesLoading,
  influencersData,
  influencersLoading,
  commentsLoading,
  profileFollowersData,
  profileFollowersLoading,
};

export { initialState, reducer, selectors };
export default reducer;
