import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useChat from 'hooks/useChat';
import Icon from '../Icon';
import './ChatComponent.scss';
import { selectors } from '../../pages/ChatPage/reducer';
import ChatItem from './ChatItem';

const ChatComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const filteredChats = useSelector(selectors.getFilteredChats);
  const newMsgCount = useSelector(selectors.newMsgCount);
  useChat();

  const toggleOpen = () => setIsOpen(!isOpen);

  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  const chatsWithNewMessages = filteredChats?.filter((chat) => chat.newMessagesCount > 0) || [];

  return (
    <div className="chat">
      <div className="chat-head" onClick={toggleOpen}>
        <Icon icon="messages" />
        <p className="chat-title">Messages</p>
        {!!newMsgCount && (
          <p className={classNames('chat-title-count', isOpen && 'chat-title-count-open')}>
            {newMsgCount}
          </p>
        )}
        <Icon
          icon={isOpen ? 'chevron-down' : 'chevron-up'}
          stroke="#98A2B3"
          className="chat-open-icon"
        />
      </div>

      {isOpen &&
        chatsWithNewMessages?.length > 0 &&
        chatsWithNewMessages.map((chat) => <ChatItem key={chat.id} chat={chat} />)}

      {isOpen && chatsWithNewMessages?.length === 0 && (
        <p className="chat-no-new-messages">No new messages...</p>
      )}
    </div>
  );
};

export default ChatComponent;
