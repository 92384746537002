import { takeLatest, all, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import FeedbacksService from './service';

export function* getFeedbacksByIdSaga({ payload }) {
  try {
    const response = yield call(FeedbacksService.getFeedbacksById, payload);

    yield put(actions.getFeedbacksByIdAction.success(response.data.data));
  } catch (error) {
    yield put(actions.getFeedbacksByIdAction.error(error));
  }
}

export function* getStatsByIdSaga({ payload }) {
  try {
    const response = yield call(FeedbacksService.getStatsById, payload);

    yield put(actions.getStatsByIdAction.success(response.data.data));
  } catch (error) {
    yield put(actions.getStatsByIdAction.error(error));
  }
}

export function* postFeedbackSaga({ payload }) {
  try {
    const response = yield call(FeedbacksService.postFeedback, payload.data);

    yield put(actions.postFeedbackAction.success(response.data.data));

    yield payload.onSuccess();
  } catch (error) {
    yield put(actions.postFeedbackAction.error(error));
    yield payload.onError();
  }
}

export function* feedbacksPageWatcherSaga() {
  yield all([
    takeLatest(actions.getFeedbacksByIdAction.REQUEST, getFeedbacksByIdSaga),
    takeLatest(actions.getStatsByIdAction.REQUEST, getStatsByIdSaga),
    takeLatest(actions.postFeedbackAction.REQUEST, postFeedbackSaga),
  ]);
}
