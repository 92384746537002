import React from 'react';
import Icon from '../Icon';
import './Footer.scss';
import { SUPPORT_EMAIL } from '../../constants';

const Footer = () => (
  <div className="footer--wrapper">
    <p className="footer--copyright">©Bup 2023</p>
    <a href={`mailto:${SUPPORT_EMAIL}`} className="link link-md link-gray footer--mail-link">
      <Icon icon="mail" className="mail-link--icon" />
      {SUPPORT_EMAIL}
    </a>
  </div>
);

export default Footer;
