import * as yup from 'yup';
import {
  passwordValidationSchema,
  passwordConfirmValidationSchema,
} from '../../../../../../global/validators';

export const setNewPasswordValidationSchema = yup.object().shape({
  password: passwordValidationSchema,
  confirm_password: passwordConfirmValidationSchema,
});

export default setNewPasswordValidationSchema;
