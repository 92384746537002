import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import CompareService from './service';

export function* getUsersByComparisonSaga({ payload }) {
  try {
    const responses = yield all(payload.map((id) => call(CompareService.getUserById, id)));

    const mappedResponses = responses.map((response) => response.data.data);
    yield put(actions.getComparisonUsersDataAction.success(mappedResponses));
  } catch (error) {
    yield put(actions.getComparisonUsersDataAction.error(error));
  }
}

export function* comparePageWatcherSaga() {
  yield all([takeLatest(actions.getComparisonUsersDataAction.REQUEST, getUsersByComparisonSaga)]);
}
