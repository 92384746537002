const SOCIAL_NETWORKS = [
  {
    id: 1,
    to: '/signup/social/facebook',
    translate: 'AddSocialPage.buttons.facebook',
    icon: 'facebook',
  },
  {
    id: 2,
    to: '/signup/social/instagram',
    translate: 'AddSocialPage.buttons.instagram',
    icon: 'instagram',
  },
  {
    id: 3,
    to: '/signup/social/youtube',
    translate: 'AddSocialPage.buttons.youtube',
    icon: 'youtube',
  },
  {
    id: 4,
    to: '/signup/social/twitter',
    translate: 'AddSocialPage.buttons.twitter',
    icon: 'twitter',
  },
  {
    id: 5,
    to: '/signup/social/tiktok',
    translate: 'AddSocialPage.buttons.tiktok',
    icon: 'tiktok',
  },
];

export default SOCIAL_NETWORKS;
