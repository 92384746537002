import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Heading from 'components/Heading';
import './VerifiedPage.scss';

const VerifiedPage = () => {
  const { t } = useTranslation();
  return (
    <div className="verified-page--wrapper">
      <Header />
      <div className="verified-page--form">
        <Heading
          subtitle="Verified.heading.subtitle"
          title="Verified.heading.title"
          position="center"
        />
        <NavLink to="/auth" className="btn btn-lg btn-primary">
          {t('Verified.buttons.see')}
        </NavLink>
        <NavLink to="/auth" className="btn btn-lg btn-secondary">
          {t('Verified.buttons.add')}
        </NavLink>
      </div>
      <Footer />
    </div>
  );
};

export default VerifiedPage;
