import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ChatPage.scss';
import ContentLoader from 'components/ContentLoader';
import useChat from 'hooks/useChat';
import { selectors } from './reducer';
import {
  changeFilterSuccess,
  getAllChatsAction,
  getChatMessagesAction,
  getNewMsgCountAction,
} from './actions';
import MessageForm from './components/MessageForm';
import NoMessageItem from './components/NoMessageItem';
import ChatItem from './components/ChatItem';
import ChatList from './components/ChatList';

const ChatPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [chatParticipant, setChatParticipant] = useState(null);
  const chatListLoading = useSelector(selectors.chatsLoading);
  const chatMessages = useSelector(selectors.chatMessages);
  const filteredChats = useSelector(selectors.getFilteredChats);
  const isFilesUploading = useSelector(selectors.isFilesUploading);
  const [activeChatId, setActiveChatId] = useState('');
  const chatWindowRef = useRef(null);
  const [replyMessage, setReplyMessage] = useState(null);
  const [shouldScroll, setShouldScroll] = useState(true);

  useEffect(() => {
    dispatch(getNewMsgCountAction.request());
    dispatch(getAllChatsAction.request());
    return () => {
      dispatch(getChatMessagesAction.success([]));
    };
  }, []);

  const onChange = (value) => {
    dispatch(changeFilterSuccess(value));
  };

  const { sendMessage, readMessage } = useChat(activeChatId);

  const scrollToBottom = () => {
    if (chatMessages) {
      const firstUnreadedMsg = chatMessages.find(
        (message) => message.status === 'SENT' && message.owner.id === chatParticipant?.id,
      );
      if (firstUnreadedMsg) {
        const element = document.getElementById(firstUnreadedMsg.id);
        if (element) {
          element.scrollIntoView({ block: 'end', behavior: 'auto' });
        }
      } else {
        const element = document.getElementById('end');
        if (element) {
          element.scrollIntoView({ behavior: 'auto' });
        }
      }
    }
  };

  useEffect(() => {
    if (shouldScroll && chatMessages && chatMessages.length) {
      setShouldScroll(false);
      scrollToBottom();
    }
  }, [chatMessages]);

  useEffect(() => {
    if (!shouldScroll && chatMessages && chatMessages.length) {
      if (
        chatMessages.filter((m) => m.status === 'SENT' && m.owner.id === chatParticipant.id)
          .length < 2
      ) {
        scrollToBottom();
      }
    }
  }, [chatMessages]);

  const handleChatClick = (chat) => {
    if (chatParticipant && chatParticipant.id === chat?.participant?.id) {
      dispatch(getChatMessagesAction.success(null));
      setChatParticipant(null);
      setActiveChatId('');
      setReplyMessage(null);
      setShouldScroll(true);
    } else {
      setChatParticipant(chat.participant);
      dispatch(getChatMessagesAction.request(chat.id));
      setActiveChatId(chat.id);
    }
  };

  useEffect(() => {
    if (location?.state?.chatId) {
      const foundChat = filteredChats.find(
        (chat) => chat?.participant?.id === location.state.chatId,
      );
      if (foundChat) handleChatClick(foundChat);
    }
  }, []);

  const handleReplyMessage = (id) => {
    const foundMessage = chatMessages.find((message) => message.id === id);
    setReplyMessage(foundMessage);
  };

  const handleReadMessage = (messageId) => {
    readMessage(messageId, activeChatId);
  };

  return (
    <div className="chat-page--wrapper">
      <h2 className="chat-page--heading">{t('ChatPage.heading.title')}</h2>
      {chatListLoading ? (
        <ContentLoader visible={chatListLoading} />
      ) : (
        <div className="page-content--wrapper">
          <ChatList
            onChange={onChange}
            handleChatClick={handleChatClick}
            chatParticipant={chatParticipant}
          />
          <div className="chat-container">
            <div className="messages--wrapper">
              {!chatParticipant ? (
                <NoMessageItem />
              ) : (
                <ChatItem
                  chatParticipant={chatParticipant}
                  chatWindowRef={chatWindowRef}
                  handleReplyMessage={handleReplyMessage}
                  handleReadMessage={handleReadMessage}
                />
              )}
            </div>
            <MessageForm
              sendMessage={sendMessage}
              chatId={activeChatId}
              replyMessage={replyMessage}
              setReplyMessage={setReplyMessage}
              isFilesUploading={isFilesUploading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatPage;
