import Icon from 'components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContentLoader from 'components/ContentLoader';
import NotificationItem from './components/NotificationItem';
import './NotificationsPage.scss';
import { selectors as mainSelectors } from '../MainPage/reducer';

const NotificationsPage = () => {
  const { t } = useTranslation();

  const notificationsData = useSelector(mainSelectors.notificationsData);
  const notificationsLoading = useSelector(mainSelectors.notificationsLoading);

  return (
    <div className="notifications-page--wrapper">
      {notificationsLoading ? (
        <ContentLoader visible={notificationsLoading} />
      ) : (
        <>
          <Link to="/feed" className="notifications-page--link-back">
            <Icon icon="arrow-left-2" />
            {t('NotificationsPage.linkBack')}
          </Link>
          <div className="app-pages--content">
            <div className="content--main">
              <div className="notifications-page--header header">
                <h2 className="header--title">{t('NotificationsPage.title')}</h2>
                <button type="button" className="header--control-btn">
                  <Icon icon="action" className="control-btn--icon" />
                </button>
              </div>
              {notificationsData?.map((item) => (
                <NotificationItem key={item.id} notification={item} />
              ))}
            </div>
            <div className="content--additional" />
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationsPage;
