import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Icon from '../../components/Icon';

const MobileDeviceAlertPage = () => {
  const { t } = useTranslation();

  return (
    <div className="additional-page--wrapper">
      <Header />
      <div className="additional-page--form" style={{ maxWidth: 700 }}>
        <div className="heading--wrapper align-center">
          <Icon icon="logo" className="heading--logo" />
          <h2 className="heading--title">{t('MobileDeviceAlertPage.title')}</h2>
          <p className="heading--subtitle" style={{ textAlign: 'left' }}>
            {t('MobileDeviceAlertPage.message.part 1')}
          </p>
          <p className="heading--subtitle" style={{ textAlign: 'left' }}>
            {t('MobileDeviceAlertPage.message.part 2')}
          </p>
          <p className="heading--subtitle" style={{ textAlign: 'left' }}>
            {t('MobileDeviceAlertPage.message.part 3')}
          </p>
          <p className="heading--subtitle" style={{ textAlign: 'left' }}>
            {t('MobileDeviceAlertPage.message.part 4')}
          </p>
          <p className="heading--subtitle" style={{ textAlign: 'left' }}>
            <span>{t('MobileDeviceAlertPage.message.best regards')},</span>
            <br />
            <span>{t('MobileDeviceAlertPage.message.team')}</span>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MobileDeviceAlertPage;
