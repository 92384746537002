import { getValidValueForBackend } from '../../global/helpers';
import ROLES from '../../constants';

export const mapValuesForUpdateProfileDetails = (profile, values) => {
  const data = {
    fullName: getValidValueForBackend(values.fullName),
    location: {
      id: values.location.value,
    },
  };

  if (values.avatar && values.avatar !== profile.cover) {
    const formData = new FormData();
    formData.append('file', values.avatar);
    data.avatar = formData;
  }

  if (profile.role === ROLES.company) {
    return {
      ...data,
      companyName: getValidValueForBackend(values.companyName),
      businessType: {
        id: values.businessType.value,
      },
      website: getValidValueForBackend(values.website),
      positionInCompany: getValidValueForBackend(values.positionInCompany),
    };
  }

  return {
    ...data,
    gender: values.gender.value,
    dob: values.birthdayDate ? new Date(values.birthdayDate).toISOString() : null,
  };
};

export default mapValuesForUpdateProfileDetails;
