import { createAction } from '@reduxjs/toolkit';
import { asyncActionCreator } from '../../global/redux';
import * as constants from './constants';

export const getUserProfileAction = asyncActionCreator(constants.GET_USER_PROFILE);
export const getFormMetadataAction = asyncActionCreator(constants.GET_FORM_METADATA);
export const getUsersBySearchAction = asyncActionCreator(constants.GET_USERS_BY_SEARCH);
export const clearSearchDataAction = createAction(constants.CLEAR_SEARCH_DATA);

export const getNotificationsAction = asyncActionCreator(constants.GET_NOTIFICATIONS);

export const getFromFavorite = asyncActionCreator(constants.GET_FAVORITE);
export const addToFavorite = asyncActionCreator(constants.ADD_TO_FAVORITE);
export const deleteFormFavorite = asyncActionCreator(constants.DELETE_FROM_FAVORITE);
export const setFavoriteListLoading = createAction(constants.FAVORITE_LIST_LOADING);

export const addToComparison = createAction(constants.ADD_TO_COMPARISON);
export const deleteFromComparison = createAction(constants.DELETE_FROM_COMPARISON);
export const clearComparisonList = createAction(constants.CLEAR_COMPARISON_LIST);
