import { createAction } from '@reduxjs/toolkit';
import * as constants from './constants';

export const openInfoModalWindow = createAction(constants.OPEN_INFO_MODAL_WINDOW);
export const closeInfoModalWindow = createAction(constants.CLOSE_INFO_MODAL_WINDOW);
export const setInitPath = createAction(constants.SET_INIT_PATH);

export const addAlertAction = createAction(constants.ADD_ALERT);
export const removeAlertAction = createAction(constants.REMOVE_ALERT);
export const clearAlertsAction = createAction(constants.CLEAR_ALERTS);
