import { takeLatest, all, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import SearchService from './service';
import { mapUsersByRole } from './helpers';

export function* getUsersBySearchParamsSaga({ payload }) {
  try {
    const response = yield call(SearchService.getUsersBySearch, payload);
    yield put(
      actions.getUsersBySearchParamsAction.success(mapUsersByRole(response.data.data.result)),
    );
  } catch (error) {
    yield put(actions.getUsersBySearchParamsAction.error(error));
  }
}

export function* getCompaniesBySearchSaga({ payload }) {
  try {
    const response = yield call(SearchService.getCompaniesBySearch, payload);

    yield put(actions.getCompaniesBySearchParamsAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.getCompaniesBySearchParamsAction.error(error));
  }
}

export function* getInfluencersBySearchSaga({ payload }) {
  try {
    const response = yield call(SearchService.getInfluencersBySearch, payload);

    yield put(actions.getInfluencersBySearchParamsAction.success(response.data.data.result));
  } catch (error) {
    yield put(actions.getInfluencersBySearchParamsAction.error(error));
  }
}

export function* getProfileFollowersSaga({ payload }) {
  try {
    const response = yield call(SearchService.getProfileFollowersApproved, payload);
    // const responseRequested = yield call(FeedService.getProfileFollowersRequested, payload);

    yield put(
      actions.getFollowersAction.success([
        ...response.data.data.result,
        // ...responseRequested.data.data.result,
      ]),
    );
  } catch (error) {
    yield put(actions.getFollowersAction.error(error));
  }
}

export function* followCompanySaga({ payload }) {
  try {
    yield call(SearchService.followUser, payload.userId);

    yield put(actions.followCompanyAction.success());
    yield put(actions.getFollowersAction.request(payload.profileId));
  } catch (error) {
    yield put(actions.followCompanyAction.error(error));
  }
}

export function* searchPageWatcherSaga() {
  yield all([
    takeLatest(actions.getUsersBySearchParamsAction.REQUEST, getUsersBySearchParamsSaga),
    takeLatest(actions.getCompaniesBySearchParamsAction.REQUEST, getCompaniesBySearchSaga),
    takeLatest(actions.getInfluencersBySearchParamsAction.REQUEST, getInfluencersBySearchSaga),
    takeLatest(actions.getFollowersAction.REQUEST, getProfileFollowersSaga),
    takeLatest(actions.followCompanyAction.REQUEST, followCompanySaga),
  ]);
}
