import { useEffect, useCallback, useState } from 'react';

const useContextMenu = (parentClassName) => {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const [evt, setEvt] = useState(null);

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      setEvt(event);
      setAnchorPoint({ x: event.pageX, y: event.pageY });
      if (event.target && event.target.closest(parentClassName)) {
        setShow(true);
      } else {
        setShow(false);
      }
    },
    [setShow, setAnchorPoint, setEvt],
  );

  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);
  const chatWindow = document.getElementById('chatWindow');
  useEffect(() => {
    document.addEventListener('click', handleClick);
    if (chatWindow) {
      chatWindow.addEventListener('contextmenu', handleContextMenu);
    }
    return () => {
      document.removeEventListener('click', handleClick);
      if (chatWindow) {
        chatWindow.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  });
  return { anchorPoint, show, evt };
};

export default useContextMenu;
